<template>
  <div>
    <!-- Dashboard Headline -->
    <div class="row">
      <div class="container my_new_container">
        <div class="dashboard-headline">
          <h1>Checkout</h1>
        </div>
      </div>
    </div>

    <!-- Page Content
        ================================================== -->
    <div class="row">
      <div class="col-xl-8">
        <div class="col-xl-12 pdng_zero">
          <!-- <div class="dashboard-box margin-top-0">
                            <div class="single_accordian  accordion-listing-box coloured_icon coins_purchase_acc">
                            <b-card>
                                <b-card-header header-tag="header" role="tab">
                                    <b-button v-b-toggle="'coins_purchase_accordion'" class="m-1 el_toggle_btn" variant="info">
                                        <div class="dashboard-box">
                                            <div class="headline">
                                                <h3>What is EL Coins</h3>
                                            </div>
                                        </div>
                                    </b-button>
                                </b-card-header>
                                <b-collapse :id="'coins_purchase_accordion'" accordion="my-accordion" role="tabpanel">
                                <b-card-body class="inner_card_bd">
                                    <p class="desktop_margin_bottom_zero">Coins on our platform are digital tokens that users can earn or purchase to facilitate the posting of full-time job listings. These tokens act as credits, enabling users to access and utilize our job posting services.</p>
                                    <div class="middle_coins_section">
                                        <p class="desktop_margin_bottom_zero">With EL Coins:</p>
                                        <ul>
                                            <li>You can post full-time job posting</li>
                                            <li>Bump your posts to get them back on top of feeds for more exposure.</li>
                                            <li>Spotlight your content to make it stand out.</li>
                                        </ul>
                                    </div>
                                    <p class="desktop_margin_bottom_zero">It's a convenient way to enhance your presence and reach your audience effectively.</p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            </div>

                        </div> -->
          <div class="col-xl-12 pdng_zero">
            <div class="dashboard-box margin-top-0">
              <!-- Headline -->
              <!-- <div class="headline">
                                <h1 class="el_coin_h1"><i class="icon-material-outline-account-circle"></i> EL Coins</h1>
                            </div> -->

              <!-- <div class="headline">
                                <h3>What is EL Coins</h3>
                            </div> -->
              <div
                class="single_accordian accordion-listing-box coloured_icon coins_purchase_acc full_custom_accordian"
              >
                <b-card-header header-tag="header" role="tab">
                  <!-- <header class="card-header"> -->
                  <b-button
                    v-b-toggle="'coins-accordian'"
                    class="m-1 el_toggle_btn custom_accordian_button"
                    variant="info"
                  >
                    <div class="dashboard-box">
                      <div class="headline">
                        <h3>What is Job Ads</h3>
                      </div>
                    </div>
                  </b-button>
                  <!-- </header> -->
                </b-card-header>
                <b-collapse :id="'coins-accordian'" accordion="my-accordion" role="tabpanel">
                  <div class="custom_inner_card">
                    <b-card-body class="inner_card_bd">
                      <p class="desktop_margin_bottom_zero">
                        Job Ads on our platform are digital tokens that users can purchase to
                        facilitate the posting of full-time job listings.
                      </p>
                      <div class="middle_coins_section">
                        <p class="desktop_margin_bottom_zero">With Job Ads, you can:</p>
                        <ul>
                          <li>Post full-time job listings.</li>
                          <li>
                            Bump your posts to bring them back to the top of feeds for increased
                            exposure.
                          </li>
                          <li>Spotlight your content to make it stand out.</li>
                        </ul>
                      </div>
                      <p class="desktop_margin_bottom_zero">
                        These tokens serve as credits, enabling users to access and utilize our job
                        posting services while enhancing your posting presence and effectively
                        reaching our audience.
                      </p>
                    </b-card-body>
                  </div>
                </b-collapse>
              </div>

              <!-- <div class="headline">
                                <h4>El Coins are the coins used to purchase package</h4>
                            </div> -->
            </div>
          </div>
        </div>
        <div class="col-xl-12 pdng_zero package_choose_section">
          <div class="row">
            <div class="col-xl-12">
              <div class="dashboard-headline common_coins_heading">
                <h1>
                  1. CHOOSE
                  <span id="headingText" style="color: #4c8bf5; font-weight: 600; font-size: 15px">
                    (Enjoy 2 complimentary Jobs Ads posting every month.)</span
                  >
                  <span id="headingText2" style="color: #4c8bf5; font-weight: 600; font-size: 15px">
                    (Enjoy 2 complimentary Jobs Ads posting every month.)</span
                  >
                </h1>
              </div>
              <div class="dashboard-box margin-top-0">
                <!-- Headline -->
                <div class="headline col-xl-6">
                  <h3 class="el_coin_h1">Job Ads Package</h3>
                  <div id="test1" class="dashboard-box desktop_margin_top_zero">
                    <!-- Headline -->
                    <div class="">
                      <!-- <div v-for="coin in coins">
                                                    <input type="radio"  name="coinss" :value="coin.id" v-model="coinData" @change="onChange(coin.id)">{{coin.coin}} El Coins <span style="color:#4c8bf5" v-if="coin.discount != null"></span>
                                                    <span class="coin_rate" v-if="coin.value_in_dollar != null"> ${{coin.value_in_dollar}}</span>
                                                </div> -->
                      <b-form-select
                        v-model="coinData"
                        id="industry"
                        class="web_bottom_zero"
                        v-on:change="getElCoinsById"
                      >
                        <option value="" disabled>Select Job Ads Package</option>
                        <option :value="coin.id" v-for="coin in coins">
                          ${{ coin.coin }}
                          <span v-if="coin.discount != 0"> ({{ coin.discount }}% off ) </span>
                          - {{ coin.no_of_post }} Ads Posting
                        </option>
                      </b-form-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 pdng_zero billing_form_section" id="billing" style="display: none">
          <div class="row">
            <div class="col-xl-12">
              <div class="dashboard-headline common_coins_heading">
                <h1>2. BILLING ADDRESS</h1>
                <span style="color: red">(All fields are mandatory.)</span>
              </div>
              <div class="dashboard-box margin-top-0 form_deails">
                <div class="row">
                  <div class="col-xl-6 submit-field">
                    <h5>Full Name</h5>
                    <input
                      type="text"
                      id="first_name"
                      class="with-border form-control required-field"
                      v-model="name"
                      :disabled="billing.length > 0"
                    />
                  </div>
                  <div class="col-xl-6 submit-field">
                    <h5>Email</h5>
                    <input
                      type="text"
                      id="first_name"
                      class="with-border form-control required-field"
                      v-model="email"
                      :disabled="billing.length > 0"
                    />
                  </div>
                  <div class="col-xl-6 submit-field">
                    <h5>Mobile Number</h5>
                    <input
                      type="text"
                      id="first_name"
                      class="with-border form-control required-field"
                      v-model="phone"
                      :disabled="billing.length > 0"
                    />
                  </div>
                  <div class="col-xl-6 submit-field">
                    <h5>Postal Code</h5>
                    <input
                      type="text"
                      id="first_name"
                      class="with-border form-control required-field"
                      v-model="code"
                      :disabled="billing.length > 0"
                    />
                  </div>
                  <div class="col-xl-12 submit-field">
                    <h5>Address</h5>
                    <input
                      type="text"
                      id="first_name"
                      class="with-border form-control required-field"
                      v-model="address"
                      :disabled="billing.length > 0"
                    />
                  </div>
                  <div class="col-xl-6 submit-field">
                    <h5>City</h5>
                    <input
                      type="text"
                      id="first_name"
                      class="with-border form-control required-field"
                      v-model="city"
                      :disabled="billing.length > 0"
                    />
                  </div>
                  <div class="col-xl-6 submit-field">
                    <h5>Country</h5>
                    <b-form-select
                      v-model="country"
                      id="industry"
                      class="web_bottom_zero"
                      :disabled="billing.length > 0"
                    >
                      <option value="" disabled>Select Country</option>
                      <option :value="country.id" v-for="country in countries">
                        {{ country.country_name }}
                      </option>
                    </b-form-select>
                  </div>
                  <div class="col-md-12 save_later_check" v-if="billing.length > 0">
                    <div class="input_divs">
                      <b-button class="btn points_events save_leter" id="" @click="editbilling"
                        ><i class="fa fa-edit"></i
                      ></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 pdng_zero package_choose_section" id="card" style="display: none">
          <div class="row">
            <div class="col-xl-12">
              <stripe-checkout
                ref="checkoutRef"
                mode="payment"
                :pk="publishableKey"
                :line-items="lineItems"
                :success-url="successURL"
                :cancel-url="cancelURL"
                @loading="(v) => (loading = v)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4" v-if="elcoinDatabyid.length > 0">
        <!-- Summary -->
        <div class="boxed-widget summary margin-top-0 side_bar_payment summary_border">
          <div class="boxed-widget-headline">
            <h3>Summary</h3>
          </div>
          <div class="boxed-widget-inner">
            <ul>
              <li>
                {{ packName }} <span>${{ parseFloat(elcoinDatabyid[0].coin).toFixed(2) }}</span>
              </li>
              <li>
                *** Free
                {{ elcoinDatabyid[0].featured_jobs ? elcoinDatabyid[0].featured_jobs : 0 }} Featured
                Post: <span> $ 0.00</span>
              </li>
              <li>
                Discount <span>{{ elcoinDatabyid[0].discount }}%</span>
              </li>

              <li>
                GST ({{ settings[0].option_value }}%)
                <span
                  >${{
                    parseFloat(
                      (elcoinDatabyid[0].value_in_dollar * settings[0].option_value) / 100
                    ).toFixed(2)
                  }}</span
                >
              </li>
              <li class="total-costs">
                Final Price <span>${{ parseFloat(total_amount).toFixed(2) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- Summary / End -->

        <!-- Checkbox -->
        <!-- <div class="checkbox margin-top-30">
                            <input type="checkbox" id="two-step">
                            <label for="two-step"><span class="checkbox-icon"></span>  I agree to the <a href="#">Terms and Conditions</a> and the <a href="#">Automatic Renewal Terms</a></label>
                        </div> -->
      </div>
      <div class="col-xl-12 mobile_btn">
        <hr class="payment_hr" />
        <div class="row">
          <!--     <div class="col-xl-2 col-md-2">
            <a
              href="#"
              @click="back"
              class="desktop_margin_top_zero desktop_margin_bottom_zero button big ripple-effect margin-top-40 margin-bottom-65 mb_btn"
              style="color: black; background-color: #bebbbb; margin-right: 20px"
              >Back</a
            >
          </div> -->
          <div class="col-xl-10 col-md-10 proceed_btn_and_text desktop_proceed_section">
            <span
              ><i class="material-icons-outlined">lock_outlined</i>Payments are safe and
              secure</span
            ><a
              href="#"
              @click="submit()"
              class="proceed_btn_payment desktop_margin_top_zero desktop_margin_bottom_zero button big ripple-effect margin-top-40 margin-bottom-65"
              >Proceed Payment</a
            >
          </div>
          <div class="col-xl-10 col-md-10 proceed_btn_and_text mobile_proceed_section">
            <a
              href="#"
              @click="submit()"
              class="proceed_btn_payment desktop_margin_top_zero desktop_margin_bottom_zero button big ripple-effect margin-top-40 margin-bottom-65"
              >Proceed Payment</a
            >
          </div>
          <div class="col-xl-12 col-md-12 proceed_btn_and_text mobile_info_secure">
            <span
              ><i class="material-icons-outlined">lock_outlined</i>Payments are safe and
              secure</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <a  v-if="page  < 3" href="#" @click="nextStep" class="button big ripple-effect margin-top-40 margin-bottom-65 mb_btn mobile_last_btn_mrgn">Purchase EL Coins</a> -->
  </div>
</template>

<script>
import { POST_API } from "../../store/actions.type";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";

export default {
  data: () => {
    console.log(
      process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      "jjjj",
      process.env.VUE_APP_URL,
      process.env.APP_SERVER_URL
    );

    return {
      webUrl: process.env.VUE_APP_URL,
      formPosition: 0,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      page: 1,
      coins: "",
      coinData: "",
      packageData: "",
      packageDataName: "",
      total_amount: 0,
      loading: false,
      cardId: "",
      cardHolderName: "",
      cardNumber: "",
      billingAdd: "",
      billing: [],
      expiryMonth: "",
      expire: "",
      expiryYear: "",
      cvv: "2222",
      packName: "",
      name: "",
      email: "",
      phone: "",
      code: "",
      address: "",
      card: "",
      address2: "",
      token: "",
      city: "",
      country: "",
      elcoinDatabyid: "",
      settings: "",
      countries: "",
      saveLater: "",
      saveLater1: "",
      companyName: "",
      loader: "",
      duration_mn: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      loading: false,
      lineItems: [
        {
          // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      webUrl: process.env.VUE_APP_URL,
      successURL: process.env.VUE_APP_URL + "v2/employer/elcoins?session_id={CHECKOUT_SESSION_ID}",
      cancelURL: process.env.VUE_APP_URL + "v2/employer/elcoins",
    };
  },
  components: {
    Datepicker,
    StripeCheckout,
  },
  methods: {
    
        nextStep() {
      this.page = this.page + 1;
    },

    back() {
      this.page = this.page - 1;
    },
    customFormatterTwo(date) {
      return moment(date).format("MMMM/YYYY");
    },

    onChangeMonth(id) {
      if (id == "month") {
        $("#month-warning-message").hide();
        $("#month").removeClass("invalid");
      } else {
        $("#year-warning-message").hide();
        $("#year").removeClass("invalid");
      }
    },
    async submit() {
      if (this.coinData == "") {
        this.$swal({
          position: "center",
          // icon: 'error',
          imageUrl: "/assets/images/404_elcy.gif",
          customClass: {
            container: "mascot_error_container",
          },
          title: "Please select Job Ads.",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (this.name == "" || this.email == "" || this.address == "" || this.city == "") {
        this.$swal({
          position: "center",
          // icon: 'error',
          imageUrl: "/assets/images/404_elcy.gif",
          customClass: {
            container: "mascot_error_container",
          },
          title: "Please Fill Billing Address.",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$store
          .dispatch(POST_API, {
            data: {
              user_id: this.company_admin_id ? this.company_admin_id : this.user_id,
              email: this.email,
              fullname: this.name,
              phone: this.phone,
              code: this.code,
              address: this.address,
              address2: this.address2,
              city: this.city,
              country: this.country,
              company_id: this.company_id,
            },
            api: "/api/storeBillAdd",
          })
          .then(() => {
            this.$refs.checkoutRef.redirectToCheckout();
            this.success_url =
              process.env.VUE_APP_URL +
              "/v2/employer/elcoins?session_id={CHECKOUT_SESSION_ID}&coin_id=" +
              this.coinData;
          });
      }

      // const amount = 40;
      // const response = await axios.post('http://localhost:3000/create-payment-intent', { amount });
      // var product = await axios.post('http://localhost:3000/create-product');

      // this.pid = response.data;
      // You will be redirected to Stripe's secure checkout page
    },

    async sessionData() {
      const response = "";

      console.log(this.$route.query.session_id);

      if (this.$route.query.session_id) {
        const response = await axios.get(
          process.env.VUE_APP_SERVER_URL +
            "/api/order/success?session_id=" +
            this.$route.query.session_id
        );
        if (response) {
          this.elcoinDatabyid = JSON.parse(localStorage.getItem("elcoinsData"));
          console.log("ghhhhhhh", this.elcoinDatabyid);

          const percent =
            (this.elcoinDatabyid[0].value_in_dollar * this.settings[0].option_value) / 100;

          this.total_amount = percent + parseFloat(this.elcoinDatabyid[0].value_in_dollar);
          this.coinData = parseInt(this.elcoinDatabyid[0].id);

          this.makePayment1(response);
        }
      }

      console.log(response);
    },

    getElCoins() {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/elCoins",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.coins = this.$store.getters.getResults.data;
            // this.coinData =  this.coins[0].id,
            // this.packageData = this.coins[0].id,
            // this.packName = this.coins[0].package_name;

            // this.getElCoinsById();
          }
        });
    },

    getElCoinsById() {
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.coinData,
          },
          api: "/api/elCoinsById",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            $("#billing").show();
            $("#card").show();

            this.elcoinDatabyid = this.$store.getters.getResults.data;

            localStorage.setItem("elcoinsData", JSON.stringify(this.elcoinDatabyid));

            this.packageDataName = this.elcoinDatabyid[0].coin + " Coins";
            this.packName = this.elcoinDatabyid[0].package_name;

            this.lineItems[0].price = this.elcoinDatabyid[0].price_id;

            const percent =
              (this.elcoinDatabyid[0].value_in_dollar * this.settings[0].option_value) / 100;

            this.total_amount = percent + parseFloat(this.elcoinDatabyid[0].value_in_dollar);
          }
        });
    },
    getCompanyNameById() {
      this.$store
        .dispatch(POST_API, {
          data: {
            company_id: this.company_id, 
          },
          api: "/api/company-name", 
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.companyData = this.$store.getters.getResults.data;
            if (this.companyData) {
              this.companyName = this.companyData.name; 
            }
          }
        });
    },
    showAlert() {
      this.$swal({
        position: "center",
        // icon: 'error',
        imageUrl: "/assets/images/404_elcy.gif",
        customClass: {
          container: "mascot_error_container",
        },
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },

    makePayment1(res) {
      this.packName = this.elcoinDatabyid[0].package_name;

      this.$store
        .dispatch(POST_API, {
          data: {
            data: JSON.stringify(res.data),
            name: res.data.cust_name,
            charge_id: res.data.id,

            number: res.data.payment_method_details.card.last4,
            expiryMonth: moment(res.data.payment_method_details.card.exp_month).format("M"),
            expiryYear: moment(res.data.payment_method_details.card.exp_year).format("YYYY"),
            cvv: this.cvv,
            user_id: this.company_admin_id ? this.company_admin_id : this.user_id,
            email: this.email,
            fullname: this.companyName,
            phone: this.phone,
            code: this.code,
            address: this.address,
            address2: this.address2,
            city: this.city,
            country: this.country,
            company_id: this.company_id,
            coinData: this.coinData,
            amount: this.elcoinDatabyid[0].value_in_dollar,
            total_coins: this.elcoinDatabyid[0].coin,
            discount: this.elcoinDatabyid[0].discount,
            featured_jobs: this.elcoinDatabyid[0].featured_jobs,
            no_of_post: this.elcoinDatabyid[0].no_of_post,
            pdf_amount: parseFloat(this.elcoinDatabyid[0].value_in_dollar).toFixed(3),
            pdf_gst_percent: this.settings[0].option_value,
            pdf_gst: parseFloat(
              (this.elcoinDatabyid[0].value_in_dollar * this.settings[0].option_value) / 100
            ).toFixed(3),
            pdf_total_amount: parseFloat(this.total_amount).toFixed(3),
            token: this.token,
            saveLater: this.saveLater,
            saveLater1: this.saveLater1,
            package_name: this.packName,
          },
          api: "/api/makePayment",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            this.loading = false;

            return [];
          } else {
            this.loading = false;
            var message = this.$store.getters.getResults.message;

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: message,
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              console.log(this.$store.getters.getResults.data);

              // window.open(this.$store.getters.getResults.data);
              window.location.replace("/v2/employer/elcoins");
            });
          }
        });
    },

    makePayment() {
      console.log(moment(this.expire).format("YYYY"), moment(this.expire).format("M"));

      this.loading = true;
      this.$swal({
        title: "Payment Confirmation",
        html:
          "Please review your information below.<br/>" +
          '<center><p class="middle_para_job"><b>$' +
          parseFloat(this.total_amount).toFixed(3) +
          "</b> will be charged to your credit card <b>",
        type: "success",
        icon: "success",
        iconColor: "#4c8bf5",
        // imageUrl: "/assets/images/success-ftjob-popup.png",
        // imageWidth: 88,
        // imageHeight: 85,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Proceed",
        customClass: {
          container: "payment_container ft_job_post_container_class",
        },
      }).then((res) => {
        if (res.value) {
          this.$store
            .dispatch(POST_API, {
              data: {
                name: this.cardHolderName,
                number: this.cardNumber,
                expiryMonth: moment(this.expire).format("M"),
                expiryYear: moment(this.expire).format("YYYY"),
                cvv: this.cvv,
                user_id: this.company_admin_id ? this.company_admin_id : this.user_id,
                email: this.email,
                fullname: this.companyName,
                phone: this.phone,
                code: this.code,
                address: this.address,
                address2: this.address2,
                city: this.city,
                country: this.country,
                company_id: this.company_id,
                coinData: this.coinData,
                amount: this.elcoinDatabyid[0].value_in_dollar,
                total_coins: this.elcoinDatabyid[0].coin,
                discount: this.elcoinDatabyid[0].discount,
                pdf_amount: parseFloat(this.elcoinDatabyid[0].value_in_dollar).toFixed(3),
                pdf_gst_percent: this.settings[0].option_value,
                pdf_gst: parseFloat(
                  (this.elcoinDatabyid[0].value_in_dollar * this.settings[0].option_value) / 100
                ).toFixed(3),
                pdf_total_amount: parseFloat(this.total_amount).toFixed(3),
                token: this.token,
                saveLater: this.saveLater,
                saveLater1: this.saveLater1,
                package_name: this.packName,
              },
              api: "/api/makePayment",
            })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
                this.loading = false;

                return [];
              } else {
                this.loading = false;

                var message = this.$store.getters.getResults.message;
                window.open(this.$store.getters.getResults.data);
                this.showSuccessAlert(message);
                window.location.replace("/v2/employer/elcoins");
              }
            });
        }
      });
    },

    getCards() {
      this.$store
        .dispatch(POST_API, {
          data: {
            userId: this.company_admin_id ? this.company_admin_id : this.user_id,
          },
          api: "/api/cards",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.cards = this.$store.getters.getResults.data;
          }
        });
    },

    getBilling() {
      this.$store
        .dispatch(POST_API, {
          data: {
            userId: this.company_admin_id ? this.company_admin_id : this.user_id,
          },
          api: "/api/billingAddress",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.billing = this.$store.getters.getResults.data;
            if (this.billing) {
              this.billingdata = this.$store.getters.getResults.data[0];
              this.email = this.billingdata.billing_email;
              this.address = this.billingdata.street;
              this.address2 = this.billingdata.address_2;
              (this.name = this.billingdata.name),
                (this.phone = this.billingdata.mobile_number),
                (this.code = this.billingdata.postal_code),
                (this.city = this.billingdata.city),
                (this.country = this.billingdata.country_id);
            }

            console.log(this.billing);
          }
        });
    },

    onChange(value) {
      alert(value);

      // this.packageData = id,
      (this.coinData = value), this.getElCoinsById();
    },
    cardChange(id) {
      this.$store
        .dispatch(POST_API, {
          data: {
            cardId: id.target.value,
            // custId:this.custId
          },
          api: "/api/cardbyStripe",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.token = this.$store.getters.getResults.data;
          }
        });
    },

    billChange(id) {
      this.$store
        .dispatch(POST_API, {
          data: {
            billid: id.target.value,
          },
          api: "/api/billById",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.billingdata = this.$store.getters.getResults.data[0];
            this.email = this.billingdata.billing_email;
            this.address = this.billingdata.street;
            this.address2 = this.billingdata.address_2;
            (this.name = this.billingdata.name),
              (this.phone = this.billingdata.mobile_number),
              (this.code = this.billingdata.postal_code),
              (this.city = this.billingdata.city),
              (this.country = this.billingdata.country_id),
              $("#billform").show();
          }
        });
    },

    show(id) {
      if (id == "card") {
        $("#cardDialogue").addClass("payment-tab-active");
        $("#paypalData").removeClass("payment-tab-active");
      } else {
        $("#paypalData").addClass("payment-tab-active");
        $("#cardDialogue").removeClass("payment-tab-active");
      }
    },

    showbill(id) {
      if (id == "bill") {
        this.billingAdd = "";

        $("#billselect").show();
        $("#billform").hide();
      } else {
        this.email = "";
        this.address = "";
        (this.name = ""),
          (this.phone = ""),
          (this.code = ""),
          (this.city = ""),
          (this.country = ""),
          $("#billselect").hide();
        $("#billform").show();
      }
    },

    getSettings() {
      this.$store
        .dispatch(POST_API, {
          api: "/api/settings",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.settings = this.$store.getters.getResults.data;
          }
        });
    },

    editbilling() {
      $("#billing input").removeAttr("disabled");
      $("#billing select").removeAttr("disabled");
    },

    getCountry() {
      this.$store
        .dispatch(POST_API, {
          api: "/api/countriesData",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              // icon: 'error',
              imageUrl: "/assets/images/404_elcy.gif",
              customClass: {
                container: "mascot_error_container",
              },
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.countries = this.$store.getters.getResults.data;
          }
        });
    },
  },

  mounted() {
    // $(".custom_accordian_button").click(function(){
    //     $(".custom_inner_card").slideToggle();
    // });
    //document.title = "EL Connect - Employer - EL Coins";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.email =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : "";
    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    this.company_admin_id =
      this.$store.getters.currentUser != null
        ? this.$store.getters.currentUser.company_admin_id
        : "";
    this.custId =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.customer_id : "";
    this.getCompanyNameById();
    this.getElCoins();
    this.getSettings();
    this.getCountry();
    this.getCards();
    this.getBilling();
    this.sessionData();
    let today = new Date();
    let startDate = new Date("2024-01-01T00:00+07:00");

    if (today >= startDate) {
      console.log("hh");

      $("#headingText").css("display", "none");
      $("#headingText2").css("display", "inline");

      // $('headingText2').show();
    } else {
      console.log("hhpp");

      $("#headingText2").css("display", "none");
      $("#headingText").css("display", "inline");
    }
  },
};
</script>
