<template>
  <div>
    <div class="row">
      <div class="container my_new_container">
        <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline"> -->
        <!-- <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>{{last_name | capitalize}} {{first_name | capitalize}} Transactions</h1> -->
        <!-- Breadcrumbs -->
        <!-- <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Transactions</li>
                </ul>
            </nav> -->
        <!-- <div class="row">

            <div class="col-xl-4 dual_admin_emp_btn col-md-4 mobile_tab_max_width_flex  search_common_margin_bottom_28">
            </div>
            </div> -->
        <!-- </div> -->
        <div id="breadcrumb-v2">
          <b-breadcrumb>
            <b-breadcrumb-item href="" :to="{ name: 'jobseekers-list' }"
              >Jobseekers</b-breadcrumb-item
            >
            <b-breadcrumb-item active
              >{{ last_name | capitalize }}
              {{ first_name | capitalize }} Transactions</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
        <div class="row">
          <div
            class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
          >
            <div class="search-box custom_search_admin desktop_margin_top_zero">
              <!-- <b-input-group> -->
              <b-input-group-prepend>
                <!-- <b-form-input id="searchBtn" v-model="filter" v-on:change="getJobseekers" placeholder="Search by keyword"></b-form-input>
                                <b-button class="btn points_events" @click="getJobseekers"><i class="fa fa-search"></i></b-button> -->

                <b-form-input
                  id="searchBtn"
                  @keyup.enter.native="filter = searchKeyword"
                  v-model="searchKeyword"
                  placeholder="Search by keyword"
                ></b-form-input>
                <b-button class="btn points_events"
                  ><i class="fa fa-search" @click="filter = searchKeyword"></i
                ></b-button>
              </b-input-group-prepend>
              <!-- </b-input-group> -->
            </div>
          </div>
          <div
            class="col-xl-8 col-md-8 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
          >
            <div class="search-box custom_search_admin desktop_margin_top_zero">
              <!-- <b-input-group> -->
              <!-- <br/> -->
              <!-- commented on 01-07-2024 -->
              <!-- <b-form-group
                id="input-group-6"
                class="common_date_range_border_new desktop_margin_bottom_zero common_date_range_picker_new"
              >
                <date-range-picker
                  class="web_bottom_zero transactions_jb_calender calender_div_job_in_job"
                  ref="picker"
                  :minDate="minDate"
                  :opens="center"
                  :locale-data="locale"
                  :auto-apply="auto"
                  v-model="dateRange"
                  :ranges="range"
                  @update="changeFilter()"
                >
                  <div slot="input" slot-scope="picker">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </div>
                </date-range-picker>
              </b-form-group> -->
              <div class="row">
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <datepicker v-model="start_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="start_date"
                      class="desktop_margin_bottom_zero"
                    ></b-form-input>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-md-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28"
                >
                  <div class="search-box custom_search_admin desktop_margin_top_zero">
                    <!-- <datepicker v-model="end_date" @input="changeTab()" :format="customFormatterTwo" id="scheduled_post" class=""></datepicker> -->
                    <b-form-input
                      id="input-1"
                      type="date"
                      placeholder=""
                      v-model="end_date"
                      class="desktop_margin_bottom_zero"
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <!-- </b-input-group> -->
            </div>
          </div>
          <div
            class="common_z_index_zero mobile_three_btn_transaction mobile_tab_max_width_flex transaction_1250_screen col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28"
          >
            <b-button
              @click="changeFilter()"
              class="desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e"
              style="width: 120px"
              >Search</b-button
            >
            <b-button
              class="desktop_margin_left_zero common_z_index_zero button ripple-effect add_btn_admin_e"
              :class="items.length == 0 ? 'disabled' : ''"
              v-if="show('export')"
              @click="exportTransaction()"
              >Export</b-button
            >
            <!-- commented on 01-07-2024 -->
            <!-- <b-button
              v-if="
                filter ||
                searchKeyword ||
                dateRange.startDate != start_date_filter ||
                dateRange.endDate != end_date_filter
              "
              @click="clearFilter()"
              class="common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero"
              style="background-color: red"
              ><span>Reset all filters</span></b-button
            > -->
            <!-- Added on 01-07-2024 -->

            <b-button
              v-if="
                filter ||
                searchKeyword ||
                start_date != start_date_filter ||
                end_date != end_date_filter
              "
              @click="clearFilter()"
              class="ml-3 new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
              style="background-color: red; margin-top: 20px;"
              >
              <span class="mobile_reset">Reset filters</span>
              </b-button>
          </div>
        </div>

        <!-- <button class="btn green-border-btn add_btn_admin_e desktop_margin_right_zero" :class="items.length == 0 ? 'disabled' : ''" @click="exportTransaction()" style="background-color: rgb(76, 139, 245);color: white;float: right;margin-top: -66px !important;">Export</button> -->
      </div>
    </div>
    <!-- Page Content
        ================================================== -->
    <div class="row">
      <div class="container my_new_container show_filter_arrow">
        <b-table
          ref="datatable"
          show-empty
          striped
          hover
          responsive
          :items="getJobseekers"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          class="nine_grid_tbl_new mobile_min_width_1500"
        >
          <template #cell(transaction_number)="items">
            {{ items.item.transaction_number }}
          </template>
          <template #cell(transaction_id)="items">
            {{ items.item.transaction_id }}
          </template>
          <template #cell(job_id)="items">
            <!-- <a :href="webUrlV1Admin+encrypt(items.item.job_id)+'/completed/null/null/null'" target="_blank" style="color: #4c8bf5;">
                            {{items.item.job_unique_id}} 
                        </a> -->
            <a
              v-if="items.item.job_status == 'in-progress'"
              :href="
                showManageDPJobs('view')
                  ? webUrlV1Admin +
                    '/admin/jobs/view-jobsDetail-inprogress/' +
                    encrypt(items.item.job_id) +
                    '/' +
                    items.item.job_status +
                    '/null/null/null'
                  : null
              "
              target="_blank"
              style="color: #4c8bf5"
            >
              {{ items.item.job_unique_id }}
            </a>
            <a
              v-else-if="items.item.job_status == 'active'"
              :href="
                showManageDPJobs('view')
                  ? webUrlV1Admin +
                    '/admin/jobs/view-jobsDetail-upcoming/' +
                    encrypt(items.item.job_id) +
                    '/' +
                    items.item.job_status +
                    '/null/null/null'
                  : null
              "
              target="_blank"
              style="color: #4c8bf5"
            >
              {{ items.item.job_unique_id }}
            </a>
            <a
              v-else-if="items.item.job_status == 'completed'"
              :href="
                showManageDPJobs('view')
                  ? webUrlV1Admin +
                    '/admin/jobs/view-jobsDetail-completed/' +
                    encrypt(items.item.job_id) +
                    '/' +
                    items.item.job_status +
                    '/null/null/null'
                  : null
              "
              target="_blank"
              style="color: #4c8bf5"
            >
              {{ items.item.job_unique_id }}
            </a>
            <a
              v-else
              :href="
                showManageDPJobs('view')
                  ? webUrlV1Admin +
                    '/admin/jobs/view-jobsDetail-cancelled/' +
                    encrypt(items.item.job_id) +
                    '/' +
                    items.item.job_status +
                    '/null/null/null'
                  : null
              "
              target="_blank"
              style="color: #4c8bf5"
            >
              {{ items.item.job_unique_id }}
            </a>
          </template>

          <template #cell(timesheet_id)="items">
            {{ items.item.timesheet_id }}
          </template>
          <template #cell(amount)="items">
            <p
              v-if="items.item.mode == 'credit'"
              v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
            >
              ${{ items.item.amount }}
            </p>
            <p
              v-if="items.item.mode == 'debit'"
              v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
            >
              ${{ items.item.amount }}
            </p>
          </template>

          <!-- <template #cell(transaction_type)="items">
                    <p class="btn yellow-bg desktop_margin_left_zero desktop_margin_right_zero" >{{items.item.transaction_type | capitalize}}</p>
                    </template> -->
          <template #cell(transaction_status)="items">
            {{ items.item.type | capitalize | removeUnderscore }}<br />
            <span v-if="items.item.sub_type_name != null"
              >({{ items.item.sub_type_name | capitalize }})</span
            >
          </template>
          <template #cell(billing_type)="items">
            {{ items.item.billing_type_name | capitalize }}
          </template>
          <template #cell(mode)="items">
            <p
              v-if="items.item.mode == 'credit'"
              v-bind:class="{ green_credit_status: items.item.mode == 'credit' }"
            >
              {{ items.item.mode | capitalize }}
            </p>
            <p
              v-if="items.item.mode == 'debit'"
              v-bind:class="{ red_debit_status: items.item.mode == 'debit' }"
            >
              {{ items.item.mode | capitalize }}
            </p>
          </template>
          <template #cell(created_at)="items">
            {{ items.item.created_at | moment }}
          </template>
          <template #cell(toggleAction)="items">
            <div class="toggle-action common_ul_radius toggle_margin_unset">
              <!-- <b-dropdown class="mx-1" right text="Action" boundary="window"> -->
              <b-dropdown class="mx-1" left text="Action" boundary="window">
                <b-dropdown-item @click="openModel(items.item.id)"
                  >View Transaction Details
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    (items.item.remarks || items.item.internal_remarks) && show('update remark')
                  "
                  v-on:click="updateRemark(items.item.id, 'update', items.item.type)"
                  >Update Remark
                </b-dropdown-item>
                <b-dropdown-item
                  v-else-if="show('add remark')"
                  v-on:click="updateRemark(items.item.id, 'add', items.item.type)"
                  >Add Remark
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    (items.item.type == 'add_back' || items.item.type == 'deduct') &&
                    show('update amount')
                  "
                  v-on:click="openAmountModel(items.item.id, items.item.amount, items.item.mode)"
                  >Update Amount
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="items.item.has_image == 'yes' && show('view images')"
                  v-on:click="openImagesModel(items.item.id)"
                  >View Images
                </b-dropdown-item>
                <!-- <b-dropdown-item :to="{name: 'jobseekerDPManagejobsAdmin', params: {id: encrypt(items.item.id)}}">DP Jobs
                                </b-dropdown-item> -->
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <nav class="pagination-box custom_pagination_border_radius">
          <div class="page-row-box d-inline-block">
            <b-form-group id="input-group-4">
              <span class="d-inline-block">Rows per page</span>
              <span class="d-inline-block">
                <b-form-select v-model="form.rowsPerPage" @change="getJobseekers">
                  <option>25</option>
                  <option>50</option>
                  <option>75</option>
                  <option>100</option>
                </b-form-select></span
              >
            </b-form-group>
          </div>
          <div class="total-page-count d-inline-block">
            <ul>
              <li>
                <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
              </li>
            </ul>
          </div>
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            prev-text=""
            next-text=""
            hide-goto-end-buttons
          />
        </nav>
      </div>
    </div>
    <b-modal
      ref="amount-modal"
      title="Update Amount"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12">
              <div class="form-map-box model_legend model_label">
                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <!-- <b-form-group id="input-group-0" label="Transaction ELC Credits" class="required model_margin_bottom_zero"> -->
                    <!-- <b-form-input id="input-0" type="text" placeholder="" :value="user_coins" @change="v => user_coins = v" class="input-with-border model_border_and_box_shadow"></b-form-input> -->
                    <label class="model_inline model_margin_bottom_zero">Transaction Amount</label
                    ><span class="model_font_16 model_float_right">
                      {{ transaction_prev_amount }}</span
                    >
                    <!-- </b-form-group> -->
                  </div>
                </b-col>

                <b-col lg="0" md="0" class="submit-field">
                  <div id="show_hide_password_login" class="submit-field">
                    <div class="row">
                      <div class="col-xl-7 mobile_col_7 common_align_middle_text">
                        <label class="model_margin_bottom_zero">Amount</label>
                      </div>
                      <div class="col-xl-5 mobile_col_5">
                        <b-form-input
                          id="input-0"
                          type="text"
                          placeholder=""
                          :value="transaction_new_amount"
                          @change="(v) => (transaction_new_amount = v)"
                          class="input-with-border model_border_and_box_shadow"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col lg="0" md="0">
                  <div slot="footer" class="form-btn common_crop_reset_btn">
                    <b-button
                      type="submit"
                      variant="success"
                      @click="submitUpdateAmountInfo($event)"
                      class="model_common_bt_success_color"
                      >Submit</b-button
                    >
                    <b-button
                      @click="hideModel()"
                      variant="light"
                      class="model_common_bt_lite_color"
                      >Cancel</b-button
                    >
                  </div>
                </b-col>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <b-modal
      ref="transaction-modal"
      title="Transaction Details"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <template>
        <div class="transaction_details">
          <div class="transaction_data">
            <h4>Transaction Number: </h4>
            <span> {{ databyid.transaction_number | capitalize }} </span>
          </div>
          <div class="transaction_data">
            <h4>Amount: </h4>
            <span> S${{ databyid.amount }} </span>
          </div>
          <div class="transaction_data">
            <h4>Transaction Status: </h4>
            <span> {{ databyid.transaction_status | capitalize }} </span>
          </div>
          <div class="transaction_data">
            <h4>Mode: </h4>
            <span> {{ databyid.mode | capitalize }} </span>
          </div>
          <div class="transaction_data">
            <h4>Type: </h4>
            <span> {{capture_return != '' ? (capture_return.ProxyType == 'MSISDN' ? 'Paynow' : (capture_return != '' ? capture_return.ProxyType == 'NRIC' ? 'Paynow (NRIC)' : 'Fast Payment' : databyid.type)) : databyid.type  | capitalize | capitalize }} </span>
          </div>
          <div v-if="(databyid.transaction_status == 'failed') && (databyid.capture_return)" class="transaction_data">
            <h4>Reason: </h4>
            <span style="word-wrap: break-word;"> {{ databyid.capture_return }} </span>
          </div>
          <div class="transaction_data">
            <h4>Created At: </h4>
            <span> {{ databyid.created_at | moment }} </span>
          </div>
          <div class="transaction_data">
            <h4>User Name: </h4>
            <span
              > {{ databyid.last_name | capitalize }} {{ databyid.first_name | capitalize }}</span
            >
          </div>
          <div class="transaction_data">
            <h4>Mobile Number: </h4>
            <span
              > {{ databyid.country_code | capitalize }}-{{ databyid.mobile_number | capitalize }}</span
            >
          </div>
          <div  v-if="databyid && databyid.type == 'fast_payment' && otherdatabyid && otherdatabyid.account_number_string" class="transaction_data">
            <h4>Bank Name: </h4>
            <span
              > {{ otherdatabyid.bank_name | capitalize }}</span
            >
          </div>
          <div  v-if="databyid && databyid.type == 'fast_payment' && otherdatabyid && otherdatabyid.account_number_string" class="transaction_data">
            <h4>Account Holder Name: </h4>
            <span
              > {{ otherdatabyid.account_holder_name | capitalize }}</span
            >
          </div>
          <div  v-if="databyid && databyid.type == 'fast_payment' && otherdatabyid && otherdatabyid.account_number_string" class="transaction_data">
            <h4>Account Number: </h4>
            <span
              > {{ otherdatabyid.account_number_string  }}</span
            >
          </div>
          <div v-if="databyid && databyid.type == 'paynow'" class="transaction_data">
            <h4>PayNow {{capture_return != '' ? (capture_return.ProxyType == 'MSISDN' ? 'Number' : 'NRIC') : 'Number'}}: </h4>
            <span
              > {{capture_return != '' ? capture_return.ProxyValue : (databyid.country_code+'-'+databyid.mobile_number) }}</span
            >
          </div>
          <div class="transaction_data">
            <h4>Remarks: </h4>
            <span> {{ databyid.remarks | capitalize }}</span>
          </div>
          <div class="transaction_data">
            <h4>Internal Remarks: </h4>
            <span> {{ databyid.internal_remarks | capitalize }}</span>
          </div>
        </div>
        <!-- <div class="pt-2 mt-3 text-md-left text-center"> -->
        <!-- <b-button variant="danger" class="text-uppercase x-md btn-shadow" @click.prevent="reset">Reset</b-button> -->
        <!-- </div> -->
      </template>
      <!-- </b-form> -->
    </b-modal>
    <b-modal ref="upload-modal" :title="modalTitle" hide-footer>
      <b-form @submit="formSubmit">
        <div class="edit-form-box">
          <div class="profile-repeate form-box-field">
            <b-row>
              <b-col lg="12" md="12">
                <div class="form-map-box">
                  <b-col
                    lg="12"
                    md="12"
                    v-if="
                      currentTab == 'jobs' &&
                      (transaction_type_val == 'add_back' || transaction_type_val == 'deduct')
                    "
                  >
                    <b-form-group
                      id="input-group-3"
                      class="contact-fields mobile_margin_bottom_zero submit-field"
                    >
                      <span class="custom-dropdown w-100">
                        <b-form-select
                          disabled
                          v-model="transaction_type_val"
                          class="model_margin_bottom_zero"
                          @change="getTransactionSubType()"
                        >
                          <option value="add_back">Add Back</option>
                          <option value="deduct">Deduct</option>
                        </b-form-select>
                      </span>
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="12"
                    md="12"
                    v-if="
                      (transaction_type_val == 'add_back' || transaction_type_val == 'deduct') &&
                      sub_transactions.length > 0 &&
                      currentTab == 'jobs'
                    "
                  >
                    <b-form-group
                      id="input-group-3"
                      class="contact-fields mobile_margin_bottom_zero submit-field"
                    >
                      <span class="custom-dropdown w-100">
                        <b-form-select
                          v-model="transaction_sub_type_val"
                          class="model_margin_bottom_zero"
                        >
                          <option value="" disabled>Select Transaction Type</option>
                          <option :value="transaction.id" v-for="transaction in sub_transactions">
                            {{ transaction.title | capitalize }}
                          </option>
                        </b-form-select>
                      </span>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12" v-if="currentTab == 'jobs'">
                    <b-form-group
                      id="input-group-3"
                      class="contact-fields mobile_margin_bottom_zero submit-field"
                    >
                      <span class="custom-dropdown w-100">
                        <b-form-select v-model="billing" class="model_margin_bottom_zero">
                          <option value="" disabled>Select Bill Type</option>
                          <option :value="bt.id" v-for="bt in billing_types">
                            {{ bt.name | capitalize }}
                          </option>
                        </b-form-select>
                      </span>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-9" label="Remarks" class="required">
                      <b-form-textarea
                        id="input-9"
                        maxlength="500"
                        v-model="form.remarks"
                        type="text"
                        placeholder=""
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col lg="0" md="0" class="model_submit_field employer_multiple_radio">
                    <b-form-group>
                      <b-form-checkbox v-model="form.copyRemarksVal" @input="clickToCopyRemark()"
                        ><strong>Internal Remark same as Remark</strong></b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <b-form-group id="input-group-9" label="Internal Remarks" class="required">
                      <b-form-textarea
                        id="input-9"
                        maxlength="500"
                        v-model="form.internal_remarks"
                        type="text"
                        placeholder=""
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <div slot="footer" class="form-btn col-md-12 col-lg-12 gallery_btn">
                    <b-button type="submit" variant="success">Submit</b-button>
                    <b-button @click="hideModel()" variant="light">Cancel</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      ref="transaction-images-modal"
      title="Transaction Images"
      hide-footer
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >
      <!-- <b-form> -->
      <div class="edit-form-box model_margin_top_zero">
        <div class="profile-repeate form-box-field">
          <b-row>
            <b-col lg="12" md="12" v-if="uploads.length > 0">
              <h3 style="margin-bottom: 14px"><b>Click to view images</b></h3>
              <div class="images_listing admin_image_listing">
                <viewer :images="uploads">
                  <img
                    v-for="docs in uploads.slice()"
                    track-by="id"
                    :src="docs"
                    :key="docs"
                    class="desktop_margin_bottom_zero"
                  />
                </viewer>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- </b-form> -->
    </b-modal>
    <!-- Spacer -->
    <!-- <div class="margin-top-70"></div> -->
    <!-- Spacer / End-->
    <!-- Row / End -->
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import permission from "../../../../../server/permission.js";
import Datepicker from "vuejs-datepicker";

export default {
  data() {
    return {
      fields: [
        { key: "transaction_number", label: "Transaction Number", sortable: true },
        { key: "transaction_id", label: "Transaction ID", sortable: true },
        { key: "job_id", label: "Job ID", sortable: true },
        { key: "timesheet_id", label: "Timesheet ID", sortable: true },
        { key: "amount", label: "Amount", sortable: true },
        // { key: 'transaction_type', label: 'Transaction Type', sortable: true   },
        { key: "transaction_status", label: "Type", sortable: true },
        { key: "billing_type", label: "Billing Type", sortable: true },
        { key: "mode", label: "Mode", sortable: true },
        { key: "created_at", label: "Transaction At", sortable: true },
        { key: "toggleAction", label: "Actions" },
      ],
      id: atob(this.$route.params.id),
      first_name: "",
      last_name: "",
      // webUrlV1Admin:process.env.VUE_APP_URL_V1_Admin + "/admin/jobs/view-jobsDetail-completed/",
      webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
      webUrlV1: process.env.VUE_APP_URL_V1,
      pager: {},
      databyid: [],
      otherdatabyid:[],
      capture_return:'',
      pageOfItems: [],
      items: [],
      totalRows: 0,
      from: "",
      to: "",
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 75, 100],
      sortBy: null,
      sortDirection: "desc",
      filter: "",
      searchKeyword: "",
      status: "active",
      sortDesc: true,
      webUrl: process.env.VUE_APP_URL,
      currentTab: "jobs",
      form: {
        rowsPerPage: 25,
        mode: "",
        date: "",
        type: "",
        status: "",
        remarks: "",
        internal_remarks: "",
        copyRemarksVal: false,
      },
      modalTitle: "Add Remark",
      type: "add",
      update_amount_transaction_id: "",
      transaction_prev_amount: "",
      transaction_amount_mode: "",
      transaction_new_amount: "",
      uploads: [],
      minDate: null,
      center: "center",
      auto: true,
      range: false,
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      //Commented on 01-07-2024
      // dateRange: {
      //   startDate: "",
      //   endDate: "",
      // },

      // dateRange: {
      //   startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
      //   endDate: moment().format("YYYY-MM-DD"),
      // },
      //Commented on 01-07-2024

      //Added on 01-07-2024
      dateRangeNew: {
        startDate: moment(new Date()).subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      start_date: "",
      // start_date:moment().subtract(1, "months").format("YYYY-MM-DD"),
      end_date: "",
      //Added on 01-07-2024

      start_date_filter: moment().subtract(1, "months").format("YYYY-MM-DD"),
      end_date_filter: new moment().format("YYYY-MM-DD"),
      actions: "",
      actionsManageFTJobs: "",
      actionsManageDPJobs: "",
      transaction_type_val: "deduct",
      sub_transactions: [],
      transaction_sub_type_val: "",
      billing_types: [],
      billing: "",
    };
  },
  components: {
    DateRangePicker,
    Datepicker,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    moment: function (date) {
      return moment(date).utc().format("DD MMM YYYY hh:mm A");
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    date: function (value) {
      if (value == null) {
        return "";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
  },
  methods: {
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        // this.$router.push({ name: 'jobseekers-list' });
        this.$refs.datatable.refresh();
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    clearFilter() {
      this.filter = "";
      this.searchKeyword = "";
      // (this.dateRange.startDate = moment().subtract(1, "months").format("YYYY-MM-DD")),
      //   (this.dateRange.endDate = moment().format("YYYY-MM-DD"));

      this.start_date = moment(new Date())
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      // this.start_date = moment().subtract(1, "months").format("YYYY-MM-DD"),
      this.end_date = new moment().format("YYYY-MM-DD");
      this.start_date=''
      this.end_date=''
      this.$refs.datatable.refresh();
    },
    changeFilter() {
      //Added on 01-07-2024
      this.dateRangeNew.startDate = this.start_date;
      if (this.end_date == ''){
        this.dateRangeNew.endDate = new moment().format("YYYY-MM-DD")}
      else {
        this.dateRangeNew.endDate = this.end_date;
      }
      //Added on 01-07-2024

      this.getJobseekers().then(() => {
        this.$refs.datatable.refresh();
      });
    },
    getJobseekers() {
      //Added on 01-07-2024
      this.dateRangeNew.startDate = this.start_date;
      if (this.end_date == ''){
        this.dateRangeNew.endDate = new moment().format("YYYY-MM-DD")}
      else {
        this.dateRangeNew.endDate = this.end_date;
      }
      //Added on 01-07-2024

      return this.$store
        .dispatch(POST_API, {
          data: {
            user_id: this.id,
            page: this.currentPage,
            // keyword: this.filter,
            keyword: this.searchKeyword,
            rowsPerPage: this.form.rowsPerPage,
            sortBy: this.sortBy ? this.sortBy : "transactions.created_at",
            sortDirection: this.sortDesc ? "desc" : "asc",
            // dateRange: this.dateRange,
            dateRange: this.dateRangeNew,
          },
          api: "/api/transaction-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.items = this.$store.getters.getResults.pageOfItems;
            this.totalRows = this.$store.getters.getResults.pager.totalItems;
            this.perPage = this.$store.getters.getResults.pager.pageSize;
            this.from = this.$store.getters.getResults.pager.from;
            this.to = this.$store.getters.getResults.pager.to;
            return this.items;
          }
        });
    },
    getTransactionImages(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            transaction_id: id,
          },
          api: "/api/transaction-images",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            var img = this.$store.getters.getResults.items;
            var arr = [];

            if (this.$store.getters.getResults.items.length > 0) {
              var j = 0;
              for (var i = 0; i < this.$store.getters.getResults.items.length; i++) {
                this.uploads.push(this.$store.getters.getResults.items[j].url);
                // this.type[j] = this.$store.getters.getResults.document[i].type;
                j++;
                // console.log(img.length , this.uploads.length, 'lenght')
                if (img.length == this.uploads.length) {
                  this.$refs["transaction-images-modal"].show();
                }
              }
            }
            console.log(this.uploads, "ll");
            // if(this.images.length > 0){
            //     this.$refs['transaction-images-modal'].show();
            // }
          }
        });
    },
    formSubmit(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            id: this.transaction_id,
            remarks: this.form.remarks,
            internal_remarks: this.form.internal_remarks,
            currentTab: this.currentTab,
            billing_type: this.billing ? this.billing : null,
            sub_type: this.transaction_sub_type_val ? this.transaction_sub_type_val : null,
          },
          api: "/api/admin-transaction-remarks-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            if (this.type == "update") {
              var message = "Remark Updated successfully";
            } else {
              var message = "Remark Added successfully";
            }
            this.hideModel();
            this.showSuccessAlert(message);
          }
        });
    },
    updateRemark(id, addUpdate, transaction_type) {
      this.clickToAdd(addUpdate);
      this.type = addUpdate;
      this.transaction_id = id;
      this.transaction_type_val = transaction_type;

      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
            currentTab: this.currentTab,
          },
          api: "/api/admin-transaction-remarks-edit",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.form.remarks = this.$store.getters.getResults.data.remarks;
            this.form.internal_remarks = this.$store.getters.getResults.data.internal_remarks;
            this.form.coins = this.$store.getters.getResults.data.coins;
            this.billing = this.$store.getters.getResults.data.billing_type
              ? this.$store.getters.getResults.data.billing_type
              : "";
            this.transaction_sub_type_val = this.$store.getters.getResults.data.sub_type
              ? this.$store.getters.getResults.data.sub_type
              : "";
          }
        });
    },
    clickToAdd(typeVal) {
      this.getTransactionSubType();
      this.getBillingTypes();
      this.form.copyRemarksVal = false;
      this.$refs["upload-modal"].show();
      this.modalTitle = typeVal == "add" ? "Add Remark" : "Update Remark";
      this.type = typeVal == "add" ? "Add" : "Update";
      this.transaction_sub_type_val = "";
      this.billing = "";
    },
    getTransactionSubType() {
      this.$store
        .dispatch(POST_API, {
          data: {
            transaction_type: this.transaction_type_val,
          },
          api: "/api/transaction-sub-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.sub_transactions = this.$store.getters.getResults.data;
          }
        });
    },
    getBillingTypes() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/billing-types",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.billing_types = this.$store.getters.getResults.data;
          }
        });
    },
    clickToCopyRemark() {
      if (this.form.copyRemarksVal) {
        this.form.internal_remarks = this.form.remarks;
      } else {
        this.form.internal_remarks = this.form.internal_remarks;
      }
    },

    exportTransaction() {
      var base_url = this.webUrlV1;
      var user_id = atob(this.$route.params.id);
      var status = this.form.status != "" ? this.form.status : 0;
      var mode = this.form.mode != "" ? this.form.mode : 0;
      var date = this.form.date != "" ? moment(this.form.date).format("YYYY-MM-DD") : "null";
      //commented on 01-07-2024
      // var start_date = this.dateRange.startDate
      //   ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
      //   : "null";
      // var end_date = this.dateRange.endDate
      //   ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
      //   : "null";

      //Added on 01-07-2024
      var start_date = this.dateRangeNew.startDate
        ? moment(this.dateRangeNew.startDate).format("YYYY-MM-DD")
        : "null";
      var end_date = this.dateRangeNew.endDate
        ? moment(this.dateRangeNew.endDate).format("YYYY-MM-DD")
        : "null";
      // var filter = this.filter != '' && this.filter != null ? this.filter : 0;
      var filter = this.searchKeyword != "" && this.searchKeyword != null ? this.searchKeyword : 0;

      var type = this.form.type != "" ? this.form.type : 0;
      var export_url =
        "/api/export-ptuser-transactions-v1/" +
        status +
        "/" +
        mode +
        "/" +
        date +
        "/" +
        filter +
        "/" +
        start_date +
        "/" +
        end_date +
        "/" +
        type +
        "/" +
        user_id;
      // this.$router.push(base_url+export_url)
      // window.location.href = base_url+export_url
      window.open(base_url + export_url, "_blank");
    },

    openAmountModel(id, transaction_prev_amount, mode) {
      this.update_amount_transaction_id = id;
      this.transaction_prev_amount = transaction_prev_amount ? transaction_prev_amount : 0;
      this.transaction_amount_mode = mode;
      this.transaction_new_amount = "";
      this.$refs["amount-modal"].show();
    },
    openImagesModel(id) {
      this.getTransactionImages(id);
      this.uploads = [];
    },
    hideModel() {
      this.$refs["upload-modal"].hide();
      this.form.remarks = "";
      this.form.internal_remarks = "";
      this.form.copyRemarksVal = false;
      this.$refs["amount-modal"].hide();
      this.update_amount_transaction_id = "";
      this.transaction_prev_amount = "";
      this.transaction_amount_mode = "";
      this.transaction_new_amount = "";
      this.$refs["transaction-images-modal"].hide();
      this.uploads = [];
    },

    submitUpdateAmountInfo(evt) {
      evt.preventDefault();
      this.$store
        .dispatch(POST_API, {
          data: {
            currentTab: "jobs",
            user_id: atob(this.$route.params.id),
            transaction_id: this.update_amount_transaction_id,
            transaction_prev_amount: this.transaction_prev_amount,
            transaction_mode: this.transaction_amount_mode,
            transaction_new_amount: this.transaction_new_amount,
          },
          api: "/api/admin-transaction-amount-update",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            var message = "Amount Updated successfully";
            this.hideModel();

            this.showSuccessAlert(message);
          }
        });
    },

    getUserData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: atob(this.$route.params.id),
          },
          api: "/api/user-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.first_name = this.$store.getters.getResults.data.first_name;
              this.last_name = this.$store.getters.getResults.data.last_name;
            }
          }
        });
    },

    openModel(id) {
      this.$refs["transaction-modal"].show();
      this.getTransactionById(id);
    },

    getTransactionById(id) {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: id,
          },
          api: "/api/transactionbyid",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            this.databyid = this.$store.getters.getResults.data;
            var json = this.databyid.capture_return != '' ? JSON.parse(this.databyid.capture_return) : '';
            this.capture_return = json != '' ? (json.Result != '' ? json.Result : '') : '';
            console.log(this.capture_return, 'capture_return')
            this.otherdatabyid = this.$store.getters.getResults.other_data;


          }
        });
    },

    permissionStaff() {
      if (this.$store.getters.getCurrentUserPermission) {
        if (
          this.$store.getters.getCurrentUserPermission.data.length > 0 &&
          this.$store.getters.currentUser.user_type_id == 2
        ) {
          var menu = this.$store.getters.getCurrentUserPermission.data;
          this.actions = permission.getPermissionStaff(menu, "Transactions");
          this.actionsManageFTJobs = permission.getPermissionStaff(menu, "Job Ads");
          this.actionsManageDPJobs = permission.getPermissionStaff(menu, "DP Jobs Admin");
        }
      }
    },
    show(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actions.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showManageFTJobs(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsManageFTJobs.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
    showManageDPJobs(action) {
      if (this.$store.getters.currentUser.user_type_id == 2) {
        return this.actionsManageDPJobs.indexOf(action) >= 0 ? true : false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    $(".show_filter_arrow .table thead th div").contents().unwrap();
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    // document.title = "EL Connect - Admin - Jobseekers List";
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.getUserData();
    this.permissionStaff();
  },
};
</script>
