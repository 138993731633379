<template>
    <div>

         <div class="row">
            <div class="container my_new_container">
                <!-- Dashboard Headline -->
                <div class="dashboard-headline">
                    <h1>Service Agreement</h1>
                    <!-- Breadcrumbs -->
                    <nav id="breadcrumbs" class="dark">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>Service Agreement</li>
                        </ul>
                    </nav>
                </div>
                <div class="row">
                    <div class="service_col_4 col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28">
                    <div class="search-box custom_search_admin desktop_margin_top_zero">
                        <!-- <b-input-group> -->
                            <!-- <br/> -->
                            <b-input-group-prepend class="common_bg_white">
                                <b-form-input id="searchBtn" @keyup.enter.native="filter = searchKeyword" v-model="searchKeyword" placeholder="Search by keyword" class="common_bg_white"></b-form-input>
                                <b-button class="btn points_events"><i class="fa fa-search common_bg_white" @click="filter = searchKeyword"></i></b-button>
                            </b-input-group-prepend>
                            <!-- </b-input-group> -->
                    </div>
                    </div>
                    <div class="service_col_4 col-xl-4  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <!-- <label class="desktop_margin_bottom_zero">Company:</label> -->
                        <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                            <multiselect id="ajax" v-model="company_id_filter" :custom-label="customLabel" tag-placeholder="Search Company" placeholder="Company" :options="companies" label="full_name" track-by="company_id" @input="changeEmpServiceAgreementFilter()" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="false" deselect-label="Can't remove this value" open-direction="bottom" class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select" ><span slot="noResult">No Company Found.</span></multiselect>
                        </b-form-group>
                    </div>
                
                    <div class="service_col_2 col-xl-4 service_agreement_btn_admin search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <b-link v-if="show('add')" :to="{}" class="btn green-border-btn ml-2 text-white upload_img_cst gallery_upload_btn" @click="clickToAdd('add')">
                            <span>Add New</span>
                        </b-link>
                        <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportEmpServiceAgreement()" style="margin-left: 19px;">Export</b-button>
                    </div>
                    
                    <div class="service_col_4 col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <label class="new_style_label required_sign required desktop_margin_bottom_zero mobile_margin_top_zero">Created At:</label>                                
                        <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">        
                                <date-range-picker class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100" ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="dateRange" :ranges='range' @update="changeEmpServiceAgreementFilter()">
                                <div slot="input" slot-scope="picker">
                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                </div>
                            </date-range-picker> 
                        </b-form-group>
                    </div>
                    <template>
                        <div class="service_col_4 col-xl-4  search_common_margin_bottom_28 mobile_tab_max_width_flex">
                        <b-form-group id="input-group-8" class="desktop_margin_bottom_zero">
                                <multiselect
                                    id="selectStatus"
                                    v-model="status"
                                    :options="statusOptions"
                                    placeholder="Status"
                                    track-by="value"
                                    label="label"
                                    class="common_black_multiselect_border job_list_multi_select model_multi_select admin_multi_select"
                                    @input="changeEmpServiceAgreementFilter()"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                    </template>
                    <div class="service_col_2 col-xl-4 search_common_margin_bottom_28 mobile_tab_max_width_flex search_common_margin_bottom_28 new_reset_btn">
                        <div class="sidebar-widget job_list_btn_search mobile_space_between penalty_btn desktop_margin_bottom_zero food_btn">
                            <!-- <b-button @click="changeEmpServiceAgreementFilter()" class="button ripple-effect search_btn_all filter_btn_widht_120 desktop_margin_left_zero desktop_margin_right_zero" style="width: 120px;">Search</b-button> -->
                            <b-button v-if="(filter || searchKeyword || status || company_id_filter || (dateRange.startDate != start_date_filter || dateRange.endDate != end_date_filter))" @click="clearFilterEmpServiceAgreement()" class="ml-3 new_reset_text  mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero  new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0" style="background-color: red; margin-top: 20px;"><span class="mobile_reset">Reset filters</span></b-button>
                        </div>
                    </div>
                </div>
                    
                <!-- Page Content
                ================================================== -->
                
                <div class="row">
                <div class="container my_new_container show_filter_arrow">
                    <b-table ref="datatableEmpServiceAgreement" show-empty striped hover responsive :items="getEmpServiceAgreement" :fields="fieldsEmpServiceAgreement" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="desktop_margin_top_zero new_box_tbl_mrgn   mobile_min_width_1000 nine_grid_tbl_new ">
                            <template #cell(name)="items">
                                <p v-if="items.item.name">
                                    <b-link :to="showEmployer('view profile') ? {name: 'employer-profile-view', params: {id: encrypt(items.item.user_id),company_id: encrypt(items.item.company_id)}} : null" target="_blank" style="color: #4c8bf5;">
                                        {{items.item.name | capitalize}}
                                    </b-link>
                                </p>
                            </template>
                            <template #cell(document)="items">
                                <p v-if="items.item.document">
                                    <a :href="items.item.document" target="_blank"><img :src="webUrl+'assets/images/pdf.png'" height="80" width="80"/></a>
                                    <!-- <a :href="items.item.document" target="_blank"><img :src="items.item.document" height="80" width="80"/></a> -->
                                </p>
                            </template>
                            <template #cell(effective_date)="items">
                                {{(items.item.effective_date ? items.item.effective_date : '' ) | date}}
                            </template>
                            <template #cell(expiration_date)="items">
                                {{(items.item.expiration_date  ? items.item.expiration_date : '' ) | date}}
                            </template>
                            <template #cell(created_at)="items">
                                {{(items.item.sa_created_at ? items.item.sa_created_at : '' ) | date}}
                            </template>
                            <template #cell(agreement_id)="items">
                                {{(items.item.agreement_id ? items.item.agreement_id : '' ) | capitalize}}
                            </template>
                            <template #cell(status)="items">
                                <p v-if="items.item.status == 'active'" v-bind:class="{ 'green_status' : items.item.status == 'active'}">{{items.item.status | capitalize}}</p>
                                <p v-if="items.item.status == 'expired' || items.item.status == 'deleted'" v-bind:class="{ 'red_status' : items.item.status == 'expired' || items.item.status == 'deleted'}">{{items.item.status | capitalize}}</p>
                            </template>
                            <template #cell(toggleAction)="items">
                                <div class="toggle-action toggle_margin_unset">
                                    <b-dropdown class="mx-1" right text="Action" boundary="window">
                                        <b-dropdown-item v-on:click="getCompanyById(items.item.id,items.item.company_id)" v-if="items.item.status == 'active' && show('edit')">Edit
                                        </b-dropdown-item>
                                        <b-dropdown-item v-on:click="deleteServiceAgreement(items.item.id)" v-if="items.item.status != 'deleted' && show('delete')">Delete
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </template>
                        </b-table>

                        <nav class="pagination-box custom_pagination_border_radius">
                            <div class="page-row-box d-inline-block">
                                <b-form-group id="input-group-4">
                                    <span class="d-inline-block">Rows per page</span>
                                    <span class="d-inline-block"><b-form-select v-model="form.rowsPerPage" @change='getEmpServiceAgreement'>
                                                <option>25</option>
                                                <option>50</option>
                                                <option>75</option>
                                                <option>100</option>
                                    </b-form-select></span>
                                </b-form-group>
                            </div>
                            <div class="total-page-count d-inline-block">
                                <ul>
                                    <li>
                                        <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                                    </li>
                                </ul>
                            </div>
                            <b-pagination :total-rows="totalRows"  :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                        </nav>
                    </div>
                </div>
                        
                </div>
                </div>
                <b-modal ref="upload-modal" :title="modalTitle" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
                    <b-form @submit="formSubmit">
                        <div class="edit-form-box model_margin_top_zero model_legend">
                            <div class="profile-repeate form-box-field">
                                <b-row>
                                    <b-col lg="12" md="12">
                                        <div class="form-map-box">
                                            <!-- <b-col lg="12" md="12"> -->
                                                <!-- <div class="col-xl-12 col-md-12 search_common_margin_bottom_28 mobile_tab_max_width_flex"> -->
                                                <div class="submit-field">
                                                    <legend class="desktop_margin_bottom_zero">Select Companies</legend>
                                                    <b-form-group id="input-group-8" class="model_margin_bottom_zero">
                                                        <multiselect id="ajax" v-model="form.company_id" :disabled="disable_company" :class="disable_company ? 'common_disbaled_multiselect' : 'common_not_disbaled_multiselect'" :custom-label="customLabel" tag-placeholder="Search Company" placeholder="Type to search company" :options="companies" label="full_name" track-by="company_id" :multiple="false" :searchable="true" :clear-on-select="true" :close-on-select="true" :allow-empty="false" deselect-label="Can't remove this value" open-direction="bottom" class="model_multiselect job_list_multi_select model_multi_select admin_multi_select" ><span slot="noResult">No Company Found.</span></multiselect>
                                                    </b-form-group>
                                                </div>
                                                <!-- </div> -->
                                            <!-- </b-col> -->
                                            <!-- <b-col lg="12" md="12"> -->
                                            <div class="row">
                                                <div class="submit-field col-xl-6 effective_date_calender">
                                                    <!-- <b-form-group id="input-group-7" label="From Date" class="model_margin_bottom_zero"> -->
                                                        <h5>Effective Date<span class="required_star">*</span></h5>
                                                        <!-- <datepicker v-model="form.effective_date" :disabled-dates="disabledFrom" :format="customFormatterOne" clear-button='' class="ticket_close_btn_from_date"></datepicker> -->
                                                        <datepicker v-model="form.effective_date" :format="customFormatterOne" clear-button='' class="ticket_close_btn_from_date" @input="onChaneEffectiveDate"></datepicker>
                                                        
                                                        <span class="input-icon calender_input_icon obtainment_date_calender"><b-img :src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                                    <!-- </b-form-group> -->
                                                </div>
                                                <div class="submit-field col-xl-6 effective_date_calender">
                                                <!-- <b-form-group id="input-group-7" label="To Date" class="model_margin_bottom_zero"> -->
                                                    <h5>Expiry Date<span class="required_star">*</span></h5>
                                                    <datepicker v-model="form.expiration_date" :disabled-dates="disabledFrom" :format="customFormatterOne" clear-button='' class="ticket_close_btn_from_date"></datepicker>
                                                    <span class="input-icon calender_input_icon obtainment_date_calender"><b-img :src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                                <!-- </b-form-group> -->
                                                </div>
                                            </div>
                                            <!-- </b-col> -->
                                            <!-- <b-col lg="12" md="12"> -->
                                              
                                            <!-- </b-col> -->
                                            <!-- <b-col lg="12" md="12"> -->
                                            <div class="submit-field">
                                                <b-form-group id="input-group-9" label="Agreement ID" class="required model_margin_bottom_zero">
                                                    <b-form-input id="input-9" maxlength="50" v-model="form.agreement_id" type="text" placeholder="" class="with-border model_margin_bottom_zero"></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <!-- </b-col> -->
                                            <!-- <b-col lg="12" md="12"> -->
                                            <div class="submit-field model_file_upload">
                                                <b-form-group id="input-group-9" label="Upload File" class="required model_margin_bottom_zero">
                                                    <div class="uploadButton margin-top-0 web_bottom_zero" v-if="!showDocument">
                                                        <input class="uploadButton-input" type="file" accept=".pdf" @change="onDocumentChange" id="upload"/>
                                                        <label class="uploadButton-button ripple-effect common_z_index_zero" for="upload"  v-if="!showDocument">Upload</label>
                                                        <span class="new_upload" v-if="!showDocument">(Maximum file size: 10 MB. PDF Only)</span>
                                                        <!-- <span id="acra" class="uploadButton-file-name unset_min_height" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                                                    </div>

                                                    <div class="multiple_view_btn" v-if="showDocument">
                                                        <!-- <button v-if="showDocument && this.form.apply_for_dp == false" type="button" class="uploadButton-button ripple-effect" data-tippy-placement="top" @click="viewACRA($event)">View</button> -->

                                                        <div class="uploadButton margin-top-0 web_bottom_zero" v-if="showDocument">
                                                            <input class="uploadButton-input" type="file" accept=".pdf" @change="onDocumentChange" id="upload"/>
                                                            <label class="uploadButton-button ripple-effect replace_btn" for="upload"  v-if="showDocument">Replace</label>
                                                            <span class="uploadButton-file-name desktop_margin_bottom_zero new_upload" v-if="showDocument">(Maximum file size: 10 MB. PDF Only)</span>
                                                        </div>
                                                    </div>

                                                    
                                                    <span class="uploadButton-file-name unset_min_height" id="document" style="color: blue;font-weight: bold;text-decoration: underline;"></span>
                                                    <!-- <button v-if="showDocument && this.form.apply_for_dp == true" type="button" class="uploadButton-button ripple-effect single_view_btn" data-tippy-placement="top" @click="viewACRA($event)">View</button> -->
                                                    <div class="" v-if="showDocument">
                                                        <div class="file_name_full_div">
                                                            <span class="file_name_span">File Name: </span>
                                                            <span class="file_name_clr file_first_last">{{form.company_name | capitalize}} - <span  class="file_name_clr">Service Agreement.pdf</span>
                                                            </span>
                                                        </div>
                                                        <!-- <span></span> -->
                                                        <span>Uploaded On:</span>
                                                        <span class="file_name_clr">{{document_detail}}</span>
                                                        <span></span>
                                                        <!-- <i v-if="acraUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
                                                        <i v-else>DOC</i> -->
                                                    </div>
                                                </b-form-group>
                                            </div>
                                            <!-- </b-col> -->
                                        <div slot="footer" class="form-btn  gallery_btn common_crop_reset_btn">
                                            <b-button type="submit" variant="success">Submit</b-button>
                                            <b-button @click = "hideModel()" variant="light">Cancel</b-button>
                                        </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-form>
                </b-modal>
        </div>
</template>

<script>
import { POST_API } from '../../../../store/actions.type'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import permission from "../../../../../../server/permission.js";

export default {

    data() {
        return {
            statusOptions: [
                { value: 'active', label: 'Active' },
                { value: 'expired', label: 'Expired' },
                { value: 'deleted', label: 'Deleted' },
            ],

            fieldsEmpServiceAgreement: [
                { key: 'name', label: 'Company Name', sortable: true },
                { key: 'effective_date', label: 'Effective Date', sortable: true },
                { key: 'expiration_date', label: 'Expiration Date', sortable: true },
                { key: 'agreement_id', label: 'Agreement ID', sortable: true },
                { key: 'created_at', label: 'Created At', sortable: true },
                { key: 'status', label: 'Status', sortable: false },
                { key: 'document', label: 'Document', sortable: true },
                { key: 'toggleAction', label: 'Actions'},
            ],
            pager: {},
            pageOfItems: [],
            minDate: null,
            center: 'center',
            date: moment().format('YYYY-MM-DD'),
            items:null,
            totalRows: 0,
            format: 'YYYY-MM-DD HH:mm',
            from: '',
            to: '',
            currentPage: 1,
            searchKeyword:'',
            perPage: 25,
            auto: true,
            range: false,

            dateRange: {
                startDate: '',
                endDate: ''
            },
           
            pageOptions: [25, 50, 75, 100],
            dateRange: {
                startDate: moment().startOf('year').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            },
            
            locale: {
                direction: 'ltr', //direction of text
                format: 'DD-MMM-YYYY', //fomart of the dates displayed
                separator: ' - ', //separator between the two ranges
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
                daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
                monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
                firstDay: 0, //ISO first day of week - see moment documenations for details
                sameDateFormat: {
                    from: "DD MM YYYY, hh:mm",
                    to: "hh:mm",
                },
            },
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            currentTab:'overall',
            status:'',
            company_id_filter: '',
            form:{
                rowsPerPage:25,
                effective_date:'',
                expiration_date:'',
                company_id:'',
                document:'',
                agreement_id:'',
                selectedDocument:{
                    name:'',
                    image:'',
                },
            },
            webServerUrl: process.env.VUE_APP_SERVER_URL,
            companies: [],
            showDocument:false,
            
            modalTitle: 'Upload New Service Agreement',
            disabledFrom: {
                to: new Date(Date.now() - 8640000)
            },
            disable_company: false,
            start_date_filter:moment().startOf('year').format('YYYY-MM-DD'),
            end_date_filter:moment().format('YYYY-MM-DD'),
            actions:'',
            actionsEmployer:'',

        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        customFormatterOne(date) {
            return moment(date).utc().format('DD MMM YYYY hh:mm A');
        },
        
        removeUnderscore: function(value) {
            if (!value) return ''
            var i, frags = value.split('_');
            for (i=0; i<frags.length; i++) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
            }
            return frags.join(' ');
        },
        date: function(date) {
            if(date){
                return moment(date).format('DD MMM YYYY');
            }else{
                return '-'
            }
        },
        moment: function () {
    return moment();
  }
    },

    components: {
        DateRangePicker,
        Multiselect,
        Datepicker
    },
    methods:{
        customLabel({ first_name, last_name,name }) {
            return `${name} (${last_name} ${first_name})`
        },
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.getEmpServiceAgreement().then(() => {
                    this.$refs.datatableEmpServiceAgreement.refresh();
                });
            });
        },
        customFormatterOne(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        onChaneEffectiveDate(){
            this.form.expiration_date = moment(this.form.effective_date).add(1, 'year').subtract(1,'day').format('YYYY-MM-DD');
            this.disabledFrom = {
                to: new Date(moment(this.form.effective_date) - 8640000),
                from: new Date(moment(this.form.effective_date).add(3, 'year') - 8640000)
            }
        },
        clickToAdd(typeVal){
            if( typeVal == 'add'){
                this.getEmployers();
            }
            this.$refs['upload-modal'].show();
            this.form.agreement_id='';
            this.disable_company=false;
            this.showDocument=false;
            this.form.company_id='';
            this.form.effective_date='';
            this.form.expiration_date='';
            this.form.selectedDocument.image='';
            this.form.selectedDocument.name='';
            this.modalTitle = typeVal == 'add' ? 'Upload New Service Agreement' : 'Edit Service Agreement'
            this.type = typeVal == 'add' ? 'Add' : 'Update'
            this.disabledFrom = {
                to: new Date(Date.now() - 8640000)
            }
        },
        hideModel(){
            this.$refs['upload-modal'].hide();
            this.form.effective_date = '';
            this.form.expiration_date = '';
            this.form.agreement_id = '';
            this.form.document = '';
            this.form.company_id = '';
            this.showDocument = false;
            this.disable_company = false;

        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },
        downloadPdf(url){
          window.open(url);
        },
        onDocumentChange(event) {
            this.showDocument = false;
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.document = input.files[0];
                if (this.form.document.type != 'application/pdf'){
                    this.error_message = 'Please select only .pdf only.';
                    this.form.selectedDocument.image = '';
                    this.form.selectedDocument.name = '';
                    $('#document').empty().append('');
                    event.target.value = '';
                    this.showAlert();
                } else if (this.form.document.size > 10523520) {

                    this.form.selectedDocument.image = this.webUrl + '/images/default-user-icon.svg'
                    this.error_message = 'ACRA should be less than 10 MB.';
                    this.form.selectedDocument.image = '';
                    this.form.selectedDocument.name = '';
                    $('#document').empty().append('');
                    event.target.value = '';
                    this.showAlert();
                } else {
                    reader.onload = (e) => {
                        this.modelBox = 'logo';
                        this.form.selectedDocument.image = e.target.result;
                        this.form.selectedDocument.name = this.form.document.name;
                        $('#document').empty().append(this.form.selectedDocument.name)
                    }
                }
            }
        },
        formSubmit(evt) {
            evt.preventDefault();
            if(this.type == 'update'){
                var api = '/api/emp-service-agreement-update';
            }else{
                var api = '/api/emp-service-agreement-store';
            }
            var err = false;
            var msg = '';
            if(this.form.expiration_date){
                if(moment().diff(moment(this.form.expiration_date)) >= 0){
                    err = true;
                    msg = 'Please select correct expiration date';
                }
                if(moment(this.form.expiration_date).diff(moment(this.form.effective_date).add(3, 'year').subtract(1,'day')) >= 0 && err == false){
                    err = true;
                    msg = 'Effective date and expiration date difference should not be more than 3 years';
                }
            }
            
            if(err == false){
                this.$store.dispatch(POST_API, {
                data: {
                    id: this.id,
                    company_id: this.form.company_id.company_id ? this.form.company_id.company_id : '',
                    agreement_id: this.form.agreement_id,
                    expiration_date: this.form.expiration_date,
                    effective_date: this.form.effective_date,
                    document: this.form.selectedDocument.image,
                    items:this.form,
                },
                api: api,
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    if(this.type == 'update'){
                        var message='Service Agreement Updated successfully';
                    }else{
                        var message='Service Agreement Added successfully';
                    }
                    this.showSuccessAlert(message);
                    this.hideModel();
                }
            });
            }else{
                this.$swal({
                position: 'center',
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            });
            }
        },
        
        getCompanyById(id,company_id) {
            this.clickToAdd('update');
            this.type = 'update';
            this.id = id;
            return this.$store.dispatch(POST_API, {
                    data:{
                        id:id,
                        company_id:company_id

                    },
                    api: '/api/emp-service-agreement-edit'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.companies = this.$store.getters.getResults.all_company_data;
                        this.form.company_id = this.$store.getters.getResults.company_data_by_id[0];
                        this.form.company_name = this.$store.getters.getResults.company_data_by_id[0].name;
                        this.disable_company = this.form.company_id ? true : false;
                        this.document_detail = this.$store.getters.getResults.document_detail;
                        this.form.effective_date = this.$store.getters.getResults.data.effective_date;
                        this.form.expiration_date = this.$store.getters.getResults.data.expiration_date;
                        this.form.agreement_id = this.$store.getters.getResults.data.agreement_id;
                        this.form.selectedDocument.image = this.$store.getters.getResults.data.document;
                        this.showDocument = (this.$store.getters.getResults.data.document != '' && this.$store.getters.getResults.data.document != null) ? true : false;
                        this.disabledFrom = {
                            to: new Date(moment(this.form.effective_date) - 8640000),
                            from: new Date(moment(this.form.effective_date).add(1, 'year') - 8640000)
                        }
                    }
                });
        },
        deleteServiceAgreement(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/emp-service-agreement-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Service Agreement Deleted successfully.';
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
        changeEmpServiceAgreementFilter()
        {  
            const selectedStatusValue = this.status ? this.status.value : null;
            this.currentTab='overall';
            this.getEmpServiceAgreement(selectedStatusValue).then(() => {
                this.$refs.datatableEmpServiceAgreement.refresh();
            });
        
        },
        clearFilterEmpServiceAgreement(){
            this.filter = '';
            this.searchKeyword = '';
            this.status = '';
            this.dateRange.startDate = moment().startOf('year').format('YYYY-MM-DD'),
            this.dateRange.endDate = moment().format('YYYY-MM-DD');
            this.company_id_filter = '';

            this.$refs.datatableEmpServiceAgreement.refresh();
        },
        
        exportEmpServiceAgreement(){
            var base_url = this.webServerUrl;
            var page= this.currentPage;
            var rowsPerPage= this.form.rowsPerPage;
            var start_date= this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '';
            var end_date= this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') :  '';
            var sortBy=  this.sortBy ? this.sortBy : 'emp_service_agreements.created_at';
            var sortDirection=this.sortDesc ? 'desc' : 'asc';
            var status = this.status ? this.status : null;
            var company_id_filter = this.company_id_filter.company_id ? this.company_id_filter.company_id : null;
            var keyword = this.filter ? this.filter : null;


            // var export_url = '/api/export-pt-user/'+this.currentTab+'/'+this.userDPAccountStatusFilterAll+'/'+this.userAccountStatusFilterAll+'/'+this.filterRejected+'/null/null'+'/'+this.form.user_id;
            var export_url = '/api/emp-service-agreement-excel/'+keyword+'/'+status+'/'+company_id_filter+'/'+page+'/'+rowsPerPage+'/'+start_date+'/'+end_date+'/'+sortBy+'/'+sortDirection;
        
        
            window.open(base_url+export_url,'_blank');
            // window.open(export_url,'_blank')

        },
        
        getEmployers(){
                this.$store.dispatch(POST_API, {
                    data:{
                    },
                    api:'/api/emp-service-agreement-allcompany'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.$swal({
                            position: 'center',
                            icon: 'error',
                            title: this.error_message,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    } else {
                        this.companies = this.$store.getters.getResults.data;
                    }
                });
            },
        getEmpServiceAgreement() {
            return this.$store.dispatch(POST_API, {
                data:{
                    page: this.currentPage,
                    keyword: this.filter,
                    type:this.currentTab,
                    status:this.status ? this.status.value : null,
                    start_date:this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                    end_date:this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                    rowsPerPage:this.form.rowsPerPage,
                    sortBy: this.sortBy ? this.sortBy: 'emp_service_agreements.created_at',
                    sortDirection:this.sortDesc ? 'desc' : 'asc',
                    company_id: this.company_id_filter.company_id ? this.company_id_filter.company_id : '',

                },
                api: '/api/emp-service-agreement-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    // this.showAlert();
                    return [];
                } else {
                    this.items = this.$store.getters.getResults.pageOfItems;
                    this.totalRows = this.$store.getters.getResults.pager.totalItems;
                    this.perPage = this.$store.getters.getResults.pager.pageSize;
                    this.from = this.$store.getters.getResults.pager.from;
                    this.to = this.$store.getters.getResults.pager.to;
                    return this.items;
                }
            });
        },
        permissionStaff(){
            if(this.$store.getters.getCurrentUserPermission){
              if (this.$store.getters.getCurrentUserPermission.data.length > 0 && this.$store.getters.currentUser.user_type_id == 2) {
               var menu = this.$store.getters.getCurrentUserPermission.data;
               this.actions = permission.getPermissionStaff(menu,'Service Aggremnet');
               this.actionsEmployer = permission.getPermissionStaff(menu,'Companies');

              }
            }
        },
        show(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                 return this.actions.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
        showEmployer(action){
            if (this.$store.getters.currentUser.user_type_id == 2) {
                return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;  
            }else{
                return true;
            }
        },
    },
     mounted(){
            $(".show_filter_arrow .table thead th div").contents().unwrap();
            $(".dashboard-content-container .simplebar-scroll-content").animate({ 
            scrollTop: 0 
            }, "fast");
            //document.title = "EL Connect - Jobseeker - Transaction";
            this.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
            this.getEmployers();
            this.permissionStaff();

            
     }
}
</script>
