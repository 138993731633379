<template>
    <div>
      <div>
          <div
            class="dashboard-content-inner my_custom_dash employer_manage_job mobile_min_width_550 desktop_padding_top_zero"
          >
            <!-- Dashboard Headline -->
            <div class="dashboard-headline">
              <h1>DP Jobs</h1>

              <!-- Breadcrumbs -->
              <nav id="breadcrumbs" class="dark employer_nav">
                <ul>
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Job</a></li>
                  <li>Manage Jobs</li>
                </ul>
              </nav>
            </div>
            <!-- Row -->
            <!-- <div class="row emplpyees_manage_job_emp_row" > -->
            <div class="row">
              <!-- Dashboard Box -->
              <!-- <div class="col-xl-12 mobile_left_right_zero_pdng"> -->
              <div class="col-xl-12">
                <div class="dashboard-box margin-top-0 daily_dashboard_box">
                  <!-- Headline -->
                  <!--<div class="headline">
                                <h3><i class="icon-material-outline-business-center"></i> My Job Listings</h3>
                            </div>-->
                  <!-- <div v-if="loading" >
                            <div class = 'loader'>
                            </div> -->

                  <!-- here put a spinner or whatever you want to indicate that a request is in progress -->
                  <!-- </div> -->
                  <!-- <div style="float: right;margin-top: -25px;">
                        <b-link class="btn green-border-btn" :to="{name:'DailyJobPost'}">Post Job</b-link>
                    </div> -->
                  <!-- <div class="double_btn" style="text-align:left !important"> -->

                  <!-- <button>Create Job</button> -->

                  <!-- <div class="col-xl-12"> -->

                  <!-- </div> -->
                  <div class="tab-section daily_job_mange_div manage_job_new_tabs_layout dp_tab_margin_top_zero">
                    <b-tabs class="my_tab_job remove_bx">
                      <div class="row desktop_search_top_div">
                        <div class="col-xl-12">
                          <div class="row">
                            <!-- <div class="upper_mix_functiobality"> -->
                            <!-- <div class="col-xl-4 col-md-4 mobile_max_width_and_flex mobile_paddding_unset search_div_daily_job new_search_manage_job"> -->
                            <div class="col-xl-3 search_common_margin_bottom_28 submit-field mobile_tab_max_width_flex  mobile_max_width_and_flex new_search_div_daily_job new_search_manage_job">
                              <div
                                class="search-box custom_search_admin new_custom_search_admin_remove"
                              >
                                <div role="group" class="input-group">
                                  <!---->
                                  <div
                                    class="input-group-prepend"
                                  >
                                    <input
                                      id="searchBtn"
                                      type="text"
                                      placeholder="Search by keyword"
                                      v-model="filterSearch"
                                      v-on:keyup.enter="
                                        getList(activeTab, activeApplicantTab, filterSearch)
                                      "
                                      class="form-control"
                                    />
                                    <b-button class="btn points_events"
                                      ><i
                                        class="fa fa-search"
                                        @click="getList(activeTab, activeApplicantTab, filterSearch)"
                                      ></i
                                    ></b-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-md-3 dropdown_daily_job">
                                        <div class="label_div text_align_end_desktop">
                                            <span>Company:</span>
                                        </div>
                                        <div class="input_div select_dropdown">
                                        <select name="cars" id="cars">
                                            <option value="All">All</option>
                                            <option value="saab">Saab</option>
                                            <option value="mercedes">Mercedes</option>
                                            <option value="audi">Audi</option>
                                            </select>
                                        </div>

                                    </div> -->
                            <div class='col-xl-2 search_common_margin_bottom_28 submit-field'>

                            <span class="search-box custom_search_admin new_custom_search_admin_remove">
                              <b-form-select
                                v-model="cat_id"
                                @input="changeTabStatus(
                                          activeTab
                                        )"
                                        placeholder="Please Select Services"
                                class="model_margin_bottom_zero common_select_size common_border_search common_select_focus"
                              >
                                <option value="0">Select Services</option>
                                <option
                                  :value="categoryVal.id"
                                  v-for="categoryVal in categories"
                                  :key="categoryVal.id"
                                >
                                  {{ categoryVal.name }}
                                </option>
                              </b-form-select>
                            </span>
                            </div>
                            <div class="col-xl-2 search_common_margin_bottom_28 submit-field">
                                <span class="search-box custom_search_admin new_custom_search_admin_remove">
                                  <b-form-select
                                    v-model="company_id"
                                    @input="changeTabStatus(
                                              activeTab
                                            )"
                                    class="model_margin_bottom_zero common_select_size common_border_search common_select_focus"
                                  >
                                    <!-- Placeholder-like option -->
                                    <option  value="0">Please Select Company</option>
                                    <option
                                      :value="companyVal.id"
                                      v-for="companyVal in companies"
                                      :key="companyVal.id"
                                    >
                                      {{ companyVal.name }}
                                    </option>
                                  </b-form-select>
                                </span>
                              </div>

                            <div class="col-xl-4 search_common_margin_bottom_28 submit-field" >
                              <div class="reset_btn_full_flex">
                                <div
                                  class="calender_div_job_in_job calneder_custom_icon width_100_per calender_border"
                                >
                                  <label
                                    class="timesheet_label new_style_label desktop_margin_bottom_zero new_layout_manage_job_label"
                                    >Date Range:</label
                                  >
                                  <!-- <div class="input_div"> -->
                                  <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                    <date-range-picker
                                      ref="picker"
                                      :minDate="minDate"
                                      :opens="center"
                                      :locale-data="locale"
                                      :auto-apply="auto"
                                      v-model="dateRange"
                                      :ranges="range"
                                      @update="
                                        changeTabStatus(
                                          activeTab
                                        )
                                      "
                                      class="common_date_range_picker_new  chatbot_tab_calender web_bottom_zero timesheet_calender_new calender_border transactions_jb_calender calender_div_job_in_job common_width_100"
                                    >
                                      <div slot="input" slot-scope="picker">
                                        {{ picker.startDate | datePicker1 }} -
                                        {{ picker.endDate | datePicker1 }}
                                      </div>
                                    </date-range-picker>
                                    <!-- <span class="input-icon manage_job_icon_input new_icon_input">
                                      <b-img
                                        v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                                        alt="Calander Icon"
                                      ></b-img>
                                    </span> -->
                                  </b-form-group>
                                  <!-- </div> -->
                                  <!-- <div class="input_div">
                                                    <input type="date" id="birthday" name="birthday">
                                                </div> -->
                                </div>
                                <!-- <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                                            <button @click="clear" class="">Reset</button>
                                            </div> -->
                              </div>
                            </div>
                            <div class="col-xl-1">
                              <div class="common_reset_admin_dp reset_btn_top reset_btn_mrgn reset_btn_manage_job_new pl-0 reset_on_big_screen">
                                <button @click="clear" class="pl-0">Reset Filter</button>
                              </div>
                            </div>
                            <!-- <div
                              class="common_desktop_hide col-8 col-sm-8 col-md-8 col-xs-8 search_common_margin_bottom_28 submit-field"
                            >
                              <div class="reset_btn_full_flex">
                                <div
                                  class="calender_div_job_in_job calneder_custom_icon width_100_per calender_border"
                                >
                                  <label
                                    class="new_style_label desktop_margin_bottom_zero new_layout_manage_job_label"
                                    >Date Range:</label
                                  >
                                  <b-form-group id="input-group-6" class="desktop_margin_bottom_zero">
                                    <date-range-picker
                                      ref="picker"
                                      :minDate="minDate"
                                      :opens="center"
                                      :locale-data="locale"
                                      :auto-apply="auto"
                                      v-model="dateRange"
                                      :ranges="range"
                                      @update="
                                        changeTabStatus(activeTab, activeApplicantTab, filterSearch, '')
                                      "
                                      class="manage_job_date_claneder date_range_border_color_manage_job common_date_range_picker_new"
                                    >
                                      <div slot="input" slot-scope="picker">
                                        {{ picker.startDate | datePicker1 }} -
                                        {{ picker.endDate | datePicker1 }}
                                      </div>
                                    </date-range-picker>
                                    <span class="input-icon manage_job_icon_input new_icon_input">
                                      <b-img
                                        v-bind:src="webUrl + 'assets/images/calendar-grey-icon02.svg'"
                                        alt="Calander Icon"
                                      ></b-img>
                                    </span>
                                  </b-form-group>
                                </div>
                              </div>
                            </div> -->
                            <!-- <div class="common_desktop_hide col-4 col-sm-4 col-md-4 col-xs-4">
                              <div class="reset_btn_top reset_btn_mrgn reset_btn_manage_job_new">
                                <button @click="clear" class="">Reset Filter</button>
                              </div>
                            </div> -->
                            <!-- </div> -->
                          </div>
                        </div>
                      </div>
                      <b-tab
                        :title="'IN PROGRESS (' + inprogressCount + ')'"
                        :active="inprogress"
                        @click.prevent="resetFilter(); changeTabStatus('inprogress')"
                        class=""
                      >
                      <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                          <div class="box_shadow box_new_shadow">
                            <div class="padding_manage_job">
                              <ul class="com_id_ul">
                                <li>
                                    <a
                                      href="javascript:void(0);"
                                      @click="dpJobs(jobDataVal.id, jobDataVal.user_job_status)"

                                    >
                                      {{ jobDataVal.job_unique_id }}
                                    </a>
                                  </li>

                                <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->


                                <li>
                                  <button
                                    class="service_cat_name_manage_job"
                                    style="pointer-events: none; border: 1px solid"
                                    :style="{
                                      color: jobDataVal.serviceCat_color,
                                      borderColor: jobDataVal.serviceCat_color,
                                    }"
                                  >
                                    {{ jobDataVal.serviceCat_name }}
                                  </button>
                                </li>
                              </ul>

                              <div class="heading_with_status">
                                <div class="comp_heading_div">
                                  <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                                </div>
                                <!-- <div class="rate_div new_rate_divs"> -->
                                <!-- <div class="status_btn new_status_btn_divs"> -->
                                <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                                <!-- <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link> -->
                                <!-- <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link> -->

                                <!-- <div class="task-count-text" v-if="jobDataVal.pdf_url != null && showPermissionEM('show sr') && showJobListRateAdmin('show_job_list_rate')">
                                                                    <div class="small_pdf_box">
                                                                        <img :src="webUrl+ 'assets/images/pdf.png'" style="max-height: 36px;" @click="pdfViewer(jobDataVal.pdf_url)" >
                                                                    </div>
                                                                </div> -->
                                <!-- </div> -->
                                <!-- <div class="status_btn">
                                                                <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                        </div> -->
                                <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">

                                                            <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                        </div> -->
                                <!-- <div class="action_div">
                                                            <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                        </div> -->
                                <!-- </div> -->
                              </div>
                              <!-- <div class="img_with_desc">

                                                </div> -->
                              <ul class="manage_job_top_ul_nw">
                                <li>
                                  <span class="material-icons-outlined">calendar_month_outlined</span
                                  >{{ jobDataVal.start_date | moment_1 }}
                                </li>
                                <li>
                                  <span class="material-icons-outlined">access_time_outlined</span
                                  >{{ jobDataVal.start_time | jobStartEndTime }} -
                                  {{ jobDataVal.end_time | jobStartEndTime }}
                                  <span class="red_note_manage_job"
                                    ><p v-if="jobDataVal.lunch_pay_hours != null">
                                      <strong>Note:</strong> Break hour not paid -
                                      {{ jobDataVal.lunch_pay_hours }} Hr
                                    </p></span
                                  >
                                </li>
                                <li
                                  v-if="
                                    showPermissionEM('show list rate') &&
                                    showJobListRateAdmin('show_job_list_rate')
                                  "
                                >
                                  <span class="material-icons-outlined">monetization_on_outlined</span
                                  >${{ jobDataVal.hourly_cost }}/Hour
                                </li>
                              </ul>
                              <div class="user_apl">
                                <img
                                  :src="webUrl + 'assets/images/pdf.png'"
                                  v-if="
                                    jobDataVal.pdf_url != null &&
                                    showPermissionEM('show sr') &&
                                    showJobListRateAdmin('show_job_list_rate')
                                  "
                                  style="max-height: 36px"
                                  @click="pdfViewer(jobDataVal.pdf_url)"
                                  class="pdf_manage_job"
                                />

                                <!-- <div class="working_completed_section">
                                  <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                                  ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                                </div> -->
                              </div>
                              <div class="full_desc_emps">
                                <!-- <ul>
                                      <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                      <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                      <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                      <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                      <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                      <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                      <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                                  </ul> -->
                              <div class="single_accordian manage_job_accordian mb-3">
                                <!-- <br> -->
                                <div>
                                  <div class="faq-content-box clearfix">
                                    <div>
                                      <div class="accordion-listing-box coloured_icon">
                                        <b-card no-body>
                                          <b-card-header header-tag="header" role="tab">
                                            <b-button
                                              v-b-toggle="
                                                'accordion_view_job_details_completed-' + jobDatakey
                                              "
                                              class="m-1"
                                              variant="info"
                                            >
                                              <div class="dashboard-box">
                                                <div class="headline">
                                                  <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                                </div>
                                              </div>
                                            </b-button>
                                          </b-card-header>
                                          <b-collapse
                                            :id="'accordion_view_job_details_completed-' + jobDatakey"
                                            role="tabpanel"
                                          >
                                            <b-card-body>
                                              <div class="">
                                                <div class="row">
                                                  <div class="col-xl-12">
                                                    <div
                                                      class="comp_heading_div_manage_job"
                                                      v-if="jobDataVal.posted_by"
                                                    >
                                                      <div class="dtl_text">
                                                        <h2>Posted By:</h2>
                                                        <p class="mb-0">
                                                          {{
                                                            jobDataVal.posted_by
                                                          }}

                                                        </p>
                                                        <p class="mb-0">
                                                          {{
                                                            jobDataVal.created_at
                                                              | customFormatterOnecreated
                                                          }}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                    >
                                                      <table
                                                        class="table custom_manage_job_tbl unset_last_super"
                                                      >
                                                        <thead>
                                                          <tr>
                                                            <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                            <!-- <th>Job Posted Date & Time:</th> -->
                                                            <!-- <th>Job Date & Time:</th>
                                                                                            <th>Job Hours:</th>
                                                                                            <th>Vacancy:</th> -->
                                                            <th
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              Work Site Contact Person
                                                            </th>
                                                            <th></th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <!-- <td v-if="jobDataVal.userData">
                                                                                                {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                            </td> -->
                                                            <!-- <td>
                                                                                                {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                            </td> -->
                                                            <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                            <td>{{jobDataVal.working_hours}}</td>
                                                                                            <td>
                                                                                                {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                            </td> -->
                                                            <td
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              {{
                                                                jobDataVal.work_site_contact_person
                                                                  ? jobDataVal.work_site_contact_person
                                                                  : ""
                                                              }}
                                                              <br />
                                                              {{
                                                                jobDataVal.work_site_contact_person_country_code
                                                                  ? jobDataVal.work_site_contact_person_country_code +
                                                                    "-"
                                                                  : ""
                                                              }}{{
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_mobile_no
                                                                  : ""
                                                              }}<br />
                                                            </td>
                                                            <td
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              {{
                                                                jobDataVal.work_site_contact_person_2
                                                                  ? jobDataVal.work_site_contact_person_2
                                                                  : ""
                                                              }}<br />
                                                              {{
                                                                jobDataVal.work_site_contact_person_2_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_2_country_code +
                                                                    "-"
                                                                  : ""
                                                              }}{{
                                                                jobDataVal.work_site_contact_person_2_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                  : ""
                                                              }}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div class="dtl_text">
                                                      <div
                                                        class="large-content-requirement-completed"
                                                      >
                                                        <h2 v-if="jobDataVal.description">
                                                          Job Requirements
                                                          <span class="manage_job_eye_span"
                                                            ><i
                                                              class="fa fa-eye-slash"
                                                              :id="
                                                                'manage_job_requirement_completed_eye_icon' +
                                                                jobDatakey
                                                              "
                                                              @click="
                                                                ManageJobRequirementCompletedEyeReveal(
                                                                  jobDatakey
                                                                )
                                                              "
                                                              style="cursor: pointer"
                                                            ></i
                                                          ></span>
                                                        </h2>
                                                        <p
                                                          :id="
                                                            'visible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.description.length <= 249"
                                                        >
                                                          {{
                                                            jobDataVal.description.substring(0, 249)
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'visible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.description.length >= 250"
                                                        >
                                                          {{
                                                            jobDataVal.description.substring(0, 250) +
                                                            ".."
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'invisible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style="display: none"
                                                          class=""
                                                        >
                                                          {{ jobDataVal.description }}
                                                        </p>
                                                      </div>
                                                      <div class="large-content-scope-completed">
                                                        <h2 v-if="jobDataVal.job_scope">
                                                          Job Scope:
                                                          <span class="manage_job_eye_span"
                                                            ><i
                                                              class="fa fa-eye-slash"
                                                              :id="
                                                                'manage_job_scope_completed_eye_icon' +
                                                                jobDatakey
                                                              "
                                                              @click="
                                                                ManageJobScopeCompletedEyeReveal(
                                                                  jobDatakey
                                                                )
                                                              "
                                                              style="cursor: pointer"
                                                            ></i
                                                          ></span>
                                                        </h2>
                                                        <p
                                                          :id="
                                                            'visible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.job_scope.length <= 249"
                                                        >
                                                          {{ jobDataVal.job_scope.substring(0, 249) }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'visible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.job_scope.length >= 250"
                                                        >
                                                          {{
                                                            jobDataVal.job_scope.substring(0, 250) +
                                                            ".."
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'invisible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style="display: none"
                                                          class=""
                                                        >
                                                          {{ jobDataVal.job_scope }}
                                                        </p>
                                                      </div>
                                                      <h2 v-if="jobDataVal.address">Job Location</h2>
                                                      <p v-if="jobDataVal.address" class="mb-0">
                                                        {{ jobDataVal.address }}
                                                      </p>
                                                      <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                      <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </b-card-body>
                                          </b-collapse>
                                        </b-card>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- New Layout Start -->
                              <b-table
                                  ref="datatable"
                                  show-empty
                                  striped
                                  hover
                                  responsive
                                  :items="[jobDataVal]"
                                  :fields="Dpfieldsadmin"
                                  :filter="filter"
                                  :sort-by.sync="sortBy"
                                  :sort-desc.sync="sortDesc"
                                  :sort-direction="sortDirection"
                                  class="tbl_height dp_jobs_admin_table"
                                  thead-tr-class="d-none"
                                >
                                <template #cell(fl_name)="items">
                                  <div class="daily_image_job">
                                    <div class="daily_job_manage_img_div">
                                      <b-img
                                        :src="
                                          items.item.profile_image != null
                                            ? items.item.profile_image
                                            : webUrl + 'assets/images/user-avatar-small-01.png'
                                        "
                                        alt="User Icon"
                                        height="100px"
                                        width="100px"
                                        class="user_img_emp"
                                      ></b-img>
                                      <div class="custom_star_rate_manage_job">
                                        <span><i class="fa fa-star"></i>{{userRating}}</span>
                                      </div>
                                    </div>
                                    <div class="daily_job_manage_user_description_div">
                                      <h2>
                                        {{ items.item.fl_name }}
                                      </h2>
                                      <div class="phone_nmber_div">
                                        <a href="javascript:void(0);">
                                          {{
                                            items.item.work_site_contact_person_country_code
                                              ? items.item.work_site_contact_person_country_code
                                              : ""
                                          }}-{{
                                            items.item.work_site_contact_person_mobile_no
                                              ? items.item.work_site_contact_person_mobile_no
                                              : ""
                                          }}
                                        </a>
                                      </div>
                                      <div>
                                        <!-- Loop through user_badges -->
                                        <b-img
                                          v-for="(badge, index) in user_badges"
                                          :key="index"
                                          class="worked_for_logo"
                                          :src="badge.badge_image"
                                          alt="Badge Icon"
                                        ></b-img>
                                      </div>
                                    </div>
                                  </div>
                                </template>

                                  <template #cell(completedCount)="items">
                                    <div class="phone_nmber_div">
                                        <a href="javascript:void(0);"
                                          >Upcoming Applied -
                                          {{upcomingCount}}</a
                                        >
                                      </div>
                                      <div class="phone_nmber_div">
                                        <a href="javascript:void(0);"
                                          >Completed Jobs -
                                          {{completedCount}}</a
                                        >
                                      </div>
                                  </template>
                              </b-table>
                              <!-- New Layout End -->

                              <!-- Old Layout Start -->
                                <!-- <div class="rating_box_content pt-lg-4 pt-3">
                                <div class="d-lg-flex d-md-flex justify-content-between align-items-center">
                                      <div class="name-td-box d-flex align-items-center">
                                        <b-img
                                                              :src="
                                                                jobDataVal.profile_image !=
                                                                null
                                                                  ? jobDataVal.profile_image
                                                                  : webUrl +
                                                                    'assets/images/user-avatar-small-01.png'
                                                              "
                                                              alt="User Icon"
                                                              height="100px"
                                                              width="100px"
                                                              class="user_img_emp"
                                        ></b-img>
                                          <p class="pl-3"><b>{{jobDataVal.fl_name}} </b></p>
                                      </div>
                                      <div class="td-address-box d-flex align-items-center">
                                          <p class="grey-text">Your Rating:
                                            <span v-for="n in 5" :key="n" class="star">
                                              {{ n <= jobDataVal.rating ? '★' : '☆' }}
                                            </span>
                                          </p>
                                      </div>
                                      <div>
                                          <div class="">
                                          <span class="add-icon clock-icon">
                                            Total Completed Jobs:
                                              {{completedCount}}
                                      </span><br>




                                      </div>
                                      </div>
                                    </div>
                              </div>  -->
                              <!-- Old Layout End -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                          v-if="jobDatas.length == 0"
                        >
                          <p class="actionData"><strong>No Record Found</strong></p>
                        </div> -->
                      </b-tab>
                      <b-tab
                        :title="'UPCOMING (' + upcomingCount + ')'"
                        :active="upcoming"
                        @click.prevent="resetFilter(); changeTabStatus('upcoming')"
                        class="reject_last_li"
                      >
                      <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                          <div class="box_shadow box_new_shadow">
                            <div class="padding_manage_job">
                              <ul class="com_id_ul">
                                <li>
                                    <a
                                      href="javascript:void(0);"
                                      @click="dpJobs(jobDataVal.id, jobDataVal.user_job_status)"

                                    >
                                      {{ jobDataVal.job_unique_id }}
                                    </a>
                                  </li>

                                <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->


                                <li>
                                  <button
                                    class="service_cat_name_manage_job"
                                    style="pointer-events: none; border: 1px solid"
                                    :style="{
                                      color: jobDataVal.serviceCat_color,
                                      borderColor: jobDataVal.serviceCat_color,
                                    }"
                                  >
                                    {{ jobDataVal.serviceCat_name }}
                                  </button>
                                </li>






                              </ul>

                              <div class="heading_with_status">
                                <div class="comp_heading_div">
                                  <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                                </div>
                                <!-- <div class="rate_div new_rate_divs"> -->
                                <!-- <div class="status_btn new_status_btn_divs"> -->
                                <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                                <!-- <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link> -->
                                <!-- <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link> -->

                                <!-- <div class="task-count-text" v-if="jobDataVal.pdf_url != null && showPermissionEM('show sr') && showJobListRateAdmin('show_job_list_rate')">
                                                                    <div class="small_pdf_box">
                                                                        <img :src="webUrl+ 'assets/images/pdf.png'" style="max-height: 36px;" @click="pdfViewer(jobDataVal.pdf_url)" >
                                                                    </div>
                                                                </div> -->
                                <!-- </div> -->
                                <!-- <div class="status_btn">
                                                                <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                        </div> -->
                                <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">

                                                            <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                        </div> -->
                                <!-- <div class="action_div">
                                                            <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                        </div> -->
                                <!-- </div> -->
                              </div>
                              <!-- <div class="img_with_desc">

                                                </div> -->
                              <ul class="manage_job_top_ul_nw">
                                <li>
                                  <span class="material-icons-outlined">calendar_month_outlined</span
                                  >{{ jobDataVal.start_date | moment_1 }}
                                </li>
                                <li>
                                  <span class="material-icons-outlined">access_time_outlined</span
                                  >{{ jobDataVal.start_time | jobStartEndTime }} -
                                  {{ jobDataVal.end_time | jobStartEndTime }}
                                  <span class="red_note_manage_job"
                                    ><p v-if="jobDataVal.lunch_pay_hours != null">
                                      <strong>Note:</strong> Break hour not paid -
                                      {{ jobDataVal.lunch_pay_hours }} Hr
                                    </p></span
                                  >
                                </li>
                                <li
                                  v-if="
                                    showPermissionEM('show list rate') &&
                                    showJobListRateAdmin('show_job_list_rate')
                                  "
                                >
                                  <span class="material-icons-outlined">monetization_on_outlined</span
                                  >${{ jobDataVal.hourly_cost }}/Hour
                                </li>
                              </ul>
                              <div class="user_apl">
                                <img
                                  :src="webUrl + 'assets/images/pdf.png'"
                                  v-if="
                                    jobDataVal.pdf_url != null &&
                                    showPermissionEM('show sr') &&
                                    showJobListRateAdmin('show_job_list_rate')
                                  "
                                  style="max-height: 36px"
                                  @click="pdfViewer(jobDataVal.pdf_url)"
                                  class="pdf_manage_job"
                                />

                                <!-- <div class="working_completed_section">
                                  <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                                  ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                                </div> -->
                              </div>
                              <div class="full_desc_emps">
                                <!-- <ul>
                                      <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                      <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                      <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                      <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                      <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                      <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                      <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                                  </ul> -->
                              <div class="single_accordian manage_job_accordian mb-3">
                                <!-- <br> -->
                                <div>
                                  <div class="faq-content-box clearfix">
                                    <div>
                                      <div class="accordion-listing-box coloured_icon">
                                        <b-card no-body>
                                          <b-card-header header-tag="header" role="tab">
                                            <b-button
                                              v-b-toggle="
                                                'accordion_view_job_details_completed-' + jobDatakey
                                              "
                                              class="m-1"
                                              variant="info"
                                            >
                                              <div class="dashboard-box">
                                                <div class="headline">
                                                  <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                                </div>
                                              </div>
                                            </b-button>
                                          </b-card-header>
                                          <b-collapse
                                            :id="'accordion_view_job_details_completed-' + jobDatakey"
                                            role="tabpanel"
                                          >
                                            <b-card-body>
                                              <div class="">
                                                <div class="row">
                                                  <div class="col-xl-12">
                                                    <div
                                                      class="comp_heading_div_manage_job"
                                                      v-if="jobDataVal.posted_by"
                                                    >
                                                      <div class="dtl_text">
                                                        <h2>Posted By:</h2>
                                                        <p class="mb-0">
                                                          {{
                                                            jobDataVal.posted_by
                                                          }}

                                                        </p>
                                                        <p class="mb-0">
                                                          {{
                                                            jobDataVal.created_at
                                                              | customFormatterOnecreated
                                                          }}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                    >
                                                      <table
                                                        class="table custom_manage_job_tbl unset_last_super"
                                                      >
                                                        <thead>
                                                          <tr>
                                                            <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                            <!-- <th>Job Posted Date & Time:</th> -->
                                                            <!-- <th>Job Date & Time:</th>
                                                                                            <th>Job Hours:</th>
                                                                                            <th>Vacancy:</th> -->
                                                            <th
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              Work Site Contact Person
                                                            </th>
                                                            <th></th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <!-- <td v-if="jobDataVal.userData">
                                                                                                {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                            </td> -->
                                                            <!-- <td>
                                                                                                {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                            </td> -->
                                                            <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                            <td>{{jobDataVal.working_hours}}</td>
                                                                                            <td>
                                                                                                {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                            </td> -->
                                                            <td
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              {{
                                                                jobDataVal.work_site_contact_person
                                                                  ? jobDataVal.work_site_contact_person
                                                                  : ""
                                                              }}
                                                              <br />
                                                              {{
                                                                jobDataVal.work_site_contact_person_country_code
                                                                  ? jobDataVal.work_site_contact_person_country_code +
                                                                    "-"
                                                                  : ""
                                                              }}{{
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_mobile_no
                                                                  : ""
                                                              }}<br />
                                                            </td>
                                                            <td
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              {{
                                                                jobDataVal.work_site_contact_person_2
                                                                  ? jobDataVal.work_site_contact_person_2
                                                                  : ""
                                                              }}<br />
                                                              {{
                                                                jobDataVal.work_site_contact_person_2_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_2_country_code +
                                                                    "-"
                                                                  : ""
                                                              }}{{
                                                                jobDataVal.work_site_contact_person_2_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                  : ""
                                                              }}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div class="dtl_text">
                                                      <div
                                                        class="large-content-requirement-completed"
                                                      >
                                                        <h2 v-if="jobDataVal.description">
                                                          Job Requirements
                                                          <span class="manage_job_eye_span"
                                                            ><i
                                                              class="fa fa-eye-slash"
                                                              :id="
                                                                'manage_job_requirement_completed_eye_icon' +
                                                                jobDatakey
                                                              "
                                                              @click="
                                                                ManageJobRequirementCompletedEyeReveal(
                                                                  jobDatakey
                                                                )
                                                              "
                                                              style="cursor: pointer"
                                                            ></i
                                                          ></span>
                                                        </h2>
                                                        <p
                                                          :id="
                                                            'visible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.description.length <= 249"
                                                        >
                                                          {{
                                                            jobDataVal.description.substring(0, 249)
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'visible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.description.length >= 250"
                                                        >
                                                          {{
                                                            jobDataVal.description.substring(0, 250) +
                                                            ".."
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'invisible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style="display: none"
                                                          class=""
                                                        >
                                                          {{ jobDataVal.description }}
                                                        </p>
                                                      </div>
                                                      <div class="large-content-scope-completed">
                                                        <h2 v-if="jobDataVal.job_scope">
                                                          Job Scope:
                                                          <span class="manage_job_eye_span"
                                                            ><i
                                                              class="fa fa-eye-slash"
                                                              :id="
                                                                'manage_job_scope_completed_eye_icon' +
                                                                jobDatakey
                                                              "
                                                              @click="
                                                                ManageJobScopeCompletedEyeReveal(
                                                                  jobDatakey
                                                                )
                                                              "
                                                              style="cursor: pointer"
                                                            ></i
                                                          ></span>
                                                        </h2>
                                                        <p
                                                          :id="
                                                            'visible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.job_scope.length <= 249"
                                                        >
                                                          {{ jobDataVal.job_scope.substring(0, 249) }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'visible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.job_scope.length >= 250"
                                                        >
                                                          {{
                                                            jobDataVal.job_scope.substring(0, 250) +
                                                            ".."
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'invisible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style="display: none"
                                                          class=""
                                                        >
                                                          {{ jobDataVal.job_scope }}
                                                        </p>
                                                      </div>
                                                      <h2 v-if="jobDataVal.address">Job Location</h2>
                                                      <p v-if="jobDataVal.address" class="mb-0">
                                                        {{ jobDataVal.address }}
                                                      </p>
                                                      <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                      <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </b-card-body>
                                          </b-collapse>
                                        </b-card>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- New Layout Start -->
                              <b-table
                                  ref="datatable"
                                  show-empty
                                  striped
                                  hover
                                  responsive
                                  :items="[jobDataVal]"
                                  :fields="Dpfieldsadmin"
                                  :filter="filter"
                                  :sort-by.sync="sortBy"
                                  :sort-desc.sync="sortDesc"
                                  :sort-direction="sortDirection"
                                  class="tbl_height dp_jobs_admin_table"
                                  thead-tr-class="d-none"
                                >
                                <template #cell(fl_name)="items">
                                  <div class="daily_image_job">
                                    <div class="daily_job_manage_img_div">
                                      <b-img
                                        :src="
                                          items.item.profile_image != null
                                            ? items.item.profile_image
                                            : webUrl + 'assets/images/user-avatar-small-01.png'
                                        "
                                        alt="User Icon"
                                        height="100px"
                                        width="100px"
                                        class="user_img_emp"
                                      ></b-img>
                                      <div class="custom_star_rate_manage_job">
                                        <span><i class="fa fa-star"></i>{{userRating}}</span>
                                      </div>
                                    </div>
                                    <div class="daily_job_manage_user_description_div">
                                      <h2>
                                        {{ items.item.fl_name }}
                                      </h2>
                                      <div class="phone_nmber_div">
                                        <a href="javascript:void(0);">
                                          {{
                                            items.item.work_site_contact_person_country_code
                                              ? items.item.work_site_contact_person_country_code
                                              : ""
                                          }}-{{
                                            items.item.work_site_contact_person_mobile_no
                                              ? items.item.work_site_contact_person_mobile_no
                                              : ""
                                          }}
                                        </a>
                                      </div>
                                      <div>
                                        <!-- Loop through user_badges -->
                                        <b-img
                                          v-for="(badge, index) in user_badges"
                                          :key="index"
                                          class="worked_for_logo"
                                          :src="badge.badge_image"
                                          alt="Badge Icon"
                                        ></b-img>
                                      </div>
                                    </div>
                                  </div>
                                </template>

                                  <template #cell(completedCount)="items">
                                    <div class="phone_nmber_div">
                                        <a href="javascript:void(0);"
                                          >Upcoming Applied -
                                          {{upcomingCount}}</a
                                        >
                                      </div>
                                      <div class="phone_nmber_div">
                                        <a href="javascript:void(0);"
                                          >Completed Jobs -
                                          {{completedCount}}</a
                                        >
                                      </div>
                                  </template>
                              </b-table>
                              <!-- New Layout End -->

                              <!-- Old Layout Start -->
                              <!-- <div class="rating_box_content pt-lg-4 pt-3">
                                          <div class="d-lg-flex d-md-flex justify-content-between align-items-center">
                                              <div class="name-td-box d-flex align-items-center">
                                                <b-img
                                                  :src="
                                                    jobDataVal.profile_image !=
                                                    null
                                                      ? jobDataVal.profile_image
                                                      : webUrl +
                                                        'assets/images/user-avatar-small-01.png'
                                                  "
                                                  alt="User Icon"
                                                  height="100px"
                                                  width="100px"
                                                  class="user_img_emp"
                                                ></b-img>
                                                  <p class="pl-3"><b>{{jobDataVal.fl_name}} </b></p>
                                              </div>
                                              <div class="td-address-box d-flex align-items-center">
                                                  <p class="grey-text">Your Rating:
                                                    <span v-for="n in 5" :key="n" class="star">
                                                      {{ n <= jobDataVal.rating ? '★' : '☆' }}
                                                    </span>
                                                  </p>
                                              </div>
                                              <div>
                                                  <div class="">
                                                  <span class="add-icon clock-icon">
                                                    Total Completed Jobs:
                                                      {{completedCount}}
                                              </span><br>




                                              </div>
                                              </div>
                                              </div>
                                </div>  -->
                                <!-- Old Layout End -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                          v-if="jobDatas.length == 0"
                        >
                          <p class="actionData"><strong>No Record Found</strong></p>
                        </div> -->
                      </b-tab>
                      <b-tab
                        :title="'COMPLETED'"
                        :active="completed"
                        @click.prevent="resetFilter(); changeTabStatus('completed')"
                        class="reject_last_li"
                      >
                        <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                          <div class="box_shadow box_new_shadow">
                            <div class="padding_manage_job">
                              <ul class="com_id_ul">
                                <li>
                                    <a
                                      href="javascript:void(0);"
                                      @click="dpJobs(jobDataVal.id, jobDataVal.user_job_status)"

                                    >
                                      {{ jobDataVal.job_unique_id }}
                                    </a>
                                  </li>

                                <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->


                                <li>
                                  <button
                                    class="service_cat_name_manage_job"
                                    style="pointer-events: none; border: 1px solid"
                                    :style="{
                                      color: jobDataVal.serviceCat_color,
                                      borderColor: jobDataVal.serviceCat_color,
                                    }"
                                  >
                                    {{ jobDataVal.serviceCat_name }}
                                  </button>
                                </li>






                              </ul>

                              <div class="heading_with_status">
                                <div class="comp_heading_div">
                                  <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                                </div>
                                <!-- <div class="rate_div new_rate_divs"> -->
                                <!-- <div class="status_btn new_status_btn_divs"> -->
                                <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                                <!-- <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link> -->
                                <!-- <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link> -->

                                <!-- <div class="task-count-text" v-if="jobDataVal.pdf_url != null && showPermissionEM('show sr') && showJobListRateAdmin('show_job_list_rate')">
                                                                    <div class="small_pdf_box">
                                                                        <img :src="webUrl+ 'assets/images/pdf.png'" style="max-height: 36px;" @click="pdfViewer(jobDataVal.pdf_url)" >
                                                                    </div>
                                                                </div> -->
                                <!-- </div> -->
                                <!-- <div class="status_btn">
                                                                <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                        </div> -->
                                <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">

                                                            <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                        </div> -->
                                <!-- <div class="action_div">
                                                            <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                        </div> -->
                                <!-- </div> -->
                              </div>
                              <!-- <div class="img_with_desc">

                                                </div> -->
                              <ul class="manage_job_top_ul_nw">
                                <li>
                                  <span class="material-icons-outlined">calendar_month_outlined</span
                                  >{{ jobDataVal.start_date | moment_1 }}
                                </li>
                                <li>
                                  <span class="material-icons-outlined">access_time_outlined</span
                                  >{{ jobDataVal.start_time | jobStartEndTime }} -
                                  {{ jobDataVal.end_time | jobStartEndTime }}
                                  <span class="red_note_manage_job"
                                    ><p v-if="jobDataVal.lunch_pay_hours != null">
                                      <strong>Note:</strong> Break hour not paid -
                                      {{ jobDataVal.lunch_pay_hours }} Hr
                                    </p></span
                                  >
                                </li>
                                <li
                                  v-if="
                                    showPermissionEM('show list rate') &&
                                    showJobListRateAdmin('show_job_list_rate')
                                  "
                                >
                                  <span class="material-icons-outlined">monetization_on_outlined</span
                                  >${{ jobDataVal.hourly_cost }}/Hour
                                </li>
                              </ul>
                              <div class="user_apl">
                                <img
                                  :src="webUrl + 'assets/images/pdf.png'"
                                  v-if="
                                    jobDataVal.pdf_url != null &&
                                    showPermissionEM('show sr') &&
                                    showJobListRateAdmin('show_job_list_rate')
                                  "
                                  style="max-height: 36px"
                                  @click="pdfViewer(jobDataVal.pdf_url)"
                                  class="pdf_manage_job"
                                />

                                <!-- <div class="working_completed_section">
                                  <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                                  ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                                </div> -->
                              </div>
                              <div class="full_desc_emps">
                                <!-- <ul>
                                      <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                      <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                      <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                      <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                      <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                      <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                      <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                                  </ul> -->
                              <div class="single_accordian manage_job_accordian mb-3">
                                <!-- <br> -->
                                <div>
                                  <div class="faq-content-box clearfix">
                                    <div>
                                      <div class="accordion-listing-box coloured_icon">
                                        <b-card no-body>
                                          <b-card-header header-tag="header" role="tab">
                                            <b-button
                                              v-b-toggle="
                                                'accordion_view_job_details_completed-' + jobDatakey
                                              "
                                              class="m-1"
                                              variant="info"
                                            >
                                              <div class="dashboard-box">
                                                <div class="headline">
                                                  <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                                </div>
                                              </div>
                                            </b-button>
                                          </b-card-header>
                                          <b-collapse
                                            :id="'accordion_view_job_details_completed-' + jobDatakey"
                                            role="tabpanel"
                                          >
                                            <b-card-body>
                                              <div class="">
                                                <div class="row">
                                                  <div class="col-xl-12">
                                                    <div
                                                      class="comp_heading_div_manage_job"
                                                      v-if="jobDataVal.posted_by"
                                                    >
                                                      <div class="dtl_text">
                                                        <h2>Posted By:</h2>
                                                        <p class="mb-0">
                                                          {{
                                                            jobDataVal.posted_by
                                                          }}

                                                        </p>
                                                        <p class="mb-0">
                                                          {{
                                                            jobDataVal.created_at
                                                              | customFormatterOnecreated
                                                          }}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                    >
                                                      <table
                                                        class="table custom_manage_job_tbl unset_last_super"
                                                      >
                                                        <thead>
                                                          <tr>
                                                            <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                            <!-- <th>Job Posted Date & Time:</th> -->
                                                            <!-- <th>Job Date & Time:</th>
                                                                                            <th>Job Hours:</th>
                                                                                            <th>Vacancy:</th> -->
                                                            <th
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              Work Site Contact Person
                                                            </th>
                                                            <th></th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <!-- <td v-if="jobDataVal.userData">
                                                                                                {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                            </td> -->
                                                            <!-- <td>
                                                                                                {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                            </td> -->
                                                            <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                            <td>{{jobDataVal.working_hours}}</td>
                                                                                            <td>
                                                                                                {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                            </td> -->
                                                            <td
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              {{
                                                                jobDataVal.work_site_contact_person
                                                                  ? jobDataVal.work_site_contact_person
                                                                  : ""
                                                              }}
                                                              <br />
                                                              {{
                                                                jobDataVal.work_site_contact_person_country_code
                                                                  ? jobDataVal.work_site_contact_person_country_code +
                                                                    "-"
                                                                  : ""
                                                              }}{{
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_mobile_no
                                                                  : ""
                                                              }}<br />
                                                            </td>
                                                            <td
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              {{
                                                                jobDataVal.work_site_contact_person_2
                                                                  ? jobDataVal.work_site_contact_person_2
                                                                  : ""
                                                              }}<br />
                                                              {{
                                                                jobDataVal.work_site_contact_person_2_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_2_country_code +
                                                                    "-"
                                                                  : ""
                                                              }}{{
                                                                jobDataVal.work_site_contact_person_2_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                  : ""
                                                              }}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div class="dtl_text">
                                                      <div
                                                        class="large-content-requirement-completed"
                                                      >
                                                        <h2 v-if="jobDataVal.description">
                                                          Job Requirements
                                                          <span class="manage_job_eye_span"
                                                            ><i
                                                              class="fa fa-eye-slash"
                                                              :id="
                                                                'manage_job_requirement_completed_eye_icon' +
                                                                jobDatakey
                                                              "
                                                              @click="
                                                                ManageJobRequirementCompletedEyeReveal(
                                                                  jobDatakey
                                                                )
                                                              "
                                                              style="cursor: pointer"
                                                            ></i
                                                          ></span>
                                                        </h2>
                                                        <p
                                                          :id="
                                                            'visible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.description.length <= 249"
                                                        >
                                                          {{
                                                            jobDataVal.description.substring(0, 249)
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'visible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.description.length >= 250"
                                                        >
                                                          {{
                                                            jobDataVal.description.substring(0, 250) +
                                                            ".."
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'invisible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style="display: none"
                                                          class=""
                                                        >
                                                          {{ jobDataVal.description }}
                                                        </p>
                                                      </div>
                                                      <div class="large-content-scope-completed">
                                                        <h2 v-if="jobDataVal.job_scope">
                                                          Job Scope:
                                                          <span class="manage_job_eye_span"
                                                            ><i
                                                              class="fa fa-eye-slash"
                                                              :id="
                                                                'manage_job_scope_completed_eye_icon' +
                                                                jobDatakey
                                                              "
                                                              @click="
                                                                ManageJobScopeCompletedEyeReveal(
                                                                  jobDatakey
                                                                )
                                                              "
                                                              style="cursor: pointer"
                                                            ></i
                                                          ></span>
                                                        </h2>
                                                        <p
                                                          :id="
                                                            'visible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.job_scope.length <= 249"
                                                        >
                                                          {{ jobDataVal.job_scope.substring(0, 249) }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'visible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.job_scope.length >= 250"
                                                        >
                                                          {{
                                                            jobDataVal.job_scope.substring(0, 250) +
                                                            ".."
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'invisible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style="display: none"
                                                          class=""
                                                        >
                                                          {{ jobDataVal.job_scope }}
                                                        </p>
                                                      </div>
                                                      <h2 v-if="jobDataVal.address">Job Location</h2>
                                                      <p v-if="jobDataVal.address" class="mb-0">
                                                        {{ jobDataVal.address }}
                                                      </p>
                                                      <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                      <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </b-card-body>
                                          </b-collapse>
                                        </b-card>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- New Layout Start -->
                              <b-table
                                  ref="datatable"
                                  show-empty
                                  striped
                                  hover
                                  responsive
                                  :items="[jobDataVal]"
                                  :fields="Dpfieldsadmin"
                                  :filter="filter"
                                  :sort-by.sync="sortBy"
                                  :sort-desc.sync="sortDesc"
                                  :sort-direction="sortDirection"
                                  class="tbl_height dp_jobs_admin_table"
                                  thead-tr-class="d-none"
                                >
                                <template #cell(fl_name)="items">
                                  <div class="daily_image_job">
                                    <div class="daily_job_manage_img_div">
                                      <b-img
                                        :src="
                                          items.item.profile_image != null
                                            ? items.item.profile_image
                                            : webUrl + 'assets/images/user-avatar-small-01.png'
                                        "
                                        alt="User Icon"
                                        height="100px"
                                        width="100px"
                                        class="user_img_emp"
                                      ></b-img>
                                      <div class="custom_star_rate_manage_job">
                                        <span><i class="fa fa-star"></i>{{userRating}}</span>
                                      </div>
                                    </div>
                                    <div class="daily_job_manage_user_description_div">
                                      <h2>
                                        {{ items.item.fl_name }}
                                      </h2>
                                      <div class="phone_nmber_div">
                                        <a href="javascript:void(0);">
                                          {{
                                            items.item.work_site_contact_person_country_code
                                              ? items.item.work_site_contact_person_country_code
                                              : ""
                                          }}-{{
                                            items.item.work_site_contact_person_mobile_no
                                              ? items.item.work_site_contact_person_mobile_no
                                              : ""
                                          }}
                                        </a>
                                      </div>
                                      <div>
                                        <!-- Loop through user_badges -->
                                        <b-img
                                          v-for="(badge, index) in user_badges"
                                          :key="index"
                                          class="worked_for_logo"
                                          :src="badge.badge_image"
                                          alt="Badge Icon"
                                        ></b-img>
                                      </div>
                                    </div>
                                  </div>
                                </template>

                                  <template #cell(completedCount)="items">
                                    <div class="phone_nmber_div">
                                        <a href="javascript:void(0);"
                                          >Upcoming Applied -
                                          {{upcomingCount}}</a
                                        >
                                      </div>
                                      <div class="phone_nmber_div">
                                        <a href="javascript:void(0);"
                                          >Completed Jobs -
                                          {{completedCount}}</a
                                        >
                                      </div>
                                      <div class="phone_nmber_div">
                                        <a>Working Hours:
                                          {{jobDataVal.working_hours}}</a>

                                      </div>
                                      <div class="phone_nmber_div">
                                        <a>PT In/Out Time:
                                          {{jobDataVal.mark_as_arrived_time | formatedMoment}} - {{jobDataVal.confirm_job_completion_time | formatedMoment}}</a>

                                      </div>
                                  </template>
                              </b-table>
                              <!-- New Layout End -->

                                <!-- Old Layout Start -->
                                <!-- <div class="rating_box_content pt-lg-4 pt-3">
                                  <div class="d-lg-flex d-md-flex justify-content-between align-items-center">
                                      <div class="name-td-box d-flex align-items-center">
                                        <b-img
                                          :src="
                                            jobDataVal.profile_image !=
                                            null
                                              ? jobDataVal.profile_image
                                              : webUrl +
                                                'assets/images/user-avatar-small-01.png'
                                          "
                                          alt="User Icon"
                                          height="100px"
                                          width="100px"
                                          class="user_img_emp"
                                        ></b-img>
                                          <p class="pl-3"><b>{{jobDataVal.fl_name}} </b></p>
                                      </div>
                                      <div class="td-address-box d-flex align-items-center">
                                          <p class="grey-text">Your Rating:
                                            <span v-for="n in 5" :key="n" class="star">
                                              {{ n <= jobDataVal.rating ? '★' : '☆' }}
                                            </span>
                                          </p>
                                      </div>
                                      <div>
                                          <div class="">
                                          <span class="add-icon clock-icon">
                                          Working Hours:
                                              {{jobDataVal.working_hours}}
                                      Hours</span><br>
                                      <span class="add-icon clock-icon">
                                          PT In/Out Time:
                                              {{jobDataVal.mark_as_arrived_time | formatedMoment}} - {{jobDataVal.confirm_job_completion_time | formatedMoment}}
                                      </span>
                                      </div>
                                      </div>
                                      </div>
                                  </div>  -->
                                  <!-- Old Layout End -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                          v-if="jobDatas.length == 0"
                        >
                          <p class="actionData"><strong>No Record Found</strong></p>
                        </div> -->
                      </b-tab>
                      <b-tab
                        :title="'CANCELLED'"
                        :active="cancelled"
                        @click.prevent="
                          resetFilter(); changeTabStatus('cancelled')
                        "
                        class="reject_last_li"
                      >
                      <div class="top_desc_div" v-for="(jobDataVal, jobDatakey) in jobDatas">
                          <div class="box_shadow box_new_shadow">
                            <div class="padding_manage_job">
                              <ul class="com_id_ul">
                                <li>
                                    <a
                                      href="javascript:void(0);"
                                      @click="dpJobs(jobDataVal.id, jobDataVal.user_job_status)"

                                    >
                                      {{ jobDataVal.job_unique_id }}
                                    </a>
                                  </li>

                                <!-- <li>SERVICE AMT: S${{jobDataVal.service_fee}}</li> -->


                                <li>
                                  <button
                                    class="service_cat_name_manage_job"
                                    style="pointer-events: none; border: 1px solid"
                                    :style="{
                                      color: jobDataVal.serviceCat_color,
                                      borderColor: jobDataVal.serviceCat_color,
                                    }"
                                  >
                                    {{ jobDataVal.serviceCat_name }}
                                  </button>
                                </li>






                              </ul>

                              <div class="heading_with_status">
                                <div class="comp_heading_div">
                                  <h2 class="top_color_h2_single">{{ jobDataVal.title }}</h2>
                                </div>
                                <!-- <div class="rate_div new_rate_divs"> -->
                                <!-- <div class="status_btn new_status_btn_divs"> -->
                                <!-- <h2 class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">S${{jobDataVal.hourly_cost}}/HR</h2> -->
                                <!-- <b-link href="" :to="{name: 'repostJobDaily', params: {id: encrypt(jobDataVal.id)}}" v-if="showPermissionEM('duplicate job')" class="button gray ripple-effect ico repost_icon_btn" title="Repost" data-tippy-placement="top"><i class="material-icons-outlined assign_users_i">repeat_outlined</i></b-link> -->
                                <!-- <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link> -->

                                <!-- <div class="task-count-text" v-if="jobDataVal.pdf_url != null && showPermissionEM('show sr') && showJobListRateAdmin('show_job_list_rate')">
                                                                    <div class="small_pdf_box">
                                                                        <img :src="webUrl+ 'assets/images/pdf.png'" style="max-height: 36px;" @click="pdfViewer(jobDataVal.pdf_url)" >
                                                                    </div>
                                                                </div> -->
                                <!-- </div> -->
                                <!-- <div class="status_btn">
                                                                <b-link href="" :to="{name: 'DailyJobEdit', params: {id: encrypt(jobDataVal.id)}}" class="button gray ripple-effect ico edit_icon_btn" title="Edit" data-tippy-placement="top"><i class="fa fa-edit"></i></b-link>
                                                        </div> -->
                                <!-- <div class="rate_text" v-if="showPermissionEM('show list rate') && showJobListRateAdmin('show_job_list_rate')">

                                                            <h2>S${{jobDataVal.hourly_cost}}/HR</h2>
                                                        </div> -->
                                <!-- <div class="action_div">
                                                            <button class="action_btn">ACTIONS<i class="fa fa-caret-down"></i></button>
                                                        </div> -->
                                <!-- </div> -->
                              </div>
                              <!-- <div class="img_with_desc">

                                                </div> -->
                              <ul class="manage_job_top_ul_nw">
                                <li>
                                  <span class="material-icons-outlined">calendar_month_outlined</span
                                  >{{ jobDataVal.start_date | moment_1 }}
                                </li>
                                <li>
                                  <span class="material-icons-outlined">access_time_outlined</span
                                  >{{ jobDataVal.start_time | jobStartEndTime }} -
                                  {{ jobDataVal.end_time | jobStartEndTime }}
                                  <span class="red_note_manage_job"
                                    ><p v-if="jobDataVal.lunch_pay_hours != null">
                                      <strong>Note:</strong> Break hour not paid -
                                      {{ jobDataVal.lunch_pay_hours }} Hr
                                    </p></span
                                  >
                                </li>
                                <li
                                  v-if="
                                    showPermissionEM('show list rate') &&
                                    showJobListRateAdmin('show_job_list_rate')
                                  "
                                >
                                  <span class="material-icons-outlined">monetization_on_outlined</span
                                  >${{ jobDataVal.hourly_cost }}/Hour
                                </li>
                              </ul>
                              <div class="user_apl">
                                <img
                                  :src="webUrl + 'assets/images/pdf.png'"
                                  v-if="
                                    jobDataVal.pdf_url != null &&
                                    showPermissionEM('show sr') &&
                                    showJobListRateAdmin('show_job_list_rate')
                                  "
                                  style="max-height: 36px"
                                  @click="pdfViewer(jobDataVal.pdf_url)"
                                  class="pdf_manage_job"
                                />

                                <!-- <div class="working_completed_section">
                                  <span>Working: {{ jobDataVal.inprogressCount.length }}</span
                                  ><span>Completed: {{ jobDataVal.completedCount.length }}</span>
                                </div> -->
                              </div>
                              <div class="full_desc_emps">
                                <!-- <ul>
                                      <li>Posted By:<span>{{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}</span></li>
                                      <li>Job Posted D& Time:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                      <li>Job Duration:<span>{{jobDataVal.start_date | moment}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.start_time | jobStartEndTime}}</span></li>
                                      <li>Job Hours:<span>{{jobDataVal.working_hours}}</span></li>
                                      <li>Vacancy:<span>{{jobDataVal.userJobs.length}}/{{jobDataVal.total_vacancies}}</span></li>
                                      <li v-if="jobDataVal.work_site_contact_person">Work Site Contact Person Name:<span>{{jobDataVal.work_site_contact_person ? jobDataVal.work_site_contact_person : ''}}</span></li>
                                      <li v-if="jobDataVal.work_site_contact_person_mobile_no">Work Site Contact Person Number:<span>{{jobDataVal.work_site_contact_person_country_code ? jobDataVal.work_site_contact_person_country_code : ''}}-{{jobDataVal.work_site_contact_person_mobile_no ? jobDataVal.work_site_contact_person_mobile_no : ''}}</span></li>
                                  </ul> -->
                              <div class="single_accordian manage_job_accordian mb-3">
                                <!-- <br> -->
                                <div>
                                  <div class="faq-content-box clearfix">
                                    <div>
                                      <div class="accordion-listing-box coloured_icon">
                                        <b-card no-body>
                                          <b-card-header header-tag="header" role="tab">
                                            <b-button
                                              v-b-toggle="
                                                'accordion_view_job_details_completed-' + jobDatakey
                                              "
                                              class="m-1"
                                              variant="info"
                                            >
                                              <div class="dashboard-box">
                                                <div class="headline">
                                                  <h3 class="heading_section_h3">VIEW JOB DETAILS</h3>
                                                </div>
                                              </div>
                                            </b-button>
                                          </b-card-header>
                                          <b-collapse
                                            :id="'accordion_view_job_details_completed-' + jobDatakey"
                                            role="tabpanel"
                                          >
                                            <b-card-body>
                                              <div class="">
                                                <div class="row">
                                                  <div class="col-xl-12">
                                                    <div
                                                      class="comp_heading_div_manage_job"
                                                      v-if="jobDataVal.posted_by"
                                                    >
                                                      <div class="dtl_text">
                                                        <h2>Posted By:</h2>
                                                        <p class="mb-0">
                                                          {{
                                                            jobDataVal.posted_by
                                                          }}

                                                        </p>
                                                        <p class="mb-0">
                                                          {{
                                                            jobDataVal.created_at
                                                              | customFormatterOnecreated
                                                          }}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="table-responsive whole_custom_tbl common_table_scroll manage_job_new_tblz"
                                                    >
                                                      <table
                                                        class="table custom_manage_job_tbl unset_last_super"
                                                      >
                                                        <thead>
                                                          <tr>
                                                            <!-- <th v-if="jobDataVal.userData">Posted By:</th> -->
                                                            <!-- <th>Job Posted Date & Time:</th> -->
                                                            <!-- <th>Job Date & Time:</th>
                                                                                            <th>Job Hours:</th>
                                                                                            <th>Vacancy:</th> -->
                                                            <th
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              Work Site Contact Person
                                                            </th>
                                                            <th></th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <!-- <td v-if="jobDataVal.userData">
                                                                                                {{(jobDataVal.userData && jobDataVal.userData.last_name) ? jobDataVal.userData.last_name : ''}} {{(jobDataVal.userData && jobDataVal.userData.first_name) ? jobDataVal.userData.first_name : ''}}
                                                                                            </td> -->
                                                            <!-- <td>
                                                                                                {{jobDataVal.created_at | customFormatterOnecreated}}
                                                                                            </td> -->
                                                            <!-- <td>{{jobDataVal.start_date | moment_1}} {{jobDataVal.start_time | jobStartEndTime}} - {{jobDataVal.end_time | jobStartEndTime}} </td>
                                                                                            <td>{{jobDataVal.working_hours}}</td>
                                                                                            <td>
                                                                                                {{jobDataVal.userJobsselected.length}}/{{jobDataVal.total_vacancies}}
                                                                                            </td> -->
                                                            <td
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              {{
                                                                jobDataVal.work_site_contact_person
                                                                  ? jobDataVal.work_site_contact_person
                                                                  : ""
                                                              }}
                                                              <br />
                                                              {{
                                                                jobDataVal.work_site_contact_person_country_code
                                                                  ? jobDataVal.work_site_contact_person_country_code +
                                                                    "-"
                                                                  : ""
                                                              }}{{
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_mobile_no
                                                                  : ""
                                                              }}<br />
                                                            </td>
                                                            <td
                                                              v-if="
                                                                jobDataVal.work_site_contact_person ||
                                                                jobDataVal.work_site_contact_person_mobile_no
                                                              "
                                                            >
                                                              {{
                                                                jobDataVal.work_site_contact_person_2
                                                                  ? jobDataVal.work_site_contact_person_2
                                                                  : ""
                                                              }}<br />
                                                              {{
                                                                jobDataVal.work_site_contact_person_2_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_2_country_code +
                                                                    "-"
                                                                  : ""
                                                              }}{{
                                                                jobDataVal.work_site_contact_person_2_mobile_no
                                                                  ? jobDataVal.work_site_contact_person_2_mobile_no
                                                                  : ""
                                                              }}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div class="dtl_text">
                                                      <div
                                                        class="large-content-requirement-completed"
                                                      >
                                                        <h2 v-if="jobDataVal.description">
                                                          Job Requirements
                                                          <span class="manage_job_eye_span"
                                                            ><i
                                                              class="fa fa-eye-slash"
                                                              :id="
                                                                'manage_job_requirement_completed_eye_icon' +
                                                                jobDatakey
                                                              "
                                                              @click="
                                                                ManageJobRequirementCompletedEyeReveal(
                                                                  jobDatakey
                                                                )
                                                              "
                                                              style="cursor: pointer"
                                                            ></i
                                                          ></span>
                                                        </h2>
                                                        <p
                                                          :id="
                                                            'visible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.description.length <= 249"
                                                        >
                                                          {{
                                                            jobDataVal.description.substring(0, 249)
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'visible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.description.length >= 250"
                                                        >
                                                          {{
                                                            jobDataVal.description.substring(0, 250) +
                                                            ".."
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'invisible-content-requirement-completed' +
                                                            jobDatakey
                                                          "
                                                          style="display: none"
                                                          class=""
                                                        >
                                                          {{ jobDataVal.description }}
                                                        </p>
                                                      </div>
                                                      <div class="large-content-scope-completed">
                                                        <h2 v-if="jobDataVal.job_scope">
                                                          Job Scope:
                                                          <span class="manage_job_eye_span"
                                                            ><i
                                                              class="fa fa-eye-slash"
                                                              :id="
                                                                'manage_job_scope_completed_eye_icon' +
                                                                jobDatakey
                                                              "
                                                              @click="
                                                                ManageJobScopeCompletedEyeReveal(
                                                                  jobDatakey
                                                                )
                                                              "
                                                              style="cursor: pointer"
                                                            ></i
                                                          ></span>
                                                        </h2>
                                                        <p
                                                          :id="
                                                            'visible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.job_scope.length <= 249"
                                                        >
                                                          {{ jobDataVal.job_scope.substring(0, 249) }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'visible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style=""
                                                          class=""
                                                          v-if="jobDataVal.job_scope.length >= 250"
                                                        >
                                                          {{
                                                            jobDataVal.job_scope.substring(0, 250) +
                                                            ".."
                                                          }}
                                                        </p>
                                                        <p
                                                          :id="
                                                            'invisible-content-scope-completed' +
                                                            jobDatakey
                                                          "
                                                          style="display: none"
                                                          class=""
                                                        >
                                                          {{ jobDataVal.job_scope }}
                                                        </p>
                                                      </div>
                                                      <h2 v-if="jobDataVal.address">Job Location</h2>
                                                      <p v-if="jobDataVal.address" class="mb-0">
                                                        {{ jobDataVal.address }}
                                                      </p>
                                                      <!-- <p v-if="jobDataVal.address"><i class="fa fa-map-marker"></i>{{jobDataVal.address}}</p> -->
                                                      <!-- <p v-if="jobDataVal.lunch_pay_hours != null"><strong>Note:</strong>Break hour not paid - {{jobDataVal.lunch_pay_hours}} Hr</p> -->
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </b-card-body>
                                          </b-collapse>
                                        </b-card>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                <!-- New Layout Start -->
                                <b-table
                                  ref="datatable"
                                  show-empty
                                  striped
                                  hover
                                  responsive
                                  :items="[jobDataVal]"
                                  :fields="Dpfieldsadmin"
                                  :filter="filter"
                                  :sort-by.sync="sortBy"
                                  :sort-desc.sync="sortDesc"
                                  :sort-direction="sortDirection"
                                  class="tbl_height dp_jobs_admin_table"
                                  thead-tr-class="d-none"
                                >
                                <template #cell(fl_name)="items">
                                  <div class="daily_image_job">
                                    <div class="daily_job_manage_img_div">
                                      <b-img
                                        :src="
                                          items.item.profile_image != null
                                            ? items.item.profile_image
                                            : webUrl + 'assets/images/user-avatar-small-01.png'
                                        "
                                        alt="User Icon"
                                        height="100px"
                                        width="100px"
                                        class="user_img_emp"
                                      ></b-img>
                                      <div class="custom_star_rate_manage_job">
                                        <span><i class="fa fa-star"></i>{{userRating}}</span>
                                      </div>
                                    </div>
                                    <div class="daily_job_manage_user_description_div">
                                      <h2>
                                        {{ items.item.fl_name }}
                                      </h2>
                                      <div class="phone_nmber_div">
                                        <a href="javascript:void(0);">
                                          {{
                                            items.item.work_site_contact_person_country_code
                                              ? items.item.work_site_contact_person_country_code
                                              : ""
                                          }}-{{
                                            items.item.work_site_contact_person_mobile_no
                                              ? items.item.work_site_contact_person_mobile_no
                                              : ""
                                          }}
                                        </a>
                                      </div>
                                      <div>
                                        <!-- Loop through user_badges -->
                                        <b-img
                                          v-for="(badge, index) in user_badges"
                                          :key="index"
                                          class="worked_for_logo"
                                          :src="badge.badge_image"
                                          alt="Badge Icon"
                                        ></b-img>
                                      </div>
                                    </div>
                                  </div>
                                </template>

                                  <template #cell(completedCount)="items">
                                    <div class="phone_nmber_div">
                                        <a href="javascript:void(0);"
                                          >Upcoming Applied -
                                          {{upcomingCount}}</a
                                        >
                                      </div>
                                      <div class="phone_nmber_div">
                                        <a href="javascript:void(0);"
                                          >Completed Jobs -
                                          {{completedCount}}</a
                                        >
                                      </div>
                                      <div class="phone_nmber_div">
                                        <a>Cancelled At:
                                          {{jobDataVal.cancelled_at|formatedMoment}}</a>

                                      </div>
                                      <div class="phone_nmber_div"  v-if="jobDataVal && jobDataVal.cancelled_by">
                                        <a>Cancelled By:
                                          {{ jobDataVal.cancelled_by }}</a>

                                      </div>
                                      <div class="phone_nmber_div">
                                        <a class="red-text">Cancellation Reason:
                                          {{ jobDataVal.cancellation_reason }}</a>

                                      </div>
                                  </template>
                              </b-table>
                              <!-- New Layout End -->

                                <!-- Old Layout Start -->
                              <!-- <div class="rating_box_content pt-lg-4 pt-3">
                                  <div class="d-lg-flex d-md-flex justify-content-between align-items-center">
                                        <div class="name-td-box d-flex align-items-center">
                                          <b-img
                                            :src="
                                              jobDataVal.profile_image !=
                                              null
                                                ? jobDataVal.profile_image
                                                : webUrl +
                                                  'assets/images/user-avatar-small-01.png'
                                            "
                                            alt="User Icon"
                                            height="100px"
                                            width="100px"
                                            class="user_img_emp"
                                          ></b-img>
                                            <p class="pl-3"><b>{{jobDataVal.fl_name}} </b></p>
                                        </div>
                                        <div class="td-address-box d-flex align-items-center">
                                            <p class="grey-text">Your Rating:
                                              <span v-for="n in 5" :key="n" class="star">
                                                {{ n <= jobDataVal.rating ? '★' : '☆' }}
                                              </span>
                                            </p>
                                        </div>
                                        <div>
                                            <div class="">
                                                <span class="add-icon clock-icon">
                                                Cancelled At:
                                                    {{jobDataVal.cancelled_at|formatedMoment}}
                                              </span><br>
                                              <span class="add-icon clock-icon" v-if="jobDataVal && jobDataVal.cancelled_by">
                                                  Cancelled By:
                                                    {{ jobDataVal.cancelled_by }}
                                              </span><br>
                                              <div>
                                                <span class="red-text">
                                                Cancellation Reason: </span>
                                                <span> {{ jobDataVal.cancellation_reason }}</span>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                </div> -->
                                <!-- Old Layout End -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="tab-box-listing border-radius-4 white-bg border-midium-light mb-3 text-center"
                          v-if="jobDatas.length == 0"
                        >
                          <p class="actionData"><strong>No Record Found</strong></p>
                        </div> -->
                      </b-tab>
                      <b-tab
                        :title="'ALL'"
                        :active="all"
                        @click="resetFilter(); getAllTabData('all')"
                        class="reject_last_li"
                      >
                      <b-table
                      ref="datatableAll"
                      show-empty
                      striped
                      hover
                      responsive
                      :items="jobDatasAll"
                      :fields="fields"
                      :filter="filter"
                      :sort-by.sync="sortByAll"
                      :sort-desc.sync="sortDescAll"
                      class="new_layout_table_like_v1 mobile_min_width_1000 revenue_table_admin dp_all_tab_tbl"
                      >
                          <template #cell(company)="items">
                              <div class="name-td-box">
                                  <b-img :src="items.item.company_logo != null ? (items.item.company_logo) : (items.item.profile_image)" alt="User Icon">
                                  </b-img>
                                  {{items.item.company_name != null ? items.item.company_name : items.item.fl_name}}

                              </div>
                          </template>
                          <template #cell(service_cat)="items">
                              <button
                        class="service_cat_name_manage_job"
                        style="pointer-events: none; border: 1px solid"
                        :style="{
                          color: items.item.serviceCat_color,
                          borderColor: items.item.serviceCat_color,
                        }"
                      >
                        {{ items.item.serviceCat_name }}
                      </button>
                          </template>
                          <template #cell(title)="items">
                              <b-link href="#" @click="dpJobs(items.item.id, items.item.user_job_status)">
                              {{items.item.job_unique_id}} </b-link> ({{items.item.title | capitalize}})
                          </template>
                          <template #cell(job_start_time)="items">
                              {{items.item.start_date |moment_2}} {{items.item.start_time | startTimeVal }}
                          </template>
                          <template #cell(applied_date)="items">
                              <!-- {{items.item.created_at | formatedMoment }} -->
                              {{items.item.user_jobs_created_at | formatedMoment }}

                          </template>
                          <template #cell(status)="items">
                              {{(items.item.withdraw_application == 'yes' ? 'Withdrawn' : items.item.user_job_status) | capitalize}}
                          </template>
                          <template #cell(withdrawn_by)="items">
                              {{(items.item.withdraw_application == 'yes' ? (items.item.withdrawn_by != null ? items.item.withdrawn_by_fullname : 'By System') : '-') | capitalize}}
                          </template>
                          <template #cell(mw_status)="items">
                              {{items.item.awarded_at != null ? 'Awarded' : 'Not Awarded'}}
                          </template>
                      </b-table>
                    <nav
                        class="dp_all_tab_tbl_nav pagination-box custom_pagination_border_radius manage_job_employer_pagination" v-if="activeTab == 'all'"
                      >
                        <div class="page-row-box d-inline-block">
                          <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                              <b-form-select
                                v-model="form.rowsPerPage"
                                @change="getAllTabData('all')"
                              >
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                              </b-form-select></span
                            >
                          </b-form-group>
                        </div>
                        <div class="total-page-count d-inline-block custom_pgn_tab">
                          <ul>
                            <li>
                              <span v-if="totalRowsAll != 0"
                                >{{ fromAll }} - {{ toAll }} of {{ totalRowsAll }}</span
                              >
                            </li>
                          </ul>
                        </div>
                        <b-pagination
                          :total-rows="totalRowsAll"
                          :per-page="perPageAll"
                          v-model="currentPageAll"
                          prev-text=""
                          next-text=""
                          hide-goto-end-buttons
                        />
                      </nav>
                      </b-tab>
                      <nav
                        class="pagination-box custom_pagination_border_radius manage_job_employer_pagination" v-if="activeTab != 'all'"
                      >
                        <div class="page-row-box d-inline-block">
                          <b-form-group id="input-group-4">
                            <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                              <b-form-select
                                v-model="form.rowsPerPage"
                                @change="getList"
                              >
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>75</option>
                                <option>100</option>
                              </b-form-select></span
                            >
                          </b-form-group>
                        </div>
                        <div class="total-page-count d-inline-block custom_pgn_tab">
                          <ul>
                            <li>
                              <span v-if="totalRows != 0"
                                >{{ from }} - {{ to }} of {{ totalRows }}</span
                              >
                            </li>
                          </ul>
                        </div>
                        <b-pagination
                          :total-rows="totalRows"
                          @input="getList"
                          :per-page="perPage"
                          v-model="currentPage"
                          prev-text=""
                          next-text=""
                          hide-goto-end-buttons
                        />
                      </nav>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
            <!-- Row / End -->
            <!-- Row / End -->
            <div class="clearfix"></div>

            <div class="clearfix"></div>
            <!-- <div class="margin-top-70"></div> -->
          </div>
        </div>
    </div>
</template>

  <script>
  import { POST_API } from "../../../store/actions.type";
  import { GET_API } from "../../../store/actions.type";

  import moment from "moment";
  import moment1 from "moment";

  import StarRating from "vue-star-rating";
  import DateRangePicker from "vue2-daterange-picker";
  import Multiselect from "vue-multiselect";
  import Datepicker from "vuejs-datepicker";
  import VueTimepicker from "vuejs-timepicker";
  import Bus from "../../../event-bus";
  import VueQRCodeComponent from "vue-qrcode-component";
  import permission from "../../../../../server/permission.js";

  export default {
    data() {
      return {
        charge_type: "",
        hours: [0, 1, 2, 3, 4, 5, 6],
        suspention_reason: "",
        job_start_time: {
          hh: "09",
          mm: "33",
          A: "AM",
        },
        job_end_time: {
          hh: "00",
          mm: "00",
          A: "AM",
        },
        disabledFromDate1: {
          from: new Date(Date.now() + 3600 * 1000 * 24),
        },
        fields: [
              { key: 'company', label: 'Company', sortable: false, thStyle: { width: "18%" }},
              { key: 'service_cat', label: 'Industry', sortable: false, thStyle: { width: "16%" }},
              { key: 'title', label: 'Job Title', sortable: false, thStyle: { width: "16%" }},
              { key: 'job_start_time', label: 'Job Start Date', sortable: false, thStyle: { width: "10%" }},
              { key: 'applied_date', label: 'Applied Date', sortable: false, thStyle: { width: "10%" }},
              { key: 'status', label: 'Job Status', sortable: false, thStyle: { width: "10%" }  },
              { key: 'withdrawn_by', label: 'Withdrawn By', sortable: false, thStyle: { width: "10%" }},
              { key: 'mw_status', label: 'MW Status', sortable: false, thStyle: { width: "10%" }},
            ],
        id: atob(this.$route.params.id),
        qrcode: "",
        job_start_date: "",
        job_end_date: "",
        lunch_pay_not_provided: "no",
        job_cancellation_reason: "",
        lunch_pay_hours: "",
        jobDatas: [],
        jobDatasAll: [],
        skill: "",
        daily: "",
        full_time: "",
        extended_hours: "",
        extension_rate: "",
        min: "",
        location: "",
        remarks: "",
        type: "",
        keyword: "",
        cancellation_reason: "",
        salary: "",
        error_message: "",
        skillsArr: [],
        tagArr: [],
        value: [0, 15000],
        pager: {},
        pageOfItems: [],
        options: [],
        items: null,
        totalRows: 0,
        totalRowsAll: 0,

        sliderValue: 50,
        costListing: [],
        from: "",
        to: "",
        currentPage: 1,
        perPage: 25,
        fromAll: "",
        toAll: "",
        currentPageAll: 1,
        perPageAll: 25,
        pageOptions: [25, 50, 75, 100],
        form: {
          rowsPerPage: 50,
        },
        activeTab: "inprogress",
        activeApplicantTab: "selected",
        keyVal: 0,
        inprogress: true,
        upcoming: false,
        completed: false,
        cancelled: false,
        all: false,
        selected: true,
        application: false,
        system_withdrawn: false,
        applicant_withdrawn: false,
        rate_review: false,
        review1: "",
        activeCount: "",
        shortlistedCount: "",
        hiredCount: "",
        rejectedCount: "",
        allCount: "",
        remaining_feature_jobs: 0,
        loading: false,
        webUrl: process.env.VUE_APP_URL,
        webServerUrl: process.env.VUE_APP_SERVER_URL,
        webUrlV1: process.env.VUE_APP_URL_V1,
        userDatas: "",
        selectedsup: [],
        supervisorName: "",
        selectedSupervisor: [],
        Dpfieldsadmin:[
          { key: "fl_name", label: "Name", sortable: true, thStyle: { width: "60%" } },
          { key: "completedCount", label: "Completed Count", sortable: true, thStyle: { width: "40%" }  },
        ],

        checkedStar: [],
        sortBy: null,
        acStatus: "",
        sortDirection: "asc",
        lunch_break: "no",
        filter: "",
        status: "active",
        sortDesc: true,
        range: false,
        date: moment().utc().format("YYYY-MM-DD"),
        format: "YYYY-MM-DD HH:mm",
        auto: true,
        center: "center",
        minDate: null,
        locale: {
          direction: "ltr", //direction of text
          format: "DD-MMM-YYYY", //fomart of the dates displayed
          separator: " - ", //separator between the two ranges
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          /*weekLabel: 'W',
                  customRangeLabel: 'Custom Range',*/
          daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
          monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
          firstDay: 0, //ISO first day of week - see moment documenations for details
          sameDateFormat: {
            from: "DD MM YYYY, hh:mm",
            to: "hh:mm",
          },
        },
        webUrlV1Admin: process.env.VUE_APP_URL_V1_Admin,
        is_production_val: "yes",
        latlongMarkers: [],
        infoContent: "",
        infoWindowPos: {
          lat: 0,
          lng: 0,
        },
        infoWinOpen: false,
        currentMidx: null,
        fullscreenControl: false,
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35,
          },
        },
        MarkerOptions: {
          zIndex: 999999,
          opacity: 0.2,
        },
        timePicker24Hour: true,
        timePicker: true,
        dateInput: {
          placeholder: "Select Date",
        },
        dismissCountDown: 0,
        countDownChanged: "",
        calendarTimeInput: {
          readonly: true,
          step: 30,
          inputClass: "my_custom_class",
        },
        rating: 0,
        addDays: moment().add("3", "days").format("YYYY-MM-DD h:m:s"),
        readOnly: true,
        dateRange: {
          startDate: "",
          endDate: "",
        },
        start_date_filter: "",
        end_date_filter: "",
        filterSearch: "",
        filterServiceCategory: null,
        qr_title: "",
        inprogressCount: 0,
        upcomingCount: 0,
        completedCount:0,
        vacancySet: 0,
        applicant_cancellation_reason: "",
        permissionActions: "",
        allManageJobDPLocal: [],
        categories: [],
        companies:[],
        company_id:0,
        extended_min: 0,
        filterSearchSelected: "",
        default_increment_in_vacancy: 0,
        cat_id:0,
        user_badges:[],
        userRating:0,
      };
    },
    // computed: {
    //   uniqueCompanies() {
    //     const companiesMap = {};
    //     this.jobDatas.forEach(job => {
    //       if (job.company_id && job.company_name) {
    //         companiesMap[job.company_id] = {
    //           id: job.company_id,
    //           name: job.company_name,
    //           logo: job.company_logo
    //         };
    //       }
    //     });
    //     return Object.values(companiesMap);
    //   }
    // },
    components: {
      StarRating,
      DateRangePicker,
      Datepicker,
      Multiselect,
      VueTimepicker,
      VueQRCodeComponent,
    },
    filters: {
      startTimeVal: function(date) {
            return moment(date, "HH:mm:ss").format("hh:mm A")

        },
      moment: function (date) {
        return moment(date).format("DD MMM YYYY");
      },
      moment_2: function (date) {
        return moment(date).format("YYYY-MM-DD");
      },
      moment_1: function (date) {
        return moment(date).format("ddd DD MMM YYYY");
      },
      capitalize: function (value) {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      capitalizeAll: function (value) {
        if (!value) return "";
        value = value.toString();
        return value.toUpperCase();
      },
      customFormatterOne(date) {
        return moment(date).utc().format("DD MMM YYYY hh:mm A");
      },

      customFormatterOnecreated(date) {
        return moment(date).utc().format("DD MMM YYYY hh:mm A");
      },
      applicantOriginalSET: function (date) {
        if (date == "" || date == null) {
          return "";
        } else {
          return moment(date, "HH:mm:ss").format("hh:mm A");
        }
      },
      formatedMoment: function(date) {
            return moment(date,'YYYY-MM-DD HH:mm:ss').format('DD MMM, YYYY hh:mm A');
        },
      applicantOriginalSET1: function (date) {
        console.log(date, "applied date1");
        if (date == "" || date == null) {
          return "";
        } else {
          return moment(date).utc().format("DD MMM YYYY hh:mm A");
        }
      },
      applicantStartEndTime: function (date) {
        if (date == "" || date == null) {
          return "";
        } else {
          return moment(date, "HH:mm:ss").format("hh:mm A");
        }
      },

      applicantStartEndTime1: function (date) {
        if (date == "") {
          return "";
        } else {
          return moment(date, "HH:mm:ss").format("hh:mm A");
        }
      },
      jobStartEndTime: function (date) {
        if (date == "" || date == null) {
          return "";
        } else {
          return moment(date, "HH:mm:ss").format("hh:mm A");
        }
      },
      datePicker1: function (value) {
        if (value == null) {
          return "";
        } else {
          return moment(value).format("DD MMM YYYY");
        }
      },
    },
    watch: {
    currentPageAll(newPage) {
      this.getAllTabData(this.activeTab);
    },
    perPageAll(newRowsPerPage) {
      this.getAllTabData(this.activeTab);
    }
  },
    methods: {
      resetFilter() {
        this.company_id = 0;
      },
      returnEndDate(date,jobdate,breakTaken,hrs){

             if(date != null){
                 var time = (breakTaken == 'yes' ? (moment(date).add(hrs,'hours')).format('YYYY-MM-DD HH:mm:ss') : (moment(date)).format('YYYY-MM-DD HH:mm:ss'));
                 console.log(time)
                 console.log(jobdate)
                 if(time > jobdate){
                     return (moment(jobdate).format('hh:mm A'));
                 }else{
                     return (moment(time).format('hh:mm A'));
                 }
             }
             else
                 return '';
         },
         getIsProductionSetting() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/get-is-production-nric",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.is_production_val = this.$store.getters.getResults.data;
          }
        });
    },
      ManageJobRequirementEyeReveal(jobDatakey) {
        $("#manage_job_requirement_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
        //   $(".manage_job_requirements").toggleClass("show_full_manage_job_requirement");
        $(".large-content-requirement-inprogress")
          .find("#visible-content-requirement-inprogress" + jobDatakey)
          .toggle();
        $(".large-content-requirement-inprogress")
          .find("#invisible-content-requirement-inprogress" + jobDatakey)
          .toggle();
      },
      ManageJobScopeEyeReveal(jobDatakey) {
        $("#manage_job_scope_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
        //   $(".manage_job_scope").toggleClass("show_full_manage_job_scope");
        $(".large-content-scope-inprogress")
          .find("#visible-content-scope-inprogress" + jobDatakey)
          .toggle();
        $(".large-content-scope-inprogress")
          .find("#invisible-content-scope-inprogress" + jobDatakey)
          .toggle();
      },
      ManageJobRequirementUpcomingEyeReveal(jobDatakey) {
        $("#manage_job_requirement_upcoming_eye_icon" + jobDatakey).toggleClass(
          "fa-eye fa-eye-slash"
        );
        //   $(".manage_job_requirements_upcoming").toggleClass(
        //     "show_full_manage_job_requirement_upcoming"
        //   );
        $(".large-content-requirement-upcoming")
          .find("#visible-content-requirement-upcoming" + jobDatakey)
          .toggle();
        $(".large-content-requirement-upcoming")
          .find("#invisible-content-requirement-upcoming" + jobDatakey)
          .toggle();
      },
      ManageJobScopeUpcomingEyeReveal(jobDatakey) {
        $("#manage_job_scope_upcoming_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
        //   $(".manage_job_scope_upcoming").toggleClass("show_full_manage_job_scope_upcoming");
        $(".large-content-scope-upcoming")
          .find("#invisible-content-scope-upcoming" + jobDatakey)
          .toggle();
        $(".large-content-scope-upcoming")
          .find("#visible-content-scope-upcoming" + jobDatakey)
          .toggle();
      },
      ManageJobRequirementCompletedEyeReveal(jobDatakey) {
        $("#manage_job_requirement_completed_eye_icon" + jobDatakey).toggleClass(
          "fa-eye fa-eye-slash"
        );
        //   $(".manage_job_requirements_completed").toggleClass(
        //     "show_full_manage_job_requirement_completed"
        //   );
        $(".large-content-requirement-completed")
          .find("#visible-content-requirement-completed" + jobDatakey)
          .toggle();
        $(".large-content-requirement-completed")
          .find("#invisible-content-requirement-completed" + jobDatakey)
          .toggle();
      },

      ManageJobScopeCompletedEyeReveal(jobDatakey) {
        $("#manage_job_scope_completed_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
        //   $(".manage_job_scope_completed").toggleClass("show_full_manage_job_scope_completed");
        $(".large-content-scope-completed")
          .find("#visible-content-scope-completed" + jobDatakey)
          .toggle();
        $(".large-content-scope-completed")
          .find("#invisible-content-scope-completed" + jobDatakey)
          .toggle();
      },
      ManageJobRequirementCancelledEyeReveal(jobDatakey) {
        $("#manage_job_requirement_cancelled_eye_icon" + jobDatakey).toggleClass(
          "fa-eye fa-eye-slash"
        );
        //   $(".manage_job_requirements_cancelled").toggleClass(
        //     "show_full_manage_job_requirement_cancelled"
        //   );
        $(".large-content-requirement-cancelled")
          .find("#visible-content-requirement-cancelled" + jobDatakey)
          .toggle();
        $(".large-content-requirement-cancelled")
          .find("#invisible-content-requirement-cancelled" + jobDatakey)
          .toggle();
      },
      ManageJobScopeCancelledEyeReveal(jobDatakey) {
        $("#manage_job_scope_cancelled_eye_icon" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
        //   $(".manage_job_scope_cancelled").toggleClass("show_full_manage_job_scope_cancelled");
        $(".large-content-scope-cancelled")
          .find("#visible-content-scope-cancelled" + jobDatakey)
          .toggle();
        $(".large-content-scope-cancelled")
          .find("#invisible-content-scope-cancelled" + jobDatakey)
          .toggle();
      },

      ManageJobRequirementAllEyeReveal(jobDatakey) {
        $("#manage_job_requirement_eye_icon_all" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
        //   $(".manage_job_requirements_all").toggleClass("show_full_manage_job_requirement_all");
        $(".large-content-requirement-all")
          .find("#visible-content-requirement-all" + jobDatakey)
          .toggle();
        $(".large-content-requirement-all")
          .find("#invisible-content-requirement-all" + jobDatakey)
          .toggle();
      },
      ManageJobScopeAllEyeReveal(jobDatakey) {
        $("#manage_job_scope_eye_icon_all" + jobDatakey).toggleClass("fa-eye fa-eye-slash");
        //   $(".manage_job_scope_all").toggleClass("show_full_manage_job_scope_all");
        $(".large-content-scope-all")
          .find("#visible-content-scope-all" + jobDatakey)
          .toggle();
        $(".large-content-scope-all")
          .find("#invisible-content-scope-all" + jobDatakey)
          .toggle();
      },
      customFormatterTwo(date) {
        return moment(date).utc().format("DD-MM-YYYY");
      },
      customFormatterTwoSuspend(date) {
        return moment(date).format("DD-MM-YYYY");
      },
      cancelJob(id) {
        // this.form.username = name;
        this.job_cancellation_reason = "";
        this.job_id = id;
        this.$refs["job-cancel-modal"].show();
      },
      totalVacancy(current_vacancy) {
        console.log(current_vacancy, this.default_increment_in_vacancy);
        return parseInt(current_vacancy) - parseInt(this.default_increment_in_vacancy);
      },

      locationPtuser(job_id, id) {
        this.job_id = job_id;
        this.getLatLong().then(() => {
          this.geolocate();
          // this.$refs.address.focus();
          this.setPlace(this.currentPlace);
          this.addMarker();
        });
        this.form.user_id = id;
        this.$refs["location-modal"].show();
      },
      clear() {
        this.filterSearch = "";
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
        this.cat_id = 0;
        this.company_id = 0;
        this.getList(this.activeTab, this.activeApplicantTab, this.filterSearch);
      },
      clearLatlong() {
        this.center = { lat: 0, lng: 0 };
        // this.latlongMarker = [];
      },
      geolocate: function () {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: +position.coords.latitude,
            lng: +position.coords.longitude,
          };
        });
      },

      addMarker() {
        if (this.currentPlace) {
          const marker = {
            lat: +parseFloat(this.currentPlace.geometry.location.lat()),
            lng: +parseFloat(this.currentPlace.geometry.location.lng()),
          };
          this.form.formattedAddress = this.currentPlace.formatted_address;
          this.markers.push({ position: marker });
          this.places.push(this.currentPlace);
          this.center = marker;
          this.currentPlace = null;
        }
      },

      setPlace(place) {
        this.currentPlace = place;
        this.addMarker();
      },

      getLatLong() {
        return this.$store
          .dispatch(POST_API, {
            data: {
              job_id: this.job_id,
              user_id: this.user_id,
              // status: status ? status : 'in-progress',
              // servicesFilter: userServices ? userServices : 'all',

              page: this.currentPage,
              keyword: this.filterSearchRate,
              page: this.currentPage,
              rowsPerPage: this.form.rowsPerPage,
              sortBy: this.sortBy,
              sortDirection: this.sortDirection,
              sortDesc: this.sortDesc,
            },
            api: "/api/get-latlong",
          })

          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.marker = this.$store.getters.getResults.data.latLong;
              this.jobMarker = this.$store.getters.getResults.data.job_location;

              this.updatelatlongMarker();
            }
          });
      },

      updatelatlongMarker() {
        var markers = [];
        $.each(this.marker, function (index, val) {
          var icon = {
            url: val.candidate_profile.profile_image, // url

            scaledSize: new google.maps.Size(30, 30), // scaled size
            // radius:20,
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(0, 0), // anchor
            labelClass: "labelIconlabelIcon",
            //shape:{coords:[17,17,18],type:"circle"},
            //set optimized to false otherwise the marker  will be rendered via canvas
            //and is not accessible via CSS
            // optimized:false,
            // title: 'spot'
            // path: google.maps.SymbolPath.CIRCLE,
            // scale: 8.5,
            // fillColor: "#F00",
            // fillOpacity: 0.4,
            // strokeWeight: 0.4,
            // scaledSize: new google.maps.Size(10,20), // scaled size
            // origin: new google.maps.Point(0,0), // origin
            // anchor: new google.maps.Point(0, 32) // anchor
          };
          markers.push({
            position: { lat: Number(val.current_lat), lng: Number(val.current_long) },
            icon: icon,
            labelClass: "labelIcon",
            // icon:val.candidate_profile.profile_image,
            type: "ptuser",
            //shape:{coords: [0,0,3,3], type: "rect"},
          });
          // str['long'][index].push(val.current_long);
        });
        var jobmarkers = [];

        $.each(this.jobMarker, function (index, val) {
          markers.push({
            position: { lat: Number(val.lat), lng: Number(val.long) },
            type: "company",
          });
          // str['long'][index].push(val.current_long);
        });
        this.latlongMarkers = markers;
        this.center = { lat: 0, lng: 0 };
        setTimeout(() => {
          this.center = this.latlongMarkers[0].position;
        }, 500);
      },

      onSubmitCancelJob(evt) {
        evt.preventDefault();
        this.$refs["job-cancel-modal"].hide();
        this.$store
          .dispatch(POST_API, {
            data: {
              job_id: this.job_id,
              cancellation_reason: this.job_cancellation_reason,
              user_id: this.user_id,
              logged_user_id: this.logged_user_id,
            },
            api: "/api/cancel-job-api",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              this.job_cancellation_reason = "";
              this.success_message = this.$store.getters.getResults.message;
              this.items = this.$store.getters.getResults.data;
              this.showSuccessAlert(this.success_message);

              // Toast.fire({
              //     type: 'success',
              //     title: 'Job Cancelled Successfully.',
              // });

              /// this.changeTabStatus(this.activeTab,this.activeApplicantTab)

              window.location.reload();
            }
          });
      },

      cancelApplicant(id, job_id, key) {
        // this.form.username = name;
        this.cancellation_reason = "";
        this.applicant_cancellation_reason = "";
        this.user_id = id;
        this.job_id = job_id;
        this.keyVal = key;
        this.$refs["cancel-modal"].show();
      },

      onSubmitReview(evt) {
        evt.preventDefault();
        this.$refs["review-modal"].hide();
        this.$store
          .dispatch(POST_API, {
            data: {
              job_id: this.job_id,
              user_id: this.user_id,
              review: this.review1,
              rating: this.rating,
              logged_user_id: this.logged_user_id,
            },
            api: "/api/supervisor-rate-ptuserWeb",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
            } else {
              this.success_message = this.$store.getters.getResults.message;
              this.form.review = "";
              this.form.user_id_id = "";
              this.rating = "";
              //this.$refs['review-modal'].hide();
              this.showSuccessAlert(this.success_message);
              this.changeApplicantTabStatus(
                this.activeTab,
                this.activeApplicantTab,
                this.job_id,
                this.keyVal,
                this.user_id
              );
              // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
              // window.location.reload();

              //this.getList(this.activeTab,'selected',this.filterSearch);
              // this.$router.push({ name: 'ViewJobDetailCompleted' });
            }
          });
      },

      pdfViewer(link) {
        window.open(link);
      },

      ratePtUser(id, job_id, key) {
        // this.form.username = name;
        this.review1 = "";
        this.user_id = id;
        this.job_id = job_id;
        this.keyVal = key;

        this.checkedStar = [];
        this.$refs["review-modal"].show();
      },
      cancellationReason(type) {
        if (type == "applicant") {
          this.applicant_cancellation_reason = document.getElementById(
            "applicant_cancellation_reason"
          ).value;
        } else {
          this.job_cancellation_reason = document.getElementById("job_cancellation_reason").value;
        }
      },
      onSubmitCancel(evt) {
        console.log(this.applicant_cancellation_reason, "this.applicant_cancellation_reason");
        evt.preventDefault();
        this.$refs["cancel-modal"].hide();
        this.$store
          .dispatch(POST_API, {
            data: {
              job_id: this.job_id,
              user_id: this.user_id,
              cancellation_reason: this.applicant_cancellation_reason,
              logged_user_id: this.logged_user_id,
            },
            api: "/api/cancel-pt-user",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_messages_cancel = this.$store.getters.getErrors;
              this.showAlertCancel();
            } else {
              this.form.cancellation_reason = "";
              this.form.user_id = "";
              this.success_message = this.$store.getters.getResults.message;
              this.showSuccessAlert(this.success_message);
              this.changeApplicantTabStatus(
                this.activeTab,
                this.activeApplicantTab,
                this.job_id,
                this.keyVal,
                this.user_id
              );
              // this.getList(this.activeTab,'selected',this.filterSearch);
              // this.changeTabStatus(this.activeTab, this.activeApplicantTab, this.filterSearch);
              // window.location.reload();
            }
          });
      },

      downloadQR(evt) {
        this.$store
          .dispatch(POST_API, {
            data: {
              id: this.qr_job_id,
            },
            api: "/api/show-qr-code",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_messages_cancel = this.$store.getters.getErrors;
              this.showAlertCancel();
            } else {
              this.qr_url = this.$store.getters.getResults.url;
              var name = this.$store.getters.getResults.name;
              this.$refs["qr-code-modal"].hide();

              window.open(this.qr_url, "_blank");
            }
          });
      },




      // suspendApplicant(user_id,job_id,lunch_pay_hours_old,lunch_pay_not_provided_old,job_start_date,start_time) {

      //     this.suspention_reason = '';
      //     this.user_id = user_id;
      //     this.job_id = job_id;

      //     this.lunch_pay_not_provided = 'no';
      //     this.lunch_break = lunch_pay_not_provided_old
      //     this.lunch_pay_hours = lunch_pay_hours_old;

      //         this.job_start_time.hh = moment((job_start_date+' '+ start_time)).utc().format('hh'),
      //         this.job_start_time.mm = moment((job_start_date+' '+ start_time)).utc().format('mm'),
      //         this.job_start_time.A = moment((job_start_date+' '+ start_time)).utc().format('A');

      //         this.job_start_date = (job_start_date+' '+ start_time) != null ? moment((job_start_date+' '+ start_time),'YYYY-MM-DD').utc().toDate() : ''

      //     this.$refs['suspend-modal'].show();

      // },




      getHourlyCost() {
        return this.$store
          .dispatch(POST_API, {
            data: {},
            api: "/api/hourly-cost",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              if (this.$store.getters.getResults.data) {
                this.costListing = this.$store.getters.getResults.data;
              }
            }
          });
      },
      showAlert() {
        this.$swal({
          position: "center",
          // icon: 'error',
          imageUrl: "/assets/images/404_elcy.gif",
          customClass: {
            container: "mascot_error_container",
          },
          title: this.error_message,
          showConfirmButton: false,
          timer: 1500,
        });
      },
      showAlertCancel() {
        this.$swal({
          position: "center",
          // icon: 'error',
          imageUrl: "/assets/images/404_elcy.gif",
          customClass: {
            container: "mascot_error_container",
          },
          title: this.error_messages_cancel,
          showConfirmButton: false,
          timer: 1500,
        });
      },

      btoa(id) {
        return btoa(id);
      },
      showSuccessAlert(message) {
        this.$swal({
          position: "center",
          icon: "success",
          iconColor: "#4c8bf5",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          // this.$router.push({ name: 'manage-jobs' });
        });
      },

      getDropdownData() {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: "service_categories",
          },
          api: "/api/service-categories-list-dp",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.categories = this.$store.getters.getResults.data;
          }
        });

    },
    getCompanyDropdownData() {
      this.$store
        .dispatch(POST_API, {
          data: {
          },
          api: "/api/getAllCompaniesNew",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.companies = this.$store.getters.getResults.data;
          }
        });
    },




    changeTabStatusData(status) {
      this.loading = true;
      this.activeTab = status;
      this.activeApplicantTab = "selected";

      this.start_date_filter = this.dateRange.startDate
        ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
        : "";
      this.end_date_filter = this.dateRange.endDate
        ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
        : "";

      const api = "/api/dailyPaidJobsDPUser";

      return this.$store
        .dispatch(POST_API, {
          data: {
            page: this.currentPage,
            rowsPerPage: this.form.rowsPerPage,
            user_id: this.id,
            cat_id: this.cat_id,
            company_id: this.company_id,
            user_type_id: this.user_type_id,
            is_company_head: this.is_company_head,
            start_date_filter: this.start_date_filter,
            end_date_filter: this.end_date_filter,
            activeTab: status,
            job_status: status == "inprogress" ? "in-progress" : status,
            filterSearch: this.filterSearch,
          },
          api: api,
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            const results = this.$store.getters.getResults;
            this.jobDatas = results.pageOfItems; // Update the reactive variable
            this.totalRows = results.pager.totalItems;
            this.perPage = results.pager.pageSize;
            this.from = results.pager.from;
            this.to = results.pager.to;
            this.inprogressCount = results.inprogresscounts;
            this.upcomingCount = results.upcommingcounts;
            this.completedCount = results.completedCount;
            this.user_badges = results.user_badges;
            this.userRating = results.userRating;
            this.companies = results.companies;
            return this.jobDatas
          }
        })
        .finally(() => {
          this.loading = false;
        });
},

 getAllTabData(status) {
  this.loading = true;
  this.activeTab = status;
  this.activeApplicantTab = "selected";

  this.start_date_filter = this.dateRange.startDate
    ? moment(this.dateRange.startDate).format("YYYY-MM-DD")
    : "";
  this.end_date_filter = this.dateRange.endDate
    ? moment(this.dateRange.endDate).format("YYYY-MM-DD")
    : "";

  const api = "/api/dailyPaidJobsDPUser";

  return this.$store
    .dispatch(POST_API, {
      data: {
        page: this.currentPageAll,
        rowsPerPage: this.form.rowsPerPage,
        user_id: this.id,
        cat_id: this.cat_id,
        company_id: this.company_id,
        user_type_id: this.user_type_id,
        is_company_head: this.is_company_head,
        start_date_filter: this.start_date_filter,
        end_date_filter: this.end_date_filter,
        activeTab: status,
        job_status: status == "inprogress" ? "in-progress" : status,
        filterSearch: this.filterSearch,
      },
      api: api,
    })
    .then(() => {
      if (this.$store.getters.containsErrors) {
        this.error_message = this.$store.getters.getErrors;
        this.showAlert();
      } else {
        const results = this.$store.getters.getResults;
        this.jobDatasAll = results.pageOfItems; // Update the reactive variable
        this.totalRowsAll = results.pager.totalItems;
        this.perPageAll = results.pager.pageSize;
        this.fromAll = results.pager.from;
        this.toAll = results.pager.to;
        this.inprogressCount = results.inprogresscounts;
        this.upcomingCount = results.upcommingcounts;
        this.completedCount = results.completedCount;
        this.user_badges = results.user_badges;
        this.userRating = results.userRating;
        this.companies = results.companies;

        
        console.log(this.jobDatasAll,'jobDatasAll',this.totalRowsAll,this.perPageAll,this.fromAll,this.toAll)
        return this.jobDatasAll
      }
    })
    .finally(() => {
      this.loading = false;
    });
},
      dpJobs(id,status) {

          if(status=='in-progress'){
          var url = this.webUrlV1Admin + "/admin/jobs/view-jobsDetail-inprogress/" + btoa(id)+ "/null/in-progress/all/all";
        }
        if(status=='upcoming'){
          var url = this.webUrlV1Admin + "/admin/jobs/view-jobsDetail-upcoming/" + btoa(id)+ "/null/upcoming/all/all";
        }
        if(status=='completed'){
          var url = this.webUrlV1Admin + "/admin/jobs/view-jobsDetail-completed/" + btoa(id)+ "/null/completed/all/all";
        }
        if(status=='cancelled'){
          var url = this.webUrlV1Admin + "/admin/jobs/view-jobsDetail-cancelled/" + btoa(id)+ "/null/cancelled/all/all";
        }


      window.open(url, "_blank");
    },
      paginate(status, user_status) {
        this.loading = true;
        this.activeTab = status;
        if (this.activeTab == "upcoming") {
          this.upcoming = true;
          this.inprogress = false;
          this.completed = false;
          this.cancelled = false;
          this.all = false;
        } else if (this.activeTab == "completed") {
          this.completed = true;
          this.inprogress = false;
          this.upcoming = false;
          this.cancelled = false;
          this.all = false;
        } else if (this.activeTab == "cancelled") {
          this.cancelled = true;
          this.inprogress = false;
          this.upcoming = false;
          this.completed = false;
          this.all = false;
        } else if (this.activeTab == "all") {
          this.all = true;
          this.inprogress = false;
          this.upcoming = false;
          this.completed = false;
          this.cancelled = false;
        } else {
          this.inprogress = true;
          this.expired = false;
          this.upcoming = false;
          this.completed = false;
          this.cancelled = false;
          this.all = false;
        }

        (this.start_date = this.dateRange.startDate
          ? moment(this.dateRange.startDate).format("YYYY-MM-DD HH:mm:ss")
          : ""),
          (this.end_date = this.dateRange.endDate
            ? moment(this.dateRange.endDate).format("YYYY-MM-DD HH:mm:ss")
            : "");
        var api ="/api/dailyPaidJobsDPUser";
        return this.$store
          .dispatch(POST_API, {
            data: {
              page: this.currentPage,
              rowsPerPage: this.form.rowsPerPage,
              user_id: this.id,
              cat_id:this.cat_id,
              company_id: this.company_id,
              user_type_id: this.user_type_id,
              is_company_head: this.is_company_head,
              activeTab: status,
              job_status: status == "inprogress" ? "in-progress" : status,
              user_status: user_status,
              filterSearch: filterSearch,
            },
            api: api,
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              this.loading = false;

              return [];
            } else {
              this.loading = false;

              this.jobDatas = this.$store.getters.getResults.pageOfItems;
              this.totalRows = this.$store.getters.getResults.pager.totalItems;
              this.perPage = this.$store.getters.getResults.pager.pageSize;
              this.from = this.$store.getters.getResults.pager.from;
              this.to = this.$store.getters.getResults.pager.to;
              // this.inprogressCount = this.$store.getters.getResults.inprogresscounts.progressJobs
              // this.upcomingCount = this.$store.getters.getResults.upcommingcounts.upcomingJobs

              return this.jobDatas;
            }
          });
      },

      getList() {
        if (this.activeTab == "all") {
          this.getAllTabData('all');
        } else{
        this.changeTabStatusData(this.activeTab).then(() => {
        // if (this.activeTab == "all") {
        //   console.log('refresh')
        //   this.$refs.datatableAll.refresh();
        // } 
        })
        }
      },
      changeTabStatus(status) {
         this.activeTab = status;
        this.activeApplicantTab = "selected";
        if (status == "all") {
          //this.company_id = 0;
          this.getAllTabData('all');
        } else{
          this.changeTabStatusData(this.activeTab);
        }
      },
      resetModal() {
        this.form.id = "";
        this.supervisorName = "";
      },


      customLabel({ first_name, last_name }) {
        return `${last_name} ${first_name}`;
      },
      hideModal() {
        this.$refs["attach-sup-modal"].hide();
      },
      showQR(code, title, job_id) {
        this.$refs["qr-code-modal"].show();
        this.qrcode = code;
        this.qr_title = title;
        this.qr_job_id = job_id;
      },
      permissionEM() {
        if (this.$store.getters.getCurrentUserPermission) {
          if (
            this.$store.getters.getCurrentUserPermission.data.length > 0 &&
            this.$store.getters.currentUser.is_company_head == "no" &&
            (this.$store.getters.currentUser.user_type_id == 3 ||
              this.$store.getters.currentUser.user_type_id == 4)
          ) {
            var menu = this.$store.getters.getCurrentUserPermission.data;
            this.permissionActions = permission.getPermission(menu, "Manage Jobs");
          }
        }
      },
      showPermissionEM(action) {
        if (
          this.$store.getters.currentUser.is_company_head == "no" &&
          (this.$store.getters.currentUser.user_type_id == 3 ||
            this.$store.getters.currentUser.user_type_id == 4)
        ) {
          return this.permissionActions.indexOf(action) >= 0 ? true : false;
        } else {
          return true;
        }
      },
      showJobListRateAdmin(action) {
        if (
          this.$store.getters.currentUser.is_company_head == "yes" &&
          (this.$store.getters.currentUser.user_type_id == 3 ||
            this.$store.getters.currentUser.user_type_id == 4)
        ) {
          return this.show_job_list_rate == "yes" ? true : false;
        } else {
          return true;
        }
      },
    },
    mounted() {
      $(document).ready(function () {
        // $(document).on('click', '.btn', function(){
        // var moreLessButton = $(".invisible-content").is(':visible') ? 'Read More' : 'Read Less';
        // $(this).text(moreLessButton);
        // $(this).parent('.large-content').find(".invisible-content").toggle();
        // $(this).parent('.large-content').find(".visible-content").toggle();
        // });
      });

      //document.title = "EL Connect - Employer - Manage Jobs";
      this.user_id =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.logged_user_id =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
      this.charge_type =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.charge_type : "";

      this.user_type_id =
        this.$store.getters.currentUser != null ? this.$store.getters.currentUser.user_type_id : "";
      this.is_company_head =
        this.$store.getters.currentUser != null
          ? this.$store.getters.currentUser.is_company_head
          : "";

      this.company_admin_id =
        this.$store.getters.currentUser != null
          ? this.$store.getters.currentUser.company_admin_id
          : "";

        this.getIsProductionSetting();
      this.permissionEM();
      Bus.$emit("active_users");
      Bus.$emit("get_em_job_count");
      this.allManageJobDPLocal = JSON.parse(localStorage.getItem("allManageJobDPLocal"))
        ? JSON.parse(localStorage.getItem("allManageJobDPLocal"))
        : "";
      this.activeTab = this.allManageJobDPLocal.activeTabLocal
        ? this.allManageJobDPLocal.activeTabLocal
        : "inprogress";
      this.getList(this.activeTab, this.activeApplicantTab, this.filterSearch);
      localStorage.removeItem("allManageJobDPLocal");
      this.getDropdownData();

      this.getCompanyDropdownData()

      // this.changeApplicantTabStatus(this.activeApplicantTab);

      $(".dashboard-content-container .simplebar-scroll-content").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
    },
  };
  </script>

  <style scoped>
  .star {
    color: gold; /* Customize star color */
    font-size: 20px; /* Adjust size */
    margin-right: 2px; /* Space between stars */
  }
  .name-td-box {
    position: relative;
    padding-left: 50px;
    min-height: 40px;

    p {
        max-width: 190px;
    }

    >img {
        height: 40px;
        width: 40px;
        border-radius: 50px;
        position: absolute;
        left: 0;
    }
}
  </style>
