<template>
  <div>
    <div class="organization_bg">
      <div class="container my_custom_container_emp desktop_padding_top_zero">
        <div v-if="showScamImage" class="image-container" @click="closeScamImage">
          <img
            v-for="docs in scam_notice_images"
            :src="docs"
            :key="docs"
            class="scam-image"
            @click.stop
          />
        </div>
        <div class="row">
          <div class="col-xl-12">
            <div class="organization_div">
              <h2 class="common_futura_bold_font">Our Trusted Partners in Opportunity</h2>
              <p>Join our community supported by leading employers</p>
              <div class="img_companies desktop_companies">
                <img :src="webUrl + 'assets/employerImages/mcd color.png'" alt="Mcd logo" />
                <img :src="webUrl + 'assets/employerImages/sats color.png'" alt="Sats logo" />
                <img
                  :src="webUrl + 'assets/employerImages/sushi tei color.png'"
                  alt="Sushi tei logo"
                />
                <img :src="webUrl + 'assets/employerImages/hilton color.png'" alt="Hilton logo" />
                <img :src="webUrl + 'assets/employerImages/jnt color.png'" alt="Jnt logo" />
                <img :src="webUrl + 'assets/employerImages/spx logo.png'" alt="Shopee logo" />
              </div>
              <div class="img_companies mobile_companies">
                <div class="row">
                  <div class="col-xl-6">
                    <img :src="webUrl + 'assets/employerImages/mcd color.png'" alt="Mcd logo" />
                  </div>
                  <div class="col-xl-6">
                    <img :src="webUrl + 'assets/employerImages/sats color.png'" alt="Sats logo" />
                  </div>
                  <div class="col-xl-6">
                    <img
                      :src="webUrl + 'assets/employerImages/sushi tei color.png'"
                      alt="Sushi tei logo"
                    />
                  </div>
                  <div class="col-xl-6">
                    <img
                      :src="webUrl + 'assets/employerImages/hilton color.png'"
                      alt="Hilton logo"
                    />
                  </div>
                  <div class="col-xl-6 margin_top_30">
                    <img :src="webUrl + 'assets/employerImages/jnt color.png'" alt="Jnt logo" />
                  </div>
                  <div class="col-xl-6 margin_top_30">
                    <img :src="webUrl + 'assets/employerImages/spx logo.png'" alt="Shopee logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my_custom_container_emp jobseeker_padding_top_zero">
      <div class="row common_padding_top_section">
        <div class="col-xl-6 col-md-6">
          <div class="second_section_left_section">
            <h2 class="common_futura_bold_font common_navy_blue_color" id="text">
              Unlocking
              <span
                class="pink_bg_txt common_futura_bold_font"
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/text-bg.png' + ')',
                }"
                >job opportunities</span
              ><br />
              and stability is our<br />
              commitment.<br />
              Explore our services,<br />
              including
            </h2>
          </div>
        </div>
        <div class="col-xl-6 col-md-6">
          <div class="second_section_right_section">
            <ul>
              <li>
                <img
                  :src="webUrl + '/assets/gif/search-icon.gif'"
                  class="common_desktop"
                  alt="Search"
                  id="myGif"
                />
                <img
                  :src="webUrl + '/assets/gif/search-icon.gif'"
                  class="common_mobile"
                  alt="Search"
                  id="myGif"
                />
                <span class="heading_span">Streamlined Job Search</span>
                <span class="para_span"
                  >Easily connect with top employers, access thousands of opportunities, and explore
                  gig earning options at EL Connect job platform in Singapore.</span
                >
              </li>
              <li>
                <img
                  :src="webUrl + '/assets/gif/notif-icon.gif'"
                  class="common_desktop"
                  alt="Notification"
                  id="myGif"
                />
                <img
                  :src="webUrl + '/assets/gif/notif-icon.gif'"
                  class="common_mobile"
                  alt="Notification"
                  id="myGif"
                />
                <span class="heading_span">Proactive Alerts</span>
                <span class="para_span"
                  >Stay updated with job alerts via email, messages, or push notifications to seize
                  perfect opportunities.</span
                >
              </li>
              <li>
                <img
                  :src="webUrl + '/assets/gif/work-icon.gif'"
                  class="common_desktop"
                  alt="Business"
                  id="myGif"
                />
                <img
                  :src="webUrl + '/assets/gif/work-icon.gif'"
                  class="common_mobile"
                  alt="Business"
                  id="myGif"
                />
                <span class="heading_span">Empowering Tools</span>
                <span class="para_span"
                  >Track job applications, gain insights into your progress, and discover
                  daily-paying jobs while waiting for responses.</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container my_custom_container_emp jobseeker_padding_top_zero">
      <div class="row common_padding_top_section">
        <div class="col-xl-8 col-md-8">
          <div
            class="pinkline_bg"
            :style="{
              backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/pink-line.png' + ')',
            }"
          >
            <h2 class="common_navy_blue_color ready_text common_futura_bold_font">
              Ready to get started? <br />Discover jobs in EL Connect job platform now!
            </h2>
            <a href="/singapore-jobs" class="browse_job_a"
              >Discover More Jobs<i class="material-icons-outlined">east_outlined</i></a
            >
            <div class="color_download_btn common_mobile">
              <button id="openLink" class="active" data-text="DOWNLOAD NOW"></button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 common_desktop">
          <div class="color_download_btn">
            <button class="active" v-b-modal.modal-22 data-text="DOWNLOAD NOW"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="container my_custom_container_emp jobseeker_padding_top_zero">
      <div class="row common_padding_top_section">
        <div class="col-xl-12">
          <div class="easy_steps_div earn_more_bg">
            <h2 class="common_futura_bold_font">
              <span
                class="easy_steps_bg common_futura_bold_font"
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/earn-more.png' + ')',
                }"
                >Earn more</span
              >
              with us!
            </h2>
          </div>
        </div>
      </div>
      <div class="row mobile_row_change">
        <div class="col-xl-7 col-md-7">
          <ul class="earn_more_ul">
            <li
              v-for="(campaign, index) in all_campaign"
              :key="index"
              :class="{ active: activeCampaignIndex === index }"
              @click="setActiveCampaign(index)"
            >
              <a href="javascript:void(0);">
                <span>{{ campaign.text }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-xl-5 col-md-5" v-if="currentCampaign">
          <div class="tab-content">
            <div class="image_with_dynamic_txt tab-pane fade in active">
              <img :src="getImageSrc(currentCampaign)" alt="Progressive incentive levels" />
              <p>{{ currentCampaign.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my_custom_container_emp jobseeker_padding_top_zero">
      <div class="row common_padding_top_section common_mobile easy_steps_mobile_section">
        <div class="col-xl-12">
          <div class="easy_steps_div">
            <h2 class="common_futura_bold_font">
              Easy
              <span
                class="easy_steps_bg common_futura_bold_font"
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/easy-steps.png' + ')',
                }"
                >steps</span
              >
              to use the app
            </h2>
          </div>
        </div>
        <div class="easy_steps_top_div">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-5 col-md-5">
                <img
                  :src="
                    webUrl + 'assets/jobeekerImages/EL Connect_Illustration+Penguin 1 desktop.png'
                  "
                  class="human_img"
                  alt="Apply"
                />
              </div>
              <div class="col-xl-7 col-md-7">
                <h3 class="common_pink_color">Step 1: Apply with Ease</h3>
                <p>
                  Find your ideal jobs. Once you've found a part time job that aligns with your
                  goals, simply click on the 'Apply' button.
                </p>
              </div>
              <!--                      <div class="col-xl-5 col-md-5">
                               <img :src="webUrl+'assets/jobeekerImages/+mobile.png'" class="add_image" alt="Plus icon">
                          </div> -->
              <!-- <div class="col-xl-7 col-md-7"></div> -->
              <div class="col-xl-5 col-md-5">
                <img
                  :src="
                    webUrl + 'assets/jobeekerImages/EL Connect_Illustration+Penguin 2 desktop.png'
                  "
                  class="human_img"
                  alt="Applied"
                />
              </div>
              <div class="col-xl-7 col-md-7">
                <h3 class="common_pink_color">Step 2: Start Working</h3>
                <p>
                  You'll receive a notification once your application is accepted. Begin your work
                  as scheduled.
                </p>
              </div>
              <!--    <div class="col-xl-5 col-md-5">
                               <img :src="webUrl+'assets/jobeekerImages/=mobile.png'" class="add_image" alt="Equal icon">
                          </div> -->
              <!--  <div class="col-xl-7 col-md-7"></div> -->
              <div class="col-xl-5 col-md-5">
                <img
                  :src="
                    webUrl + 'assets/jobeekerImages/EL Connect_Illustration+Penguin 3 desktop.png'
                  "
                  class="human_img human_last_img"
                  alt="Part Time"
                />
              </div>
              <div class="col-xl-7 col-md-7">
                <h3 class="common_pink_color">Step 3: Get Paid</h3>
                <p>
                  Receive prompt payment for completed jobs. Choose your preferred payment method,
                  such as PayNow or bank transfer.
                </p>
              </div>
              <div class="col-xl-12">
                <div class="get_started_anchor">
                  <a href="#" class="browse_job_a common_base_blue_color"
                    >JOIN US<i class="material-icons-outlined">east_outlined</i></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row common_padding_top_section common_desktop">
        <div class="col-xl-12">
          <div class="easy_steps_div">
            <h2 class="common_futura_bold_font">
              Easy
              <span
                class="easy_steps_bg common_futura_bold_font"
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/easy-steps.png' + ')',
                }"
                >steps</span
              >
              to use the app
            </h2>
          </div>
        </div>
        <div class="easy_steps_top_div">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-3 width_65">
                <img
                  :src="
                    webUrl + 'assets/jobeekerImages/EL Connect_Illustration+Penguin 1 desktop.png'
                  "
                  class="human_img"
                  alt="Apply"
                />
                <h3 class="common_pink_color">Step 1: Apply with Ease</h3>
                <p>
                  Find your ideal jobs. Once you've found a part time job that aligns with your
                  goals, simply click on the 'Apply' button.
                </p>
              </div>
              <!--   <div class="col-xl-1 width_35">
                              <img :src="webUrl+'assets/jobeekerImages/+.png'" class="add_image" alt="Plus icon">
                          </div> -->
              <div class="col-xl-3 width_65">
                <img
                  :src="
                    webUrl + 'assets/jobeekerImages/EL Connect_Illustration+Penguin 2 desktop.png'
                  "
                  class="human_img"
                  alt="Applied"
                />
                <h3 class="common_pink_color">Step 2: Start Working</h3>
                <p>
                  You'll receive a notification once your application is accepted. Begin your work
                  as scheduled.
                </p>
              </div>
              <!--  <div class="col-xl-1 width_35">
                              <img :src="webUrl+'assets/jobeekerImages/=.png'" class="add_image" alt="Equal icon">
                          </div> -->
              <div class="col-xl-4 width_65">
                <img
                  :src="
                    webUrl + 'assets/jobeekerImages/EL Connect_Illustration+Penguin 3 desktop.png'
                  "
                  class="human_img human_last_img"
                  alt="Part Time"
                />
                <h3 class="common_pink_color">Step 3: Get Paid</h3>
                <p>
                  Receive prompt payment for completed tasks. Choose your preferred payment method,
                  such as PayNow or bank transfer
                </p>
              </div>
              <div class="col-xl-12">
                <div class="get_started_anchor">
                  <a href="#" class="browse_job_a common_base_blue_color"
                    >JOIN US<i class="material-icons-outlined">east_outlined</i></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="home_blog_content_new_index section white padding-bottom-50 full-width-carousel-fix blog_slider mobile_padding_bottom_25"
    >
      <div class="container my_custom_container_emp">
        <div class="row">
          <div class="col-xl-12">
            <div class="margin-top-30 margin-bottom-10">
              <h2 class="common_futura_bold_font">Blog</h2>
            </div>
          </div>
          <div class="col-xl-12">
            <VueSlickCarousel
              :arrows="true"
              :dots="true"
              v-bind="settingsHomeBlog"
              ref="carousel"
              v-if="featured_post.length"
              class="job_detail_slider"
            >
              <div class="index_blog_compact_item" v-for="(featured, h) in featured_post" :key="h">
                <a
                  href="#"
                  @click="blogDetail($event, featured.id, featured.title, featured.seo_permalink)"
                  class=""
                >
                  <img :src="featured.image" alt="Blogs" class="index_blog_image" />
                  <span class="line_index_span"
                    ><img :src="webUrl + 'assets/images/Line 24.png'" alt="Blog Line" /><span
                      class="index_blog_item_tag"
                      >{{ featured.tag }}</span
                    ></span
                  >
                  <div class="index_blog_compact_item_content">
                    <h3 v-if="featured.title.length < 97">{{ featured.title }}</h3>
                    <h3 v-else>{{ featured.title.substring(0, 97) + "" }}</h3>
                    <p
                      v-if="featured.description.length < 150"
                      v-html="featured.description"
                      class="description_with_h_to_p"
                    ></p>
                    <p
                      v-else
                      v-html="featured.description.substring(0, 150) + '...'"
                      class="description_with_h_to_p"
                    ></p>
                  </div>
                </a>
              </div>
            </VueSlickCarousel>
          </div>
          <div class="col-xl-12 btn_div_center mobile_form_last_section_register_btn">
            <!--  <button class="common_animation_btn pink_animation_btn" @click="redirectToBlog">
                <div><span class="first_animation_span">See All Articles</span><span class="second_animation_span">See All Articles</span></div></button> -->
            <b-link class="browse_job_a index_blog_btn" :to="{ name: 'blog' }" target="_blank"
              >See All Articles</b-link
            >
          </div>
        </div>
      </div>
    </div>

    <div class="container my_custom_container_emp jobseeker_padding_top_zero">
      <div class="row common_padding_top_section">
        <div class="col-xl-12">
          <div
            class="pinkline_bg new_pinkline_bg_mobile"
            :style="{
              backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/pink-line-2.png' + ')',
            }"
          >
            <h2 class="common_navy_blue_color ready_text common_futura_bold_font">
              Ready to unlock a world of possibilities?<br />Join EL Connect now
            </h2>
            <a href="/singapore-jobs" class="browse_job_a"
              >Discover More Jobs<i class="material-icons-outlined">east_outlined</i></a
            >
            <div class="color_download_btn color_download_bt2 common_desktop">
              <button class="active" v-b-modal.modal-22 data-text="DOWNLOAD NOW"></button>
              <!-- <img :src="webUrl+'assets/jobeekerImages/download-button.png'"> -->
            </div>

            <div class="color_download_btn common_mobile">
              <!-- <a :href="qrdata.link" target="_blank" class="active">Download Now</a> -->

              <button id="openLink1" class="active" data-text="DOWNLOAD NOW"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jobseeker_slider_section container my_custom_container_emp padding-top-0">
      <div class="common_padding_top_section">
        <h2 class="common_navy_blue_color ready_text common_futura_bold_font">
          What do our users says?
        </h2>
        <VueSlickCarousel
          :arrows="true"
          v-bind="settings"
          :dots="true"
          ref="carousel"
          v-if="testimonial.length"
          class=""
        >
          <div class="testimonial-box" v-for="(test, h) in testimonial">
            <div class="testimonial-avatar">
              <img
                :src="test.image"
                alt="Freelancer Avatar"
                loading="lazy"
                width="85"
                height="85"
              />
            </div>
            <div class="testimonial">
              {{ test.description }}
            </div>
            <div class="testimonial-author">
              <h4>{{ test.title }}</h4>
              <!-- <span>{{test.user_type | capitalize}}</span> -->
            </div>
            <div class="ratings_div_jobseeker_testimonials">
              <i class="material-icons-outlined">star</i>
              <i class="material-icons-outlined">star</i>
              <i class="material-icons-outlined">star</i>
              <i class="material-icons-outlined">star</i>
              <i class="material-icons-outlined">star</i>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
    <div class="container my_custom_container_emp jobseeker_padding_top_zero margin_bottom_85">
      <div class="row">
        <div class="col-xl-4 col-md-4 mobile_z_index">
          <div
            class="jobseeker_company_count"
            v-if="Math.ceil((ft_jobs_count + dp_jobs_count) / 1000) > 1"
          >
            <p class="d-none" id="company-count">
              {{ Math.ceil((ft_jobs_count + dp_jobs_count) / 1000) }}
            </p>
            <p class="number_count common_base_blue_color">
              <span
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Ellipse-31.png' + ')',
                }"
                class="count_bg_img"
                ><span
                  class="count_if"
                  :data-count="Math.ceil((ft_jobs_count + dp_jobs_count) / 1000)"
                  >0</span
                >K+</span
              >
            </p>
            <p class="text_1_count common_base_blue_color">Total Jobs</p>
            <p class="text_2_count common_navy_blue_color">posted on our platform</p>
          </div>
          <div class="jobseeker_company_count" v-else>
            <p class="d-none" id="company-count">
              {{ Math.ceil((ft_jobs_count + dp_jobs_count) / 1000) * 1000 }}
            </p>
            <p class="number_count common_base_blue_color">
              <span
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Ellipse-31.png' + ')',
                }"
                class="count_bg_img"
                ><span
                  class="count1"
                  :data-count="Math.ceil((ft_jobs_count + dp_jobs_count) / 1000) * 1000"
                  >0</span
                >+</span
              >
            </p>
            <p class="text_1_count common_base_blue_color">Total Jobs</p>
            <p class="text_2_count common_navy_blue_color">posted on our platform</p>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div class="jobseeker_company_count">
            <p class="number_count common_base_blue_color">
              <span
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Ellipse-32.png' + ')',
                }"
                class="count_bg_img"
                ><span class="count" :data-count="Math.ceil(companies_count / 10) * 10">0</span
                >+</span
              >
            </p>
            <p class="text_1_count common_base_blue_color">Total Companies</p>
            <p class="text_2_count common_navy_blue_color">onboarded with us</p>
          </div>
        </div>
        <div class="col-xl-4 col-md-4">
          <div class="jobseeker_company_count">
            <p class="number_count common_base_blue_color">
              <span
                :style="{
                  backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/Ellipse-33.png' + ')',
                }"
                class="count_bg_img"
                ><span class="count" :data-count="Math.ceil(users_count / 1000) * 1000">0</span
                >+</span
              >
            </p>
            <p class="text_1_count common_base_blue_color">Total Users</p>
            <p class="text_2_count common_navy_blue_color">registered</p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_bg_jobseeker common_mobile common_padding_top_section">
      <div class="container container my_custom_container_emp jobseeker_padding_top_zero">
        <div class="row">
          <div class="col-xl-12 col-md-12">
            <div class="jobseeker_last_section_left">
              <div class="last_section">
                <h2 class="common_navy_blue_color common_futura_bold_font">
                  Seize Opportunities on the Go <br /><span class="linear_span">Download</span> Our
                  App Today!
                </h2>
                <p>
                  Connect with top employers, access thousands of job opportunities, and explore gig
                  earning options with ease, all through our intuitive platform.
                </p>
              </div>
              <div class="last_section_store_icon_jobseeker">
                <div class="header_store_icon">
                  <div class="inner_anchor_mobile inner_jobseeker_a">
                    <a
                      href="https://apps.apple.com/sg/app/el-connect/id1515715893"
                      class="tab_margin_left_zero first_a_footer common_animation_btn"
                      target="_blank"
                    >
                      <div>
                        <span class="first_animation_span"
                          ><i class="fa fa-apple"></i
                          ><span class="full_span"
                            >Get it on the <span class="app_bt">App Store</span></span
                          ></span
                        ><span class="second_animation_span"
                          ><i class="fa fa-apple"></i
                          ><span class="full_span"
                            >Get it on the <span class="app_bt">App Store</span></span
                          ></span
                        >
                      </div>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.elconnectptuser"
                      target="_blank"
                      class="tab_margin_left_zero last_a_footer common_animation_btn"
                    >
                      <div>
                        <span class="first_animation_span"
                          ><i class="fab fa-google-play"></i
                          ><span class="full_span"
                            >Get it on<span class="app_bt">Google Play</span></span
                          ></span
                        ><span class="second_animation_span"
                          ><i class="fab fa-google-play"></i
                          ><span class="full_span"
                            >Get it on<span class="app_bt">Google Play</span></span
                          ></span
                        >
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="mobile_app_layout_div">
                  <img :src="webUrl + 'assets/jobeekerImages/Phone-layout.png'" alt="Phone" />
                </div>
              </div>

              <div class="relativ_div_mobile">
                <div class="penguin_image_mobile">
                  <img
                    :src="webUrl + 'assets/jobeekerImages/ELC_wavingpenguin.png'"
                    class="penguin_static_img"
                    alt="Mascot"
                  />
                  <img
                    :src="webUrl + 'assets/jobeekerImages/ELC_wavingpenguin.gif'"
                    class="penguin_gif_img"
                    alt="Waving mascot"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="footer_bg_jobseeker common_desktop"
      :style="{ backgroundImage: 'url(' + webUrl + 'assets/jobeekerImages/footer-bg.png' + ')' }"
    >
      <div class="container container my_custom_container_emp jobseeker_padding_top_zero">
        <div class="row">
          <div class="col-xl-6 col-md-6">
            <div class="jobseeker_last_section_left">
              <div class="last_section">
                <h2 class="common_navy_blue_color common_futura_bold_font">
                  Seize Opportunities on the Go <br /><span class="linear_span">Download</span> Our
                  App Today!
                </h2>
                <p>
                  Connect with top employers, access thousands of job opportunities, & explore gig
                  earning options with ease, all through our platform.
                </p>
              </div>
              <div class="last_section_store_icon_jobseeker">
                <div class="header_store_icon jobseekers_store_icon">
                  <div class="inner_anchor_mobile">
                    <a
                      href="https://apps.apple.com/sg/app/el-connect/id1515715893"
                      class="first_a_footer common_animation_btn"
                      target="_blank"
                    >
                      <div>
                        <span class="first_animation_span"
                          ><i class="fa fa-apple"></i
                          ><span class="full_span"
                            >Get it on the <span class="app_bt">App Store</span></span
                          ></span
                        ><span class="second_animation_span"
                          ><i class="fa fa-apple"></i
                          ><span class="full_span"
                            >Get it on the <span class="app_bt">App Store</span></span
                          ></span
                        >
                      </div>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.elconnectptuser"
                      target="_blank"
                      class="last_a_footer common_animation_btn"
                    >
                      <div>
                        <span class="first_animation_span"
                          ><i class="fab fa-google-play"></i
                          ><span class="full_span"
                            >Get it on<span class="app_bt">Google Play</span></span
                          ></span
                        ><span class="second_animation_span"
                          ><i class="fab fa-google-play"></i
                          ><span class="full_span"
                            >Get it on<span class="app_bt">Google Play</span></span
                          ></span
                        >
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-md-6">
            <div class="multiple_circle">
              <img :src="webUrl + 'assets/jobeekerImages/Footer-img.png'" alt="Mobile" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-22" hide-header  centered ref="rewards-modal-home-page" title="" hide-footer content-class="common_model_header common_model_title" modal-class="background_custom_class rewards_pop_up">

      <!-- <h4 class="top_pop_up_heading">{{ qrdata.title }}</h4> -->
      <!-- <div class="new_app_images">
                              <img :src="webUrl+'assets/images/dual-store-icon.png'">
                          </div> -->
      <!-- <div class="full_pop_up_div desktop_full_pop_up">
        <div class="big_image_pop_up_desktop">
          <img :src="webUrl + 'assets/images/new-pop-up-mobile.png'" />
        </div>
        <div class="small_pop_up_image">
          <a target="_blank"
            ><img
              :src="qrdata.image != null ? qrdata.image : webUrl + 'assets/images/pop-up-qr.png'"
              class="width_162 mobile_width_140"
          /></a>
          <div class="pop_up_store_icon_detail">
            <div class="store_icons">
              <a :href="link[0] ? link[0].option_value : '#'" target="_blank">
                <button class="store_btn">
                  <div class="play_icon width_162 mobile_width_140">
                    <div class="apple_logo_div">
                      <img :src="webUrl + 'assets/jobeekerImages/play_icon.png'" />
                    </div>
                    <div class="content_icon_div">
                      <h2 class="font_ten">
                        GET IT ON<br />
                        <span>Google Play</span>
                      </h2>
                    </div>
                  </div>
                </button>
              </a>
              <a :href="link[1] ? link[1].option_value : '#'" target="_blank">
                <button class="store_btn margin_top_20">
                  <div class="apple_icon width_162 mobile_width_140">
                    <div class="apple_logo_div">
                      <i class="fa fa-apple" aria-hidden="true"></i>
                    </div>
                    <div class="content_icon_div">
                      <h2>
                        Available on the<br />
                        <span>App Store</span>
                      </h2>
                    </div>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div> -->
      <div class="reward_modal_whole_div">
                <div class="left_gradient_div_rewards">
                    <div class="reward_cut_img">
                        <img :src="webUrl+'assets/images/Group-808.png'">
                    </div>
                </div>
                 <div class="right_content_div_rewards">
                    <div class="button_div_rewards">
                        <button type="button" aria-label="Close" class="close" @click="closeRewardsModel()">×</button>
                    </div>
                    <div class="inner_content_rewards">
                        <h2>{{ qrdata.title }}</h2>
                        <p>Discover daily paid jobs and full-time opportunities at your fingertips. Stay connected and manage your profile easily with our app. </p>
                        <div class="whole_icon_rewards">
                            <div class="qr_div_reward">
                                <!-- <img :src="webUrl+'assets/images/image-560.png'" class="qr_rewards"> -->
                                <a target="_blank" href="https://onelink.to/pkye4c"
                                  ><img
                                    :src="qrdata.image != null ? qrdata.image : webUrl + 'assets/images/pop-up-qr.png'"
                                    class="qr_rewards"
                                /></a>
                            </div>
                            <div class="store_div_rewards">
                                <a :href="link[1] ? link[1].option_value : '#'" target="_blank">
                                    <img :src="webUrl+'assets/images/image-550.png'" class="apple_rewards">
                                </a>
                                <br>
                                <a :href="link[0] ? link[0].option_value : '#'" target="_blank">
                                    <img :src="webUrl+'assets/images/image-551.png'" class="play_rewards">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
      <!-- <div class="download_now_btn_div_pop_up">
                              <a :href="qrdata.link" target="_blank" class="apply-now-button">Download Now <span class="icon-feather-download"></span></a>
                          </div> -->
    </b-modal>

    <!-- <b-modal
      ref="scam-notice-modal"
      title=""
      id="background_custom_class_for_banner"
      hide-footer
      centered
      content-class="common_model_header common_model_title"
      modal-class="background_custom_class"
    >

      <template>
        <div class="row"><img :src="webUrl + 'assets/jobeekerImages/beware-of-scam.png'" /></div>
      </template>
    </b-modal> -->
    <!-- <div class="margin-bottom-40"></div> -->
  </div>
</template>

<script>
import { POST_API, LOGIN, PERMISSION_DATA } from "../../../store/actions.type";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import commonLocalBusinesSchemaComponent from "../commonLocalBusinesSchemaComponent.vue";

export default {
  metaInfo: {
    title: "Ultimate Job Matching Platform in Singapore - EL Connect",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content:
          "EL Connect is the ultimate job matching platform in Singapore that offers flexible daily gigs, permanent part time and full time jobs. Apply for jobs now!",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "keywords",
        content:
          "el connect, job platform singapore, full time jobs, daily paid jobs, gig jobs, permanent part time, flexible work, part time daily paid jobs, daily paid jobs singapore, job posting site, part time jobs, daily paid, daily paid job, apply for full time jobs",
      },
      { property: "og:title", content: "Ultimate Job Matching Platform in Singapore - EL Connect" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "EL Connect is the ultimate job matching platform in Singapore that offers flexible daily gigs, permanent part time and full time jobs. Apply for jobs now!",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg" }],
  },
  data() {
    return {
      featured_post: [],
      fl_page_companies_count: "",
      fl_page_dp_ft_jobs_count: "",
      users_count: "",
      email: "",
      password: "",
      user_type: "5",
      background: null,
      banner: null,
      slide1: null,
      slide2: null,
      slide3: null,
      video: null,
      content: null,
      images: null,
      fname: "",
      lname: "",
      semail: "",
      spassword: "",
      srepassword: "",
      suser_type: "5",
      items: null,
      testimonial: [],
      webUrl: process.env.VUE_APP_URL,
      error_message: "",
      job_items: [],
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 800,
        infinite: false,
        responsive: [
          {
            // breakpoint: 768,
            // settings: {
            // 	"slidesToShow": 1,
            // 		"slidesToScroll": 1,
            // 		"speed": 800,
            // 		"infinite": false,
            //         "dots":true,
            //         "arrows":false
            //     }
            breakpoint: 998,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // "infinite": true,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // "infinite": true,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: true,
              arrow: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              arrow: true,
            },
          },
        ],
      },
      settingsHomeBlog: {
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 800,
        infinite: false,
        responsive: [
          {
            breakpoint: 998,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // "infinite": true,
              dots: false,
              arrow: true,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // "infinite": true,
              dots: false,
              arrow: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: false,
              arrow: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              arrow: true,
            },
          },
        ],
      },
      name: "",
      email: "",
      phone_number: "",
      qrdata: [],
      link: [],

      company_name: "",
      success_message: "",
      totalFLCount: "14,000",
      activeSlideIndex: 0,
      user_type_id: "",
      ft_jobs_count: "",
      dp_jobs_count: "",
      companies_count: "",
      all_campaign: [],
      country_name: "",
      scam_notice_images: [],
      showScamImage: false,

      activeCampaignIndex: 0,
    };
  },

  destroyed() {
    window.removeEventListener("scroll", this.reveal);
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    dateTimeAgo: function (value) {
      if (!value) return "";
      const dateTimeAgo = moment(value).fromNow();
      return dateTimeAgo;
    },
    customizeRoleType: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },
  computed: {
    currentCampaign() {
      return this.all_campaign[this.activeCampaignIndex];
    },
  },
  methods: {
    closeRewardsModel(){
      this.$refs["rewards-modal-home-page"].hide();
    },
    setActiveCampaign(index) {
      this.activeCampaignIndex = index;
    },
    getImageSrc(campaign) {
      return campaign.image ? campaign.image : this.webUrl + "assets/jobeekerImages/1.png";
    },
    redirectToBlog() {
      this.$router.push({ name: "blog" });
    },
    getBlogData() {
      this.$store
        .dispatch(POST_API, {
          data: { keyword: this.filter },
          api: "/api/blog-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.featured_post = this.$store.getters.getResults.featured_post;
            this.recent_post = this.$store.getters.getResults.recent_post;
            this.trending_post = this.$store.getters.getResults.trending_post;
          }
        });
    },
    blogDetail(event, id, title, seo_permalink) {
      if (event) {
        event.stopPropagation();
      }
      if (seo_permalink == null) {
        var titleNew = title.replace(/ /g, "-");
      } else {
        var titleNew = seo_permalink;
      }
      window.location.replace("/blog/" + titleNew);
      // var titleNew = title.replace(/ /g, "-");
      // window.location.replace('/blog/'+btoa(id)+ '/' +titleNew);
    },
    jobseekerMobileRetypePasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_retype_password_jobseeker_mobile input").attr("type") == "text") {
        $("#show_hide_retype_password_jobseeker_mobile input").attr("type", "password");
        $("#show_hide_retype_password_jobseeker_mobile i").addClass("fa-eye-slash");
        $("#show_hide_retype_password_jobseeker_mobile i").removeClass("fa-eye");
      } else if (
        $("#show_hide_retype_password_jobseeker_mobile input").attr("type") == "password"
      ) {
        $("#show_hide_retype_password_jobseeker_mobile input").attr("type", "text");
        $("#show_hide_retype_password_jobseeker_mobile i").removeClass("fa-eye-slash");
        $("#show_hide_retype_password_jobseeker_mobile i").addClass("fa-eye");
      }
    },
    getlinkdata() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/linkSettings",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.link = this.$store.getters.getResults.data;
            } else {
            }
          }
        });
    },
    jobseekerMobilePasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_jobseeker_mobile input").attr("type") == "text") {
        $("#show_hide_password_jobseeker_mobile input").attr("type", "password");
        $("#show_hide_password_jobseeker_mobile i").addClass("fa-eye-slash");
        $("#show_hide_password_jobseeker_mobile i").removeClass("fa-eye");
      } else if ($("#show_hide_password_jobseeker_mobile input").attr("type") == "password") {
        $("#show_hide_password_jobseeker_mobile input").attr("type", "text");
        $("#show_hide_password_jobseeker_mobile i").removeClass("fa-eye-slash");
        $("#show_hide_password_jobseeker_mobile i").addClass("fa-eye");
      }
    },
    getQRData() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/qr-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.qrdata = this.$store.getters.getResults.data;
            } else {
            }
          }
        });
    },
    reveal() {
      var fadein = this.$el.querySelectorAll(".fade-in");
      var fadedown = this.$el.querySelectorAll(".fade-down");
      var fadeleft = this.$el.querySelectorAll(".fade-left");
      var faderight = this.$el.querySelectorAll(".fade-right");
      var windowHeight = window.innerHeight;
      var elementVisible = 120;

      for (var i = 0; i < fadein.length; i++) {
        var elementTop = fadein[i].getBoundingClientRect().top;
        if (elementTop < windowHeight - elementVisible) {
          fadein[i].classList.add("active");
        } else {
          fadein[i].classList.remove("active");
        }
      }
      for (var i = 0; i < fadedown.length; i++) {
        var elementTop = fadedown[i].getBoundingClientRect().top;
        if (elementTop < windowHeight - elementVisible) {
          fadedown[i].classList.add("fade-down", "active");
        } else {
          fadedown[i].classList.remove("active");
        }
      }
      for (var i = 0; i < fadeleft.length; i++) {
        var elementTop = fadeleft[i].getBoundingClientRect().top;
        if (elementTop < windowHeight - elementVisible) {
          fadeleft[i].classList.add("fade-left", "active");
        } else {
          fadeleft[i].classList.remove("active");
        }
      }
      for (var i = 0; i < faderight.length; i++) {
        var elementTop = faderight[i].getBoundingClientRect().top;
        if (elementTop < windowHeight - elementVisible) {
          faderight[i].classList.add("fade-right", "active");
        } else {
          faderight[i].classList.remove("active");
        }
      }
    },
    setActiveSlide(index) {
      if (index >= 0 && index < this.slide1.length) {
        this.activeSlideIndex = index;
      } else if (index >= this.slide1.length && index < this.slide1.length + this.slide2.length) {
        this.activeSlideIndex = index - this.slide1.length;
      }
    },
    radioClickLogin() {
      if (this.user_type == 5) {
        $("#empcontr").hide();
        $("#jobcontr").show();
      } else {
        $("#jobcontr").hide();
        $("#empcontr").show();
      }
      $(".login_forms").fadeOut();
      $(".login_forms").fadeIn(200);
      // $(".login_forms").css({"position":"relative","opacity": 0, "left":"+=100"});
      // $(".login_forms").animate({left:0, opacity:1},500);
    },
    passwordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_login input").attr("type") == "text") {
        $("#show_hide_password_login input").attr("type", "password");
        $("#show_hide_password_login i").addClass("fa-eye-slash");
        $("#show_hide_password_login i").removeClass("fa-eye");
      } else if ($("#show_hide_password_login input").attr("type") == "password") {
        $("#show_hide_password_login input").attr("type", "text");
        $("#show_hide_password_login i").removeClass("fa-eye-slash");
        $("#show_hide_password_login i").addClass("fa-eye");
      }
    },
    repasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repassword_signup input").attr("type") == "text") {
        $("#show_hide_repassword_signup input").attr("type", "password");
        $("#show_hide_repassword_signup i").addClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").removeClass("fa-eye");
      } else if ($("#show_hide_repassword_signup input").attr("type") == "password") {
        $("#show_hide_repassword_signup input").attr("type", "text");
        $("#show_hide_repassword_signup i").removeClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").addClass("fa-eye");
      }
    },
    getMenuPermission() {
      this.$store
        .dispatch(PERMISSION_DATA, {
          data: {
            user_id: this.$store.getters.currentUser.id,
          },
          api: "/api/emp-menu-permissions",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            // console.log(this.$store.getters.getResults.data.full_name)
            this.menus = this.$store.getters.getResults.data;
            // JwtService.setMenuPermission(JSON.stringify(this.menus));
            // window.location.replace('/admin/dashboard');
            if (
              this.is_company_head == "no" &&
              (this.user_type_id == "3" || this.user_type_id == "4")
            ) {
              // this.getMenuPermission();
              // if (this.account_verification_status == "completed") {
              //   window.location.replace("/v2/employer/dp-job-manage");
              // } else if (this.ft_account_status == "completed") {
              //   window.location.replace("/v2/employer/manage-jobs");
              // } else {
              //   window.location.replace("/v2/employer/om-supervisor-profile");
              // }
              // window.location.replace('/v2/employer/om-supervisor-profile');
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/om-supervisor-profile");
              }
            } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
              // this.getMenuPermission();
              // if (this.account_verification_status == "completed") {
              //   window.location.replace("/v2/employer/dp-job-manage");
              // } else if (this.ft_account_status == "completed") {
              //   window.location.replace("/v2/employer/manage-jobs");
              // } else {
              //   window.location.replace("/v2/employer/employer-profile");
              // }
              // window.location.replace('/v2/employer/employer-profile');
              if (this.ft_account_status == "completed") {
                window.location.replace("/v2/employer/dashboard");
              } else {
                window.location.replace("/v2/employer/employer-profile");
              }
            } else if (this.user_type_id == "5") {
              window.location.replace("/v2/jobseeker/jobseeker-profile");
            } else {
              window.location.replace("/v2/admin/dashboard");
            }
          }
        });
    },
    signup() {
      console.log("fhf");
      var err = false;
      $("#reg_button_bottom").hide();
      $("#loaderregbottom").show();

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).removeClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });
      $(".required-field-password_jb").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).parent().addClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').addClass('show');
          // $(this).parent().parent().find('.error_x_white').addClass('show');
        } else {
          $(this).parent().removeClass("error-jobseekers");
          // $(this).parent().parent().find('.required_content').removeClass('show');
          // $(this).parent().parent().find('.error_x_white').removeClass('show');
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error-jobseekers").first().focus();

        $(".error-jobseekers").unbind("keypress");
        $(".error-jobseekers").bind("keypress", function () {
          $(this).removeClass("error-jobseekers");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);

      // if($('#checkbox:checked').val() != "true" && err == false){
      //     err = true;
      //     document.getElementById("errorIdSignup").classList.add("show");
      //     document.getElementById("errorIdSignup").textContent = 'Please accept terms and condition first.';
      // }
      if (err == false) {
        document.getElementById("errorIdSignup").classList.remove("show");
        document.getElementById("errorIdSignup").value = "";
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              email: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              repassword: this.spassword,
              register_from: "web",
              popup: true,
              checkbox: true,
            },
            api: "/api/signup",
          })
          .then(() => {
            // $('#reg_button').show();
            //     $('#loaderreg').hide();
            if (this.$store.getters.containsErrors) {
              $("#reg_button_bottom").show();
              $("#loaderregbottom").hide();
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              document.getElementById("errorIdSignup").classList.add("show");
              document.getElementById("errorIdSignup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignup").classList.remove("show");
              document.getElementById("errorIdSignup").value = "";

              var userTypeId = {
                user_type_id: this.user_type,
              };
              localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({ name: "login" });
              });
            }
          });
      } else {
        $("#reg_button_bottom").show();
        $("#loaderregbottom").hide();
      }
    },
    slogin() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              document.getElementById("errorIdSignup").classList.add("show");
              document.getElementById("errorIdSignup").textContent = this.error_message;
            } else {
              document.getElementById("errorIdSignup").classList.remove("show");
              document.getElementById("errorIdSignup").value = "";
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                this.getMenuPermission();
                // if(this.is_company_head == 'no' && (this.user_type_id == '3' || this.user_type_id == '4')){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/om-supervisor-profile');
                //     }
                //     // window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type_id == '3' && this.is_company_head == 'yes'){
                //     if(this.account_verification_status == 'completed'){
                //         window.location.replace('/v2/employer/dp-job-manage');
                //     }else if(this.ft_account_status == 'completed'){
                //         window.location.replace('/v2/employer/manage-jobs');
                //     }else{
                //         window.location.replace('/v2/employer/employer-profile');
                //     }
                //     // window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type_id == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
              });
            }
          });
      }
    },
    clickForgotPassword(event) {
      if (event) {
        event.stopPropagation();
      }
      var userTypeId = {
        user_type_id: this.user_type,
      };
      localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
      this.$refs["bv-modal-example"].hide();
      this.$router.push({ name: "forgotpassword" });
    },
    getWebsiteData() {
      return this.$store
        .dispatch(POST_API, {
          data: { keyword: this.filter },
          api: "/api/website-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.items = this.$store.getters.getResults.partners;
            this.testimonial = this.$store.getters.getResults.testimonial_jobseeker;
            this.background = this.$store.getters.getResults.background;
            this.banner = this.$store.getters.getResults.banner;
            this.slide1 = this.$store.getters.getResults.slide1;
            this.slide2 = this.$store.getters.getResults.slide2;
            this.slide3 = this.$store.getters.getResults.slide3;
            this.video = this.$store.getters.getResults.video;
            this.content = this.$store.getters.getResults.content;
            this.images = this.$store.getters.getResults.image;
            this.fl_page_dp_ft_jobs_count = this.$store.getters.getResults.fl_page_dp_ft_jobs_count;
            this.fl_page_companies_count = this.$store.getters.getResults.fl_page_companies_count;
            this.users_count = this.$store.getters.getResults.users_count;
            this.companies_count = this.$store.getters.getResults.companies_count;
            this.dp_jobs_count = this.$store.getters.getResults.dp_jobs_count;
            this.ft_jobs_count = this.$store.getters.getResults.ft_jobs_count;
            this.all_campaign = this.$store.getters.getResults.all_campaign;
            this.country_name = this.$store.getters.getResults.country_name;
            // if (this.country_name == "all") {
            // if (this.country_name == "IN") {
              this.showScamImage = true;
              this.scam_notice_images.push(
                // this.webUrl + "assets/jobeekerImages/beware-of-scam.png"
                this.webUrl + "assets/jobeekerImages/scam_poster_all.jpg"


              );
            // }
          }
        });
    },
    closeScamImage() {
      this.showScamImage = false;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
      Swal.fire({
        position: "top-center",
        type: "error",
        text: this.error_message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    // getorganizationSchema(){
    //     const organizationSchema = document.createElement('script');
    //     organizationSchema.setAttribute('type', 'application/ld+json');
    //     organizationSchema.text = `
    //         {
    //             "@context": "https://schema.org",
    //             "@type": "Organization",
    //             "name": "EL Connect",
    //             "url": "https://elconnect.sg",
    //             "logo": "https://elconnect.sg//assets/jobseekerImages/NewLogo.png",
    //             "contactPoint": {
    //                 "@type": "ContactPoint",
    //                 "telephone": "+65 68029119",
    //                 "contactType": "customer service",
    //                 "areaServed": "SG",
    //                 "availableLanguage": "en"
    //             },
    //             "sameAs": [
    //                 "https://www.facebook.com/elconnectsgp",
    //                 "https://www.instagram.com/elconnectsgp",
    //                 "https://www.youtube.com/c/elconnect"
    //             ]
    //         }`;
    //     document.head.appendChild(organizationSchema);
    // },
    getlocalbusinesSchema() {
      // const localbusinesSchema = document.createElement('script');
      // localbusinesSchema.setAttribute('type', 'application/ld+json');
      // localbusinesSchema.text = `
      //     {
      //         "@context": "https://schema.org",
      //         "@type": "Organization",
      //         "name": "EL Connect",
      //         "url": "https://elconnect.sg/",
      //         "logo": "https://elconnect.sg/logo.png",
      //         "image": "https://elconnect.sg/logo.png",
      //         "address": {
      //             "@type": "PostalAddress",
      //             "streetAddress": "745 Lor. 5 Toa Payoh, #03-02",
      //             "addressLocality": "Singapore",
      //             "addressRegion": "Singapore",
      //             "addressCountry": "SG",
      //             "postalCode": "319455",
      //             "Telephone": "+65 68029119"
      //         },
      //         "sameAs": [
      //             "https://www.facebook.com/elconnectsgp",
      //             "https://www.instagram.com/elconnectsgp",
      //             "https://www.youtube.com/c/elconnect"
      //         ]
      //     }`;
      // document.head.appendChild(localbusinesSchema);
      <commonLocalBusinesSchemaComponent />;
    },


  },

  mounted() {
    // this.getlocalbusinesSchema();
    // this.getorganizationSchema();
    this.getBlogData();
    $("#openLink").click(function () {
      window.open("https://elconnectptuser.app.link/OSbf0HwLOib", "_blank");
    });

    $("#openLink1").click(function () {
      window.open("https://elconnectptuser.app.link/OSbf0HwLOib", "_blank");
    });
    this.getQRData();
    this.getlinkdata();
    $(".earn_more_ul a").click(function () {
      $(this).tab("show");
    });
    $(".earn_more_ul a").on("shown.bs.tab", function (event) {
      var x = $(event.target).text();
      var y = $(event.relatedTarget).text();
    });
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    function isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();

      return elemBottom <= docViewBottom && elemTop >= docViewTop;
    }
    $(".count").each(function () {
      $(this).text(0);
    });
    $(window).scroll(function () {
      var element = document.getElementById("company-count");
      var value = element.textContent;
      console.log(value);
      $(".count").each(function () {
        if (isScrolledIntoView(this) === true) {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).data("count"),
              },
              {
                duration: 3000,
                easing: "swing",
                step: function (now) {
                  $(this).text(Math.ceil(now).toLocaleString("en-IN"));
                },
              }
            );
        }
      });

      $(".count1").each(function () {
        if (isScrolledIntoView(this) === true) {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).data("count"),
              },
              {
                duration: 3000,
                easing: "swing",
                step: function (now) {
                  if (value >= 1000) {
                    if (value == now) {
                      var roundedValue = Math.round(now / 1000);
                      $(this).text(`${roundedValue}K`);
                    } else {
                      $(this).text(Math.ceil(now).toLocaleString("en-IN"));
                    }
                  } else {
                    $(this).text(Math.ceil(now).toLocaleString("en-IN"));
                  }
                },
              }
            );
        }
      });
      $(".count_if").each(function () {
        if (isScrolledIntoView(this) === true) {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).data("count"),
              },
              {
                duration: 3000,
                easing: "swing",
                step: function (now) {
                  if (value >= 1000) {
                    if (value == now) {
                      var roundedValue = Math.round(now / 1000);
                      $(this).text(`${roundedValue}K`);
                    } else {
                      $(this).text(Math.ceil(now).toLocaleString("en-IN"));
                    }
                  } else {
                    $(this).text(Math.ceil(now).toLocaleString("en-IN"));
                  }
                },
              }
            );
        }
      });
    });

    $(".slider-new").slick({
      dots: true,
      fade: true,
      pauseOnHover: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 900,
      slidesToShow: 1,
    });
    window.addEventListener("scroll", this.reveal);

    $(".login_forms").show();
    window.scrollTo(0, 0);
    this.getWebsiteData();
    $("#slideshow > div:gt(0)").hide();

    // Function for changing to the next slide
    let nextSlide = (count, delay) => {
      setTimeout(() => {
        $("#slideshow > div:nth-of-type(" + count + ")")
          .hide()
          .removeClass("slide_dynamic_class"); // Hide the current slide
        count !== $("#slideshow > div").length ? count++ : (count = 1);
        $("#slideshow > div:nth-of-type(" + count + ")")
          .show()
          .addClass("slide_dynamic_class"); // Show the next slide
        $(".slide_dynamic_class").css({ position: "relative", opacity: 0, left: "+=10000" });
        $(".slide_dynamic_class").animate({ left: 0, opacity: 1 });

        delay = $("#slideshow > div:nth-of-type(" + count + ")").data("delay"); // Set the delay to the value of the data-delay attribute on the slide elements

        nextSlide(count, delay); // Call this function recursively
      }, delay);
    };

    nextSlide(1, $("#slideshow > div:first").data("delay"));

    //Mobile Slide Start

    $("#slideshow-mobile > div:gt(0)").hide();

    // Function for changing to the next slide
    let nextSlideMobile = (count, delay) => {
      // Set the delay on the slide
      setTimeout(() => {
        // Hide the current slide
        $("#slideshow-mobile > div:nth-of-type(" + count + ")")
          .hide()
          .removeClass("slide_dynamic_class");
        // If we've reached the end of the slides, set count back to 1,
        // otherwise, increment by 1
        count !== $("#slideshow-mobile > div").length ? count++ : (count = 1);
        // Show the next slide
        $("#slideshow-mobile > div:nth-of-type(" + count + ")")
          .show()
          .addClass("slide_dynamic_class");
        $(".slide_dynamic_class").css({ position: "relative", opacity: 0, left: "+=10000" });
        $(".slide_dynamic_class").animate({ left: 0, opacity: 1 });
        // Set the delay to the value of the data-delay attribute on the
        // slide elements
        delay = $("#slideshow-mobile > div:nth-of-type(" + count + ")").data("delay");
        // Call this function recursively
        nextSlideMobile(count, delay);
      }, delay);
    };

    nextSlideMobile(1, $("#slideshow-mobile > div:first").data("delay"));
    const gif = document.getElementById('myGif');
    if (gif) {
      gif.loading = "eager";
    }
  },

  components: {
    VueSlickCarousel,
    Carousel,
    Slide,
    commonLocalBusinesSchemaComponent,
  },

};
</script>
<style>
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.scam-image {
  width: auto;
  max-width: 100%;
  max-height: calc(100vh - 40px);
  height: auto;
  cursor: pointer;
  margin: 10px 0;
}
</style>
