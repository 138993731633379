<template>
  <div>
    <!-- Dashboard Headline -->

    <!-- Page Content
        ================================================== -->

    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <!-- Hedaline -->
        <!-- <div class="dashboard-headline">
                    <h1>Edit Billing Address</h1>
               </div> -->
        <div id="breadcrumb-v2" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb :items="breadCrumb()" />
          </div>
        </div>

        <!-- Billing Cycle Radios  -->
        <div class="billing-cycle desktop_display_block">
          <!-- Radio -->

          <div class="content">
            <ul class="fields-ul chek_page second_indiv_step_wrap">
              <li class="common_input_field desktop_padding_top_zero">
                <div class="row">
                  <div></div>
                  <div class="col-xl-6">
                    <div class="submit-field error_submit_field">
                      <h5>Branch Name</h5>
                      <b-form-input
                        type="text"
                        v-model="name"
                        id="name"
                        class="input-text with-border required-field"
                        name="name"
                        placeholder=""
                        value=""
                      />
                    </div>
                    <span class="error_x_white">Please enter Branch Name </span>
                  </div>
                  <div class="col-xl-6">
                    <div class="submit-field error_submit_field">
                      <h5>Query text</h5>
                      <b-form-input
                        type="text"
                        v-model="query_text"
                        id="query_text"
                        class="input-text with-border required-field"
                        name="query_text"
                        placeholder=""
                        value=""
                      />
                    </div>
                    <span class="error_x_white">Please enter Query Text </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- Radio -->
        </div>
      </div>

      <div class="col-xl-12 post_jb_btn">
        <a
          @click="submit"
          class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn"
          style="background: #4c8bf5 !important"
          >Submit</a
        >
        <a
          @click="$router.go(-1)"
          class="common_vertical_align_bottom button ripple-effect big margin-top-30 employee_btn"
          style="background: black !important"
          >Cancel</a
        >
      </div>
      <!-- <div class="col-xl-12">
                    <a @click="submit" class="button ripple-effect big margin-top-30 employee_btn mobile_last_btn_mrgn">Submit</a>
                </div> -->
      <!-- Summary -->
    </div>
  </div>
</template>

<script>
import { POST_API } from "../../../store/actions.type";

export default {
  data: () => {
    return {
      formPosition: 0,
      page: 1,
      coins: "",
      coinData: "",
      packageData: "",
      total_amount: 0,
      loading: false,
      query_text: "",
      name: "",
    };
  },
  methods: {
    breadCrumb() {
      var item = [
        {
          to: { name: "Branches" , params: { id: atob(this.$route.params.company_id) }},
          text: "Branches",
        },
        {
          to: null,
          text: "Edit Branch",
        },
      ];
      return item;
    },
    nextStep() {
      this.page = this.page + 1;
    },

    back() {
      this.page = this.page - 1;
    },
    getEditData() {
      return this.$store.dispatch(POST_API, {
          data: {
              id: atob(this.$route.params.id),
          },
          api: '/api/branch-edit'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
          } else {
              this.items = this.$store.getters.getResults.data.data;
              this.name = this.$store.getters.getResults.data.name;
              this.query_text = this.$store.getters.getResults.data.query_text;
          }
      });
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        position: "center",
        icon: "success",
        iconColor: "#4c8bf5",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    submit() {
      var err = false;
    
      $(".required-field").each(function () {
        if ($(this).val() == "" || ($(this).val() && $(this).val().trim().length === 0)) {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              id: atob(this.$route.params.id),
              company_id: atob(this.$route.params.company_id),
              name: this.name,
              query_text: this.query_text,
            },
            api: "/api/branch-update",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              // var message = 'Job Bookmarked successfully.'
              var message = this.$store.getters.getResults.message;
              this.showSuccessAlert(message);
              this.loading = false;
              //$router.go(-1)
              window.location.replace(
                "/v2/admin/employer/company-branch/"+(this.$route.params.company_id)
              );
            }
          });
      }
    },

    onChange(id) {
      (this.packageData = id), (this.coinData = id), this.getElCoinsById();
    },

    show(id) {
      if (id == "card") {
        $("#cardDialogue").addClass("payment-tab-active");
        $("#paypalData").removeClass("payment-tab-active");
      } else {
        $("#paypalData").addClass("payment-tab-active");
        $("#cardDialogue").removeClass("payment-tab-active");
      }
    },
  },

  mounted() {
    //document.title = "EL Connect - Employer - Add Billing Address";
    this.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.email =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.email : "";

    this.company_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.company_id : "";
    this.getEditData();

  },
};
</script>
