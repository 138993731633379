<template>
    <div class="animated fadeIn">
      <div class="manage-job-section">
        <div
          class="black-strip-head d-lg-flex d-sm-flex align-items-center padding_top_25 manage_job_top_anchor new_mobile_display_block justify-content-between"
        >
          <div class="dashboard-headline">
            <h1 class="mr-auto">Revenue Report</h1>
          </div>

          <!-- <div class="top_black_btn margin_left_15_desktop mobile_text_start">
                  <b-button v-if="show('export')" class="common_z_index_zero button ripple-effect add_btn_admin_e  desktop_margin_right_zero" @click="exportData()" style="margin-left: 19px;">Export</b-button>
                </div> -->
        </div>
      </div>
      <div class="border-radius-4 white-bg border-midium-light p-3 mb-4">
            <b-row>
                <b-col  md="6" sm="12">
                    <p><strong>Wages</strong> : S${{inv != null ? inv.wages : 0 | formatPrice}}</p>
                    <p><strong>CPF Contributions</strong> : S${{inv != null ? inv.cpf : 0  | formatPrice}}</p>
                    <p><strong>Rebates/Promotion Given</strong> : S${{inv != null ? inv.rebate : 0  | formatPrice}}</p>
                    <p><strong>Cancellation Fee Collected</strong> : S${{cancel != null ? cancel.amount : 0  | formatPrice}}</p>
                </b-col>
                <b-col  md="6" sm="12" class="mobile_margin_top_15">
                    <div class="float_right_content">
                    <p><strong>Service Fee Charged</strong> : S${{inv != null ? inv.service_fee : 0  | formatPrice}}</p>
                    <p><strong>Total Revenue</strong> : S${{inv != null ? inv.total_revenue : 0  | formatPrice}}</p>
                    <p><strong>GST Amount</strong> : S${{inv != null ? inv.gst : 0  | formatPrice}}</p>
                    </div>
                </b-col>
            </b-row>
      </div>
      <b-row>
        <b-col lg="12">
          <div class="tab-box--head top_custom_head desktop_search_top_div">
            <div class="row">
               <div class="col-xl-4 mobile_tab_max_width_flex new_search_div_daily_job search_common_margin_bottom_28" >
                <div class="search-box custom_search_admin desktop_margin_top_zero">
                  <b-input-group-prepend>
                    <b-form-input
                      id="searchBtn"
                      @keyup.enter.native="filter = searchKeyword"
                      v-model="searchKeyword"
                      placeholder="Search by keyword"
                    ></b-form-input>
                    <b-button class="btn points_events"
                      ><i class="fa fa-search" @click="filter = searchKeyword"></i
                    ></b-button>
                  </b-input-group-prepend>
                </div>
              </div>
              <div class="col-xl-4 mobile_max_widht_and_flex">
                <div class="select-service-box date-picker-field clearfix">
                  <!-- <div class="label_div text_align_end_desktop">
                            <label>Date</label>
                          </div> -->
                  <b-form-group id="input-group-7" class="required">
                    <date-range-picker
        class="manage_job_date_claneder common_date_range_picker_new transactions_jb_calender common_width_100"
        ref="picker"
        :minDate="minDate"
        :opens="center"
        :locale-data="locale"
        :auto-apply="auto"
        v-model="dateRange"
        :ranges="range"
        @update="changeDate()"
      >
        <div slot="input" slot-scope="picker">
          {{ picker.startDate | date }} - {{ picker.endDate | date }}
        </div>
      </date-range-picker>
                  </b-form-group>
                </div>
              </div>
              <div
                class="col-xl-4 mobile_tab_max_width_flex  search_common_margin_bottom_28"
              >
              <b-button
              @click="clearFilter()"
              class="new_reset_text mobile_tab_padding_right_zero mobile_tab_padding_left_zero mobile_tab_padding_top_zero mobile_tab_padding_bottom_zero new_black_reset_filter common_font_size_14 common_reset_btn_filter_transactions button ripple-effect search_all_red_btn filter_btn_widht_120 desktop_margin_right_zero m-0"
              style="background-color: red; margin-top: 20px;"
              >
              <span class="mobile_reset">Reset filters</span>
              </b-button>
              <b-button
                  v-if="show('export')"
                  class="common_export_btn common_z_index_zero button ripple-effect  desktop_margin_right_zero"
                  @click="exportData()"
                  >Export Report</b-button
                >
              </div>
          </div>
          <div class="container my_new_container show_filter_arrow p-0">
            <b-table
              ref="datatable"
              show-empty
              striped
              hover
              responsive
              :items="getInvoiceListing"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              class="new_layout_table_like_v1  revenue_table_admin"
            >
                <template #cell(customer_id)="items">
                    {{items.item.customer_id}}
                </template>
                <template #cell(employer_name)="items">
                    <p >{{items.item.user.last_name != null ? items.item.user.last_name : ''}} {{items.item.user.first_name}}</p>
                </template>
                <template #cell(company_name)="items">
                    <p v-if="items.item.user.company_id != null">
                        {{items.item.user.billing_address.length > 0 ? (items.item.user.billing_address[0].billing_company_name != null ? items.item.user.billing_address[0].billing_company_name : items.item.user.company.name) : items.item.user.company.name}}
                    </p>
                    <p v-else></p>
                    <a href="javascript:void(0);"> <div v-if="items.item.user.company.has_department == 'yes'" @click="items.toggleDetails" class="revenue_show_hide_btns"> {{ items.detailsShowing ? 'Hide Department' : 'Show Department' }}</div></a>
                </template>
                <template #cell(invoice_no)="items">
                    <a :href="items.item.invoice_id!= null ? items.item.invoice_id.url : ''">{{items.item.invoice_number}}</a>
                </template>
                <template #cell(invoice_date)="items">
                    <!-- {{items.item.invoice_date | date_formate}} -->
                        {{items.item.end_date != null ? items.item.end_date : '' | date}}
                </template>
                <template #cell(due_date)="items">
                    <!-- {{items.item.invoice_date | date_formate}} -->
                        {{items.item.due_date != null ? items.item.due_date : '' | date}}
                </template>
                <template #cell(invoice_amount)="items">
                    S${{items.item.total_revenue | ifNumber}}
                </template>
                <template #cell(credit_note)="items">
                    <div v-if="items.item.credit_note.length>0  ">
                        <b>Rebate amount:</b> S${{items.item.credit_note.rebate | ifNumber}} <br>
                        <b>GST amount:</b> S${{items.item.credit_note.gst_amount | ifNumber}} <br>
                        <b>Comments:</b> {{items.item.credit_note.comments | capitalize}}
                    </div>
                </template>
                <template #cell(wages)="items">
                    S${{items.item.wages | ifNumber}}
                </template>
                <template #cell(cpf_contribution)="items">
                    S${{items.item.cpf |ifNumber}}
                </template>
                <template #cell(promotion)="items">
                    S${{items.item.rebate |ifNumber}}
                </template>
                <template #cell(service_fee)="items">
                    S${{items.item.service_fee |ifNumber}}
                </template>
                <template #cell(total_revenue)="items">
                    S${{items.item.total_revenue |ifData}}
                </template>
                <template #cell(gst_amount)="items">
                    S${{items.item.gst |ifNumber}}
                </template>
                <template #cell(cancel)="items">
                    S${{items.item.others |ifNumber}}
                </template>
                <template #cell(date_recieved)="items">
                    <div v-if="items.item.status =='open'">
                        <div v-if="typeof(items.item.enable_date) == 'undefined' || items.item.enable_date == 'no' && (items.item.department_invoices.length == 0)" @dblclick="editabledate(items.index)">
                            {{items.item.amount_recieved_date | ifDate}}
                        </div>
                        <div v-if="typeof(items.item.enable_date) != 'undefined' && items.item.enable_date == 'yes' && (items.item.department_invoices.length == 0)">
                            <b-form-group id="input-group-6">
                                <datepicker v-model="items.item.amount_recieved_date" :format="customFormatterTwo" @input="updateData(items.item,'recieved_date')" :disabled-dates="disabledDates(items.item.invoice_date)" @selected='open()'></datepicker>
                                <span class="input-icon">
                                    <b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img>
                                </span>
                            </b-form-group>
                        </div>
                        <div v-if="items.item.outstanding.length > 0">
                            <div v-for="(data,key) in items.item.outstanding">
                                <div v-if="typeof(data.enable_date) == 'undefined' || data.enable_date == 'no'" @dblclick="editablesubdate(items.index,key)">
                                    {{data.amount_recieved_date | ifDate}}
                                </div>
                                <div v-if="typeof(data.enable_date) != 'undefined' && data.enable_date == 'yes'">
                                    <b-form-group id="input-group-6">
                                        <datepicker v-model="data.amount_recieved_date" :format="customFormatterTwo" @input="updateSubData(items.item,data,'recieved_date')" :disabled-dates="disabledDates(items.item.invoice_date)"></datepicker>
                                        <span class="input-icon">
                                            <b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img>
                                        </span>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        {{items.item.amount_recieved_date | ifDate}}
                        <div v-if="items.item.outstanding.length > 0">
                            <div v-for="(data,key) in items.item.outstanding">
                                {{data.amount_recieved_date | ifDate}}
                            </div>
                        </div>
                    </div>
                </template>
                <template #cell(recieved_amount)="items">
                    <div v-if="items.item.status =='open'">
                        <div v-if="typeof(items.item.enable_recieved_amount) == 'undefined' || items.item.enable_recieved_amount == 'no' && (items.item.department_invoices.length == 0)" @dblclick="editableAmount(items.index)">
                            {{items.item.recieved_invoice_amount|ifNumber}}
                        </div>
                        <div v-if="typeof(items.item.enable_recieved_amount) != 'undefined' && items.item.enable_recieved_amount == 'yes' && (items.item.department_invoices.length == 0)">
                            <b-form-input v-model="items.item.recieved_invoice_amount" @change="updateData(items.item,'recieved_invoice_amount')"></b-form-input>
                        </div>
                        <div v-if="items.item.outstanding.length > 0">
                            <div v-for="(data,key) in items.item.outstanding">
                                <div v-if="typeof(data.enable_recieved_amount) == 'undefined' || data.enable_recieved_amount == 'no'" @dblclick="editableSubAmount(items.index,key)">
                                    {{data.recieved_invoice_amount | ifNumber}}
                                </div>
                                <div v-else-if="typeof(data.enable_recieved_amount) != 'undefined' && data.enable_recieved_amount == 'yes'">
                                    <b-form-group id="input-group-6">
                                        <b-form-input v-model="data.recieved_invoice_amount" @change="updateSubData(items.item,data,'recieved_invoice_amount')"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        {{items.item.recieved_invoice_amount|ifNumber}}
                        <div v-if="items.item.outstanding.length > 0">
                            <div v-for="(data,key) in items.item.outstanding">
                                {{data.recieved_invoice_amount | ifNumber}}
                            </div>
                        </div>
                    </div>
                </template>
                <template #cell(customer_payment_no)="items">
                    <div v-if="items.item.status =='open'">
                        <div v-if="typeof(items.item.customer_payment) == 'undefined' || items.item.customer_payment == 'no' && (items.item.department_invoices.length == 0)" @dblclick="editablepayment(items.index)">
                            {{items.item.payment_reference_no|ifData}}
                        </div>
                        <div v-if="typeof(items.item.customer_payment) != 'undefined' && items.item.customer_payment == 'yes' && (items.item.department_invoices.length == 0)">
                            <b-form-input v-model="items.item.payment_reference_no" @change="updateData(items.item,'payment_reference_no')"></b-form-input>
                        </div>
                        <div v-if="items.item.outstanding.length > 0">
                            <div v-for="(data,key) in items.item.outstanding">
                                <div v-if="typeof(data.customer_payment) == 'undefined' || data.customer_payment == 'no'" @dblclick="editableSubpayment(items.index,key)">
                                    {{data.payment_reference_no | ifData}}
                                </div>
                                <div v-else-if="typeof(data.customer_payment) != 'undefined' && data.customer_payment == 'yes'">
                                    <b-form-group id="input-group-6">
                                        <b-form-input v-model="data.payment_reference_no" @change="updateSubData(items.item,data,'payment_reference_no')"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        {{items.item.payment_reference_no|ifData}}
                        <div v-if="items.item.outstanding.length > 0">
                            <div v-for="(data,key) in items.item.outstanding">
                                {{data.payment_reference_no | ifData}}
                            </div>
                        </div>
                    </div>
                </template>
                <template #cell(comments)="items">
                    <div v-if="items.item.status == 'open'">
                    <div v-if="typeof(items.item.is_comments) == 'undefined' || items.item.is_comments == 'no' && (items.item.department_invoices.length == 0)" @dblclick="editablecomments(items.index)">
                        {{items.item.comments|ifData}}
                    </div>
                    <div v-if="typeof(items.item.is_comments) != 'undefined' && items.item.is_comments == 'yes' && (items.item.department_invoices.length == 0)">
                        <b-form-input v-model="items.item.comments" @change="updateData(items.item,'comments')">
                        </b-form-input>
                    </div>
                    <div v-if="items.item.outstanding.length > 0">
                        <div v-for="(data,key) in items.item.outstanding" class="add_remove_btn">
                            <div v-if="typeof(data.is_comments) == 'undefined' || data.is_comments == 'no'" @dblclick="editableSubcomments(items.index,key)">
                                {{data.comments | ifData}}
                            </div>
                            <div v-else-if="typeof(data.is_comments) != 'undefined' && data.is_comments == 'yes'">
                                <b-form-group id="input-group-6">
                                    <b-form-input v-model="data.comments" @change="updateSubData(items.item,data,'comments')"></b-form-input>
                                </b-form-group>
                            </div>

                            <div @click="removeSub(items.index,key,data)" v-if="items.item.status == 'open' && data.type == 'normal'" class="closeInvoice">X</div>
                            <div v-else></div>
                        </div>
                    </div>
                    </div>
            <div v-else>
            {{items.item.comments|ifData}}
            <div v-if="items.item.outstanding.length > 0">
                <div v-for = "(data,key) in items.item.outstanding">

                    {{data.comments | ifData}}

                </div>
                </div>
            </div>
                </template>
                <template #cell(outstading_amount)="items">
                    S${{items.item.outstanding_amount | ifNumber}}
                </template>
                <template #cell(status)="items">
                    <p v-if="items.item.status =='open'" class="red-text">
                        <b>
                            {{items.item.status | capitalize}}
                        </b>
                    </p>
                    <p v-if="items.item.status =='closed'" class="green-text">
                        <b>
                            {{items.item.status | capitalize}}
                        </b>
                    </p>
                </template>
                <template #cell(action)="items">
                    <!-- <div @click="addMore(items.index,items.item.outstanding.length)" class="green-bg text-white" v-if="items.item.status == 'open'"> Add</div> -->
                    <button @click="addMore(items.index,items.item.outstanding.length)" class="btn button text-white ml-0 revenue_add_btn mr-0" v-if="items.item.status == 'open'"> Add</button>
                    <!-- <div v-if="typeof(items.item.id) == 'undefined'" @click="remove(items.index)"  class="red-bg"> Remove</div> -->
                    <!-- <div v-if="items.item.user.company.has_department == 'yes'" @click="items.toggleDetails" class="blue-bg text-white"> {{ items.detailsShowing ? 'Hide' : 'Show' }}</div> -->

                </template>
                <template #row-details="row">
                    <b-table ref="datatable1" show-empty striped hover responsive :items="row.item.department_invoices" thead-class="hidden_header" :fields="fields1">
                        <template #cell(department_inv)="items">
                            {{items.item.dep_invoice_no != null ? items.item.dep_invoice_no : ''}}
                        </template>
                        <template #cell(department)="items">
                        {{items.item.department_id.department_name != null ? items.item.department_id.department_name : '' | capitalize}}
                        </template>
                        <template #cell(amount)="items">
                        ${{items.item.total != null ? items.item.total : 0 }}
                        </template>
                        <template #cell(amount_with_gst)="items">
                        ${{items.item.total_with_gst != null ? items.item.total_with_gst : 0 }}
                        </template>
                        <template #cell(download)="items">
                            <b-button size="sm" variant="danger" @click="closeDepInvoice(items.item)" v-if="items.item.status == 'open'">
                                Close Invoice
                            </b-button>
                            <b-button size="sm" variant="success" v-else>
                                Closed
                            </b-button>
                        </template>
                        <template #cell(closed_on)="items">
                            {{items.item.status == 'closed' ? items.item.closed_on : '-'  | date_formate}}
                        </template>
                        <template #cell(remarks)="items">
                            {{items.item.remarks != null ? items.item.remarks : '-' | capitalize}}
                        </template>
                        <template #cell(created_on)="items">
                            {{items.item.created_at != null ? items.item.created_at : '' | date_formate}}
                        </template>
                    </b-table>
                </template>
            </b-table>
          </div>
          <nav class="pagination-box custom_pagination_border_radius">
            <div class="page-row-box d-inline-block">
              <b-form-group id="input-group-4">
                <span class="d-inline-block">Rows per page</span>
                <span class="d-inline-block"
                  ><b-form-select v-model="form.rowsPerPage" @change="getInvoiceListing">
                    <option>25</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                  </b-form-select></span
                >
              </b-form-group>
            </div>
            <div class="total-page-count d-inline-block">
              <ul>
                <li>
                  <span v-if="totalRows != 0">{{ from }} - {{ to }} of {{ totalRows }}</span>
                </li>
              </ul>
            </div>
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              prev-text=""
              next-text=""
              hide-goto-end-buttons
            />
          </nav>
        </div>
        </b-col>
      </b-row>
      <b-modal ref="payslip-modal" title="Select Month" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
            <!-- <b-form> -->
                <div class="edit-form-box model_margin_top_zero model_legend model_half_calender">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <!-- <b-col lg="12" md="12"> -->
                                <div class="form-map-box">
                                    <b-col lg="12" md="12">
                                        <b-form-group id="input-group-6" label="Payslip Month & Year" class="model_margin_bottom_zero required">
                                            <datepicker v-model="month" :format="customFormatterTwo" initial-view="year" minimum-view="month" :disabled-dates="disabledFromDate"></datepicker>
                                            <span class="input-icon"><b-img v-bind:src="webUrl+'assets/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="12" md="12">
                                        <b-form-group>
                                            <b-form-checkbox v-model = 'withNoEmail'  @input="withNoEmailFunc($event)"><strong>With No Email</strong></b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="12" md="12">
                                        <div slot="footer" class="form-btn common_crop_reset_btn">
                                            <b-button type="submit" variant="success" @click="generatePayslip()" class="model_common_bt_success_color">Submit</b-button>
                                            <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                        </div>
                                    </b-col>
                                </div>
                            <!-- </b-col> -->
                        </b-row>
                    </div>
                </div>
            <!-- </b-form> -->
      </b-modal>
      <b-modal ref="invoice-modal" title="Closing Remarks" hide-footer modal-class="background_custom_class" content-class="common_model_header common_model_title">
            <!-- <b-form> -->
                <div class="edit-form-box model_margin_top_zero model_legend ">
                    <div class="profile-repeate form-box-field">
                        <b-row>
                            <b-col lg="12" md="12">
                                <div class="form-map-box">
                                    <b-form-group id="input-group-6" label="Closing Date" class="required">
                                        <datepicker v-model="month" :format="customFormatterTwo" class="revenue_datepicker"></datepicker>
                                        <span class="input-icon normal_calender_single"><b-img v-bind:src="webUrl+'/images/calendar-grey-icon02.svg'" alt="Calander Icon"></b-img></span>
                                    </b-form-group>
                                    <b-form-group id="input-group-5" label="Remarks" class="required">
                                        <b-form-textarea id="input-5" type="text" placeholder="" v-model="remarks" class="mb-0"></b-form-textarea>
                                    </b-form-group>
                                    <div slot="footer" class="form-btn common_crop_reset_btn mt-4">
                                        <b-button type="submit" variant="success" @click="closeDeprartmentInv()" class="model_common_bt_success_color">Submit</b-button>
                                        <b-button @click = "hideModel()" variant="light" class="model_common_bt_lite_color">Cancel</b-button>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <!-- </b-form> -->
      </b-modal>
    </div>
</template>
  <script>
  import { POST_API, GET_API } from "../../../store/actions.type";
  import moment from "moment";
  import Datepicker from "vuejs-datepicker";
  import Vue from "vue";
  import Multiselect from 'vue-multiselect'
  import permission from "../../../../../server/permission.js";
  import DateRangePicker from "vue2-daterange-picker";

  export default {
    components: {
      Datepicker,
      Multiselect,
      DateRangePicker
    },
    data() {
      return {
        fields: [
        { key: 'customer_id', label: 'Customer ID', sortable: true, thStyle: {width: '7%'} },
        { key: 'employer_name', label: 'Employer/Individual Name', sortable: true, thStyle: {width: '10%'} },
        { key: 'company_name', label: 'Company Name', sortable: true, thStyle: {width: '10%'} },
        { key: 'invoice_no', label: 'Invoice No.', sortable: true, thStyle: {width: '8%'} },
        { key: 'invoice_date', label: 'Invoice Date', sortable: true, thStyle: {width: '12%'} },
        { key: 'due_date', label: 'Due Date', sortable: true, thStyle: {width: '12%'} },
        { key: 'invoice_amount', label: 'Invoice Amount', sortable: true, thStyle: {width: '5%'} },
        { key: 'credit_note', label: 'Credit Note', sortable: false, thStyle: {width: '5%'} },
        { key: 'wages', label: 'Wages', sortable: true, thStyle: {width: '5%'} },
        { key: 'cpf_contribution', label: 'CPF Contribution', sortable: true, thStyle: {width: '5%'} },
        { key: 'promotion', label: 'Promotion Given', sortable: true, thStyle: {width: '5%'} },
        { key: 'service_fee', label: 'Service Fee Charged', sortable: true, thStyle: {width: '5%'} },
        { key: 'total_revenue', label: 'Total Revenue', sortable: true, thStyle: {width: '5%'} },
        { key: 'gst_amount', label: 'GST Amount', sortable: true, thStyle: {width: '5%'} },
        { key: 'cancel', label: 'Cancellation Amount', sortable: true, thStyle: {width: '5%'} },
        { key: 'date_recieved', label: 'Date Received', sortable: true, variant: 'danger', thStyle: {width: '10%'} },
        { key: 'customer_payment_no', label: 'Customer Payment Ref No.', sortable: true, variant: 'danger', thStyle: {width: '8%'} },
        { key: 'recieved_amount', label: 'Received Amount', sortable: true, variant: 'danger', thStyle: {width: '8%'} },
        { key: 'comments', label: 'Comments', sortable: true, variant: 'danger' },
        { key: 'outstading_amount', label: 'Outstanding Amount', sortable: true, thStyle: {width: '5%'} },
        { key: 'status', label: 'Status', sortable: true, thStyle: {width: '5%'} },
        { key: 'action', label: 'Actions', }
            ],
            fields1: [
                { key: 'department_inv', label: 'Department', sortable: false },
                { key: 'department', label: 'Department', sortable: false },
                { key: 'amount', label: 'Amount', sortable: false },
                { key: 'amount_with_gst', label: 'Amount(+GST)', sortable: false },
                { key: 'download', label: 'Download', sortable: false },
                { key: 'closed_on', label: 'Download', sortable: false },
                { key: 'remarks', label: 'Download', sortable: false },
                { key: 'created_on', label: 'Created On', sortable: false },
            ],
        form: {
          rowsPerPage: 50,
        },
        items: null,
        minDate: null,
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [25, 50, 75, 100],
        sortBy: null,
        sortDirection: "desc",
        filter: null,
        center: "center",
        auto:true,
        status: "all",
        isBusy: false,
        sortDesc: true,
        to: "",
        from: "",
        range: false,
        dat: new Date(),
        webUrl: process.env.VUE_APP_URL,
        actions: "",
        user_type_id: "",
        startDate: new Date(moment().format("YYYY-MM-01")),
        defaultDate: moment().subtract(1, "month").format("DD MMM YYYY"),
        disabledFromDate: {
          from: new Date(moment().subtract(1, "month")),
        },
        disabledFromDate1: {
          to: new Date(),
        },
        locale: {
        direction: "ltr", //direction of text
        format: "DD-MMM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        /*weekLabel: 'W',
                customRangeLabel: 'Custom Range',*/
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        sameDateFormat: {
          from: "DD MM YYYY, hh:mm",
          to: "hh:mm",
        },
      },
      dateRange: {
        startDate:'',
        endDate: '',
      },
        dynamicHeading: moment().subtract(1, "month").format("MMMM-YYYY"),
        dynamicHeading1: moment().subtract(2, "month").format("MMMM-YYYY"),
        withNoEmail: true,
        month: "",
        payslip_generate_for_user: "",
        webServerUrl: process.env.VUE_APP_SERVER_URL,
        actionsEmployer: "",
        searchKeyword:"",
        isAugustData:false,
        success_message:"",
        year:'',
        isRemove: true,
        append: [],
        val: null,
        inv: null,
        cancel: null,
      };
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        date_formate: function(date) {

            return ((date != null && date != '-') ? moment(date).format('DD-MM-YYYY hh:mm A') : '-')
        },
        monthYear: function(value) {
            if (value == null) {
                return '';
            } else {
              var spl = value.split('-');

              if(spl[2] == '16'){
                return moment(value).format('16-MMM-YYYY')
              }else{
                return moment(value).format('01-MMM-YYYY')
              }
            }
        },
        ifData: function(value) {
            if (!value) {
                return "-";
            }
            return value;
        },
        ifDate: function(value) {
            if (!value || value == "") {
                return '-';
            }
            return moment(value).format('DD-MM-YYYY');
        },
        ifNumber: function(value) {
            if (!value) {
                return '0.00';
            }
            return parseFloat(value).toFixed(2);
        },
        date: function(value) {
            if (value == null) {
                return '';
            } else {

                return moment(value).format('DD MMM YYYY')
            }
        },
        formatPrice(value) {
  if (value == null || isNaN(value)) return "0.00";
  return Number(value)
    .toFixed(2) // Ensure two decimal places
    .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousands
}
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      customFormatterOne(date) {
        return moment(date).format("MMMM-YYYY");
      },
      customFormatterTwo(date) {
        return moment(date).format("YYYY-MM-DD");
      },
      changeDate() {
      this.$refs.datatable.refresh();
    },
      reset() {
        this.filter = null;
        this.$refs.picker.start = null;
        this.$refs.picker.end = null;
        this.getInvoiceListing().then(() => {
          this.$refs.datatable.refresh();
        });
      },
      selectEmployer(selectedOption){
      this.status=selectedOption.value
      this.$refs.datatable.refresh()
     },
      getInvoiceListing() {
        return this.$store.dispatch(POST_API, {
                    data: {
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage: this.form.rowsPerPage,
                        sortBy: this.sortBy,
                        sortDesc: this.sortDesc,
                        startDate: this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') : '',
                        endDate: this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : '',
                    },
                    api: '/api/revenue-report'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        this.inv = this.$store.getters.getResults.invoice;
                        this.cancel = this.$store.getters.getResults.cancel;
                        console.log(this.inv)
                        console.log(this.cancel)
                        // console.log(this.inv);
                        return this.items;
                    }
                });
      },

      downloadPdf(url) {
        window.open(url, "_blank");
      },
      exportData() {
        var base_url = this.webServerUrl;
        var startDate= this.dateRange.startDate ? moment(this.dateRange.startDate).format('YYYY-MM-DD') :null;
        var endDate= this.dateRange.endDate ? moment(this.dateRange.endDate).format('YYYY-MM-DD') : null;
        var sortBy = this.sortBy ? this.sortBy : "users.nric";
        var sortDesc= this.sortDesc;
        var keyword = this.filter ? this.filter : null;
        var export_url =
          "/api/revenue-report-export/" +
          keyword +
          "/" +
          startDate +
          "/" +
          endDate +
          "/" +
          sortBy +
          "/" +
          sortDesc;
        window.open(base_url + export_url, "_blank");
        // window.open(export_url,'_blank')
      },



      addMore(index, outlen) {

      var item = this.items[index];
      /*console.log(item);*/
      /*var obj = {
          "amount_recieved_date": "",
          "recieved_invoice_amount": "",
          "payment_reference_no": "",
          "comments": "",
          "type": "normal",
      }*/
      var len = outlen;
      var outlen = len - 1;
      var blank = false;

      if (len == 0) {
          var ard = item.amount_recieved_date;
          var ria = item.recieved_invoice_amount;
          var prn = item.payment_reference_no;
          /*console.log(ria);
          console.log(ard);
          console.log(prn);*/
          /*var cmt = item.outstanding[outlen].comments;*/
          if (ard == "" || (ria == null) || prn == "" /*|| cmt ==""*/ ) {
              blank = true;
          } else {
              blank = false;
          }

      } else {
          /*console.log(item.outstanding[outlen], len);*/
          var ard = item.outstanding[outlen].amount_recieved_date;
          var ria = item.outstanding[outlen].recieved_invoice_amount;
          var prn = item.outstanding[outlen].payment_reference_no;
          /*var cmt = item.outstanding[outlen].comments;*/
          if (ard == "" || (ria == null) || prn == "" /*|| cmt ==""*/ ) {
              blank = true;
          } else {
              blank = false;
          }
      }

      if (len > 0 && blank) {
        this.error_message="Please fill the added row"
        this.showAlert()

      } else if (blank) {
        this.error_message="Please fill all the record"
        this.showAlert()
      } else {
          /*item.outstanding.push(obj);*/
          /*this.items.splice(index, 1, item);
          this.$refs.datatable.refresh();*/
          /*console.log(len, 'len');
          console.log(this.items);*/
          //Need to create a record in outstanding
          this.createOutstanding(item.id);
      }

      },
      editabledate(index) {
          //this.items[index].enable_date = "yes"

          this.updateDBKey(this.items[index].id,'enable_date');
          //this.items = [];
          //this.$refs.datatable.refresh();
      },
      editableAmount(index) {
          //console.log(this.items[index].payment_reference_no);
          if (this.items[index].payment_reference_no != null && this.items[index].amount_recieved_date != null) {

              //console.log('isme',this.items[index].payment_reference_no);
              this.updateDBKey(this.items[index].id,'enable_recieved_amount');
          }else if(this.items[index].amount_recieved_date == null){
            this.error_message = "Please fill Date Recieved."
                        this.showAlert();

          }else if(this.items[index].payment_reference_no == null){
            this.error_message ="Please fill  Payment reference no.";
                        this.showAlert();

          }
          //

      },
      updateDBKey(id,column){
            return this.$store.dispatch(POST_API, {
                    data: {
                        id: id,
                        column: column,
                    },
                    api: '/api/update-invoice-key'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.$refs.datatable.refresh()
                    }
                });
        },
      editablepayment(index) {
          //this.items[index].customer_payment = "yes";
          this.updateDBKey(this.items[index].id,'customer_payment');
          //this.$refs.datatable.refresh();
      },
      editablecomments(index) {
          //this.items[index].is_comments = "yes";
          this.updateDBKey(this.items[index].id,'is_comments');
          //this.$refs.datatable.refresh();
      },
      disabledDates: function(date) {


            var d = new Date(date);
            //var c = new Date(Date.now());
            return {
                to: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
                //from: new Date(c.getFullYear(), c.getMonth(), c.getDate()+1)
            };
        },

        updateData(item, column) {
            console.log(item, column);
            if (column == "recieved_date") {
                this.val = moment(item.amount_recieved_date).format('YYYY-MM-DD')
            } else if (column == "recieved_invoice_amount") {
                this.val = item.recieved_invoice_amount
            } else if (column == "payment_reference_no") {
                this.val = item.payment_reference_no
            } else {
                this.val = item.comments
            }

            return this.$store.dispatch(POST_API, {
                    data: {
                        id: item.id,
                        value: this.val,
                        item: item,
                        column: column,
                    },
                    api: '/api/update-revenue-item'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        // this.getInvoiceListing()
                        return [];
                    } else {
                        // this.getInvoiceListing()
                    }
                });
        },
        updateSubData(item, data, column) {
            //console.log(item,data,column);
            if (column == "recieved_date") {
                this.val = moment(data.amount_recieved_date).format('YYYY-MM-DD')
            } else if (column == "recieved_invoice_amount") {
                this.val = data.recieved_invoice_amount
            } else if (column == "payment_reference_no") {
                this.val = data.payment_reference_no
            } else {
                this.val = data.comments
            }

            return this.$store.dispatch(POST_API, {
                    data: {

                        value: this.val,
                        data: data,
                        item: item,
                        column: column,
                    },
                    api: '/api/update-sub-revenue-item'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        //this.getInvoiceListing()
                        return [];
                    } else {
                        //this.getInvoiceListing()
                    }
                });
        },
      createOutstanding(item_id){
            return this.$store.dispatch(POST_API, {
                    data: {
                        invoice_id : item_id,
                    },
                    api: '/api/create-outstanding'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.$refs.datatable.refresh()
                    }
                });
        },
        deleteSub(id) {
            return this.$store.dispatch(GET_API, {
                    data: {

                    },
                    api: '/api/delete-outstanding/' + id
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        console.log('deleted')
                        this.getInvoiceListing()
                    }
                });
        },
        removeSub(index, key, data) {
            if (typeof data.id != "undefined") {
                this.deleteSub(data.id);
                this.items[index].outstanding.splice(key, 1);
            } else {
                this.items[index].outstanding.splice(key, 1);
            }

            this.$refs.datatable.refresh();
        },
        updatesubDBKey(id,column){
            return this.$store.dispatch(POST_API, {
                    data: {
                        id: id,
                        column: column,
                    },
                    api: '/api/update-sub-invoice-key'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.$refs.datatable.refresh()
                    }
                });
        },
        editablesubdate(index, key) {
            this.items[index].outstanding[key].enable_date = "yes";

            this.updatesubDBKey(this.items[index].outstanding[key].id,'enable_date');
            //this.$refs.datatable.refresh();
        },
        editableSubAmount(index, key) {
            if (this.items[index].outstanding[key].payment_reference_no != null && this.items[index].outstanding[key].amount_recieved_date != null) {
               this.updatesubDBKey(this.items[index].outstanding[key].id,'enable_recieved_amount');
           }else if(this.items[index].outstanding[key].amount_recieved_date == null){
            this.error_message = "Please fill Date Recieved.";
                        this.showAlert();

            }else if(this.items[index].outstanding[key].payment_reference_no == null){
                this.error_message ="Please fill Payment reference no.";
                        this.showAlert();

            }
            //this.items[index].outstanding[key].enable_recieved_amount = "yes";

            //this.$refs.datatable.refresh();
        },
        editableSubpayment(index, key) {
            //this.items[index].outstanding[key].customer_payment = "yes";
            this.updatesubDBKey(this.items[index].outstanding[key].id,'customer_payment');
            //this.$refs.datatable.refresh();
        },
        editableSubcomments(index, key) {
            //this.items[index].outstanding[key].is_comments = "yes";
            this.updatesubDBKey(this.items[index].outstanding[key].id,'is_comments');
            //this.$refs.datatable.refresh();
        },
        unsetAllKey(){
            return this.$store.dispatch(GET_API, {
                    data: {

                    },
                    api: '/api/unset-invoice-key'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                        return [];
                    } else {
                        this.$refs.datatable.refresh();
                        this.isDatepickerOpen = false;
                    }
                });
        },
        customFormatterTwo(date) {
            if(date == null) return false;
            return moment(date).format('DD-MM-YYYY');
        },
        dateFilterFunction(status)
        {
            this.getInvoiceListing().then(() => {
                this.$refs.datatable.refresh();
            });
        },
      showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                return null
            });
        },
      showAlert() {
        // window.scrollTo(0,0);
        this.dismissCountDown = this.dismissSecs;
        this.$swal({
          position: "center",
          icon: "error",
          iconColor:'#4c8bf5',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000,
        });
      },
      clearFilter(){
      this.filter="";
      this.searchKeyword="",
      this.dateRange.startDate="",
      this.dateRange.endDate="",
      this.$refs.datatable.refresh();
    },
    closeDepInvoice(data){
            this.$refs['invoice-modal'].show();
            this.depData = data;

        },
        closeDeprartmentInv(){
            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.depData.id,
                    remarks: this.remarks,
                    closing_date: moment(this.month).format('YYYY-MM-DD hh:mm:ss'),

                },
                api: '/api/update-department-invoice'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    var message="Department Invoice Closed successfully"
                    this.showSuccessAlert(message)
                    this.$refs['invoice-modal'].hide();
                    this.depData = '';
                    this.remarks = '';
                    this.month = '';

                    this.$refs.datatable.refresh()
                    this.$refs.datatable1.refresh()

                }
            });
        },
        hideModel(){
            this.$refs['invoice-modal'].hide();

        },
      permissionStaff() {
        if (this.$store.getters.getCurrentUserPermission) {
          if (
            this.$store.getters.getCurrentUserPermission.data.length > 0 &&
            this.$store.getters.currentUser.user_type_id == 2
          ) {
            var menu = this.$store.getters.getCurrentUserPermission.data;
            this.actions = permission.getPermissionStaff(menu, "EL Revenue Reporting");
          }
        }
      },
      show(action) {
        if (this.$store.getters.currentUser.user_type_id == 2) {
          return this.actions.indexOf(action) >= 0 ? true : false;
        } else {
          return true;
        }
      },
      showEmployer(action){
              if (this.$store.getters.currentUser.user_type_id == 2) {
                  return this.actionsEmployer.indexOf(action) >= 0 ? true : false ;
              }else{
                  return true;
              }
          },
    },
    mounted() {
      $(".show_filter_arrow .table thead th div").contents().unwrap();
      $(".dashboard-content-container .simplebar-scroll-content").animate(
        {
          scrollTop: 0,
        },
        "fast"
      );
      this.user_type_id = this.$store.getters.currentUser.user_type_id;
      this.permissionStaff();
      this.getInvoiceListing()
      this.unsetAllKey();
    },
  };
  </script>
