<template>
  <div>
    <!-- Dashboard Headline -->
    <div class="dashboard-headline">
      <h1>Full Time Profile</h1>
      <!-- <span class="job_span">Complete your profile</span> -->

      <!-- Breadcrumbs -->
      <nav id="breadcrumbs" class="dark nav_on_emp">
        <ul>
          <li><a href="#">Home</a></li>
          <li>Profile</li>
        </ul>
      </nav>
    </div>
    <!-- Row -->
    <div class="notification notice" v-if="status == 'pending'">
      <p><span class="icon-feather-info"></span> Account status {{ status }}</p>
      <a class="close"></a>
    </div>
    <div
      class="notification warning"
      v-if="status == 'incomplete'"
      style="background-color: rgb(236 159 167 / 49%); color: rgb(234, 20, 81)"
    >
      <p>
        <span class="icon-feather-info"></span> Account status {{ status }} , to complete profile:
      </p>
      <ul class="notification_box" v-for="(err, key) in validErr">
        <li>{{ key + 1 }}. {{ err }}</li>
        <!-- <li> Save changes to profile </li> -->
      </ul>
      <a class="close"></a>
    </div>
    <!-- <div class="row static_page_row  second_indiv_step_wrap overflow_hidden_mobile all_padding_same"> -->
    <div class="row second_indiv_step_wrap overflow_hidden_mobile all_padding_same">
      <b-form method="post" id="register-account-form" class="single_jb_frm">
        <!-- Dashboard Box -->
        <!-- <div class="col-xl-12 common_zero_pdng"> -->
        <div class="col-xl-12">
            <!--<b-form-group id="link-group"  class="top_banner_jobs_full">
                      <div class="single-image-upload">
                          <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChange" ref="image"  v-model="form.banner_image"></b-form-file>
                          <span>
                              <b-img v-if='form.selectedBannerImage' :src="form.selectedBannerImage.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 200px;"></b-img>
                          <b-img v-if='form.selectedBannerImage.image' :src="form.selectedBannerImage.image"></b-img>

                          <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>
                          <i v-else   class="fa fa-camera default_img"></i>
                          </span>
                      </div>
            </b-form-group>-->
          <!--<p class="img_txt">Banner Image<span>(Banner Image size should be 952*280.)</span></p>-->
          <div class="dashboard-box desktop_margin_top_zero">
            <!-- Headline -->
            <div class="headline">
              <h3 class="common_pink_text_color common_font_weight_800">
                <i class="icon-material-outline-account-circle common_pink_text_color"></i> My
                Account
              </h3>
            </div>

            <div class="content with-padding new_account_pdng">
              <div class="row">
                <!-- <div class="col-auto mobile_cl">
                                    <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                        <img class="profile-pic" :src="form.image" alt="" />
                                        <div class="upload-button"></div>
                                        <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                    </div>
                                    <div class="single-image-upload">
                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.image"></b-form-file>
                                        <span>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'" :disabled="true" style="max-width: 200px;"></b-img>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 200px;"></b-img>
                                        </span>
                                    </div>
                                </div>-->

                <div class="col">
                  <div class="row">
                    <!-- <div class="col-xl-4">
                                            <div class="submit-field  error_submit_field">
                                                <h5>Given Name <span class="required_star">*</span></h5>
                                                <input type="text" v-model="form.first_name" class="with-border required-field inut_margin_bottom form-control" id="first_name" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'">
                                                <input type="hidden" v-model="form.user_id" class="with-border" >
                                            </div>
                                                     <span class="error_x_white">Please enter Given Name </span>
                                        </div>

                                        <div class="col-xl-4">
                                            <div class="submit-field  error_submit_field">
                                                <h5>Surname <span class="required_star">*</span></h5>
                                                <input type="text" v-model="form.last_name" class="with-border required-field inut_margin_bottom form-control" id="last_name" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'">
                                            </div>
                                                     <span class="error_x_white">Please enter Surname </span>
                                        </div> -->

                    <div class="col-xl-6">
                      <div class="submit-field error_submit_field">
                        <h5>Full Time Account Status</h5>
                        <input
                          type="text"
                          v-model="ft_account_status"
                          class="with-border inut_margin_bottom form-control text_capitalize"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="submit-field mobile_bottom_two_eight">
                        <h5>Expected Salary <span class="required_star"></span></h5>
                        <input
                          type="text"
                          id="salary"
                          class="with-border web_bottom_zero"
                          v-model="form.expected_salary"
                        />
                      </div>
                      <!-- <div class="show_span">
                                                        <span class="error_x_white">Please enter Expected Salary </span>
                                                    </div> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="submit-field big_screen_bottom_28 mobile_margin_bottom_28">
                        <h5>Upload CV <span class="required_star">*</span></h5>

                        <!-- Attachments -->
                        <!-- <div class="attachments-container margin-top-0 margin-bottom-0 single_attachment_container">
                                                        <a :href="cvUrl" target="_blank">
                                                            <div class="attachment-box ripple-effect cv_profile" v-show="showCV">
                                                                <span>CV</span>
                                                                <i v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
                                                                <i v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'" style="margin-left: -10px;">IMAGE</i>
                                                                <i v-else>DOC</i>
                                                            </div>
                                                        </a>
                                                        <button v-if="showCV" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button>
                                                    </div>
                                                    <div class="clearfix"></div> -->
                        <div class="attachments-container margin-top-0 margin-bottom-0">
                          <div class="" v-if="showCV">
                            <div class="file_name_full_div">
                              <span class="file_name_span">File Name </span>
                              <span class="file_name_clr file_first_last"
                                >{{ form.last_name | capitalize }}
                                {{ form.first_name | capitalize }} -
                                <span v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'pdf'"
                                  >Resume.pdf</span
                                >
                                <span
                                  v-else-if="
                                    cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'png'
                                  "
                                  class="file_name_clr"
                                  >Resume.png</span
                                >
                                <span
                                  v-else-if="
                                    cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'jpg'
                                  "
                                  class="file_name_clr"
                                  >Resume.jpg</span
                                >
                                <span
                                  v-else-if="
                                    cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'jpeg'
                                  "
                                  class="file_name_clr"
                                  >Resume.jpeg</span
                                >
                                <span
                                  v-else-if="
                                    cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'docx'
                                  "
                                  class="file_name_clr"
                                  >Resume.docx</span
                                >
                                <span v-else class="file_name_clr">Resume.doc</span>
                                <button
                                  v-if="showCV && this.form.ft_status_completed == false"
                                  type="button"
                                  class="remove-attachment remove_cv_btn"
                                  @click="removeCV($event)"
                                  data-tippy-placement="top"
                                  title="Remove"
                                >
                                  <i
                                    class="fa fa-trash-o"
                                    style="font-family: 'FontAwesome'; color: red; font-size: 22px"
                                  ></i>
                                </button>
                              </span>
                            </div>
                            <!-- <span></span><br> -->
                            <span>Uploaded </span>
                            <span class="file_name_clr">{{ cv_detail }}</span>
                            <span></span>
                          </div>
                          <!-- <button v-if="showCV && this.form.ft_status_completed == false" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button> -->
                        </div>
                        <div class="clearfix"></div>

                        <!-- Upload Button -->
                        <div
                          class="uploadButton web_bottom_zero single_upload_btn_pf"
                          v-if="!showCV"
                        >
                          <span v-show="showCV"
                            ><input
                              class="uploadButton-input"
                              type="file"
                              accept=".pdf"
                              @change="onResumeChange"
                              id="upload"
                          /></span>
                          <label class="uploadButton-button ripple-effect" for="upload"
                            >Upload</label
                          >
                          <span class="">Maximum file size: 5 MB</span>
                          <!-- <span class="uploadButton-file-name unset_min_height" id="resume" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                        </div>
                        <!-- <button v-if="showCV" type="button" class="uploadButton-button ripple-effect" data-tippy-placement="top" @click="viewCV($event)">View</button> -->
                        <!-- commented on 28 june 2023
                                                    <div class="multiple_view_btn" v-if="showCV && this.form.ft_status_completed == false">
                                                    commented on 28 june 2023 -->
                        <div class="multiple_view_btn" v-if="showCV">
                          <button
                            v-if="showCV"
                            type="button"
                            class="uploadButton-button ripple-effect"
                            data-tippy-placement="top"
                            @click="viewCV($event)"
                          >
                            View
                          </button>
                          <!-- commented on 28 june 2023
                                                        <div class="uploadButton margin-top-0 web_bottom_zero" :class="!showCV ? 'mobile_cv_show' : ''"  v-if="showCV && this.form.ft_status_completed == false">
                                                        commented on 28 june 2023 -->
                          <div
                            class="uploadButton margin-top-0 web_bottom_zero"
                            :class="!showCV ? 'mobile_cv_show' : ''"
                            v-if="showCV"
                          >
                            <input
                              class="uploadButton-input"
                              type="file"
                              accept=".pdf"
                              @change="onResumeChange"
                              id="upload"
                            />
                            <label
                              class="uploadButton-button ripple-effect replace_btn"
                              for="upload"
                              >Replace</label
                            >
                            <!-- <span class="uploadButton-file-name">Maximum file size: 5 MB</span> -->
                            <!-- <span id="resume" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                          </div>
                        </div>
                        <!-- commented on 28 june 2023
                                                    <p class="uploadButton-file-name desktop_margin_bottom_zero" v-if="showCV && this.form.ft_status_completed == false">Maximum file size: 5 MB</p>
                                                    commented on 28 june 2023 -->
                        <p class="uploadButton-file-name desktop_margin_bottom_zero" v-if="showCV">
                          Maximum file size: 5 MB
                        </p>
                        <span
                          class="uploadButton-file-name unset_min_height"
                          id="resume"
                          style="color: blue; font-weight: bold; text-decoration: underline"
                        ></span>
                        <!-- commented on 28 june 2023
                                                    <button v-if="showCV && this.form.ft_status_completed == true" type="button" class="uploadButton-button ripple-effect single_view_btn" data-tippy-placement="top" @click="viewCV($event)">View</button>
                                                    commented on 28 june 2023 -->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="submit-field web_bottom_zero">
                        <h5>About Me<span class="required_star"></span></h5>
                        <!-- <ckeditor v-model="form.about" :config="editorConfig" class="employer_editor"></ckeditor>  -->
                        <textarea
                          cols="30"
                          rows="2"
                          class="with-border web_bottom_zero"
                          v-model="form.about"
                          placeholder="Write Something..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-xl-4" v-if="this.form.mobile_verified_check == true && this.form.email_verify == 'yes'">
                                             <b-link class="btn green-border-btn profile_btn_blue" :to="{name:'jobseeker-daily-job-profile'}">Apply for daily jobs</b-link>
                                        </div> -->

                  <!-- <b-input-group> -->

                  <!-- <div class="col-xl-6">

                                                <div class="submit-field error_submit_field big_screen_bottom_28 mobile_view_numbr mobile_email web_bottom_zero mobile_margin_bottom_28" >
                                                    <h5>Mobile Number <span class="required_star">*</span>
                                                        <span class="verify_badge" v-if="this.form.mobile_verified_check == true">
                                                            <i class="fa fa-check"></i>Verified</span>
                                                        <div class="tooltip_icon" @click="mobileToolTipNumber" :class="this.form.mobile_verified_check == true ? 'email_tooltip_with_verified' : 'email_tooltip_with_no_verified'" v-if="this.form.mobile_verified_check == true" ><span class="material-icons-outlined info_tooltip_icon">info_outlined</span>
                                                            <span class="tooltip_text tooltip_txt_mobile_number">Note: Contact support to update Mobile number</span>
                                                        </div>
                                                    </h5>
                                                    <div class="input-group mb-3 web_bottom_zero error_submit_field">
                                                    <input type="text" v-model="form.country_code" class="country_code_padding with-border mobile_input_zero web_bottom_zero inut_margin_bottom mobile_input_prf form-control required-field country_code_txt" readonly="readonly">

                                                    <input type="text" v-model="form.mobile_number" class="with-border mobile_input_zero mobile_input_prf  form-control required-field" id="number" @keyup.enter="sendOTP">
                                                    <div class="input-group-append" v-if="this.form.mobile_verified_check == false" >
                                                        <button @click="sendOTP" class="btn btn-outline-primary verify_btn_jb otp_btn_hgt" type="button">Send OTP</button>
                                                    </div>

                                                    </div>
                                                    <span class="error_x_white desktop_margin_bottom_zero mobile_margin_bottom_28">Please enter Mobile Number </span>
                                                </div>

                                            </div> -->
                  <!-- <div class="col-xl-6"> -->

                  <!-- <div class="submit-field error_submit_field mobile_view_numbr mobile_email web_bottom_zero" > -->
                  <!-- <h5>Email Address<span class="required_star">*</span>
                                                        <span class="verify_badge" v-if="this.form.email_verify == 'yes'">
                                                            <i class="fa fa-check"></i>Verified</span>
                                                        <div class="tooltip_icon" @click="mobileToolTipEmail" :class="this.form.email_verify == 'yes' ? 'email_tooltip_with_verified' : 'email_tooltip_with_no_verified'" v-if="this.form.email_verify == 'yes'"><span class="material-icons-outlined info_tooltip_icon">info_outlined</span>
                                                            <span class="tooltip_text tooltip_txt_email">Note: Contact support to update Email</span>
                                                        </div>
                                                    </h5> -->
                  <!-- <div class="input-group mb-3 web_bottom_zero error_submit_field"> -->
                  <!-- <input type="text" v-model="form.email" class="with-border   mobile_input_prf form-control required-field" id="email"> -->
                  <!-- <div class="input-group-append" v-if="this.form.email_verify == 'no' && this.showVerifyEmail">
                                                        <button class="btn btn-outline-primary verify_btn_jb otp_btn_hgt" @click="emailVerify" type="button">Resend Link</button>
                                                    </div> -->
                  <!-- <h5 class="web_bottom_zero" v-if="countDownEmail > 0">Didn't receive email? Resend email in <span style="color: #4c8bf5 !important;"> {{ countDownEmail }} seconds</span></h5> -->
                  <!-- </div> -->
                  <!-- <h5 class="web_bottom_zero margin_top_13" v-if="countDownEmail > 0">Didn't receive email? Resend email in <span style="color: #4c8bf5 !important;"> {{ countDownEmail }} seconds</span></h5> -->
                  <!-- <span class="error_x_white desktop_margin_bottom_zero">Please enter Email </span> -->
                  <!-- </div> -->
                  <!-- <div class="show_span desktop_margin_bottom_zero">
                                                     <span class="error_x_white desktop_margin_bottom_zero">Please enter Email </span>
                                                  </div> -->
                  <!-- </div> -->
                  <!-- <div class="col-xl-12" v-if="this.form.mobile_verified_check == true && this.form.email_verify == 'no'">
                                                    <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Mobile number</b></span>
                                                </div>
                                                <div class="col-xl-12" v-else-if="this.form.mobile_verified_check == false && this.form.email_verify == 'yes'">
                                                    <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Email</b></span>
                                                </div>
                                                    <div class="col-xl-12" v-else-if="this.form.mobile_verified_check == true && this.form.email_verify == 'yes'">
                                                    <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Mobile number or Email</b></span>
                                                </div> -->
                  <!--<div class="col-xl-6">

                                                <div class="submit-field mobile_view_numbr mobile_email"  v-if="this.form.mobile_verified_check == false"  >
                                                    <h5>Verification Code</h5>
                                                    <div class="input-group mb-3">
                                                    <input type="text" v-model="form.var_code" class="with-border mobile_input_prf form-control" id="verification">
                                                    <div class="input-group-append" v-if="this.form.verify_otp_button == true" >
                                                        <button class="btn btn-outline-primary" @click="mobileVerify" type="button">Verify OTP</button>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="show_span">
                                                                <span id="verification-warning-message" class="text-danger"> </span>
                                                            </div>
                                            </div>-->

                  <!-- </b-input-group> -->
                  <!--<div class="col-xl-6">

                                            <div class="submit-field mobile_view_numbr mobile_email" >
                                                    <h5>Email <span class="required_star">*</span><span class="verify_badge" v-if="this.form.email_verify == 'yes'"><i class="fa fa-check"></i>Verified</span></h5>
                                                    <div class="input-group mb-3">
                                                    <input type="text" v-model="form.email" class="with-border mobile_input_prf form-control required-field" id="email">
                                                    <div class="input-group-append" v-if="this.form.email_verify == 'no'" >
                                                        <button class="btn btn-outline-primary" @click="emailVerify" type="button">Resend Link</button>
                                                    </div>
                                                    </div>
                                                </div>
                                                 <div class="show_span">
                                                     <span class="error_x_white">Please enter Email </span>
                                                  </div>
                                        </div>-->
                  <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Address</h5>
                                            <input type="text" class="with-border required-field" v-model="form.address" id="address">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Address </span>
                                                  </div>
                                    </div>-->
                  <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Gender <span class="required_star">*</span></h5>
                                            <b-form-select data-size="7" title="Select Gender"  v-model="form.gender" class="custom-select" id="gender">
                                                <option value="" disabled>Select</option>
                                                <option value="female">Female</option>
                                                <option value="male" selected>Male</option>
                                            </b-form-select>
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please select Gender </span>
                                                  </div>
                                    </div>-->
                  <!--<div class="col-xl-6">
                                        <div class="submit-field">
                                            <h5>Nationality <span class="required_star">*</span></h5>
                                            <b-form-select v-model="form.nationality" class="custom-select">
                                                <option value="" disabled>Select</option>
                                                <option :value="country.id" v-for="(country,id) in countries" :key="id">{{country.country_name}}</option>
                                            </b-form-select>
                                        </div>
                                    </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Dashboard Box -->
        <!-- <div class="col-xl-12 common_zero_pdng"> -->
        <div class="col-xl-12">
          <!-- <div class="dashboard-box"> -->

          <!-- Headline -->
          <!-- <div class="headline">
              <h3><i class="icon-material-outline-account-circle"></i> Personal Profile</h3>
          </div> -->
          <div class="content">
            <!-- <ul class="fields-ul"> -->
            <!-- <li class="common_padding_30"> -->
            <div class="row">
              <!-- <div class="col-auto mobile_cl col-xl-3 submit-field max_width_and_flex_769 mobile_max_width_and_flex big_screen_max_width_and_flex "> -->
              <!-- <h5 class="web_bottom_zero line_height_12">Passport/Selfie Image</h5> -->
              <!-- <b-link @click="showsampleimageModel" style="color: #4c8bf5 !important;" class="example_smpl">(Example Sample)</b-link> -->

              <!-- <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                        <img class="profile-pic" :src="form.image" alt="" />
                                        <div class="upload-button"></div>
                                        <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                    </div> -->
              <!--<div class="single-image-upload emp_img_one">
                                        <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.image"></b-form-file>
                                        <span>
                                            <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'" :disabled="true" style="max-width: 200px;"></b-img>
                                             //<b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 200px;"></b-img>
                                        </span>
                                    </div>-->

              <!-- <b-form-group id="link-group"  class="top_banner_jobs_full new_update_banner profile_top_div mobile_tab_image_change"> -->
              <!-- <div class="single-image-upload"> -->
              <!-- <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.image" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'"></b-form-file> -->
              <!-- <span> -->
              <!-- <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'../assets/images/user-avatar-small-01.png'"></b-img> -->
              <!--<b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->

              <!-- </span> -->
              <!-- </div> -->
              <!-- </b-form-group> -->
              <!-- </div> -->

              <!-- <div class="col-xl-9 submit-field"> -->
              <!-- <h5 class="img_txt height_38_banner_heading">Profile Banner Image<span>(Recommended Banner size should be 952*280.)</span></h5> -->
              <!-- <b-form-group id="link-group"  class="top_banner_jobs_full new_update_banner"> -->
              <!-- <div class="single-image-upload"> -->
              <!-- <b-form-file class="customUpload form-control-file" name="BannerImage" accept="image/*" @change="onBannerChange" ref="image"  v-model="form.banner_image"></b-form-file> -->
              <!-- <span> -->
              <!-- <b-img v-if='form.selectedBannerImage' :src="form.selectedBannerImage.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 200px;"></b-img>-->
              <!-- <b-img v-if='form.selectedBannerImage.image' :src="form.selectedBannerImage.image"></b-img> -->

              <!-- <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
              <!-- <i v-else   class="fa fa-camera default_img"></i> -->
              <!-- </span> -->
              <!-- </div> -->
              <!-- </b-form-group> -->
              <!-- </div> -->
              <div class="col-xl-12">
                <div class="row">
                  <!-- <div class="col-xl-6 dob_fileds">
                                        <h5>Date of Birth</h5>
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <select :id="name + 'dates'" v-model="day" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'" class="custom-select desktop_margin_bottom_zero">
                                                    <option value="" disabled>Date</option>
                                                    <option v-for="i in dayArray" :value="i.toString()" :key="name + 'dates_' + i">
                                                        {{ i.toString() }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-xl-4">
                                                <select :id="name + 'months'" v-model="month" @change="generateDateDropdown()" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'" class="custom-select desktop_margin_bottom_zero">
                                                    <option value="" disabled>Month</option>
                                                    <option v-for="i in monthArray" :value="i.toString()" :key="name + 'month_' + i">
                                                        {{ getMonths(i) }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="col-xl-4">
                                                <select :id="name + 'years'" v-model="year" @change="generateDateDropdown()" class="custom-select desktop_margin_bottom_zero" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'">
                                                    <option value="" disabled>Year</option>
                                                    <option
                                                        v-for="i in fullYear"
                                                        v-if="i >= (fullYear - 100)"
                                                        :value="i"
                                                        :key="name + 'years_' + i"
                                                    >
                                                        {{ i }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                  <!-- <div class="col-xl-6"> -->
                  <!-- <div class="row"> -->
                  <!-- <div class="col-xl-6"> -->
                  <!-- <div class="submit-field  mobile_bottom_two_eight">
                                                    <h5>Gender</h5>
                                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.gender" class="custom-select web_bottom_zero" id="gender" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'">
                                                        <option value="" disabled>Select</option>
                                                        <option value="female">Female</option>
                                                        <option value="male">Male</option>
                                                    </b-form-select>
                                                </div> -->
                  <!-- <div class="show_span">
                                                            <span class="error_x_white">Please select Gender </span>
                                                        </div> -->
                  <!-- </div> -->
                  <!-- <div class="col-xl-6">
                                                <div class="submit-field">
                                                    <h5>Race</h5>
                                                    <b-form-select data-size="7" title="Select Gender"  v-model="form.raceid" class="custom-select web_bottom_zero" id="nation" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'">
                                                        <option value="" disabled>Select</option>
                                                        <option :value="race.id" v-for="(race,id) in race" :key="id">{{race.title}}</option>

                                                    </b-form-select>
                                                </div>

                                                </div> -->
                  <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>

              <!-- <div class="col-xl-3">
                                        <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                            <h5>Date of Birth</h5>
                                            <datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterDob" :open-date="changePicker()" :minimumView="'day'" :maximumView="'year'" id="fromyear" :max-date="maxdate" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'" :class="account_verification_status == 'completed' || account_verification_status == 'pending' ? 'if_disabled' : 'if_not_disabled'" class="web_bottom_zero add_calender_icon new_add_calender_icon"></datepicker>

                                        </div>

                                    </div> -->

              <!-- <div class="col-xl-6"> -->
              <!-- <div class="submit-field">
                                <h5>Address</h5>
                                <input type="text" class="with-border" v-model="form.address" id="address">
                            </div> -->
              <!-- <div class="show_span">
                                            <span class="error_x_white">Please enter Address </span>
                                        </div> -->
              <!-- </div> -->
              <!-- <div class="col-xl-6">
                                                        <div class="submit-field  mobile_bottom_two_eight">
                                                            <h5>Nationality <span class="required_star"></span></h5>
                                                            <b-form-select v-model="form.nationality" class="custom-select web_bottom_zero" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'">
                                                                <option value="" disabled>Select</option>
                                                                <option :value="country.id" v-for="(country,id) in countries" :key="id">{{country.title}}</option>
                                                            </b-form-select>
                                                     </div>
                        </div> -->

              <!-- <div class="col-xl-12">
                                        <div class="submit-field">
                                            <h5>Short Introduction <span class="required_star"></span> (Max 100 characters are allowed)</h5>
                                            <input type="text" class="with-border"  v-model="form.sortdesc" maxlength = "100" >
                                        </div>
                                    </div> -->

              <!--<div class="col-xl-12">
                                        <div v-for="(app, index) in appendQa" :key="index">
                                            <div class="row">
                                                <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Qualification</h5>
                                                        <select data-size="7" title="Select Qualification" class="custom-select"  v-model="app.qualification" :id="index" >
                                                            <option value="" disabled>Select</option>
                                                            <option :value="qua.id" v-for="(qua,id) in qualificationArr" :key="id">{{qua.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Field of Study</h5>
                                                        <select data-size="7" title="Select Field of Study" :id="index" v-model="app.field_of_study" class="custom-select" @change="showCategory(app.field_of_study, index)">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="fi.id" v-for="(fi,id) in fieldOfStudyArr" :key="id">{{fi.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 cat_inner_divs other_category_class" >
                                                    <div class="submit-field" :id="['other_category_class' + index]" v-if="app.field_of_study == other_category_id_field_study">
                                                        <h5>Specify Field of study</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="app.other_field_study"
                                                            class="input-text with-border"
                                                            name="company_reg_no"
                                                            placeholder=""
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                                 <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Description</h5>
                                                        <input type="text" class="with-border"  v-model="app.edu_description" >
                                                    </div>
                                                </div>
                                                 <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Institute/University</h5>
                                                        <select data-size="7" title="Select University"  v-model="app.university" class="custom-select" @change="showUniversity(app.university, index)">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="uni.id" v-for="(uni,id) in universityArr" :key="id">{{uni.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 cat_inner_divs other_category_class" >
                                                    <div :id="['other_university_class' + index]">
                                                    <div class="submit-field" v-if="app.university == other_category_id_university" >
                                                        <h5>Specify University</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="app.other_university"
                                                            class="input-text with-border"
                                                            name="company_reg_no"
                                                            placeholder=""
                                                            value=""
                                                        />
                                                    </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4">

                                                    <div class="submit-field">
                                                        <h5>Start</h5>
                                                        <datepicker v-model="app.completion_year_from" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'" id="fromyear"></datepicker>

                                                         <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018">
                                                    </div>
                                                </div>

                                                 <div class="col-xl-4">

                                                    <div class="submit-field">
                                                        <h5>End</h5>
                                                        <datepicker v-model="app.completion_month_to" :disabled-dates="disabledFromDate" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'"></datepicker>

                                                        <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018">
                                                    </div>
                                                </div>

                                                <div class="col-xl-2"  v-if="index != 0" >
                                                    <div class="submit-field remove_bt">
                                                        <a href="#" class="button ripple-effect margin-top-30" style="background-color: red;" @click="removeQualification(index)" >Remove</a>
                                                    </div>
                                                </div>
                                                <div class="col-xl-2">
                                                    <div class="submit-field top_btn_jb">
                                                        <a href="#" class="button ripple-effect margin-top-30" v-if="index == 0" @click="addQualification">Add More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->
              <!--<div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Expected Salary <span class="required_star">*</span></h5>
                                            <input type="text" id="salary" class="with-border required-field" v-model="form.expected_salary">
                                        </div>
                                         <div class="show_span">
                                                     <span class="error_x_white">Please enter Expected Salary </span>
                                                  </div>
                                    </div>-->

              <!--<div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Skills <span class="required_star">*</span></h5>
                                            <b-form-tags input-id="tags-basic" v-model="form.skills" placeholder="Add Skills" id="skill"></b-form-tags>
                                             <input type="text" class="with-border" v-model="form.skills" >
                                        </div>
                                        <div class="show_span">
                                                                <span id="skill-warning-message" class="text-danger"> </span>
                                                            </div>
                                    </div>-->

              <!--<div class="col-xl-4">
                                        <div class="submit-field">
                                            <h5>Upload CV <span class="required_star">*</span></h5>

                                            Attachments
                                            <div class="attachments-container margin-top-0 margin-bottom-0">
                                                <a :href="cvUrl" target="_blank">
                                                    <div class="attachment-box ripple-effect" v-show="showCV">
                                                        <span>CV</span>
                                                        <i>PDF</i>
                                                    </div>
                                                </a>
                                                <button v-if="showCV" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button>
                                            </div>
                                            <div class="clearfix"></div>

                                             Upload Button
                                            <div class="uploadButton margin-top-0">
                                                <input class="uploadButton-input" type="file" accept=".pdf,.doc,.docx" @change="onResumeChange" id="upload"/>
                                                <label class="uploadButton-button ripple-effect" for="upload">Upload</label>
                                                <span class="uploadButton-file-name">Maximum file size: 5 MB</span>
                                            </div>

                                        </div>
                                    </div>-->
            </div>
            <!-- </li> -->
            <!-- <li>
                                <div class="row">


                                    <div class="col-xl-12 ">
                                        <div v-for="(app, index) in appendExp" :key="index">
                                            <div class="row job_rw">
                                                <div class="jb1">
                                                    <div class="submit-field">
                                                        <h5>Previous Job Title</h5>
                                                        <input type="text" class="with-border"  v-model="app.job_title" >
                                                    </div>
                                                </div>
                                                <div class="jb2">
                                                    <div class="submit-field">
                                                        <h5>Previous Job Company</h5>
                                                        <input type="text" class="with-border"  v-model="app.job_company" >
                                                    </div>
                                                </div>
                                                <div class="jb3">
                                                    <div class="submit-field">
                                                        <h5>Start</h5>
                                                        <datepicker v-model="app.job_yrsfrom" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'"></datepicker>


                                                    </div>
                                                </div>
                                                <div class="jb4">
                                                    <div class="submit-field">
                                                        <h5>End</h5>
                                                        <datepicker v-model="app.job_mnsfrom" :disabled-dates="disabledFromDate" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'" :id="['year'+ index]"></datepicker>


                                                    </div>
                                                </div>

                                                <div v-if="index == 0">
                                                    <input id="cvv" type="checkbox" placeholder="" v-model="form.job_current" style="
                                                            height: 12px;width: 12px;margin-top: 44px;" @click="currently($event)" > Currently Working
                                                </div>

                                                 <div class="col-md-9 pdng_lft_zero_mbl">
                                                    <div class="submit-field">
                                                        <h5>Description</h5>
                                                        <input type="text" class="with-border"  v-model="app.description" >
                                                    </div>
                                                </div>


                                                <div class="jb5"  v-if="index != 0">
                                                    <div class="submit-field remove_bt">
                                                        <a href="#" class="button ripple-effect margin-top-30" style="background-color: red;" @click="removeExperience(index)" >Remove</a>
                                                    </div>
                                                </div>
                                                <div class="jb6">
                                                    <div class="submit-field">
                                                        <a href="#" class="button ripple-effect margin-top-30" v-if="index == 0" @click="addExperience">Add+</a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->
            <!--<div class="col-xl-12">
                                        <div class="submit-field">
                                            <h5>Short Description <span class="required_star"></span></h5>
                                            <input type="text" class="with-border"  v-model="form.sortdesc" >
                                        </div>
                                    </div>-->
            <!--<div class="col-xl-12">
                                        <div class="submit-field">
                                            <h5>Introduce Yourself <span class="required_star">*</span></h5>
                                            <ckeditor v-model="form.about" :config="editorConfig" class="employer_editor"></ckeditor> <br>
                                            <textarea cols="30" rows="5" class="with-border" v-model="form.about"></textarea>
                                        </div>
                                    </div>

                                </div>
                            </li>-->
            <!-- </ul> -->
          </div>
          <!-- </div> -->
        </div>

        <!-- <div class="col-xl-12 common_zero_pdng">
                    <div id="" class="dashboard-box">

                        <div class="headline">
                            <h3><i class="fas fa-tools"></i> Skills</h3>


                        </div>

                        <div class="content with-padding">
                            <div class="row">
                                <div class="col-xl-12">
                                        <div class="submit-field web_bottom_zero">
                                            <h5>Find & add skills to increase the chance of getting your dream job <span class="required_star"></span></h5>
                                            <b-form-tags v-model="form.skills" no-outer-focus class="mb-2 new_tag_ul">
                                    <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                                        <b-input-group class="mb-2 full_skill_border">
                                        <b-form-input
                                            v-bind="inputAttrs"
                                            v-on="inputHandlers"
                                            placeholder="Add Skills"
                                            class="form-control remove_shadow"
                                        ></b-form-input>
                                        <b-input-group-append class="plus_div">
                                            <b-button @click="addTag()" variant="primary">+</b-button>
                                        </b-input-group-append>
                                        </b-input-group>
                                        <div class="d-inline-block tag_blocks_section" style="font-size: 1.5rem;" v-if="form.skills.length>0">
                                        <b-form-tag
                                            v-for="tag in tags"
                                            @remove="removeTag(tag)"
                                            :key="tag"
                                            :title="tag"
                                            :variant="tagVariant"
                                            class="mr-1"
                                        >{{ tag }}</b-form-tag>
                                        </div>
                                    </template>
                                    </b-form-tags>
                                        </div>
                                        <div class="show_span">
                                                                <span id="skill-warning-message" class="text-danger"> </span>
                                                            </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div> -->
        <!-- <div class="col-xl-12 common_zero_pdng"> -->
        <b-container class="single_accordian pink_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab">
                      <b-button
                        v-b-toggle="'accordion-education-history-jbs-' + key"
                        class="m-1"
                        variant="info"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3 class="common_pink_text_color common_font_weight_800">
                              <i
                                class="common_pink_text_color icon-material-outline-school jobseekeres_i"
                              ></i
                              >Education History
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-education-history-jbs-' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="content with-padding new_account_pdng">
                          <div class="row">
                            <div class="col-xl-12">
                              <div v-for="(app, index) in appendQa" :key="index">
                                <div class="row">
                                  <div class="col-xl-4">
                                    <div class="submit-field">
                                      <h5>Qualification</h5>
                                      <select
                                        data-size="7"
                                        title="Select Qualification"
                                        class="custom-select web_bottom_zero new_mobile_margin_bottom_zero"
                                        v-model="app.qualification"
                                        :id="index"
                                        @change="enablecurrent()"
                                      >
                                        <option value="" disabled>Select</option>
                                        <option
                                          :value="qua.id"
                                          v-for="(qua, id) in qualificationArr"
                                          :key="id"
                                        >
                                          {{ qua.title }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-xl-4">
                                    <div class="submit-field">
                                      <h5>Field of Study</h5>
                                      <select
                                        data-size="7"
                                        title="Select Field of Study"
                                        :id="index"
                                        v-model="app.field_of_study"
                                        class="custom-select web_bottom_zero new_mobile_margin_bottom_zero"
                                        @change="showFieldOfStudy(app.field_of_study, index)"
                                      >
                                        <option value="" disabled>Select</option>
                                        <option
                                          :value="fi.id"
                                          v-for="(fi, id) in fieldOfStudyArr"
                                          :key="id"
                                        >
                                          {{ fi.title }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-xl-4 cat_inner_divs other_category_class">
                                    <div
                                      class="submit-field"
                                      :id="['other_category_class' + index]"
                                      v-if="app.field_of_study == other_category_id_field_study"
                                    >
                                      <h5>Specify Field of study</h5>
                                      <b-form-input
                                        type="text"
                                        v-model="app.other_field_study"
                                        class="input-text with-border web_bottom_zero"
                                        name="company_reg_no"
                                        placeholder="Please Specify Field of Study"
                                        value=""
                                        @change="enablecurrent()"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-xl-4">
                                    <div class="submit-field">
                                      <h5>Institute/University</h5>
                                      <select
                                        data-size="7"
                                        title="Select University"
                                        v-model="app.university"
                                        class="custom-select web_bottom_zero new_mobile_margin_bottom_zero"
                                        @change="showUniversity(app.university, index)"
                                      >
                                        <option value="" disabled>Select</option>
                                        <option
                                          :value="uni.id"
                                          v-for="(uni, id) in universityArr"
                                          :key="id"
                                        >
                                          {{ uni.title }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div class="col-xl-4 cat_inner_divs other_category_class">
                                    <div :id="['other_university_class' + index]">
                                      <div
                                        class="submit-field"
                                        v-if="app.university == other_category_id_university"
                                      >
                                        <h5>Specify University</h5>
                                        <b-form-input
                                          type="text"
                                          v-model="app.other_university"
                                          class="input-text with-border"
                                          name="company_reg_no"
                                          placeholder="Please Specify University"
                                          value=""
                                          @change="enablecurrent()"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-xl-4">
                                    <!-- <div class="submit-field">
                                                                                            <h5>Description</h5>
                                                                                            <input type="text" class="with-border"  v-model="app.edu_description" maxlength = "500">
                                                                                        </div> -->
                                  </div>

                                  <div class="col-xl-3">
                                    <div
                                      class="submit-field mobile_margin_bottom_28 big_screen_calender_font big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight"
                                    >
                                      <h5>Start Date</h5>
                                      <datepicker
                                        v-model="app.completion_year_from"
                                        :format="customFormatterTwo"
                                        :minimumView="'month'"
                                        :maximumView="'year'"
                                        id="fromyear"
                                        class="web_bottom_zero add_calender_icon"
                                        @input="enablecurrent()"
                                      ></datepicker>

                                      <!-- <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018"> -->
                                    </div>
                                  </div>

                                  <div class="col-xl-3">
                                    <div
                                      class="submit-field mobile_bottom_two_eight margin_bottom_28_999 big_screen_calender_font mobile_margin_bottom_28 big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight"
                                    >
                                      <h5>Graduation Date</h5>
                                      <datepicker
                                        v-model="app.completion_month_to"
                                        :format="customFormattermonth"
                                        :minimumView="'month'"
                                        :maximumView="'year'"
                                        class="web_bottom_zero add_calender_icon"
                                        :id="['study' + index]"
                                      ></datepicker>

                                      <!-- <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018"> -->
                                    </div>
                                  </div>

                                  <!-- <div v-if="index == 0"  class="col-xl-3 profile_check_box">
                                                                                        <input id="cvv" type="checkbox" placeholder="" v-model="app.study_current" class="current_checkbox " @click="currentlyStudy($event)" ><span> Currently Studying</span>
                                                                                    </div> -->

                                  <div class="col-xl-2" v-if="index != 0">
                                    <div
                                      class="submit-field remove_bt mobile_bottom_zero web_bottom_zero"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        class="new_tab_margin_top_zero margin_top_zero_993 button eleven_zero_one_margin_top_zero common_vertical_align_bottom ripple-effect margin-top-30 mobile_anchore_set remove_btn_add_last_class"
                                        style="background-color: red"
                                        @click="removeQualification(index)"
                                        ><span class="add_plus">x</span>Remove</a
                                      >
                                    </div>
                                  </div>
                                  <div class="col-xl-3">
                                    <div
                                      class="submit-field top_btn_jb web_bottom_zero mobile_bottom_two_eight new_mobile_margin_bottom_zero"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        class="button ripple-effect margin-top-30 mobile_anchore_set add_class_in_hr common_vertical_align_bottom"
                                        v-if="index == 0"
                                        @click="addQualification"
                                        ><span class="add_plus">+</span> Add More</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <hr class="mobile_hr" v-if="index + 1 != appendQa.length" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <!-- <div class="col-xl-12">
                    <div  class="dashboard-box"> -->

        <!-- Headline -->
        <!-- <div class="headline">
                            <h3><i class="icon-material-outline-school jobseekeres_i"></i>Education History</h3>

                        </div> -->

        <!-- <div class="content with-padding  new_account_pdng">
                            <div class="row">
                                <div class="col-xl-12">
                                        <div v-for="(app, index) in appendQa" :key="index"> -->
        <!-- <div class="row"> -->
        <!-- <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Qualification</h5>
                                                        <select data-size="7" title="Select Qualification" class="custom-select web_bottom_zero new_mobile_margin_bottom_zero"  v-model="app.qualification" :id="index" @change="enablecurrent()">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="qua.id" v-for="(qua,id) in qualificationArr" :key="id">{{qua.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Field of Study</h5>
                                                        <select data-size="7" title="Select Field of Study" :id="index" v-model="app.field_of_study" class="custom-select web_bottom_zero new_mobile_margin_bottom_zero" @change="showFieldOfStudy(app.field_of_study, index)">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="fi.id" v-for="(fi,id) in fieldOfStudyArr" :key="id">{{fi.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 cat_inner_divs other_category_class" >
                                                    <div class="submit-field" :id="['other_category_class' + index]" v-if="app.field_of_study == other_category_id_field_study">
                                                        <h5>Specify Field of study</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="app.other_field_study"
                                                            class="input-text with-border web_bottom_zero"
                                                            name="company_reg_no"
                                                            placeholder="Please Specify Field of Study"
                                                            value=""
                                                            @change="enablecurrent()"
                                                        />
                                                    </div>
                                                </div>

                                                 <div class="col-xl-4">
                                                    <div class="submit-field">
                                                        <h5>Institute/University</h5>
                                                        <select data-size="7" title="Select University"  v-model="app.university" class="custom-select web_bottom_zero new_mobile_margin_bottom_zero" @change="showUniversity(app.university, index)">
                                                            <option value="" disabled>Select</option>
                                                            <option :value="uni.id" v-for="(uni,id) in universityArr" :key="id">{{uni.title}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 cat_inner_divs other_category_class" >
                                                    <div :id="['other_university_class' + index]">
                                                    <div class="submit-field" v-if="app.university == other_category_id_university" >
                                                        <h5>Specify University</h5>
                                                        <b-form-input
                                                            type="text"
                                                            v-model="app.other_university"
                                                            class="input-text with-border"
                                                            name="company_reg_no"
                                                            placeholder="Please Specify University"
                                                            value=""
                                                            @change="enablecurrent()"
                                                        />
                                                    </div>
                                                    </div>
                                                </div> -->
        <!-- <div class="col-xl-4"> -->
        <!-- <div class="submit-field">
                                                        <h5>Description</h5>
                                                        <input type="text" class="with-border"  v-model="app.edu_description" maxlength = "500">
                                                    </div> -->
        <!-- </div> -->

        <!-- <div class="col-xl-3"> -->

        <!-- <div class="submit-field mobile_margin_bottom_28 big_screen_calender_font big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight"> -->
        <!-- <h5>Start Date</h5>
                                                        <datepicker v-model="app.completion_year_from"  :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'" id="fromyear" class="web_bottom_zero add_calender_icon" @input="enablecurrent()"></datepicker> -->

        <!-- <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018"> -->
        <!-- </div> -->
        <!-- </div> -->

        <!-- <div class="col-xl-3"> -->

        <!-- <div class="submit-field mobile_bottom_two_eight margin_bottom_28_999  big_screen_calender_font mobile_margin_bottom_28 big_screen_bottom_28 web_bottom_zero mobile_bottom_two_eight"> -->
        <!-- <h5>Graduation Date</h5>
                                                        <datepicker v-model="app.completion_month_to" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'" class="web_bottom_zero add_calender_icon" :id="['study'+ index]"></datepicker> -->

        <!-- <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018"> -->
        <!-- </div> -->
        <!-- </div> -->

        <!-- <div v-if="index == 0"  class="col-xl-3 profile_check_box">
                                                    <input id="cvv" type="checkbox" placeholder="" v-model="app.study_current" class="current_checkbox " @click="currentlyStudy($event)" ><span> Currently Studying</span>
                                                </div> -->

        <!-- <div class="col-xl-2"  v-if="index != 0" >
                                                    <div class="submit-field remove_bt mobile_bottom_zero web_bottom_zero">
                                                        <a href="javascript:void(0);" class="new_tab_margin_top_zero margin_top_zero_993 button eleven_zero_one_margin_top_zero common_vertical_align_bottom ripple-effect margin-top-30 mobile_anchore_set remove_btn_add_last_class" style="background-color: red;" @click="removeQualification(index)" ><span class="add_plus">x</span>Remove</a>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3">
                                                    <div class="submit-field top_btn_jb web_bottom_zero mobile_bottom_two_eight new_mobile_margin_bottom_zero">
                                                        <a href="javascript:void(0);" class="button ripple-effect margin-top-30 mobile_anchore_set add_class_in_hr common_vertical_align_bottom" v-if="index == 0" @click="addQualification"><span class="add_plus">+</span> Add More</a>

                                                    </div>
                                                </div> -->
        <!-- </div> -->
        <!-- <hr class="mobile_hr" v-if= "index + 1 != appendQa.length"> -->
        <!-- </div>

                                    </div>
                            </div>
                        </div> -->
        <!-- </div>
                </div> -->
        <b-container class="single_accordian pink_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab">
                      <b-button
                        v-b-toggle="'accordion-employment-history-jbs-' + key"
                        class="m-1"
                        variant="info"
                        aria-expanded="true"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3 class="common_pink_text_color common_font_weight_800">
                              <i
                                class="common_pink_text_color material-icons-outlined new_icon_sidebar_material accordian_contact"
                                >work_outline_outlined</i
                              >
                              Employment History
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-employment-history-jbs-' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                      v-model="isExpanded"
                    >
                      <b-card-body>
                        <div class="content with-padding new_account_pdng">
                          <div class="row job_rw">
                            <div class="">
                              <div v-for="(app, index) in appendExp" :key="index">
                                <div class="row job_rw">
                                  <div class="col-12" style="padding: 0px">
                                    <div
                                      v-if="index == 0"
                                      class="col-12 jb_0 big_screen_bottom_28 pb-3" style="padding: 0px"
                                    >
                                      <input
                                        id="experience"
                                        type="checkbox"
                                        placeholder=""
                                        v-model="experience"
                                        class="current_checkbox m-0"
                                        style="font-size: 8px; "
                                        @change="handleNoExperience()"
                                      />
                                      <span style="margin-left: 10px;">No experience</span>
                                    </div>
                                  </div>
                                  <div class="jb1">
                                    <div class="submit-field" :class="{ input_checked: experience }">
                                      <h5>Job Title</h5>
                                      <input
                                        type="text"
                                        class="with-border"
                                        :class="{ input_checked: experience, 'required-field': !experience }"
                                        v-model="app.job_title"
                                        :disabled="experience"
                                      />
                                    </div>
                                  </div>
                                  <div class="jb2">
                                    <div class="submit-field" :class="{ input_checked: experience }">
                                      <h5>Company Name</h5>
                                      <input
                                        type="text"
                                        class="with-border"
                                        :class="{ input_checked: experience, 'required-field': !experience }"
                                        v-model="app.job_company"
                                        :disabled="experience"
                                      />
                                    </div>
                                  </div>
                                  <div class="jb3">
                                    <div
                                      class="submit-field employment_history big_screen_calender_font"
                                    >
                                      <h5>Start Date</h5>
                                      <datepicker
                                        v-model="app.job_yrsfrom"
                                        :format="customFormatterTwo"
                                        :minimumView="'month'"
                                        :maximumView="'year'"
                                        class="add_calender_icon web_bottom_zero"
                                        :class="{ input_checked: experience, 'required-field': !experience && !app.job_yrsfrom }"
                                        :disabled="experience"
                                        style="border: 0px;"
                                      ></datepicker>
                                    </div>
                                  </div>
                                  <div class="jb4">
                                    <div
                                      class="submit-field employment_history big_screen_calender_font"
                                    >
                                      <h5>End Date</h5>
                                      <datepicker
                                        v-model="app.job_mnsfrom"
                                        :disabled="app.job_current || experience"
                                        :format="customFormattermonth"
                                        :minimumView="'month'"
                                        :maximumView="'year'"
                                        :id="['year' + index]"
                                        class="add_calender_icon web_bottom_zero"
                                        :class="{ input_checked: app.job_current || experience, 'required-field': !app.job_mnsfrom && !experience && !app.job_current }"
                                        style="border: 0px;"
                                      ></datepicker>
                                    </div>
                                  </div>

                                  <div
                                    v-if="index == 0"
                                    class="jb_5 profile_check_box big_screen_bottom_28"
                                  >
                                    <input
                                      id="cvv"
                                      type="checkbox"
                                      placeholder=""
                                      v-model="app.job_current"
                                      :disabled="experience"
                                      class="current_checkbox"
                                      @click="currently($event)"
                                    />
                                    <span>Currently Working</span>
                                  </div>

                                  <div
                                    class="col-md-9 padding_right_993 max_width_and_flex_769 mobile_max_width_and_flex eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex pdng_lft_zero_mbl lft_pdng_zr big_screen_max_width_and_flex big_screen_padding_left_right_zero"
                                  >
                                    <div
                                      class="submit-field margin_bottom_28_999 big_screen_bottom_28 mobile_bottom_two_eight web_bottom_zero " :class="{ input_checked: experience }"
                                    >
                                      <h5>Job Description</h5>
                                      <input
                                        type="text"
                                        class="with-border web_bottom_zero"
                                        :class="{ input_checked: experience, 'required-field': !experience }"
                                        :disabled="experience"
                                        v-model="app.description"
                                        maxlength="500"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    class="jb5 padding_right_993 mobile_remove_left_pdng col-md-3 max_width_and_flex_769 mobile_max_width_and_flex eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex mobile_remove_left_pdng big_screen_padding_left_right_zero"
                                    v-if="index != 0"
                                  >
                                    <div
                                      class="submit-field remove_bt web_bottom_zero new_mobile_margin_bottom_zero"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        class="button margin_top_zero_993 ripple-effect margin-top-30 mobile_anchore_set common_vertical_align_bottom"
                                        style="background-color: red"
                                        @click="removeExperience(index)"
                                        ><span class="add_plus">x</span>Remove</a
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="jb6 max_width_and_flex_993 col-md-3 eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_remove_left_pdng big_screen_max_width_and_flex big_screen_padding_left_right_zero"
                                  >
                                    <div
                                      class="submit-field new_mobile_margin_bottom_zero web_bottom_zero"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero margin-top-30 mobile_anchore_set common_vertical_align_bottom"
                                        v-if="index == 0"
                                        @click="addExperience"
                                        ><span class="add_plus">+</span> Add More</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <hr class="mobile_hr" v-if="index + 1 != appendExp.length" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <!-- <div class=""> -->
        <!-- <div class="col-xl-12 common_zero_pdng"> -->
        <!-- <div class="col-xl-12"> -->
        <!-- <div  class="dashboard-box"> -->

        <!-- Headline -->
        <!-- <div class="headline">
                            <h3><i class="icon-material-outline-business-center"></i> Employment History</h3>


                        </div> -->

        <!-- <div class="content with-padding  new_account_pdng">
                            <div class="row job_rw">
                                <div class="">
                                        <div v-for="(app, index) in appendExp" :key="index">
                                            <div class="row job_rw">
                                                <div class="jb1">
                                                    <div class="submit-field">
                                                        <h5>Job Title</h5>
                                                        <input type="text" class="with-border"  v-model="app.job_title" >
                                                    </div>
                                                </div>
                                                <div class="jb2">
                                                    <div class="submit-field">
                                                        <h5>Company Name</h5>
                                                        <input type="text" class="with-border"  v-model="app.job_company" >
                                                    </div>
                                                </div>
                                                <div class="jb3">
                                                    <div class="submit-field employment_history big_screen_calender_font">
                                                        <h5>Start Date</h5>
                                                        <datepicker v-model="app.job_yrsfrom" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'" class="add_calender_icon web_bottom_zero "></datepicker>


                                                    </div>
                                                </div>
                                                <div class="jb4">
                                                    <div class="submit-field employment_history big_screen_calender_font">
                                                        <h5>End Date</h5>
                                                        <datepicker v-model="app.job_mnsfrom" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'" :id="['year'+ index]" class="add_calender_icon web_bottom_zero" :class="{ 'input_checked': app.job_current }"></datepicker>


                                                    </div>
                                                </div>

                                                <div v-if="index == 0" class="jb_5 profile_check_box big_screen_bottom_28">
                                                    <input id="cvv" type="checkbox" placeholder="" v-model="app.job_current"  class="current_checkbox" @click="currently($event)" > <span>Currently Working</span>
                                                </div>

                                                 <div class="col-md-9  padding_right_993 max_width_and_flex_769 mobile_max_width_and_flex  eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex pdng_lft_zero_mbl lft_pdng_zr big_screen_max_width_and_flex big_screen_padding_left_right_zero">
                                                    <div class="submit-field margin_bottom_28_999 big_screen_bottom_28 mobile_bottom_two_eight web_bottom_zero">
                                                        <h5>Job Description</h5>
                                                        <input type="text" class="with-border web_bottom_zero"  v-model="app.description" maxlength = "500">
                                                    </div>
                                                </div>


                                                <div class="jb5 padding_right_993 mobile_remove_left_pdng col-md-3 max_width_and_flex_769 mobile_max_width_and_flex  eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_max_width_and_flex mobile_remove_left_pdng big_screen_padding_left_right_zero"  v-if="index != 0">
                                                    <div class="submit-field remove_bt web_bottom_zero new_mobile_margin_bottom_zero">
                                                        <a href="javascript:void(0);" class="button  margin_top_zero_993 ripple-effect margin-top-30 mobile_anchore_set common_vertical_align_bottom" style="background-color: red;" @click="removeExperience(index)" ><span class="add_plus">x</span>Remove</a>
                                                    </div>
                                                </div>
                                                <div class="jb6  max_width_and_flex_993 col-md-3 eleven_zero_one_padding_zero eleven_zero_one_max_width_and_flex mobile_remove_left_pdng big_screen_max_width_and_flex big_screen_padding_left_right_zero ">
                                                    <div class="submit-field new_mobile_margin_bottom_zero web_bottom_zero">
                                                        <a href="javascript:void(0);" class="margin_top_zero_993 button ripple-effect eleven_zero_one_margin_top_zero margin-top-30 mobile_anchore_set common_vertical_align_bottom" v-if="index == 0" @click="addExperience"><span class="add_plus">+</span> Add More</a>

                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="mobile_hr" v-if= "index + 1 != appendExp.length">

                                        </div>

                                    </div>
                            </div>
                        </div> -->
        <!-- </div> -->
        <!-- </div> -->
        <!-- </div> -->
        <b-container class="single_accordian pink_accordian">
          <!-- <br> -->
          <div>
            <div class="faq-content-box clearfix">
              <div>
                <div class="accordion-listing-box">
                  <b-card no-body>
                    <b-card-header header-tag="header" role="tab">
                      <b-button
                        v-b-toggle="'accordion-full-time-job-prefrences' + key"
                        class="m-1"
                        variant="info"
                      >
                        <div class="dashboard-box">
                          <div class="headline">
                            <h3 class="common_pink_text_color common_font_weight_800">
                              <i
                                class="common_pink_text_color icon-material-outline-school jobseekeres_i"
                              ></i
                              >Full Time Job Preferences
                            </h3>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-full-time-job-prefrences' + key"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="content with-padding new_account_pdng">
                          <div class="row">
                            <div class="col-xl-12 checkbox_upper_div">
                              <input
                                type="checkbox"
                                @click="checkAll()"
                                v-model="isCheckAll"
                              /><label>Select All </label>

                              <span v-for="cat in category">
                                <input
                                  type="checkbox"
                                  v-bind:value="cat.id"
                                  v-model="categories"
                                  @change="updateCheckall()"
                                />
                                <label>{{ cat.name }}</label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </b-container>
        <!-- <div class="col-xl-12"> -->
        <!-- <div  class="dashboard-box"> -->

        <!-- Headline -->
        <!-- <div class="headline">
                            <h3><i class="icon-material-outline-school jobseekeres_i"></i>Job Preferences</h3>

                        </div> -->

        <!-- <div class="content with-padding  new_account_pdng">
                            <div class="row">
                                <div class="col-xl-12 checkbox_upper_div">
                                    <input type='checkbox' @click='checkAll()' v-model='isCheckAll'> Check All

                                        <span v-for='cat in category'>
                                        <input type='checkbox' v-bind:value='cat.id' v-model='categories' @change='updateCheckall()'> <label>{{ cat.name }}</label>
                                        </span>

                                    </div>
                            </div>
                        </div> -->
        <!-- </div> -->
        <!-- </div> -->
        <!-- Dashboard Box -->
        <!-- <div class="col-xl-12 common_zero_pdng coloured_icon">
                    <div id="test1" class="dashboard-box">

                        <div class="headline">
                            <h3><i class="icon-line-awesome-bullhorn"></i> Linked Social Account (Increase your connectivity or get others to know you better)</h3>


                        </div>

                        <div class="content with-padding">
                            <div class="row">
                                <div class="col-xl-8">
                                    <div class="submit-field">
                                        <h5><span class="icon-feather-globe"></span> Personal Website URL Link</h5>
                                        <input type="text" class="with-border" v-model="link.website" >
                                    </div>
                                </div>
                                <div class="col-xl-8">
                                    <div class="submit-field">
                                        <h5><span class="icon-brand-facebook-f"></span> Personal Facebook URL Link</h5>
                                        <input type="text" class="with-border" v-model="link.fb" >
                                    </div>
                                </div>
                                <div class="col-xl-8">
                                    <div class="submit-field">
                                        <h5><span class="icon-line-awesome-twitter-square"></span>Personal Twitter URL Link</h5>
                                        <input type="text" class="with-border"  v-model="link.twitter">
                                    </div>
                                </div>
                                <div class="col-xl-8">
                                    <div class="submit-field">
                                        <h5><span class="icon-brand-google-plus-g"></span>&nbsp;Google Profile URL Link</h5>
                                        <input type="text" class="with-border"  v-model="link.google">
                                    </div>
                                </div> -->
        <!-- <div class="col-xl-8">
                                    <div class="submit-field web_bottom_zero">
                                        <h5><span class="icon-brand-linkedin-in"></span>Personal LinkedIn URL Link</h5>
                                        <input type="text" class="with-border"  v-model="link.linkedin">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <!-- Button -->
        <!-- <div class="col-xl-12 common_zero_pdng"> -->
        <div class="row hide_old_data_full_time">
          <div class="col-xl-6">
            <div class="submit-field error_submit_field">
              <h5>Full Name<span class="required_star">*</span></h5>
              <input
                type="text"
                v-model="form.full_name"
                class="with-border required-field inut_margin_bottom form-control"
                id="first_name"
                :disabled="
                  account_verification_status == 'completed' ||
                  account_verification_status == 'pending'
                "
              />
              <input type="hidden" v-model="form.user_id" class="with-border" />
            </div>
            <div class="show_span">
              <span class="error_x_white">Please enter Full Name </span>
            </div>
          </div>
          <b-input-group>
            <div class="col-xl-6">
              <div
                class="submit-field error_submit_field big_screen_bottom_28 mobile_view_numbr mobile_email web_bottom_zero mobile_margin_bottom_28"
              >
                <h5>
                  Mobile Number <span class="required_star">*</span>
                  <span class="verify_badge" v-if="this.form.mobile_verified_check == true">
                    <i class="fa fa-check"></i>Verified</span
                  >
                  <div
                    class="tooltip_icon"
                    @click="mobileToolTipNumber"
                    :class="
                      this.form.mobile_verified_check == true
                        ? 'email_tooltip_with_verified'
                        : 'email_tooltip_with_no_verified'
                    "
                    v-if="this.form.mobile_verified_check == true"
                  >
                    <span class="material-icons-outlined info_tooltip_icon">info_outlined</span>
                    <span class="tooltip_text tooltip_txt_mobile_number"
                      >Note: Contact support to update Mobile number</span
                    >
                  </div>
                </h5>
                <div class="input-group mb-3 web_bottom_zero error_submit_field">
                  <input
                    type="text"
                    v-model="form.country_code"
                    class="country_code_padding with-border mobile_input_zero web_bottom_zero inut_margin_bottom mobile_input_prf form-control required-field country_code_txt"
                    readonly="readonly"
                  />

                  <input
                    type="text"
                    v-model="form.mobile_number"
                    class="with-border mobile_input_zero mobile_input_prf form-control required-field"
                    id="number"
                    @keyup.enter="sendOTP"
                  />
                  <div class="verify-btn" v-if="this.form.mobile_verified_check == false">
                    <button
                      @click="sendOTP"
                      class="btn btn-outline-primary verify_btn_jb otp_btn_hgt"
                      type="button"
                    >
                      Send OTP
                    </button>
                  </div>
                </div>
                <span class="error_x_white desktop_margin_bottom_zero mobile_margin_bottom_28"
                  >Please enter Mobile Number
                </span>
              </div>
              <!-- <div class="show_span desktop_margin_bottom_zero">
                        <span class="error_x_white desktop_margin_bottom_zero mobile_margin_bottom_28">Please enter Contact Number </span>
                    </div> -->
            </div>
            <div class="col-xl-6">
              <div
                class="submit-field error_submit_field mobile_view_numbr mobile_email web_bottom_zero"
              >
                <h5>
                  Email Address<span class="required_star">*</span>
                  <span class="verify_badge" v-if="this.form.email_verify == 'yes'">
                    <i class="fa fa-check"></i>Verified</span
                  >
                  <div
                    class="tooltip_icon"
                    @click="mobileToolTipEmail"
                    :class="
                      this.form.email_verify == 'yes'
                        ? 'email_tooltip_with_verified'
                        : 'email_tooltip_with_no_verified'
                    "
                    v-if="this.form.email_verify == 'yes'"
                  >
                    <span class="material-icons-outlined info_tooltip_icon">info_outlined</span>
                    <span class="tooltip_text tooltip_txt_email"
                      >Note: Contact support to update Email</span
                    >
                  </div>
                </h5>
                <div class="input-group mb-3 web_bottom_zero error_submit_field">
                  <input
                    type="text"
                    v-model="form.email"
                    class="with-border mobile_input_prf form-control required-field"
                    id="email"
                  />
                  <div
                    class="verify-btn"
                    v-if="this.form.email_verify == 'no' && this.showVerifyEmail"
                  >
                    <button
                      class="btn btn-outline-primary verify_btn_jb otp_btn_hgt"
                      @click="emailVerify"
                      type="button"
                    >
                      Resend Link
                    </button>
                  </div>
                  <!-- <h5 class="web_bottom_zero" v-if="countDownEmail > 0">Didn't receive email? Resend email in <span style="color: #4c8bf5 !important;"> {{ countDownEmail }} seconds</span></h5> -->
                </div>
                <h5 class="web_bottom_zero margin_top_13" v-if="countDownEmail > 0">
                  Didn't receive email? Resend email in
                  <span style="color: #4c8bf5 !important"> {{ countDownEmail }} seconds</span>
                </h5>
                <span class="error_x_white desktop_margin_bottom_zero">Please enter Email </span>
              </div>
              <!-- <div class="show_span desktop_margin_bottom_zero">
                        <span class="error_x_white desktop_margin_bottom_zero">Please enter Email </span>
                    </div> -->
            </div>
            <!-- <div class="col-xl-12" v-if="this.form.mobile_verified_check == true && this.form.email_verify == 'no'">
                    <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Mobile number</b></span>
                </div>
                <div class="col-xl-12" v-else-if="this.form.mobile_verified_check == false && this.form.email_verify == 'yes'">
                    <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Email</b></span>
                </div>
                    <div class="col-xl-12" v-else-if="this.form.mobile_verified_check == true && this.form.email_verify == 'yes'">
                    <span ><b style="color: rgb(8, 118, 177);">Note: Contact support to update Mobile number or Email</b></span>
                </div> -->
            <!--<div class="col-xl-6">

                <div class="submit-field mobile_view_numbr mobile_email"  v-if="this.form.mobile_verified_check == false"  >
                    <h5>Verification Code</h5>
                    <div class="input-group mb-3">
                    <input type="text" v-model="form.var_code" class="with-border mobile_input_prf form-control" id="verification">
                    <div class="input-group-append" v-if="this.form.verify_otp_button == true" >
                        <button class="btn btn-outline-primary" @click="mobileVerify" type="button">Verify OTP</button>
                    </div>
                    </div>
                </div>
                <div class="show_span">
                                <span id="verification-warning-message" class="text-danger"> </span>
                            </div>
            </div>-->
          </b-input-group>
          <div class="col-xl-12">
            <div class="dashboard-box">
              <!-- Headline -->
              <div class="headline">
                <h3><i class="icon-material-outline-account-circle"></i> Personal Profile</h3>
              </div>

              <div class="content">
                <ul class="fields-ul">
                  <li class="common_padding_30">
                    <div class="row">
                      <div
                        class="col-auto mobile_cl col-xl-3 submit-field max_width_and_flex_769 mobile_max_width_and_flex big_screen_max_width_and_flex"
                      >
                        <h5 class="web_bottom_zero line_height_12">Passport/Selfie Image</h5>
                        <b-link
                          @click="showsampleimageModel"
                          style="color: #4c8bf5 !important"
                          class="example_smpl"
                          >(Example Sample)</b-link
                        >

                        <!-- <div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                                    <img class="profile-pic" :src="form.image" alt="" />
                                    <div class="upload-button"></div>
                                    <input class="file-upload" type="file" accept="image/*" @change="onprofileChange"/>
                                </div> -->
                        <!--<div class="single-image-upload emp_img_one">
                                    <b-form-file class="customUpload form-control-file" name="Image" accept="image/*" @change="onprofileChange" ref="image"  v-model="form.image"></b-form-file>
                                    <span>
                                        <b-img v-if='form.selectedImage' :src="form.selectedImage.image ? form.selectedImage.image : webUrl+'assets/images/user-avatar-small-01.png'" :disabled="true" style="max-width: 200px;"></b-img>
                                            //<b-img v-if='form.selectedImage' :src="form.selectedImage.image" :disabled="true" style="max-width: 200px;"></b-img>
                                    </span>
                                </div>-->

                        <b-form-group
                          id="link-group"
                          class="top_banner_jobs_full new_update_banner profile_top_div mobile_tab_image_change"
                        >
                          <div class="single-image-upload">
                            <b-form-file
                              class="customUpload form-control-file"
                              name="Image"
                              accept="image/*"
                              @change="onprofileChange"
                              ref="image"
                              v-model="form.image"
                              :disabled="
                                account_verification_status == 'completed' ||
                                account_verification_status == 'pending'
                              "
                            ></b-form-file>
                            <span>
                              <b-img
                                v-if="form.selectedImage"
                                :src="
                                  form.selectedImage.image
                                    ? form.selectedImage.image
                                    : webUrl + '../assets/images/user-avatar-small-01.png'
                                "
                              ></b-img>
                              <!--<b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                            </span>
                          </div>
                        </b-form-group>
                      </div>

                      <div class="col-xl-9 submit-field">
                        <h5 class="img_txt height_38_banner_heading">
                          Profile Banner Image<span
                            >(Recommended Banner size should be 952*280.)</span
                          >
                        </h5>
                        <b-form-group
                          id="link-group"
                          class="top_banner_jobs_full new_update_banner"
                        >
                          <div class="single-image-upload">
                            <b-form-file
                              class="customUpload form-control-file"
                              name="BannerImage"
                              accept="image/*"
                              @change="onBannerChange"
                              ref="image"
                              v-model="form.banner_image"
                            ></b-form-file>
                            <span>
                              <!-- <b-img v-if='form.selectedBannerImage' :src="form.selectedBannerImage.image ? form.selectedBannerImage.image : webUrl+'assets/images/upload-icon.svg'" :disabled="true" style="max-width: 200px;"></b-img>-->
                              <b-img
                                v-if="form.selectedBannerImage.image"
                                :src="form.selectedBannerImage.image"
                              ></b-img>

                              <!-- <b-img v-else :src="webUrl+'assets/images/upload-icon.svg'" class="default_img"></b-img>-->
                              <i v-else class="fa fa-camera default_img"></i>
                            </span>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-xl-12">
                        <div class="row">
                          <div class="col-xl-6 dob_fileds">
                            <h5>Date of Birth</h5>
                            <div class="row">
                              <div class="col-xl-4">
                                <select
                                  :id="name + 'dates'"
                                  v-model="day"
                                  :disabled="
                                    account_verification_status == 'completed' ||
                                    account_verification_status == 'pending'
                                  "
                                  class="custom-select desktop_margin_bottom_zero"
                                >
                                  <option value="" disabled>Date</option>
                                  <option
                                    v-for="i in dayArray"
                                    :value="i.toString()"
                                    :key="name + 'dates_' + i"
                                  >
                                    {{ i.toString() }}
                                  </option>
                                </select>
                              </div>
                              <div class="col-xl-4">
                                <select
                                  :id="name + 'months'"
                                  v-model="month"
                                  @change="generateDateDropdown()"
                                  :disabled="
                                    account_verification_status == 'completed' ||
                                    account_verification_status == 'pending'
                                  "
                                  class="custom-select desktop_margin_bottom_zero"
                                >
                                  <option value="" disabled>Month</option>
                                  <option
                                    v-for="i in monthArray"
                                    :value="i.toString()"
                                    :key="name + 'month_' + i"
                                  >
                                    {{ getMonths(i) }}
                                  </option>
                                </select>
                              </div>

                              <div class="col-xl-4">
                                <select
                                  :id="name + 'years'"
                                  v-model="year"
                                  @change="generateDateDropdown()"
                                  class="custom-select desktop_margin_bottom_zero"
                                  :disabled="
                                    account_verification_status == 'completed' ||
                                    account_verification_status == 'pending'
                                  "
                                >
                                  <option value="" disabled>Year</option>
                                  <option
                                    v-for="i in fullYear"
                                    v-if="i >= fullYear - 100"
                                    :value="i"
                                    :key="name + 'years_' + i"
                                  >
                                    {{ i }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="submit-field mobile_bottom_two_eight">
                                  <h5>Gender</h5>
                                  <b-form-select
                                    data-size="7"
                                    title="Select Gender"
                                    v-model="form.gender"
                                    class="custom-select web_bottom_zero"
                                    id="gender"
                                    :disabled="
                                      account_verification_status == 'completed' ||
                                      account_verification_status == 'pending'
                                    "
                                  >
                                    <option value="" disabled>Select</option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                  </b-form-select>
                                </div>
                                <!-- <div class="show_span">
                                                        <span class="error_x_white">Please select Gender </span>
                                                    </div> -->
                              </div>
                              <div class="col-xl-6">
                                <div class="submit-field">
                                  <h5>Race</h5>
                                  <b-form-select
                                    data-size="7"
                                    title="Select Gender"
                                    v-model="form.raceid"
                                    class="custom-select web_bottom_zero"
                                    id="nation"
                                    :disabled="
                                      account_verification_status == 'completed' ||
                                      account_verification_status == 'pending'
                                    "
                                  >
                                    <option value="" disabled>Select</option>
                                    <option :value="race.id" v-for="(race, id) in race" :key="id">
                                      {{ race.title }}
                                    </option>
                                  </b-form-select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-xl-3">
                                    <div class="submit-field mobile_margin_bottom_28 mobile_bottom_two_eight big_screen_bottom_28 web_bottom_zero">
                                        <h5>Date of Birth</h5>
                                        <datepicker v-model="form.dob" :disabled-dates="disabledFromDate" :format="customFormatterDob" :open-date="changePicker()" :minimumView="'day'" :maximumView="'year'" id="fromyear" :max-date="maxdate" :disabled="account_verification_status == 'completed' || account_verification_status == 'pending'" :class="account_verification_status == 'completed' || account_verification_status == 'pending' ? 'if_disabled' : 'if_not_disabled'" class="web_bottom_zero add_calender_icon new_add_calender_icon"></datepicker>

                                    </div>

                                </div> -->

                      <div class="col-xl-6">
                        <div class="submit-field">
                          <h5>Address</h5>
                          <input
                            type="text"
                            class="with-border"
                            v-model="form.address"
                            id="address"
                          />
                        </div>
                        <!-- <div class="show_span">
                                        <span class="error_x_white">Please enter Address </span>
                                    </div> -->
                      </div>
                      <div class="col-xl-6">
                        <div class="submit-field mobile_bottom_two_eight">
                          <h5>Nationality <span class="required_star"></span></h5>
                          <b-form-select
                            v-model="form.nationality"
                            class="custom-select web_bottom_zero"
                            :disabled="
                              account_verification_status == 'completed' ||
                              account_verification_status == 'pending'
                            "
                          >
                            <option value="" disabled>Select</option>
                            <option
                              :value="country.id"
                              v-for="(country, id) in countries"
                              :key="id"
                            >
                              {{ country.title }}
                            </option>
                          </b-form-select>
                        </div>
                      </div>
                      <div class="col-xl-12">
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="submit-field mobile_bottom_two_eight">
                              <h5>Expected Salary <span class="required_star"></span></h5>
                              <input
                                type="text"
                                id="salary"
                                class="with-border web_bottom_zero"
                                v-model="form.expected_salary"
                              />
                            </div>
                            <!-- <div class="show_span">
                                            <span class="error_x_white">Please enter Expected Salary </span>
                                        </div> -->
                          </div>
                          <div class="col-xl-6">
                            <div
                              class="submit-field web_bottom_zero big_screen_bottom_28 mobile_margin_bottom_28"
                            >
                              <h5>Upload CV <span class="required_star">*</span></h5>

                              <!-- Attachments -->
                              <!-- <div class="attachments-container margin-top-0 margin-bottom-0 single_attachment_container">
                                        <a :href="cvUrl" target="_blank">
                                            <div class="attachment-box ripple-effect cv_profile" v-show="showCV">
                                                <span>CV</span>
                                                <i v-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='pdf'">PDF</i>
                                                <i v-else-if="cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='png' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpg' || cvUrl.split(/[#?]/)[0].split('.').pop().trim() =='jpeg'" style="margin-left: -10px;">IMAGE</i>
                                                <i v-else>DOC</i>
                                            </div>
                                        </a>
                                        <button v-if="showCV" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button>
                                    </div>
                                    <div class="clearfix"></div> -->
                              <div class="attachments-container margin-top-0 margin-bottom-0">
                                <div class="" v-if="showCV">
                                  <div class="file_name_full_div">
                                    <span class="file_name_span">File Name </span>
                                    <span class="file_name_clr file_first_last"
                                      >{{ form.last_name | capitalize }}
                                      {{ form.first_name | capitalize }} -
                                      <span
                                        v-if="
                                          cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'pdf'
                                        "
                                        >Resume.pdf</span
                                      >
                                      <span
                                        v-else-if="
                                          cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'png'
                                        "
                                        class="file_name_clr"
                                        >Resume.png</span
                                      >
                                      <span
                                        v-else-if="
                                          cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'jpg'
                                        "
                                        class="file_name_clr"
                                        >Resume.jpg</span
                                      >
                                      <span
                                        v-else-if="
                                          cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'jpeg'
                                        "
                                        class="file_name_clr"
                                        >Resume.jpeg</span
                                      >
                                      <span
                                        v-else-if="
                                          cvUrl.split(/[#?]/)[0].split('.').pop().trim() == 'docx'
                                        "
                                        class="file_name_clr"
                                        >Resume.docx</span
                                      >
                                      <span v-else class="file_name_clr">Resume.doc</span>
                                      <button
                                        v-if="showCV && this.form.ft_status_completed == false"
                                        type="button"
                                        class="remove-attachment remove_cv_btn"
                                        @click="removeCV($event)"
                                        data-tippy-placement="top"
                                        title="Remove"
                                      >
                                        <i
                                          class="fa fa-trash-o"
                                          style="
                                            font-family: 'FontAwesome';
                                            color: red;
                                            font-size: 22px;
                                          "
                                        ></i>
                                      </button>
                                    </span>
                                  </div>
                                  <!-- <span></span><br> -->
                                  <span>Uploaded </span>
                                  <span class="file_name_clr">{{ cv_detail }}</span>
                                  <span></span>
                                </div>
                                <!-- <button v-if="showCV && this.form.ft_status_completed == false" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button> -->
                              </div>
                              <div class="clearfix"></div>

                              <!-- Upload Button -->
                              <div
                                class="uploadButton web_bottom_zero single_upload_btn_pf"
                                v-if="!showCV"
                              >
                                <span v-show="showCV"
                                  ><input
                                    class="uploadButton-input"
                                    type="file"
                                    accept=".pdf"
                                    @change="onResumeChange"
                                    id="upload"
                                /></span>
                                <label class="uploadButton-button ripple-effect" for="upload"
                                  >Upload</label
                                >
                                <span class="">Maximum file size: 5 MB</span>
                                <!-- <span class="uploadButton-file-name unset_min_height" id="resume" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                              </div>
                              <!-- <button v-if="showCV" type="button" class="uploadButton-button ripple-effect" data-tippy-placement="top" @click="viewCV($event)">View</button> -->
                              <!-- commented on 28 june 2023
                                    <div class="multiple_view_btn" v-if="showCV && this.form.ft_status_completed == false">
                                    commented on 28 june 2023 -->
                              <div class="multiple_view_btn" v-if="showCV">
                                <button
                                  v-if="showCV"
                                  type="button"
                                  class="uploadButton-button ripple-effect"
                                  data-tippy-placement="top"
                                  @click="viewCV($event)"
                                >
                                  View
                                </button>
                                <!-- commented on 28 june 2023
                                        <div class="uploadButton margin-top-0 web_bottom_zero" :class="!showCV ? 'mobile_cv_show' : ''"  v-if="showCV && this.form.ft_status_completed == false">
                                        commented on 28 june 2023 -->
                                <div
                                  class="uploadButton margin-top-0 web_bottom_zero"
                                  :class="!showCV ? 'mobile_cv_show' : ''"
                                  v-if="showCV"
                                >
                                  <input
                                    class="uploadButton-input"
                                    type="file"
                                    accept=".pdf"
                                    @change="onResumeChange"
                                    id="upload"
                                  />
                                  <label
                                    class="uploadButton-button ripple-effect replace_btn"
                                    for="upload"
                                    >Replace</label
                                  >
                                  <!-- <span class="uploadButton-file-name">Maximum file size: 5 MB</span> -->
                                  <!-- <span id="resume" style="color: blue;font-weight: bold;text-decoration: underline;"></span> -->
                                </div>
                              </div>
                              <!-- commented on 28 june 2023
                                    <p class="uploadButton-file-name desktop_margin_bottom_zero" v-if="showCV && this.form.ft_status_completed == false">Maximum file size: 5 MB</p>
                                    commented on 28 june 2023 -->
                              <p
                                class="uploadButton-file-name desktop_margin_bottom_zero"
                                v-if="showCV"
                              >
                                Maximum file size: 5 MB
                              </p>
                              <span
                                class="uploadButton-file-name unset_min_height"
                                id="resume"
                                style="color: blue; font-weight: bold; text-decoration: underline"
                              ></span>
                              <!-- commented on 28 june 2023
                                    <button v-if="showCV && this.form.ft_status_completed == true" type="button" class="uploadButton-button ripple-effect single_view_btn" data-tippy-placement="top" @click="viewCV($event)">View</button>
                                    commented on 28 june 2023 -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-xl-12">
                                    <div class="submit-field">
                                        <h5>Short Introduction <span class="required_star"></span> (Max 100 characters are allowed)</h5>
                                        <input type="text" class="with-border"  v-model="form.sortdesc" maxlength = "100" >
                                    </div>
                                </div> -->
                      <div class="col-xl-12">
                        <div class="submit-field web_bottom_zero">
                          <h5>About Me<span class="required_star"></span></h5>
                          <!-- <ckeditor v-model="form.about" :config="editorConfig" class="employer_editor"></ckeditor>  -->
                          <textarea
                            cols="30"
                            rows="5"
                            class="with-border web_bottom_zero"
                            v-model="form.about"
                          ></textarea>
                        </div>
                      </div>
                      <!--<div class="col-xl-12">
                                    <div v-for="(app, index) in appendQa" :key="index">
                                        <div class="row">
                                            <div class="col-xl-4">
                                                <div class="submit-field">
                                                    <h5>Qualification</h5>
                                                    <select data-size="7" title="Select Qualification" class="custom-select"  v-model="app.qualification" :id="index" >
                                                        <option value="" disabled>Select</option>
                                                        <option :value="qua.id" v-for="(qua,id) in qualificationArr" :key="id">{{qua.title}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="submit-field">
                                                    <h5>Field of Study</h5>
                                                    <select data-size="7" title="Select Field of Study" :id="index" v-model="app.field_of_study" class="custom-select" @change="showCategory(app.field_of_study, index)">
                                                        <option value="" disabled>Select</option>
                                                        <option :value="fi.id" v-for="(fi,id) in fieldOfStudyArr" :key="id">{{fi.title}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 cat_inner_divs other_category_class" >
                                                <div class="submit-field" :id="['other_category_class' + index]" v-if="app.field_of_study == other_category_id_field_study">
                                                    <h5>Specify Field of study</h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="app.other_field_study"
                                                        class="input-text with-border"
                                                        name="company_reg_no"
                                                        placeholder=""
                                                        value=""
                                                    />
                                                </div>
                                            </div>
                                                <div class="col-xl-4">
                                                <div class="submit-field">
                                                    <h5>Description</h5>
                                                    <input type="text" class="with-border"  v-model="app.edu_description" >
                                                </div>
                                            </div>
                                                <div class="col-xl-4">
                                                <div class="submit-field">
                                                    <h5>Institute/University</h5>
                                                    <select data-size="7" title="Select University"  v-model="app.university" class="custom-select" @change="showUniversity(app.university, index)">
                                                        <option value="" disabled>Select</option>
                                                        <option :value="uni.id" v-for="(uni,id) in universityArr" :key="id">{{uni.title}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-xl-4 cat_inner_divs other_category_class" >
                                                <div :id="['other_university_class' + index]">
                                                <div class="submit-field" v-if="app.university == other_category_id_university" >
                                                    <h5>Specify University</h5>
                                                    <b-form-input
                                                        type="text"
                                                        v-model="app.other_university"
                                                        class="input-text with-border"
                                                        name="company_reg_no"
                                                        placeholder=""
                                                        value=""
                                                    />
                                                </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-4">

                                                <div class="submit-field">
                                                    <h5>Start</h5>
                                                    <datepicker v-model="app.completion_year_from" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'" id="fromyear"></datepicker>

                                                        <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018">
                                                </div>
                                            </div>

                                                <div class="col-xl-4">

                                                <div class="submit-field">
                                                    <h5>End</h5>
                                                    <datepicker v-model="app.completion_month_to" :disabled-dates="disabledFromDate" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'"></datepicker>

                                                    <input type="text" class="with-border" v-model="app.completion_year" placeholder="eg.2018">
                                                </div>
                                            </div>

                                            <div class="col-xl-2"  v-if="index != 0" >
                                                <div class="submit-field remove_bt">
                                                    <a href="#" class="button ripple-effect margin-top-30" style="background-color: red;" @click="removeQualification(index)" >Remove</a>
                                                </div>
                                            </div>
                                            <div class="col-xl-2">
                                                <div class="submit-field top_btn_jb">
                                                    <a href="#" class="button ripple-effect margin-top-30" v-if="index == 0" @click="addQualification">Add More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                      <!--<div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Expected Salary <span class="required_star">*</span></h5>
                                        <input type="text" id="salary" class="with-border required-field" v-model="form.expected_salary">
                                    </div>
                                        <div class="show_span">
                                                    <span class="error_x_white">Please enter Expected Salary </span>
                                                </div>
                                </div>-->

                      <!--<div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Skills <span class="required_star">*</span></h5>
                                        <b-form-tags input-id="tags-basic" v-model="form.skills" placeholder="Add Skills" id="skill"></b-form-tags>
                                            <input type="text" class="with-border" v-model="form.skills" >
                                    </div>
                                    <div class="show_span">
                                                            <span id="skill-warning-message" class="text-danger"> </span>
                                                        </div>
                                </div>-->

                      <!--<div class="col-xl-4">
                                    <div class="submit-field">
                                        <h5>Upload CV <span class="required_star">*</span></h5>

                                        Attachments
                                        <div class="attachments-container margin-top-0 margin-bottom-0">
                                            <a :href="cvUrl" target="_blank">
                                                <div class="attachment-box ripple-effect" v-show="showCV">
                                                    <span>CV</span>
                                                    <i>PDF</i>
                                                </div>
                                            </a>
                                            <button v-if="showCV" type="button" class="remove-attachment" @click="removeCV($event)" data-tippy-placement="top" title="Remove"><i class="fa fa-trash-o" style="font-family: 'FontAwesome';color: red;font-size: 22px;"></i></button>
                                        </div>
                                        <div class="clearfix"></div>

                                            Upload Button
                                        <div class="uploadButton margin-top-0">
                                            <input class="uploadButton-input" type="file" accept=".pdf,.doc,.docx" @change="onResumeChange" id="upload"/>
                                            <label class="uploadButton-button ripple-effect" for="upload">Upload</label>
                                            <span class="uploadButton-file-name">Maximum file size: 5 MB</span>
                                        </div>

                                    </div>
                                </div>-->
                    </div>
                  </li>
                  <!-- <li>
                            <div class="row">


                                <div class="col-xl-12 ">
                                    <div v-for="(app, index) in appendExp" :key="index">
                                        <div class="row job_rw">
                                            <div class="jb1">
                                                <div class="submit-field">
                                                    <h5>Previous Job Title</h5>
                                                    <input type="text" class="with-border"  v-model="app.job_title" >
                                                </div>
                                            </div>
                                            <div class="jb2">
                                                <div class="submit-field">
                                                    <h5>Previous Job Company</h5>
                                                    <input type="text" class="with-border"  v-model="app.job_company" >
                                                </div>
                                            </div>
                                            <div class="jb3">
                                                <div class="submit-field">
                                                    <h5>Start</h5>
                                                    <datepicker v-model="app.job_yrsfrom" :disabled-dates="disabledFromDate" :format="customFormatterTwo" :minimumView="'month'" :maximumView="'year'"></datepicker>


                                                </div>
                                            </div>
                                            <div class="jb4">
                                                <div class="submit-field">
                                                    <h5>End</h5>
                                                    <datepicker v-model="app.job_mnsfrom" :disabled-dates="disabledFromDate" :format="customFormattermonth" :minimumView="'month'" :maximumView="'year'" :id="['year'+ index]"></datepicker>


                                                </div>
                                            </div>

                                            <div v-if="index == 0">
                                                <input id="cvv" type="checkbox" placeholder="" v-model="form.job_current" style="
                                                        height: 12px;width: 12px;margin-top: 44px;" @click="currently($event)" > Currently Working
                                            </div>

                                                <div class="col-md-9 pdng_lft_zero_mbl">
                                                <div class="submit-field">
                                                    <h5>Description</h5>
                                                    <input type="text" class="with-border"  v-model="app.description" >
                                                </div>
                                            </div>


                                            <div class="jb5"  v-if="index != 0">
                                                <div class="submit-field remove_bt">
                                                    <a href="#" class="button ripple-effect margin-top-30" style="background-color: red;" @click="removeExperience(index)" >Remove</a>
                                                </div>
                                            </div>
                                            <div class="jb6">
                                                <div class="submit-field">
                                                    <a href="#" class="button ripple-effect margin-top-30" v-if="index == 0" @click="addExperience">Add+</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                  <!--<div class="col-xl-12">
                                    <div class="submit-field">
                                        <h5>Short Description <span class="required_star"></span></h5>
                                        <input type="text" class="with-border"  v-model="form.sortdesc" >
                                    </div>
                                </div>-->
                  <!--<div class="col-xl-12">
                                    <div class="submit-field">
                                        <h5>Introduce Yourself <span class="required_star">*</span></h5>
                                        <ckeditor v-model="form.about" :config="editorConfig" class="employer_editor"></ckeditor> <br>
                                        <textarea cols="30" rows="5" class="with-border" v-model="form.about"></textarea>
                                    </div>
                                </div>

                            </div>
                        </li>-->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <button
            @click="updatePersonalInfo()"
            type="button"
            class="button ripple-effect margin-top-30"
          >
            Save Changes
          </button>
        </div>
      </b-form>
      <b-modal
        ref="crop-modal"
        title="Edit Passport/Selfie Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImg">
          <template>
            <div>
              <div class="content" @change="setImage">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="1 / 1"
                      :src="tempLogo"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImage"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="crop-modal-banner"
        id="background_custom_class_for_banner"
        title="Edit Banner Image"
        hide-footer
        :no-close-on-backdrop="noClose"
        content-class="common_model_header common_model_title"
        modal-class="background_custom_class"
      >
        <b-form @submit="onSubmitImgBanner">
          <template>
            <div>
              <div class="content" @change="setImageBanner">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="10 / 3"
                      :src="tempLogoBanner"
                      preview=".preview"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div class="pt-2 mt-3 text-md-left text-center common_crop_reset_btn">
              <b-button
                variant="success"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="cropImageBanner"
                >Crop</b-button
              >

              <b-button
                variant="danger"
                class="text-uppercase x-md btn-shadow"
                @click.prevent="reset"
                >Reset</b-button
              >
            </div>
          </template>
        </b-form>
      </b-modal>
      <b-modal
        ref="verify-mobile-modal"
        no-close-on-backdrop
        hide-footer
        content-class="mobile_verification_model common_model_title"
        title="Phone Verification"
        modal-class="background_custom_class mobile_pop_up_mdl"
      >
        <template #modal-title>
          Phone Verification
          <span
            ><button type="button" @click="mobileVerifyCancel" class="close closefirstmodal">
              &times;
            </button></span
          >
        </template>
        <b-form class="">
          <div class="edit-form-box web_top_zero">
            <div class="profile-repeate form-box-field">
              <div class="col-xl-12">
                <!-- <h3><b>Phone Verification</b></h3> -->
                <h5 class="gray_h5">
                  Enter the verification code that<br />
                  was sent to your mobile number.
                </h5>
              </div>
              <b-row>
                <b-col lg="12" md="12">
                  <div class="form-map-box">
                    <b-col lg="12" md="12" class="input-with-icon-left">
                      <i class="fa fa-mobile pop_up_i"></i>
                      <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                        <b-form-input
                          id="input-9"
                          maxlength="20"
                          v-model="form.mobile_number"
                          type="text"
                          placeholder=""
                          readonly="readonly"
                          class="number_input"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12">
                      <b-form-group id="input-group-9" label="" class="web_bottom_zero">
                        <input
                          type="text"
                          v-model="form.var_code"
                          class="with-border mobile_input_prf form-control"
                          placeholder="Enter the 4-digit code"
                        />
                      </b-form-group>
                    </b-col>

                    <div slot="footer" class="form-btn col-md-12 col-lg-12">
                      <b-button
                        @click="mobileVerify"
                        variant="success"
                        style="background-color: #4c8bf5 !important"
                        class="otp_btn verify_otp_btn_mod"
                        >Verify OTP</b-button
                      >
                      <!-- <b-button @click = "hideVerifyMobileModel()" variant="light">Cancel</b-button> -->
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div class="col-xl-12 bottom_text_pop_phone">
                <h5>
                  Verification code may take up to a few <br />minutes to be sent to your phone.
                </h5>
                <h5 class="web_bottom_zero" v-if="countDown > 0">
                  Didn't receive the code? Resend code in
                  <span style="color: #4c8bf5 !important"> {{ countDown }} seconds</span>
                </h5>
                <h5 class="web_bottom_zero" v-else>
                  Didn't recevie the code?
                  <b-link @click="sendOTP" style="color: #4c8bf5 !important"> Resend Code</b-link>
                </h5>
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>

      <b-modal
        ref="sample-image-modal"
        no-close-on-backdrop
        hide-footer
        content-class="mobile_verification_model sample_image_model"
        modal-class="background_custom_class"
        title="Sample Image"
      >
        <b-form class="">
          <div class="edit-form-box web_top_zero">
            <div class="profile-repeate form-box-field">
              <!-- <div class="col-xl-12">
                        <h3 class="margin-bottom-20"><b>Sample Image</b></h3>
                        </div> -->
              <b-row>
                <b-col lg="12" md="12">
                  <img
                    src="https://sgp1.digitaloceanspaces.com/elconnect/v2_images/sample_profile.png"
                    alt="Italian Trulli"
                    class="sample_image_single"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { POST_API, GET_API } from "../../../store/actions.type";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import CKEditor from "ckeditor4-vue";
import Bus from "../../../event-bus";
import axios from 'axios';

export default {
  name: "DateDropdown",
  props: ["min", "max", "date"],
  data() {
    return {
      elcyUrl: process.env.VUE_APP_URL_ELCY,
      isExpanded: true,
      fullYear: new Date().getFullYear(),
      form: {
        first_name: "",
        last_name: "",
        full_name: "",
        email: "",
        banner_image: "",
        profile_image: "",
        resume: "",
        address: "",
        mobile_number: "",
        verification_code: "",
        verify_otp_button: false,
        mobile_verified_check: false,
        sortdesc: "",
        skills: [],
        expected_salary: "",
        about: "",
        ft_profile_image_thumbnail: "",
        ft_status_completed: false,
        selectedBannerImage: {
          name: "",
          image: "",
        },
        selectedImage: {
          name: "",
          image: "",
        },
        selectedResume: {
          name: "",
          image: "",
        },
        user_id: "",
        gender: "",
        nationality: "5",
        email_verify: "no",
        mobile_verify: "no",
        job_current: false,
        study_current: false,
        dob: "",
        raceid: "",
      },
      experience: false,
      ft_account_status: "",
      name: "DateDropdown",
      d_date: "",
      year: "",
      month: "",
      day: "",
      dayArray: [],
      monthArray: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      countDown: 60,
      countDownEmail: 0,
      showVerifyOtp: true,
      showVerifyEmail: true,
      link: {
        website: "",
        fb: "",
        google: "",
        twitter: "",
        linkedin: "",
      },
      other_category_id_university: "",
      other_category_id_field_study: "",
      age: 0,
      email: "",
      password: "",
      user_type: "5",
      status: "",
      account_verification_status: "",
      showDismissibleAlert: false,
      error_message: "",
      success_message: "",
      isActive: 1,
      appendQa: [],
      workExperienceData: [],
      qualificationData: [],
      socialData: [],
      blockRemovalQualification: true,

      appendExp: [],
      blockRemovalExperience: true,

      skillsArr: [],
      countries: [],
      qualificationArr: [],
      fieldOfStudyArr: [],
      universityArr: [],

      disabledFromDate: {
        from: new Date(Date.now() - 8640000),
        to: new Date(moment().subtract(100, "years")),
      },
      disabledFromDateDob: {
        from: new Date(moment(moment().subtract(15, "years")).format("DD-MM-YYYY")),
      },
      personal: true,
      links: false,
      disabled: true,
      img: "",
      showCV: "",
      cv_detail: "",
      cvUrl: "",
      cropImg: "",
      data: null,
      cropmove: null,
      tempLogo: "",
      cropImgBanner: "",
      validErr: [],

      tempLogoBanner: "",
      noClose: true,
      duration_yrs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      duration_mn: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      webUrl: process.env.VUE_APP_URL,
      editorConfig: {
        removeButtons: "Maximize",
        extraPlugins: "copyformatting,colorbutton,bidi,colordialog,widget,justify",
      },
      coreStyles_bold: {
        element: "b",
        overrides: "strong",
      },
      // Custom style definition for the Italic feature.
      coreStyles_italic: {
        element: "i",
        overrides: "em",
      },
      status: "",
      race: [],
      isCheckAll: false,
      category: [],
      categories: [],
    };
  },
  components: {
    Multiselect,
    Datepicker,
    VueCropper,
    ckeditor: CKEditor.component,
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    generateDateDropdown() {
      const daysInMonth = new Date(this.year, this.month, 0).getDate();
      console.log(daysInMonth, "++++++++++++++++++++++++++");
      this.dayArray = [];
      for (let i = 1; i <= daysInMonth; i++) {
        this.dayArray.push(i.toString().padStart(2, 0));
      }
      if (this.day > daysInMonth) {
        this.day = "";
      }
      console.log(this.dayArray, "++++++++++++++++++++++++++");
    },
    getMonths(m) {
      return moment()
        .month(parseInt(m) - 1)
        .format("MMMM");
    },
    mobileToolTipNumber() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_mobile_number").toggleClass("tooltip_text_mobile_view_number");
      }
    },
    mobileToolTipEmail() {
      var newWindowWidth = $(window).width();
      if (newWindowWidth <= 999) {
        $(".tooltip_txt_email").toggleClass("tooltip_text_mobile_view_email");
      }
    },
    customFormatterDob(date) {
      return moment(date).format("DD MMM YYYY");
    },

    countDownTimer() {
      if (this.countDown > 0) {
        this.showVerifyOtp = false;
        this.timeOutCounter = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.showVerifyOtp = true;
      }
    },
    changePicker() {
      if (this.form.dob) {
        return this.form.dob;
      } else {
        return new Date(moment().subtract(15, "years"));
      }
    },
    countDownTimerEmail() {
      if (this.countDownEmail > 0) {
        this.showVerifyEmail = false;
        this.timeOutCounterEmail = setTimeout(() => {
          this.countDownEmail -= 1;
          this.countDownTimerEmail();
        }, 1000);
      } else {
        this.showVerifyEmail = true;
      }
    },
    showFieldOfStudy(id, index) {
      if (
        this.appendQa[0].qualification != "" &&
        this.appendQa[0].completion_year_from != "" &&
        this.appendQa[0].field_of_study != "" &&
        this.appendQa[0].university != ""
      ) {
        $("#cvv").removeAttr("disabled");
      }
      if (id == this.other_category_id_field_study) {
        $("#other_category_class" + index).css("display", "block");
      } else {
        $("#other_category_class" + index).css("display", "none");
      }
    },

    showUniversity(id, index) {
      if (
        this.appendQa[0].qualification != "" &&
        this.appendQa[0].completion_year_from != "" &&
        this.appendQa[0].field_of_study != "" &&
        this.appendQa[0].university != ""
      ) {
        $("#cvv").removeAttr("disabled");
      }
      if (id == this.other_category_id_university) {
        $("#other_university_class" + index).css("display", "block");
      } else {
        $("#other_university_class" + index).css("display", "none");
      }
    },
    cropImage() {
      this.$refs["crop-modal"].hide();
      this.form.selectedImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImg = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },

    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    showImg() {
      this.$refs["crop-modal"].show();
    },
    onSubmitImg(evt) {
      evt.preventDefault();
      this.$refs["crop-modal"].hide();
      this.$refs["crop-modal"].refresh();
    },

    cropImageBanner() {
      this.$refs["crop-modal-banner"].hide();
      this.form.selectedBannerImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setImageBanner(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgBanner = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    enablecurrent() {
      if (
        this.appendQa[0].qualification != "" &&
        this.appendQa[0].completion_year_from != "" &&
        this.appendQa[0].field_of_study != "" &&
        this.appendQa[0].university != ""
      ) {
        $("#cvv").removeAttr("disabled");
      }
    },

    currently(e) {
      if (this.form.job_current == true) {
        this.form.job_current = false;
      } else {
        this.form.job_current = true;
      }
      if (this.form.job_current) {
        this.appendExp[0].job_mnsfrom = "";

        $("#year0").css("pointer-events", "none");
        $("#month0").css("pointer-events", "none");
      } else {
        $("#year0").css("pointer-events", "auto");
        $("#month0").css("pointer-events", "auto");
      }
    },

    handleNoExperience() {
      console.log("first",this.experience)
      this.appendExp[0].job_current = false;
      this.appendExp[0].job_title = "";
      this.appendExp[0].job_company = "";
      this.appendExp[0].job_yrsfrom = "";
      this.appendExp[0].job_mnsfrom = "";
      this.appendExp[0].job_yrsto = "";
      this.appendExp[0].job_mnsto = "";
      this.appendExp[0].description = "";

    },

    checkAll: function () {
      this.isCheckAll = !this.isCheckAll;
      this.categories = [];
      if (this.isCheckAll) {
        // Check all
        for (var key in this.category) {
          this.categories.push(this.category[key].id);
        }
      }
    },
    updateCheckall: function () {
      if (this.categories.length == this.category.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },

    currentlyStudy(e) {
      if (this.form.study_current == true) {
        this.form.study_current = false;
      } else {
        this.form.study_current = true;
      }
      if (this.form.study_current) {
        $("#study0").css("pointer-events", "none");
        $("#study0").val("");
        $("#month0").css("pointer-events", "none");
      } else {
        $("#study0").css("pointer-events", "auto");
        $("#month0").css("pointer-events", "auto");
      }
    },
    resetImageUploader() {
      this.form.image = "";
    },
    showImgBanner() {
      this.$refs["crop-modal-banner"].show();
    },
    onSubmitImgBanner(evt) {
      evt.preventDefault();
      this.$refs["crop-modal-banner"].hide();
      this.$refs["crop-modal-banner"].refresh();
    },
    onBannerChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.banner_image = input.files[0];
        if (
          this.form.banner_image.type != "image/png" &&
          this.form.banner_image.type != "image/jpeg" &&
          this.form.banner_image.type != "image/jpg"
        ) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.banner_image.size > 5242880) {
          this.form.selectedBannerImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Banner image should be less than 5 MB.";
          this.form.selectedBannerImage.image = "";
          this.form.selectedBannerImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogoBanner = e.target.result;
            this.form.selectedBannerImage.name = this.form.banner_image.name;
            this.showImgBanner();
            event.target.value = "";
          };
        }
      }
    },

    onprofileChange(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.image = input.files[0];
        if (
          this.form.image.type != "image/png" &&
          this.form.image.type != "image/jpeg" &&
          this.form.image.type != "image/jpg"
        ) {
          this.form.selectedImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Please select only .png,.jpg or .jpeg image.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else if (this.form.image.size > 1048576 * 2) {
          this.form.selectedImage.image = this.webUrl + "../assets/images/upload-icon.svg";
          this.error_message = "Profile image should be less than 2 MB.";
          this.form.selectedImage.image = "";
          this.form.selectedImage.name = "";
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = (e) => {
            this.tempLogo = e.target.result;
            this.form.selectedImage.name = this.form.image.name;
            this.showImg();
            event.target.value = "";
          };
        }
      }
    },
    activeTab() {
      this.personal = true;
      this.links = false;
    },
    showAlert() {
      this.$swal({
        position: "center",
        icon: "error",
        title: this.error_message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    customFormatterTwo(date) {
      return moment(date).format("MMMM, YYYY");
    },

    customFormattermonth(date) {
      return moment(date).format("MMMM, YYYY");
    },
    getRace() {
      return this.$store
        .dispatch(GET_API, {
          api: "/api/race",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.race = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },
    getCountryCode() {
      return this.$store
        .dispatch(POST_API, {
          api: "/api/get-nationality-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
          } else {
            this.countries = this.$store.getters.getResults.data;

            // this.showSuccessPopup();
          }
        });
    },
    // onprofileChange(event) {
    //     var input = event.target;
    //     if (input.files && input.files[0]) {
    //         var reader = new FileReader();
    //         reader.readAsDataURL(input.files[0]);
    //         this.form.profile_image = input.files[0];

    //         if (this.form.profile_image.type != 'image/png' && this.form.profile_image.type != 'image/jpeg' && this.form.profile_image.type != 'image/jpg') {
    //             this.form.selectedImage.image = this.webUrl + '/images/default-user-icon.svg'
    //             this.error_message = 'Please select only .png,.jpg or .jpeg image.';
    //             this.selectedImage.image = '';
    //             this.showAlert();
    //         } else if (this.form.profile_image.size > 1048576) {

    //             this.form.selectedImage.image = this.webUrl + '/images/default-user-icon.svg'
    //             this.error_message = 'Profile image should be less than 1 MB.';
    //             this.form.selectedImage.image = '';
    //             this.showAlert();
    //         } else {
    //             reader.onload = (e) => {
    //                 this.modelBox = 'logo';
    //                 this.form.selectedImage.image = e.target.result;
    //                 this.form.selectedImage.name = this.form.profile_image.name;
    //                 $('#profile').empty().append(this.form.selectedImage.name)

    //             }
    //         }
    //     }
    // },
    viewCV(event) {
      if (event) {
        event.stopPropagation();
      }
      window.open(this.cvUrl, "_blank");
    },
    removeCV(event) {
      if (event) {
        event.stopPropagation();
      }

      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to remove CV?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then((result) => {
          if (result.value) {
            return this.$store
              .dispatch(POST_API, {
                data: {
                  user_id: this.form.user_id,
                },
                api: "/api/jobseeker-resume-remove",
              })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  // this.showAlert();
                  return [];
                } else {
                  this.showCV = false;
                  this.form.ft_status_completed = false;
                  $("#resume").empty().append("");
                  this.$swal({
                    position: "center",
                    icon: "success",
                    iconColor: "#4c8bf5",
                    title: "CV removed successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.addQualification();
                  this.addExperience();
                  this.getUserData();
                  this.getQualification();
                  // this.getDropdownData('service_categories');
                  this.getDropdownDataUniversity("universities");
                  this.getDropdownData("field_of_studies");
                  this.getCountryCode();
                  this.getRace();
                }
              });
          }
        })
        .catch((err) => {});
    },
    onResumeChange(event) {
      // console.log(`elcy url`,this.elcyUrl)
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        this.form.resume = input.files[0];
        if (this.form.resume.type != "application/pdf") {
          this.error_message = "Please select only .pdf only.";
          this.form.selectedResume.image = "";
          this.form.selectedResume.name = "";
          $("#resume").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else if (this.form.resume.size > 5261760) {
          this.form.selectedResume.image = this.webUrl + "/images/default-user-icon.svg";
          this.error_message = "Resume should be less than 5 MB.";
          this.form.selectedResume.image = "";
          this.form.selectedResume.name = "";
          $("#resume").empty().append("");
          event.target.value = "";
          this.showAlert();
        } else {
          reader.onload = async (e) => {
            $("#loader_jbs").show();
            await axios.post('https://elconnect.sg/elcy-api' + "/resume-check", {
              data: e.target.result
            }, {
              headers: {
                'Content-Type': 'application/json'
              },
            })
              .then((res) => {
                if (res.data == 'failed') {
                  this.error_message = 'failed to load the resume';
                  this.showAlert();
                }
                // console.log(res.data, typeof (res.data))
                if (res.data.status == false) {
                  $("#loader_jbs").hide();
                  this.error_message = "Please make sure correct resume file is uploaded";
                  this.form.selectedResume.image = "";
                  this.form.selectedResume.name = "";
                  $("#resume").empty().append("");
                  event.target.value = "";
                  this.showAlert();
                }
                else {
                  // this.result = "The uploaded file is a resume."
                  // this.showCV = false;
                  $("#loader_jbs").hide();
                  this.modelBox = "logo";
                  this.form.selectedResume.image = e.target.result;
                  this.form.selectedResume.name = this.form.resume.name;
                  $("#resume").empty().append(this.form.selectedResume.name);
                }
              }).catch(err => {
                console.log(err)
                this.error_message = err.message;
              })
          };
        }
      }
    },
    getQualification() {
      this.$store
        .dispatch(POST_API, {
          data: {},
          api: "/api/qualification",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            this.qualificationArr = this.$store.getters.getResults.data;
          }
        });
    },
    getDropdownData(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: type,
          },
          api: "/api/dropdown-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            if (type == "field_of_studies")
              this.fieldOfStudyArr = this.$store.getters.getResults.data;
            this.other_category_id_field_study =
              this.$store.getters.getResults.other_category_study_id;
          }
        });
    },

    getDropdownDataUniversity(type) {
      this.$store
        .dispatch(POST_API, {
          data: {
            table: type,
          },
          api: "/api/dropdown-list",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;

            this.$swal({
              position: "center",
              icon: "error",
              title: this.error_message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            if (type == "universities") this.universityArr = this.$store.getters.getResults.data;
            this.other_category_id_university =
              this.$store.getters.getResults.other_category_university_id;
          }
        });
    },

    // getDropdownParamsData(type,field,id,row)
    // {

    //     return this.$store.dispatch(POST_API, {
    //             data:{
    //                 field:field,
    //                 id:id,
    //                 table:type

    //             },
    //             api:'/api/get-params-dropdown-data',
    //         })
    //         .then(() => {
    //             if (this.$store.getters.containsErrors) {
    //                 this.error_message = this.$store.getters.getErrors;
    //                 this.showAlert();
    //             } else {
    //                 $('.fos-'+row).val();

    //                 this.fieldOfStudyArr[row] = '';
    //                 this.fieldOfStudyArr[row] = this.$store.getters.getResults.data;
    //                 return this.fieldOfStudyArr[row];
    //             }
    //         });
    // },
    onInput(value) {
      this.inputArr = value.map((a) => a.id);
    },
    updatePersonalInfo() {
      var err = false;
      var descriptionErr = "";

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .addClass("show")
            .parent()
            .find(".error_submit_field")
            .addClass("bind_error_class");
        } else {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".required_content")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        }
      });

      //   $('#gender').each(function () {

      //     if( !$('#gender').val() ){
      //         err = true;

      //         $(this).addClass('error');
      //         $(this).parent().parent().find('.required_content').addClass('show');
      //         $(this).parent().parent().find('.error_x_white').addClass('show');
      //     } else {

      //         $(this).removeClass('error');
      //         $(this).parent().parent().find('.required_content').removeClass('show');
      //         $(this).parent().parent().find('.error_x_white').removeClass('show');
      //     }

      // });
      this.appendExp.forEach((element, key) => {
        // if(element.job_title != '' || element.job_company != '' || element.job_yrsfrom != '' ||  ((element.job_mnsfrom != '' || element.job_mnsfrom != null || element.job_mnsfrom != '0')  && element.job_current != false)  || element.description != ''){
        //     if(element.job_title == '' || element.job_company == '' || element.job_yrsfrom == '' ||  ((element.job_mnsfrom == '' || element.job_mnsfrom == null || element.job_mnsfrom == '0') && element.job_current == false) || element.description == ''){
        //         emperr = true;
        //     }
        // }
        // if(moment(element.job_yrsfrom).format('yyyy') > moment(element.job_mnsfrom).format('yyyy') ){
        //     if(key == 0 && element.job_current == true){
        //         yearERR = false;
        //     }else{
        //         yearERR = true;
        //     }

        // }else if(moment(element.job_yrsfrom).format('yyyy') == moment(element.job_mnsfrom).format('yyyy'))  {

        //     if(moment(element.job_yrsfrom).format('MM') > moment(element.job_mnsfrom).format('MM')){
        //             if(key == 0 && element.job_current == true){
        //              monthERR = false;
        //                 }else{
        //                     monthERR = true;
        //                 }
        //             // monthERR = true;
        //     }
        // }

        if (element.description.length > 5000) {
          descriptionErr = true;
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this)
            .parent()
            .parent()
            .find(".error_x_white")
            .removeClass("show")
            .parent()
            .find(".error_submit_field")
            .removeClass("bind_error_class");
        });
      }, 100);

      if (!this.form.mobile_number.match("^[0-9]*$")) {
        err = true;

        this.$swal({
          position: "center",
          icon: "error",
          title: "Only number allow for mobile number",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      this.form.dob = this.year + this.month + this.day;
      console.log(this.form.dob);
      this.form.skills = this.form.skills != [] ? this.form.skills : null;
      var cur = moment().diff(this.form.dob, "years");

      if (err == false && this.form.dob && cur < 16) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "The age should be minimum of 16 years old.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (err == false && descriptionErr == true) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Employment History Description exceeded 5000 charecters.",
          showConfirmButton: false,
          timer: 5000,
        });
      }
      if (err == false) {
        // this.form.dob = this.form.dob == "Invalid date" ? null : this.form.dob;

        this.$store
          .dispatch(POST_API, {
            data: {
              items: this.form,
              image: this.form.selectedImage.image,
              appendQ: this.appendQa,
              appendE: this.appendExp,
              categories: this.categories,
              links: this.link,
            },
            api: "/api/update-profile-jobseeker",
          })
          .then(() => {
            var yearERR = "";
            var otherErr = "";
            var err = "";
            var emperr = "";
            var monthERR = ""; // see the change here
            var empmonthERR = "";
            var empyearERR = "";
            // see the change here

            this.appendQa.forEach((element) => {
              if (element.field_of_study == this.other_category_id_field_study) {
                if (element.other_field_study == "") {
                  otherErr = true;
                }
              }
              if (element.university == this.other_category_id_university) {
                if (element.other_university == "") {
                  otherErr = true;
                }
              }

              // if(element.field_of_study != '' || element.university != '' || element.completion_year_from != '' || ( element.completion_month_to != '' && element.study_current != false) || element.qualification != ''){
              //      if(element.field_of_study == '' || element.university == '' || element.completion_year_from == '' || ( element.completion_month_to == '' && element.study_current == false) || element.qualification == ''){

              //         err = true;

              //     }

              // }

              if (
                element.field_of_study != "" ||
                element.university != "" ||
                element.completion_year_from != "" ||
                element.completion_month_to != "" ||
                element.qualification != ""
              ) {
                if (
                  element.field_of_study == "" ||
                  element.university == "" ||
                  element.completion_year_from == "" ||
                  element.completion_month_to == "" ||
                  element.qualification == ""
                ) {
                  err = true;
                }
              }

              if (
                moment(element.completion_year_from).format("yyyy") >
                moment(element.completion_month_to).format("yyyy")
              ) {
                yearERR = true;
              } else if (
                moment(element.completion_year_from).format("yyyy") ==
                moment(element.completion_month_to).format("yyyy")
              ) {
                if (
                  moment(element.completion_year_from).format("MM") >
                  moment(element.completion_month_to).format("MM")
                ) {
                  monthERR = true;
                }
              }
            });

            this.appendExp.forEach((element, key) => {
              if (
                element.job_title != "" ||
                element.job_company != "" ||
                element.job_yrsfrom != "" ||
                ((element.job_mnsfrom != "" ||
                  element.job_mnsfrom != null ||
                  element.job_mnsfrom != "0") &&
                  element.job_current != false) ||
                element.description != ""
              ) {
                if (
                  element.job_title == "" ||
                  element.job_company == "" ||
                  element.job_yrsfrom == "" ||
                  ((element.job_mnsfrom == "" ||
                    element.job_mnsfrom == null ||
                    element.job_mnsfrom == "0") &&
                    element.job_current == false) ||
                  element.description == ""
                ) {
                  emperr = true;
                }
              }

              if (
                moment(element.job_yrsfrom).format("yyyy") >
                moment(element.job_mnsfrom).format("yyyy")
              ) {
                if (key == 0 && element.job_current == true) {
                  empyearERR = false;
                } else {
                  empyearERR = true;
                }
              } else if (
                moment(element.job_yrsfrom).format("yyyy") ==
                moment(element.job_mnsfrom).format("yyyy")
              ) {
                if (
                  moment(element.job_yrsfrom).format("MM") >
                  moment(element.job_mnsfrom).format("MM")
                ) {
                  empmonthERR = true;
                }
              }
            });
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              // if(this.$store.getters.getErrors){

              //       this.$swal({
              //         position: 'center',
              //         icon: 'error',
              //         title: 'Contact Number is already in use.',
              //         showConfirmButton: false,
              //         timer: 1500
              //     });
              // }

              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (otherErr == true) {
              this.error_message = this.$store.getters.getErrors;

              // if(this.$store.getters.getErrors){

              this.$swal({
                position: "center",
                icon: "error",
                title: "Please enter specified field.",
                showConfirmButton: false,
                timer: 1500,
              });
              // }

              // this.$swal({
              //     position: 'center',
              //     icon: 'error',
              //     title: this.error_message,
              //     showConfirmButton: false,
              //     timer: 1500
              // });
            } else if (yearERR == true) {
              this.error_message = this.$store.getters.getErrors;

              // if(this.$store.getters.getErrors){

              this.$swal({
                position: "center",
                icon: "error",
                title: "Please select correct Education Start Date and End Date.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (monthERR == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Please select correct Education Start Date and End Date.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (empmonthERR == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Please select correct Employment Start Date and End Date.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (empyearERR == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Please select correct Employment Start Date and End Date.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (err == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Kindly complete all of the Education History fields.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (emperr == true) {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Kindly complete all of the Employment History fields.",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.success_message = this.$store.getters.getResults.message;
              // Bus.$emit('active_users');

              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: this.success_message,
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.$store.getters.currentUser.profile_image != this.form.profile_image){
                this.$store.getters.currentUser.profile_image = this.form.profile_image;
                window.location.reload();
                // this.addQualification();
                // this.addExperience();
                // this.getUserData();
                // this.getQualification();
                // // this.getDropdownData('service_categories');
                // this.getDropdownDataUniversity('universities');
                // this.getDropdownData('field_of_studies');
                // this.getCountryCode();
                // this.getRace();

                // }

                window.scrollTo(0, 0);
              });
            }
          });
      }
    },
    addQualification() {
      this.appendQa.push({
        qualification: "",
        field_of_study: "",
        completion_year_from: "",
        completion_year_to: "",
        completion_month_from: "",
        completion_month_to: "",
        university: "",
        // edu_description:"",
        other_field_study: "",
        other_university: "",
        study_current: false,
      });
    },
    removeQualification(lineId) {
      if (this.blockRemovalQualification) {
        this.appendQa.splice(lineId, 1);
      }
    },
    addExperience() {
      this.appendExp.push({
        job_title: "",
        job_company: "",
        job_yrsfrom: "",
        job_mnsfrom: "",
        job_yrsto: "",
        job_mnsto: "",
        description: "",
        job_current: false,
      });
    },
    removeExperience(lineId) {
      if (this.blockRemovalExperience) {
        this.appendExp.splice(lineId, 1);
      }
    },
    sendOTP() {
      if (this.showVerifyOtp) {
        clearTimeout(this.timeOutCounter);
        this.$store
          .dispatch(POST_API, {
            data: {
              mobile: this.form.mobile_number,
              user_id: this.form.user_id,
              user_type_id: 5,
            },
            api: "/api/jobseekerSendOTP",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              this.$swal({
                position: "center",
                icon: "error",
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.form.verify_otp_button = true;
              if (this.form.paynow == "") {
                this.form.paynow = this.$store.getters.getResults.data.mobile_number;
              }
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "OTP sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.showVerifyMobileModel();
              });
            }
          });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please try again in " + this.countDown + " seconds.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getUserData() {
      return this.$store
        .dispatch(POST_API, {
          data: {
            id: this.form.user_id,
          },
          api: "/api/user-data",
        })
        .then(async () => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            return [];
          } else {
            if (this.$store.getters.getResults.data) {
              this.form.selectedImage.image =
                this.$store.getters.getResults.data.ft_profile_image != null
                  ? this.$store.getters.getResults.data.ft_profile_image
                  : this.webUrl + "../assets/images/user-avatar-small-01.png";
              this.form.selectedBannerImage.image =
                this.$store.getters.getResults.data.banner_image;
              this.form.first_name = this.$store.getters.getResults.data.first_name;
              this.form.full_name = this.$store.getters.getResults.data.full_name;

              this.form.mobile_number = this.$store.getters.getResults.data.mobile_number;
              this.form.country_code = this.$store.getters.getResults.data.country_code;
              this.form.ft_profile_image_thumbnail =
                this.$store.getters.getResults.data.ft_profile_image_thumbnail;

              this.form.verify_otp_button = this.$store.getters.getResults.data.mobile_number
                ? (this.form.verify_otp_button = true)
                : (this.form.verify_otp_button = false);
              this.user_type = this.$store.getters.getResults.data.user_type_id;
              this.form.expected_salary = this.$store.getters.getResults.data.expected_salary;
              this.form.about = this.$store.getters.getResults.data.about;
              this.form.gender = this.$store.getters.getResults.data.gender
                ? this.$store.getters.getResults.data.gender
                : "";
              this.form.nationality = this.$store.getters.getResults.data.nationality
                ? this.$store.getters.getResults.data.nationality
                : "";
              this.form.email = this.$store.getters.getResults.data.email;
              this.form.last_name = this.$store.getters.getResults.data.last_name;
              this.form.address = this.$store.getters.getResults.data.address;
              this.form.raceid = this.$store.getters.getResults.data.race_id;
              // moment( moment().subtract(15, 'years') ).format("YYYY-MM-DD")
              this.form.dob = this.$store.getters.getResults.data.dob
                ? this.$store.getters.getResults.data.dob
                : "";
              this.d_date = this.form.dob.split("-");
              if (this.form.dob == "") {
                // this.year = '';
                // this.month = '';
                // this.day = '';
                // console.log('if block')
              } else {
                console.log("else block");
                this.year = this.d_date[0];
                this.month = this.d_date[1];
                await this.generateDateDropdown();
                this.day = this.d_date[2];
              }
              this.form.skills =
                this.$store.getters.getResults.data.skills != null
                  ? this.$store.getters.getResults.data.skills.split(",")
                  : null;
              // this.form.skills = ["Vue", "Angular", "React"];
              this.form.email_verify = this.$store.getters.getResults.data.email_verified;
              this.form.ft_status_completed =
                this.$store.getters.getResults.data.ft_account_status == "completed"
                  ? (this.form.ft_status_completed = true)
                  : (this.form.ft_status_completed = false);
              this.ft_account_status = this.$store.getters.getResults.data.ft_account_status;
              this.cv_detail = this.$store.getters.getResults.cv_detail;
              this.showCV =
                this.$store.getters.getResults.data.cv != "" &&
                this.$store.getters.getResults.data.cv != null
                  ? true
                  : false;
              this.cvUrl =
                this.$store.getters.getResults.data.cv != "" &&
                this.$store.getters.getResults.data.cv != null
                  ? this.$store.getters.getResults.data.cv
                  : "";
              this.form.resume = this.cvUrl;
              this.form.profile_image = this.$store.getters.getResults.data.ft_profile_image
                ? this.$store.getters.getResults.data.ft_profile_image
                : null;
              this.form.image = this.$store.getters.getResults.data.ft_profile_image
                ? this.$store.getters.getResults.data.ft_profile_image
                : null;

              this.form.banner_image = this.$store.getters.getResults.data.banner_image
                ? this.$store.getters.getResults.data.banner_image
                : null;

              this.form.mobile_verify = this.$store.getters.getResults.data.mobile_verified;

              this.workExperienceData = this.$store.getters.getResults.experience;
              this.qualificationData = this.$store.getters.getResults.qualification;
              this.socialData = this.$store.getters.getResults.social;
              this.category = this.$store.getters.getResults.category;

              this.form.mobile_verified_check =
                this.$store.getters.getResults.data.mobile_verified == "yes"
                  ? (this.form.mobile_verified_check = true)
                  : (this.form.mobile_verified_check = false);
              this.status = this.$store.getters.getResults.data.ft_account_status;
              this.form.sortdesc = this.$store.getters.getResults.data.short_description;
              this.account_verification_status =
                this.$store.getters.getResults.data.account_verification_status;
              this.age = moment().diff(this.form.dob, "years");

              if (this.form.mobile_verified_check != true) {
                this.validErr.push("Verify Mobile Number");
              }
              if (this.form.email_verify != "yes") {
                this.validErr.push("Verify Email Address");
              }
              if (this.cvUrl.length == 0) {
                this.validErr.push("Please upload CV");
              }

              this.appendExp = [];
              this.appendQa = [];

              var appendQa = this.appendQa;
              var appendExp = this.appendExp;

              for (var key in this.$store.getters.getResults.preferences) {
                this.categories.push(
                  this.$store.getters.getResults.preferences[key].service_category_id
                );
              }

              if (this.workExperienceData.length > 0) {
                this.workExperienceData.forEach(function (item, index) {
                  appendExp.push({
                    job_company: item.company_name,
                    job_title: item.job_title,
                    job_yrs: item.experience_in_year != null ? item.experience_in_year : "0",
                    job_yrsfrom:
                      item.from_year != null
                        ? moment(
                            moment(item.from_year).format("yyyy") +
                              "-" +
                              moment(item.from_year).format("MM") +
                              "-01"
                          ).format()
                        : "",
                    // job_yrsto:item.to_year != null ? moment(moment(item.to_year).format('yyyy') + "-01-01").format(): '',
                    // job_mnsto:item.to_month != null ? moment("2022-0"+ moment(item.to_year).format('yyyy')+"-01").format(): '',
                    job_mnsfrom:
                      item.from_month != null
                        ? moment(
                            moment(item.to_year).format("yyyy") +
                              "-" +
                              moment(item.to_year).format("MM") +
                              "-01"
                          ).format()
                        : "sbc",
                    description: item.description,
                    job_mns: item.months != null ? item.months : "0",
                    job_current: item.currently != "no" ? true : false,
                  });
                });

                this.form.job_current = this.appendExp[0].job_current;

                if (this.form.job_current == true) {
                  this.appendExp[0].job_mnsfrom = null;
                  $("#year0").css("pointer-events", "none");
                }
              } else {
                this.experience = true
                this.appendExp = [];
                this.addExperience();
              }

              if (this.qualificationData.length > 0) {
                //   $("#cvv").removeAttr("disabled");

                var appendQa = this.appendQa;
                var th = this;
                this.qualificationData.forEach(function (item, index) {
                  appendQa.push({
                    qualification: item.qualification_name,
                    field_of_study: item.field_of_study,
                    other_field_study: item.specified_field_study,
                    other_university: item.specified_university,

                    completion_year_from: item.from_year != null ? item.from_year : "",
                    completion_year_to: item.to_year != null ? item.to_year : "",
                    completion_month_from: item.from_month != null ? item.from_month : "",
                    completion_month_to: item.to_month != null ? item.to_month : "",
                    university: item.university_name != null ? item.university_name : "0",
                    current: item.currently,

                    // edu_description : item.description != null ? item.description : '',
                    study_current: item.currently != "no" ? true : false,
                  });

                  // th.getDropdownParamsData('field_of_studies','educational_qualification_id', item.qualification_name, index)
                });

                this.form.study_current = this.appendQa[0].study_current;
                //31-01
                // if(this.form.study_current == true){

                //     this.appendQa[0].completion_year_to = null;
                //     this.appendQa[0].completion_month_to = null;
                //     $("#study0").css('pointer-events', 'none');

                //     $("#month0").css('pointer-events', 'none');

                // }
                //31-01
              } else {
                // $("#cvv").attr("disabled", true);
                this.appendQa = [];
                this.addQualification();
              }

              if (this.socialData != []) {
                var link = this.link;
                this.socialData.forEach(function (accounts) {
                  if (accounts.account_type == "fb") {
                    link.fb = accounts.url;
                  } else if (accounts.account_type == "google") {
                    link.google = accounts.url;
                  } else if (accounts.account_type == "linkedin") {
                    link.linkedin = accounts.url;
                  } else if (accounts.account_type == "website") {
                    link.website = accounts.url;
                  } else if (accounts.account_type == "twitter") {
                    link.twitter = accounts.url;
                  }
                  // code
                });
              }

              if (this.form.email_verify == "yes") {
                $("#email").attr("disabled", "disabled");
              } else {
                $("#email").removeAttr("disabled");
              }

              if (this.form.mobile_verify == "yes") {
                $("#number").attr("disabled", "disabled");
              } else {
                $("#number").removeAttr("disabled");
              }
            } else {
            }
          }
        });
    },
    emailVerify() {
      if (this.showVerifyEmail) {
        clearTimeout(this.timeOutCounterEmail);
        // this.countDownEmail = 30;
        return this.$store
          .dispatch(POST_API, {
            data: {
              email: this.form.email,
              user_id: this.form.user_id,
            },
            api: "/api/sendEmailVerify",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              return [];
            } else {
              (this.countDownEmail = 30), this.countDownTimerEmail();
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Verification mail sent successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // window.location.reload();
              });
            }
          });
      } else {
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please try again in " + this.countDownEmail + " seconds.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    dailyData() {
      window.location.reload("/v2/jobseekeer/daily-job-profile");
    },
    showVerifyMobileModel() {
      (this.countDown = 60), (this.form.var_code = "");
      this.countDownTimer();
      this.$refs["verify-mobile-modal"].show();
    },

    showsampleimageModel() {
      this.$refs["sample-image-modal"].show();
    },
    mobileVerifyCancel() {
      this.$swal({
        title: "Please Confirm",
        text: "Are you sure you want to close this popup?",
        // text: "You can resend OTP in " +this.countDown+ " seconds.Are you sure you want to close this popup?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((value) => {
        if (value.isConfirmed) {
          //clearTimeout(this.timeOutCounter);
          this.$refs["verify-mobile-modal"].hide();
        }
      });
    },
    hideVerifyMobileModel() {
      clearTimeout(this.timeOutCounter);
      this.$refs["verify-mobile-modal"].hide();
    },
    mobileVerify() {
      if (!$("#verification").val()) {
        $("#verification").addClass("invalid");
        $("#verification-warning-message").show();
        $("#verification-warning-message").html("Please Enter Verification code!");
      }

      $("#verification").focusin(function () {
        $("#verification-warning-message").hide();
        $("#verification").removeClass("invalid");
      });
      $("#verification").focusout(function () {
        if ($("#verification").val() === "") {
          $("#verification").addClass("invalid");
          $("#verification-warning-message").show();
        } else {
          $("#verification").removeClass("invalid");
          $("#verification-warning-message").hide();
        }
      });
      return this.$store
        .dispatch(POST_API, {
          data: {
            verification_code: this.form.var_code,
            user_id: this.form.user_id,
          },
          api: "/api/verifyMobile",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showAlert();
            // return [];
          } else {
            this.hideVerifyMobileModel();

            this.form.mobile_verified_check = true;

            this.$swal({
              position: "center",
              icon: "success",
              iconColor: "#4c8bf5",
              title: "Mobile Number verified successfully.",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              this.form.mobile_verify == "yes";

              $("#number").attr("disabled", "disabled");

              // window.location.reload();
            });
          }
        });
    },
  },
  mounted() {
    this.fullYear = new Date();
    this.fullYear.setFullYear(this.fullYear.getFullYear() - 15);
    this.fullYear = this.fullYear.getFullYear();
    // Setting current date and time
    this.year = moment().format("YYYY");
    this.month = moment().format("MM");
    this.generateDateDropdown();
    this.day = moment().format("DD");

    //document.title = "EL Connect - Jobseeker - Profile";
    $(".dashboard-content-container .simplebar-scroll-content").animate(
      {
        scrollTop: 0,
      },
      "fast"
    );
    this.form.user_id =
      this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : "";
    this.addQualification();
    this.addExperience();
    this.getUserData();
    this.getQualification();
    // this.getDropdownData('service_categories');
    this.getDropdownDataUniversity("universities");
    this.getDropdownData("field_of_studies");
    this.getCountryCode();
    this.getRace();
    Bus.$emit("active_users");

    $("#sent").hide();
    $("#salary").keyup(function (e) {
      if (/\D/g.test(this.value)) {
        // Filter non-digits from input value.
        this.value = this.value.replace(/\D/g, "");
      }
    });
  },
};
</script>
<style>
.single-image-upload .form-control-file,
.single-image-upload .form-control-range {
  position: absolute;
  height: 100%;
  opacity: 0;
}
.single-image-upload .form-control-file,
.custom-file-input {
  height: 200px;
}
</style>
