<template>
    <div>

        <div class="row">

            <div class="container my_new_container">
                 <!-- Dashboard Headline -->
        <!-- <div class="dashboard-headline"> -->
            <!-- <h1><span class="icon-material-outline-arrow-back new_arrow_back" @click="$router.go(-1)"></span>{{last_name | capitalize}} {{first_name | capitalize}} Audit Jobs</h1> -->
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark email_tmp_tbl_nav">
                <ul>
                    <li><a href="#">Home</a></li>
                    <li>Audit Jobs</li>
                </ul>
            </nav>
        <!-- </div> -->
        <div id="breadcrumb-v2">
                <b-breadcrumb>
                    <b-breadcrumb-item href="" :to="{name: 'employer-list'}">Employers</b-breadcrumb-item>
                    <b-breadcrumb-item active>{{last_name | capitalize}} {{first_name | capitalize}} Audit Jobs</b-breadcrumb-item>
                </b-breadcrumb>
        </div>
                <!-- <div class="col-xl-4 remove_search_box_pdng">
                    <div class="search-box custom_search_admin">
                        <b-input-group>

                            </b-input-group>
                        </div>
                    </div> -->
                </div>
                </div>
        <!-- Page Content
        ================================================== -->
        <div class="row">
           <div class="container my_new_container show_filter_arrow">
				<!-- <b-table ref="datatable" show-empty striped hover responsive :items="getAuditJobs" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="static_page five_grid_tbl  new_box_tbl_mrgn desktop_margin_top_zero mobile_min_width_1000 payslip_table"> -->
				<b-table ref="datatable" show-empty striped hover responsive :items="getAuditJobs" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" class="mobile_min_width_1000 countries_five_grid_table">

                    <template #cell(job_unique_id)="items">
  <b-link 
    v-if="items.item.status === 'completed'" 
    :to="`/admin/jobs/view-jobsDetail-completed/${encrypt(items.item.id)}/completed/null/null/null`" 
    target="_blank">
    {{ items.item.job_unique_id | capitalize }}
  </b-link>

  <b-link 
    v-else-if="items.item.status === 'cancelled'" 
    :to="`/admin/jobs/view-jobsDetail-cancelled/${encrypt(items.item.id)}/cancelled/null/null/null`" 
    target="_blank">  
    {{ items.item.job_unique_id | capitalize }}
  </b-link>

  <b-link 
    v-else-if="items.item.status === 'active'" 
    :to="`/admin/jobs/view-jobsDetail-upcoming/${encrypt(items.item.id)}/active/null/null/null`" 
    target="_blank">  
    {{ items.item.job_unique_id | capitalize }}
  </b-link>

  <b-link 
    v-else-if="items.item.status === 'in-progress'" 
    :to="`/admin/jobs/view-jobsDetail-inprogress/${encrypt(items.item.id)}/in-progress/null/null/null`" 
    target="_blank">
    {{ items.item.job_unique_id | capitalize }}
  </b-link>

  <span v-else>
    {{ items.item.job_unique_id | capitalize }}
  </span>
</template>
                    <template #cell(title)="items">
                        {{items.item.title | capitalize}}
                    </template>
                    <template #cell(date)="items">
                    {{items.item.date | date_formate}}
                    </template>
                     <template #cell(status)="items">
                        {{items.item.status | capitalize}}
                    </template>
                    <template #cell(toggleAction)="items">
                        <div class="toggle-action toggle_margin_unset top_100">
                            <b-dropdown class="mx-1" right text="Action" boundary="window">
                                <b-dropdown-item :to="{name: 'employer-audit-jobs-detail', params: {id: encrypt(items.item.id)}}">Audit Detail
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                <nav class="pagination-box custom_pagination_border_radius">
                    <div class="page-row-box d-inline-block">
                        <b-form-group id="input-group-4">
                             <span class="d-inline-block">Rows per page</span>
                            <span class="d-inline-block">
                            <b-form-select v-model="form.rowsPerPage" @change='getAuditJobs'>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>75</option>
                                        <option>100</option>
                            </b-form-select></span>
                        </b-form-group>
                    </div>
                    <div class="total-page-count d-inline-block">
                        <ul>
                            <li>
                                <span v-if='totalRows != 0'>{{from}} - {{to}} of {{totalRows}}</span>
                            </li>
                        </ul>
                    </div>
                    <b-pagination :total-rows="totalRows"   :per-page="perPage" v-model="currentPage" prev-text="" next-text="" hide-goto-end-buttons />
                </nav>
			</div>
        </div>
        <!-- Spacer -->
        <!-- <div class="margin-top-70"></div> -->
        <!-- Spacer / End-->
        <!-- Row / End -->
    </div>
</template>

<script>
import { POST_API } from '../../../store/actions.type'
import moment from "moment";

export default {

    data() {
        return {
            fields: [
                { key: 'job_unique_id', label: 'Job ID', sortable: true },
                { key: 'title', label: 'Title', sortable: true},
                { key: 'date', label: 'Date', sortable: true},
                { key: 'status', label: 'Status', sortable: true},
                { key: 'toggleAction', label: 'Actions', }
            ],
            id: atob(this.$route.params.id),
            company_id: atob(this.$route.params.company_id),
            first_name:'',
            last_name:'',
            pager: {},
            pageOfItems: [],
            items:null,
            totalRows: 0,
            from: '',
            to: '',
            currentPage: 1,
            perPage: 25,
            pageOptions: [25, 50, 75, 100],
            sortBy: null,
            sortDirection: 'desc',
            filter: '',
            status: 'active',
            sortDesc: true,
            webUrl: process.env.VUE_APP_URL,
            form:{
                rowsPerPage:25,
            }
        }
    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        moment: function(date) {
            return moment(date).format('DD MMM YYYY')
        },
        date_formate: function(date) {
      if(date != null)
        return moment(date).format('DD-MM-YYYY hh:mm A')
      else
        return '-';
    },
    },
    methods:{
        showSuccessAlert(message) {
            this.$swal({
                position: 'center',
                icon: 'success',
                iconColor:'#4c8bf5',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then((result) => {
                this.$router.push({ name: 'jobseekers-list' });
            });
        },
        showAlert() {
            this.$swal({
                position: 'center',
                icon: 'error',
                title: this.error_message,
                showConfirmButton: false,
                timer: 1500
            })
        },

        getAuditJobs() {
            return this.$store.dispatch(POST_API, {
                    data:{
                        user_id:this.id,
                        company_id: this.company_id,
                        page: this.currentPage,
                        keyword: this.filter,
                        rowsPerPage:this.form.rowsPerPage,
                        sortBy: this.sortBy ? this.sortBy: 'id',
                        sortDirection:this.sortDesc ? 'desc' : 'asc',
                    },
                    api: '/api/emp-audit-jobs-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        return [];
                    } else {
                        this.items = this.$store.getters.getResults.pageOfItems;
                        this.totalRows = this.$store.getters.getResults.pager.totalItems;
                        this.perPage = this.$store.getters.getResults.pager.pageSize;
                        this.from = this.$store.getters.getResults.pager.from;
                        this.to = this.$store.getters.getResults.pager.to;
                        return this.items;
                    }
                });
        },
        verifyEmail(id)
        {
            return this.$store.dispatch(POST_API, {
                data:{
                id: id,
                },
                api:'/api/jobseeker-verify-email-admin',
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var message='Email Verified successfully.';
                    this.$refs.datatable.refresh();
                    this.showSuccessAlert(message);

                }
            });

        },
        getUserData() {
            return this.$store.dispatch(POST_API, {
                data: {
                    id: atob(this.$route.params.id),
                },
                api: '/api/user-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                    return [];
                } else {
                    if(this.$store.getters.getResults.data){
                        this.first_name = this.$store.getters.getResults.data.first_name;
                        this.last_name = this.$store.getters.getResults.data.last_name;
                    }
                }
            });
        },

        deleteJobseeker(id)
        {
            this.$swal({
                title: 'Please Confirm',
                text: 'Are you sure you want to delete this record?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4C8BF5',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            })
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                        id: id,
                        },
                        api:'/api/admin-empjobseeker-delete',
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showAlert();
                        } else {
                            var message='Jobseeker Deleted successfully.';
                            this.$refs.datatable.refresh();
                            this.showSuccessAlert(message);

                        }
                    });
                }
            })
            .catch(err => {
            })
        },
    },
    mounted(){
        $(".show_filter_arrow .table thead th div").contents().unwrap();
        $(".dashboard-content-container .simplebar-scroll-content").animate({
            scrollTop: 0
        }, "fast");
       // document.title = "EL Connect - Admin - Jobseekers List";
        this.form.user_id = (this.$store.getters.currentUser != null ? this.$store.getters.currentUser.id : '');
        this.getUserData();
    }
}
</script>
