<template>
  <div>
    <!-- Main Banner -->
    <div
      class="main-banner custom_main_banner jobseeker_landing_page_banner"
      id="top"
      v-for="(background, h) in background"
      :key="h"
      :style="{ backgroundImage: ` url(${background.image})` }"
    >
      <div
        class="inner_bg_top_header"
        v-for="(banner, h) in banner"
        :key="h"
        :style="{ backgroundImage: ` url(${banner.image})` }"
      >
        <div class="container my_custom_container_emp">
          <div class="row">
            <div class="col-lg-12">
              <div class="item header-text">
                <h1 class="common_font_family">Ultimate Job Matching Platform</h1>
                <h2 class="common_font_family">
                  Connect with Leading Employers and Land Your Dream Job
                </h2>
                <ul class="desktop_header_ul">
                  <li>
                    <span>Access Thousand of <b>Job Opportunities</b> on our Platform</span>
                  </li>
                  <li>
                    <span>Explore a Variety of <b>Gig Earning Options</b> though our app</span>
                  </li>
                  <li>
                    <span
                      ><b>Receive Job Alerts</b> via email, messaging platforms or push
                      notification</span
                    >
                  </li>
                  <li>
                    <span
                      >Experience an <b>Intuitive Platform </b>anytime, anywhere, on my device</span
                    >
                  </li>
                  <li class="scroll-to-section">
                    <div class="enquirebutton works_btn">
                      <!-- <div class="signUpbtn"><a @click="$bvModal.show('bv-modal-example')">Join Us Now !</a></div> -->
                      <div class="signUpbtn" @click="$bvModal.show('bv-modal-example')">
                        <a>Join Us Now !</a>
                      </div>
                    </div>
                  </li>
                </ul>

                <!--     <div class="tab_screen_bg_image">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <ul>
                                            <li><span>Access Thousand of <b>Job Opportunities</b> on our Platform</span></li>
                                            <li><span>Explore a Variety of <b>Gig Earning Options</b> though our app</span> </li>
                                            <li><span><b>Receive Job Alerts</b> via email, messaging platforms or push notification</span></li>
                                            <li><span>Experience an <b>Intuitive Platform </b>anytime, anywhere, on my device</span></li>
                                            <li class="scroll-to-section">
                                                <div class="enquirebutton works_btn">
                                                    <div class="signUpbtn" @click="$bvModal.show('bv-modal-example')"><a >Join Us Now !</a></div>
                                                </div>
                                            </li>
                                        </ul>
                                </div>
                                <div class="col-lg-6 col-md-6 tab_margin_top_1_rem">
                                    <img :src="banner.image" class="">
                                </div>
                            </div>
                        </div> -->

                <div class="store_icons my_custom_margin_top_30 common_padding_bottom_50">
                  <a href="https://apps.apple.com/sg/app/el-connect/id1515715893" target="_blank">
                    <img :src="webUrl + 'assets/jobseekerImages/appstore.png'" class="app_store" />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.elconnectptuser"
                    target="_blank"
                  >
                    <img
                      :src="webUrl + 'assets/jobseekerImages/playstore.png'"
                      class="play_store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ultimate job platform ... -->
    <div class="reasons common_padding_bottom_50" id="reason">
      <div class="desktop_padding_left_15 container my_custom_container_emp">
        <div class="row">
          <!-- <div class="col-xl-12"> -->
          <!-- <div class="section-heading">
                  <h1 class="why_heading common_font_family">Ultimate Job Matching Platform</h1>
              </div> -->
          <!-- <div class="row"> -->
          <div class="heading desktop_margin_right_zero col-lg-6">
            <div class="fact-item">
              <img
                src="assets/jobseekerImages/visibility.png"
                alt="Application monitoring"
                class="visibility"
              />
              <h3 class="count-title">Application Monitoring</h3>
            </div>
            <h4>
              Track your job application progress with EL Connect and get insights into your
              application history, employer responses, and interview schedules to make informed
              decisions for successful job search.
            </h4>
          </div>
          <div class="heading desktop_margin_right_zero col-lg-6">
            <div class="fact-item">
              <img src="assets/jobseekerImages/payments.png" alt="Job Hunt Stability" />
              <h3 class="count-title">Job Hunt Stability</h3>
            </div>
            <h4 class="mobile_margin_bottom_zero">
              As you patiently await responses to your job applications, Explore daily paying jobs
              that provide immediate compensation, keeping you financially stable and productive
              while you wait for job application replies.
            </h4>
          </div>
          <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>

    <!-- Hiring Partners -->
    <div class="clientele common_padding_bottom_50" id="clientele">
      <div class="container my_custom_container_emp">
        <div class="row">
          <div class="col-xl-12">
            <div class="header">
              <h1 class="common_font_family fade-in">Companies</h1>
            </div>
            <logo-slider>
              <div class="slider1 fade-left">
                <div v-for="(item, val) in items" :key="val" class="slider_logo">
                  <img :title="item.name" :src="item.logo" alt="Partner Logo" />
                </div>
              </div>
            </logo-slider>
          </div>
        </div>
      </div>
    </div>

    <!-- Slide -->
    <div class="about-us section common_padding_bottom_50 new_about" id="about">
      <div class="container my_custom_container_emp">
        <div class="row">
          <div class="col-xl-12">
            <div class="slideshow-container">
              <h1 class="common_font_family fade-left">#1 Part Time Job Platform</h1>
            </div>
            <div class="dp_slider_desktop">
              <div class="slider-new">
                <div class="common_text_slider_works_new">
                  <div class="content_full_div_nw" v-for="(slide, h) in slide1" :key="h">
                    <div class="column left content_slider_new fade-left">
                      <h3>{{ slide.text }}</h3>
                      <ul class="switch_ul">
                        <li class="active_li"></li>
                        <li class="non_active_li"></li>
                        <li class="non_active_li"></li>
                      </ul>
                      <h4>{{ slide.description }}</h4>
                    </div>
                    <div class="column right slider_gif_new fade-right">
                      <div class="phone-image_new">
                        <img :src="slide.image" class="phone-img-new" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="common_text_slider_works_new">
                  <div class="content_full_div_new" v-for="(slide, h) in slide2" :key="h">
                    <div class="column left content_slider_new fade-left">
                      <h3>{{ slide.text }}</h3>
                      <ul class="switch_ul">
                        <li class="non_active_li"></li>
                        <li class="active_li"></li>
                        <li class="non_active_li"></li>
                      </ul>
                      <h4>{{ slide.description }}</h4>
                    </div>
                    <div class="column right slider_gif_new fade-right">
                      <div class="phone-image_new">
                        <img :src="slide.image" class="phone-img-new" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="common_text_slider_works_new">
                  <div class="content_full_div_new" v-for="(slide, h) in slide3" :key="h">
                    <div class="column left content_slider_new fade-left">
                      <h3>{{ slide.text }}</h3>
                      <ul class="switch_ul">
                        <li class="non_active_li"></li>
                        <li class="non_active_li"></li>
                        <li class="active_li"></li>
                      </ul>
                      <h4>{{ slide.description }}</h4>
                    </div>
                    <div class="column right slider_gif_new fade-right">
                      <div class="phone-image_new">
                        <img :src="slide.image" class="phone-img-new" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dp_slider_mobile">
              <div class="slider-new">
                <div class="common_text_slider_works_new">
                  <div class="content_full_div_nw" v-for="(slide, h) in slide1" :key="h">
                    <div class="column right slider_gif_new fade-right">
                      <div class="phone-image_new">
                        <img :src="slide.image" class="phone-img-new" />
                      </div>
                    </div>
                    <div class="column left content_slider_new fade-left">
                      <h3>{{ slide.text }}</h3>
                      <ul class="switch_ul">
                        <li class="active_li"></li>
                        <li class="non_active_li"></li>
                        <li class="non_active_li"></li>
                      </ul>
                      <h4>{{ slide.description }}</h4>
                    </div>
                  </div>
                </div>
                <div class="common_text_slider_works_new">
                  <div class="content_full_div_new" v-for="(slide, h) in slide2" :key="h">
                    <div class="column right slider_gif_new fade-right">
                      <div class="phone-image_new">
                        <img :src="slide.image" class="phone-img-new" />
                      </div>
                    </div>
                    <div class="column left content_slider_new fade-left">
                      <h3>{{ slide.text }}</h3>
                      <ul class="switch_ul">
                        <li class="non_active_li"></li>
                        <li class="active_li"></li>
                        <li class="non_active_li"></li>
                      </ul>
                      <h4>{{ slide.description }}</h4>
                    </div>
                  </div>
                </div>
                <div class="common_text_slider_works_new">
                  <div class="content_full_div_new" v-for="(slide, h) in slide3" :key="h">
                    <div class="column right slider_gif_new fade-right">
                      <div class="phone-image_new">
                        <img :src="slide.image" class="phone-img-new" />
                      </div>
                    </div>
                    <div class="column left content_slider_new fade-left">
                      <h3>{{ slide.text }}</h3>
                      <ul class="switch_ul">
                        <li class="non_active_li"></li>
                        <li class="non_active_li"></li>
                        <li class="active_li"></li>
                      </ul>
                      <h4>{{ slide.description }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="enquirebutton works_btn">
              <!-- <div class="signUpbtn"><a @click="$bvModal.show('bv-modal-example')">Start Earning Now !</a></div> -->
              <div class="signUpbtn" @click="$bvModal.show('bv-modal-example')">
                <a>Start Earning Now !</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Exploration -->
    <!--   <div class="video common_padding_bottom_50" id="exploration">
       <div class="container my_custom_container_emp">
           <div class="row">
               <div class="col-xl-12">
                   <h1 class="fade-in">Exploration</h1>
                    <div class="exploration" v-for="(exploration, h) in video" :key="h">
                      <iframe :src = "exploration.link"
                              class="fade-in"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      <div class="desc fade-down">
                        <h4>{{ exploration.description }}</h4>
                      </div>
                    </div>
               </div>
           </div>
       </div>
  </div>
 -->

    <!-- Testimonial -->
    <div
      class="testimonial gray common_padding_bottom_50 default_slider_hide_home"
      id="testimonial"
    >
      <div class="container my_custom_container_emp">
        <div class="row">
          <div class="col-xl-12 fade-in">
            <h1 class="common_font_family">Testimonials</h1>

            <VueSlickCarousel
              :arrows="true"
              :dots="true"
              ref="carousel"
              v-if="testimonial.length"
              :adaptive-height="true"
            >
              <div class="testimonial-box" v-for="(test, h) in testimonial">
                <div class="testimonial-avatar">
                  <img :src="test.image" alt="freelancer_avatar" />
                </div>
                <div class="testimonial-author">
                  <h4>{{ test.title }}</h4>
                  <span>{{ test.user_type | capitalize }}</span>
                  <img src="assets/jobseekerImages/user_star.png" />
                </div>
                <div class="testimonial-desc">
                  {{ test.description }}
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="row my_custom_margin_top_30 my_custom_margin_bottom_30">
            <div class="description_box">
              <div class="job_box col-xl-3 fade-left">
                <img src="assets/jobseekerImages/jobs_vector.png" class="jobs" />
                <h4>{{ fl_page_dp_ft_jobs_count }} Jobs</h4>
                <p>Jobs Currently Available</p>
              </div>
              <div class="rating_box col-xl-3 fade-down">
                <img src="assets/jobseekerImages/rating_vector.png" class="stars" />
                <h4>4.7 Stars</h4>
                <p>Overall Platform Rating Average</p>
              </div>
              <div class="company_box col-xl-3 fade-right">
                <img src="assets/jobseekerImages/company_vector.png" class="company" />
                <h4>{{ fl_page_companies_count }} Companies</h4>
                <p>On Board With Us</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Incentivisation -->
    <div class="section-6 common_padding_bottom_50">
      <div class="container my_custom_container_emp">
        <div class="row">
          <div class="col-xl-12">
            <div class="desktop_view" v-for="(incentivisation, h) in content" :key="h">
              <div class="column left col-xl-6 fade-left">
                <h1>Incentivisation</h1>
                <h3>{{ incentivisation.text }}</h3>
                <h4>{{ incentivisation.description }}</h4>
                <h4>You might be the next one!</h4>

                <div class="enquirebutton works_btn">
                  <a @click="$bvModal.show('bv-modal-example')"
                    ><div class="signUpbtn">Start Earning Now !</div></a
                  >
                </div>
              </div>
              <div class="column right col-xl-6 fade-right">
                <div id="slider">
                  <ul>
                    <li v-for="(img, index) in images" :key="index">
                      <div class="slide-container">
                        <img :src="img.image" alt="Image" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="mobile_view" v-for="(incentivisation, h) in content" :key="h">
              <h1>Incentivisation</h1>
              <h3>{{ incentivisation.text }}</h3>

              <div class="column left col-xl-6">
                <h4>{{ incentivisation.description }}</h4>
                <h4>You might be the next one!</h4>
              </div>
              <div class="column right col-xl-6">
                <div id="slider">
                  <ul>
                    <li v-for="(img, index) in images" :key="index">
                      <div class="slide-container">
                        <img :src="img.image" alt="Image" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="enquirebutton works_btn">
                <a @click="$bvModal.show('bv-modal-example')"
                  ><div class="signUpbtn">Start Earning Now !</div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Local Media -->
    <div class="media common_padding_bottom_50" id="exploration">
      <div class="container my_custom_container_emp">
        <div class="row">
          <div class="col-xl-12 fade-in">
            <div class="items">
              <h1>Featured On...</h1>
              <h3>The Crucial Role of Trust in Part-Time Employment</h3>
              <h4>Some of the key takeaways include:</h4>

              <ul>
                <li>
                  Part-time workers face challenges with slow and unreliable payrolls, as well as
                  low stability and pay.
                </li>
                <li>
                  Despite their importance as a key pillar supporting society, they are often
                  overlooked.
                </li>
                <li>
                  EL Connect has taken steps to ensure greater stability for both part-timers and
                  hirers.
                </li>
                <li>
                  Check out the podcast to learn more about the challenges facing part-time workers
                  and how to address them.
                </li>
              </ul>
            </div>

            <div class="column left col-xl-6 fade-in">
              <a href="https://www.moneyfm893.sg/guest/hugen-liu-el-connect/" target="_blank">
                <img :src="webUrl + 'assets/jobseekerImages/img1.jpg'" class="img1" />
              </a>
              <a href="https://www.moneyfm893.sg/guest/hugen-liu-el-connect/" target="_blank">
                <img :src="webUrl + 'assets/jobseekerImages/img2.jpg'" class="img2" />
              </a>
              <a
                href="https://www.moneyfm893.sg/guest/hugen-liu-el-connect/"
                target="_blank"
                class="podcast_a"
              >
                <span class="podcast_span">Listen to the Podcast</span></a
              >
            </div>

            <div class="column right col-xl-6 fade-in">
              <a
                href="https://www.zaobao.com.sg/news/singapore/story20211129-1217974"
                target="_blank"
              >
                <img :src="webUrl + 'assets/jobseekerImages/img3.jpg'" class="img3" />
              </a>
              <a
                href="https://www.zaobao.com.sg/news/singapore/story20211129-1217974"
                target="_blank"
                class="podcast_a"
              >
                <span class="podcast_span">Read Article</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Launch -->
    <div class="contact-us common_padding_bottom_50" id="contact">
      <div class="container my_custom_container_emp">
        <div class="row">
          <!-- <div class="col-lg-14"> -->
          <div class="col-lg-12 fade-in">
            <h1>Launch Your Job Hunt !</h1>
            <h3>
              Take the first step towards finding your dream job and kick-starting your career
              today.
            </h3>
            <div class="row">
              <div class="column left col-xl-6 my_custom_margin_top_30 col-lg-6 fade-left">
                <img class="mobile_phones" src="assets/jobseekerImages/mobile_phones.png" />
              </div>

              <div class="column right col-xl-6 col-lg-6 fade-right">
                <p>Begin your job hunt today <br />and take control of your career.</p>
                <div class="enquirebutton works_btn my_custom_margin_bottom_30">
                  <a
                    href="#contact"
                    @click="$bvModal.show('bv-modal-example')"
                    class="signup_anchor"
                    ><div class="signUpbtn">Sign Up Here !</div></a
                  >
                </div>
                <div class="Download">
                  <h2>Download Now!</h2>
                </div>
                <div
                  class="store_icons my_custom_margin_top_30 common_padding_bottom_50 tab_margin_bottom_35"
                >
                  <a href="https://apps.apple.com/sg/app/el-connect/id1515715893" target="_blank">
                    <img :src="webUrl + 'assets/jobseekerImages/appstore.png'" class="app_store" />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.elconnectptuser"
                    target="_blank"
                  >
                    <img
                      :src="webUrl + 'assets/jobseekerImages/playstore.png'"
                      class="play_store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Register Form -->
    <div class="home_mdl">
      <b-modal
        ref="bv-modal-example"
        id="bv-modal-example"
        title="BootstrapVue login"
        class="home_model"
      >
        <div slot="modal-title" class="custom_model_header">
          <div
            class="modal"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            style="display: block"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="">
                  <div class="">
                    <div class="">
                      <div>
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs" role="tablist">
                          <li role="presentation" class="active" id="login_li">
                            <a
                              href="#register"
                              aria-controls="register"
                              role="tab"
                              data-toggle="tab"
                              >Register</a
                            >
                          </li>
                          <li>
                            <b-button
                              class="mt-3 custom_close_btn model_margin_left_zero"
                              block
                              @click="$bvModal.hide('bv-modal-example')"
                              >x</b-button
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tab panes -->
        <div class="tab-content">
          <div role="tabpanel" class="" id="register">
            <div class="welcome-text remove_mrgn top_mrgn">
              <h3 style="font-size: 26px">Let's create your account!</h3>
              <br />
            </div>
            <!-- <b-form @submit.prevent="signup"> -->

            <!-- <div class="account-type">
                      <div>
                          <input type="radio" v-model="suser_type" name="account-type-radio" id="freelancer-radio" checked="checked" value="5" class="account-type-radio">
                          <label for="freelancer-radio" class="ripple-effect-dark"><i class="icon-material-outline-account-circle"></i> Jobseeker</label>
                              </div><div><input type="radio" v-model="suser_type" name="account-type-radio" id="employer-radio" value="3" class="account-type-radio">
                              <label for="employer-radio" class="ripple-effect-dark">
                                  <i class="icon-material-outline-business-center"></i> Employer</label>

                      </div>
                  </div> -->
            <div class="login_forms">
              <b-form
                @submit.prevent="signup"
                id="register-account-form"
                class="pop_up_frm new_pop_up_frm"
              >
                <div class="account-type remove_mrgn input-with-icon-left mobile_tab_login_pp">
                  <div>
                    <div class="full_width_input">
                      <i class="fa fa-user pop_up_user common_profile_icon" aria-hidden="true"></i
                      ><b-form-input
                        id="fname"
                        name="login-email"
                        v-model="fname"
                        type="text"
                        placeholder="Given Name"
                        class="input-text with-border form-control required-field"
                      />
                    </div>
                    <div class="show_span">
                      <span class="error_x_white">Please enter Given Name </span>
                    </div>
                  </div>

                  <div>
                    <div class="full_width_input">
                      <i
                        class="fa fa-user pop_up_user common_profile_icon surname_icon"
                        aria-hidden="true"
                      ></i
                      ><b-form-input
                        id="lname"
                        name="login-email"
                        type="text"
                        v-model="lname"
                        placeholder="Surname"
                        class="input-text with-border form-control required-field"
                      />
                    </div>
                    <div class="show_span">
                      <span class="error_x_white">Please enter Surname </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="input-with-icon-left">
                    <i class="icon-material-baseline-mail-outline common_profile_icon"></i>
                    <b-form-input
                      id="semail"
                      v-model="semail"
                      name="login-email"
                      type="text"
                      placeholder="john@example.com"
                      class="input-text with-border form-control required-field"
                    />
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Email </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left" id="show_hide_password_login">
                    <i class="icon-material-outline-lock common_profile_icon"></i>
                    <b-form-input
                      id="spassword"
                      v-model="spassword"
                      name="signup-password"
                      type="password"
                      placeholder="Password"
                      class="input-text with-border form-control required-field"
                    />
                    <div class="input-group-addon-custom">
                      <span @click="passwordClick"
                        ><i
                          class="fa fa-eye-slash cusror_point common_profile_icon"
                          aria-hidden="true"
                        ></i
                      ></span>
                    </div>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Password </span>
                  </div>
                </div>
                <div>
                  <div class="input-with-icon-left" id="show_hide_repassword_signup">
                    <i class="common_profile_icon icon-material-outline-lock"></i>
                    <b-form-input
                      id="srepassword"
                      name="repeate-password"
                      v-model="srepassword"
                      type="password"
                      placeholder="Confirm Password"
                      class="input-text with-border form-control required-field"
                    />
                    <div class="input-group-addon-custom">
                      <b-link @click="repasswordClick"
                        ><i class="fa fa-eye-slash common_profile_icon" aria-hidden="true"></i
                      ></b-link>
                    </div>
                  </div>
                  <div class="show_span">
                    <span class="error_x_white">Please enter Confirm Password </span>
                  </div>
                </div>
                <div class="">
                  <div class="tacbox agree_note common_sgn_agree">
                    <input id="checkbox" type="checkbox" value="true" style="font-size: 8px" />
                    <span for="" id="empcontr" style="display: none">
                      I hereby agree to the
                      <!-- <a
                        href="https://elconnect-preprod.sgp1.digitaloceanspaces.com/EL%20Connect%20Employer%20T&C%202024.pdf"
                        target="_blank"
                        >Terms of Service</a
                      > -->
                      <a href="/em-terms-of-service" target="_blank">Terms of Service</a>
                      of EL Connect Pte Ltd.</span
                    >
                    <span for="" id="jobcontr">
                      I hereby agree to the
                      <a href="/terms-of-service" target="_blank">Terms of Service</a> of EL Connect
                      Pte Ltd.</span
                    >
                  </div>
                </div>
              </b-form>

              <button
                type="submit"
                form="register-account-form"
                class="button full-width button-sliding-icon ripple-effect model_btn"
                id="reg_button"
              >
                Register <i class="icon-material-outline-arrow-right-alt"></i>
              </button>
              <button
                form="register-account-form"
                class="button full-width button-sliding-icon ripple-effect model_btn"
                style="display: none"
                id="loaderreg"
                disabled
              >
                <span class="fa fa-spinner fa-spin" id="spinner"></span> Loading
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { POST_API, LOGIN } from "../../../store/actions.type";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  metaInfo: {
    title: "EL Connect - Jobseekers Landing Page",
    meta: [
      { charset: "utf-8" },
      { equiv: "content-type", content: "text/html" },
      {
        name: "description",
        content: "EL Connect - Connect with Leading Employers and Land Your Dream Job",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { property: "og:title", content: "EL Connect - Home" },
      { property: "og:site_name", content: "EL Connect" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://elconnect.sg" },
      {
        property: "og:image",
        content:
          "https://elconnect.sgp1.digitaloceanspaces.com/v2/general_setting/202281202127_general_setting.png",
      },
      {
        property: "og:description",
        content:
          "EL Connect - Access Thousand of Job Opportunities on our Platform, Explore varity of gig earning option through our app.",
      },
      { name: "twitter:card", content: "summary" },
    ],
    link: [{ rel: "canonical", href: "https://elconnect.sg" }],
  },
  data() {
    return {
      email: "",
      password: "",
      user_type: "5",
      background: null,
      banner: null,
      slide1: null,
      slide2: null,
      slide3: null,
      video: null,
      content: null,
      images: null,
      fname: "",
      lname: "",
      semail: "",
      spassword: "",
      srepassword: "",
      suser_type: "5",
      items: null,
      testimonial: [],
      webUrl: process.env.VUE_APP_URL,
      error_message: "",
      job_items: [],
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 800,
        infinite: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 800,
              infinite: false,
            },
          },
        ],
      },
      name: "",
      email: "",
      phone_number: "",
      company_name: "",
      success_message: "",
      totalFLCount: "14,000",
      activeSlideIndex: 0,
    };
  },

  destroyed() {
    window.removeEventListener("scroll", this.reveal);
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    dateTimeAgo: function (value) {
      if (!value) return "";
      const dateTimeAgo = moment(value).fromNow();
      return dateTimeAgo;
    },

    customizeRoleType: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("-");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },

    removeUnderscore: function (value) {
      if (!value) return "";
      var i,
        frags = value.split("_");
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
  },

  methods: {
    reveal() {
      var fadein = this.$el.querySelectorAll(".fade-in");
      var fadedown = this.$el.querySelectorAll(".fade-down");
      var fadeleft = this.$el.querySelectorAll(".fade-left");
      var faderight = this.$el.querySelectorAll(".fade-right");
      var windowHeight = window.innerHeight;
      var elementVisible = 120;

      for (var i = 0; i < fadein.length; i++) {
        var elementTop = fadein[i].getBoundingClientRect().top;
        if (elementTop < windowHeight - elementVisible) {
          fadein[i].classList.add("active");
        } else {
          fadein[i].classList.remove("active");
        }
      }
      for (var i = 0; i < fadedown.length; i++) {
        var elementTop = fadedown[i].getBoundingClientRect().top;
        if (elementTop < windowHeight - elementVisible) {
          fadedown[i].classList.add("fade-down", "active");
        } else {
          fadedown[i].classList.remove("active");
        }
      }
      for (var i = 0; i < fadeleft.length; i++) {
        var elementTop = fadeleft[i].getBoundingClientRect().top;
        if (elementTop < windowHeight - elementVisible) {
          fadeleft[i].classList.add("fade-left", "active");
        } else {
          fadeleft[i].classList.remove("active");
        }
      }
      for (var i = 0; i < faderight.length; i++) {
        var elementTop = faderight[i].getBoundingClientRect().top;
        if (elementTop < windowHeight - elementVisible) {
          faderight[i].classList.add("fade-right", "active");
        } else {
          faderight[i].classList.remove("active");
        }
      }
    },

    setActiveSlide(index) {
      if (index >= 0 && index < this.slide1.length) {
        this.activeSlideIndex = index;
      } else if (index >= this.slide1.length && index < this.slide1.length + this.slide2.length) {
        this.activeSlideIndex = index - this.slide1.length;
      }
    },

    radioClickLogin() {
      if (this.user_type == 5) {
        $("#empcontr").hide();
        $("#jobcontr").show();
      } else {
        $("#jobcontr").hide();
        $("#empcontr").show();
      }
      $(".login_forms").fadeOut();
      $(".login_forms").fadeIn(200);
      // $(".login_forms").css({"position":"relative","opacity": 0, "left":"+=100"});
      // $(".login_forms").animate({left:0, opacity:1},500);
    },

    passwordClick(event) {
      event.preventDefault();
      if ($("#show_hide_password_login input").attr("type") == "text") {
        $("#show_hide_password_login input").attr("type", "password");
        $("#show_hide_password_login i").addClass("fa-eye-slash");
        $("#show_hide_password_login i").removeClass("fa-eye");
      } else if ($("#show_hide_password_login input").attr("type") == "password") {
        $("#show_hide_password_login input").attr("type", "text");
        $("#show_hide_password_login i").removeClass("fa-eye-slash");
        $("#show_hide_password_login i").addClass("fa-eye");
      }
    },

    repasswordClick(event) {
      event.preventDefault();
      if ($("#show_hide_repassword_signup input").attr("type") == "text") {
        $("#show_hide_repassword_signup input").attr("type", "password");
        $("#show_hide_repassword_signup i").addClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").removeClass("fa-eye");
      } else if ($("#show_hide_repassword_signup input").attr("type") == "password") {
        $("#show_hide_repassword_signup input").attr("type", "text");
        $("#show_hide_repassword_signup i").removeClass("fa-eye-slash");
        $("#show_hide_repassword_signup i").addClass("fa-eye");
      }
    },

    signup() {
      var err = false;
      $("#reg_button").hide();
      $("#loaderreg").show();

      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);

      if ($("#checkbox:checked").val() != "true" && err == false) {
        err = true;
        this.$swal({
          position: "center",
          icon: "error",
          title: "Please accept terms and condition first.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (err == false) {
        this.$store
          .dispatch(POST_API, {
            data: {
              first_name: this.fname,
              last_name: this.lname,
              email: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              repassword: this.srepassword,
              register_from: "web",
              popup: true,
              checkbox: $("#checkbox:checked").val() == "true" ? true : false,
            },
            api: "/api/signup",
          })
          .then(() => {
            $("#reg_button").show();
            $("#loaderreg").hide();
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
              this.showAlert();
            } else {
              this.slogin();
            }
          });
      } else {
        $("#reg_button").show();
        $("#loaderreg").hide();
      }
    },

    slogin() {
      var err = false;
      $(".required-field").each(function () {
        if ($(this).val() == "" || $(this).val().trim().length === 0) {
          err = true;
          $(this).addClass("error");
          $(this).parent().parent().find(".required_content").addClass("show");
          $(this).parent().parent().find(".error_x_white").addClass("show");
        } else {
          $(this).removeClass("error");
          $(this).parent().parent().find(".required_content").removeClass("show");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        }
      });

      setTimeout(function () {
        $(".second_indiv_step_wrap .error").first().focus();

        $(".error").unbind("keypress");
        $(".error").bind("keypress", function () {
          $(this).removeClass("error");
          $(this).parent().parent().find(".error_x_white").removeClass("show");
        });
      }, 100);
      if (err == false) {
        this.$store
          .dispatch(LOGIN, {
            data: {
              emailormobile: this.semail,
              password: this.spassword,
              user_type: this.user_type,
              role: "user",
              login_on: "web",
              popup: true,
            },
            api: "/api/login",
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showAlert();
              if (this.error_message == "Incorrect Email or Password.") {
                this.$swal({
                  position: "center",
                  icon: "error",
                  // iconColor:'#4c8bf5',
                  title: this.error_message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            } else {
              this.$swal({
                position: "center",
                icon: "success",
                iconColor: "#4c8bf5",
                title: "Register successfully.",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // if(this.user_type == '3'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else{
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }
                // this.is_company_head = this.$store.getters.currentUser.is_company_head;
                // if(this.is_company_head == 'no' && (this.user_type == '3' || this.user_type == '4')){
                //     window.location.replace('/v2/employer/om-supervisor-profile');
                // }else if(this.user_type == '3' && this.is_company_head == 'yes'){
                //     window.location.replace('/v2/employer/employer-profile');
                // }else if(this.user_type == '5'){
                //     window.location.replace('/v2/jobseeker/jobseeker-profile');
                // }else{
                //     window.location.replace('/v2/admin/dashboard');
                // }
                this.is_company_head = this.$store.getters.currentUser.is_company_head;
                this.user_type_id = this.$store.getters.currentUser.user_type_id;
                this.account_verification_status =
                  this.$store.getters.currentUser.account_verification_status;
                this.ft_account_status = this.$store.getters.currentUser.ft_account_status;
                if (
                  this.is_company_head == "no" &&
                  (this.user_type_id == "3" || this.user_type_id == "4")
                ) {
                  if (this.account_verification_status == "completed") {
                    window.location.replace("/v2/employer/dp-job-manage");
                  } else if (this.ft_account_status == "completed") {
                    window.location.replace("/v2/employer/manage-jobs");
                  } else {
                    window.location.replace("/v2/employer/om-supervisor-profile");
                  }
                  // window.location.replace('/v2/employer/om-supervisor-profile');
                } else if (this.user_type_id == "3" && this.is_company_head == "yes") {
                  if (this.account_verification_status == "completed") {
                    window.location.replace("/v2/employer/dp-job-manage");
                  } else if (this.ft_account_status == "completed") {
                    window.location.replace("/v2/employer/manage-jobs");
                  } else {
                    window.location.replace("/v2/employer/employer-profile");
                  }
                  // window.location.replace('/v2/employer/employer-profile');
                } else if (this.user_type_id == "5") {
                  window.location.replace("/v2/jobseeker/jobseeker-profile");
                } else {
                  window.location.replace("/v2/admin/dashboard");
                }
              });
            }
          });
      }
    },

    clickForgotPassword(event) {
      if (event) {
        event.stopPropagation();
      }
      var userTypeId = {
        user_type_id: this.user_type,
      };
      localStorage.setItem("userTypeId", JSON.stringify(userTypeId));
      this.$refs["bv-modal-example"].hide();
      this.$router.push({ name: "forgotpassword" });
    },

    getWebsiteData() {
      return this.$store
        .dispatch(POST_API, {
          data: { keyword: this.filter },
          api: "/api/website-data",
        })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            return [];
          } else {
            this.items = this.$store.getters.getResults.partners;
            this.testimonial = this.$store.getters.getResults.testimonial_jobseeker;
            this.background = this.$store.getters.getResults.background;
            this.banner = this.$store.getters.getResults.banner;
            this.slide1 = this.$store.getters.getResults.slide1;
            this.slide2 = this.$store.getters.getResults.slide2;
            this.slide3 = this.$store.getters.getResults.slide3;
            this.video = this.$store.getters.getResults.video;
            this.content = this.$store.getters.getResults.content;
            this.images = this.$store.getters.getResults.image;
            this.fl_page_dp_ft_jobs_count = this.$store.getters.getResults.fl_page_dp_ft_jobs_count;
            this.fl_page_companies_count = this.$store.getters.getResults.fl_page_companies_count;
          }
        });
    },

    openLink() {
      window.open("https://elconnectptuser.app.link/OSbf0HwLOib", "_blank");
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs;
      Swal.fire({
        position: "top-center",
        type: "error",
        text: this.error_message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },

  mounted() {
    $(".slider-new").slick({
      dots: true,
      fade: true,
      pauseOnHover: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 900,
      slidesToShow: 1,
    });
    window.addEventListener("scroll", this.reveal);

    $(".login_forms").show();
    window.scrollTo(0, 0);
    this.getWebsiteData();
    $("#slideshow > div:gt(0)").hide();

    // Function for changing to the next slide
    let nextSlide = (count, delay) => {
      setTimeout(() => {
        $("#slideshow > div:nth-of-type(" + count + ")")
          .hide()
          .removeClass("slide_dynamic_class"); // Hide the current slide
        count !== $("#slideshow > div").length ? count++ : (count = 1);
        $("#slideshow > div:nth-of-type(" + count + ")")
          .show()
          .addClass("slide_dynamic_class"); // Show the next slide
        $(".slide_dynamic_class").css({ position: "relative", opacity: 0, left: "+=10000" });
        $(".slide_dynamic_class").animate({ left: 0, opacity: 1 });

        delay = $("#slideshow > div:nth-of-type(" + count + ")").data("delay"); // Set the delay to the value of the data-delay attribute on the slide elements

        nextSlide(count, delay); // Call this function recursively
      }, delay);
    };

    nextSlide(1, $("#slideshow > div:first").data("delay"));

    //Mobile Slide Start

    $("#slideshow-mobile > div:gt(0)").hide();

    // Function for changing to the next slide
    let nextSlideMobile = (count, delay) => {
      // Set the delay on the slide
      setTimeout(() => {
        // Hide the current slide
        $("#slideshow-mobile > div:nth-of-type(" + count + ")")
          .hide()
          .removeClass("slide_dynamic_class");
        // If we've reached the end of the slides, set count back to 1,
        // otherwise, increment by 1
        count !== $("#slideshow-mobile > div").length ? count++ : (count = 1);
        // Show the next slide
        $("#slideshow-mobile > div:nth-of-type(" + count + ")")
          .show()
          .addClass("slide_dynamic_class");
        $(".slide_dynamic_class").css({ position: "relative", opacity: 0, left: "+=10000" });
        $(".slide_dynamic_class").animate({ left: 0, opacity: 1 });
        // Set the delay to the value of the data-delay attribute on the
        // slide elements
        delay = $("#slideshow-mobile > div:nth-of-type(" + count + ")").data("delay");
        // Call this function recursively
        nextSlideMobile(count, delay);
      }, delay);
    };

    nextSlideMobile(1, $("#slideshow-mobile > div:first").data("delay"));
  },

  components: {
    VueSlickCarousel,
    Carousel,
    Slide,
  },
};
</script>
